// import { toast } from 'react-toastify'
import { makeAuthenticatedServerRequest } from '../utils/helpers'

async function SubmitQuiz (quizSubmitData, studentAnswer, time, quizRef) {
  // var data = new FormData();
  // data.append("quizResponse", JSON.stringify(quizSubmitData));
  // data.append("quizId", quizRef._id);
  // data.append("topic_name", quizRef.topic_name);
  // data.append("subject", quizRef.subject);
  // data.append("status", "completed");
  // data.append("timeTaken", JSON.stringify(time));

  const data = {
    quizResponse: studentAnswer,
    quizId: quizRef._id,
    topic_name: quizRef.topic_name,
    subject: quizRef.subject,
    status: 'completed',
    timeTaken: time
  }

  // var config = {
  //   method: "post",
  //   url: `${API_BASE_URL}/quiz/savequizresponse`,
  //   headers: {
  //     Authorization: `Bearer ${accessToken}`,
  //     "Content-Type": "multipart/form-data",
  //   },
  //   data: data,
  // };
  try {
    const response = await makeAuthenticatedServerRequest('/quiz/savequizresponse', 'POST', data)
    if (response.status === 200) {
      return true
    } else {
      return false
    }
  } catch (error) {
    alert('Error in internet connection')
    console.log(error)
    return false
  }
}

export default SubmitQuiz
