import React, { useRef, useState } from 'react'
import ImageTag from '../../atoms/ImageTag'
import downloadIcon from '../../../assets/images/Icons/download-quiz-icon.svg'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  makeServerGETRequest
} from '../../../utils/helpers'
import ReactLoading from 'react-loading'
import { useOutsideAlerter } from '../../../utils/componentHelpers'
import TooltipLeft from '../Others/TooltipLeft'

export default function DownloadDropdown ({ downloadLink, saveQuiz }) {
  const authState = useSelector((state) => state.authReducer)
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const downloadIconRef = useRef(null)
  useOutsideAlerter(downloadIconRef, () => setShow(false))

  const createDownloadLink = async () => {
    const saveresult = await saveQuiz()
    if (saveresult === false) {
      return false
    }
    const res = await makeServerGETRequest(
      '/downloadQuiz/questionDownload',
      {
        quizId: searchParams.get('quizId')
      }
    )
    return res.data
  }

  const makeDownloadLinkXml = async () => {
    const saveresult = await saveQuiz()
    if (saveresult === false) {
      return false
    }
    const res = await makeServerGETRequest(
      '/downloadQuiz/xmlDownload',
      {
        quizId: searchParams.get('quizId')
      }
    )
    return res.data
  }

  const clickHandlerRubric = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (authState.isLoggedIn) {
      setShow(false)
    }
    const link = await createDownloadLink()
    if (link === false) {
      setLoading(false)
      return false
    }

    window.location.href = link.QuestionAnswer
    setLoading(false)
    // if (authState.isLoggedIn) {
    //   setShow(true)
    // }
  }

  const clickHandlerQuestion = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (authState.isLoggedIn) {
      setShow(false)
    }
    const link = await createDownloadLink()
    if (link === false) {
      setLoading(false)
      return false
    }

    window.location.href = link.QuestionOnly
    setLoading(false)
  }

  const clickHandlerXml = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (authState.isLoggedIn) {
      setShow(false)
    }
    const link = await makeDownloadLinkXml()
    if (link === false) {
      setLoading(false)
      return false
    }
    window.location.href = link.QuestionAnswer
    setLoading(false)
  }
  return (
      <div ref={downloadIconRef} className="relative text-left">
        {loading
          ? (
          <ReactLoading type="spin" color="#916AF8" height={40} width={35} />
            )
          : (<ImageTag src={downloadIcon}
          className='joyride_download_quiz w-5 cursor-pointer'
          onClick={() => {
            if (authState.isLoggedIn) {
              setShow(!show)
            } else {
              const saveQuizMessage = 'Please login to download quiz.'
              if (window.confirm(saveQuizMessage) === true) {
                navigate('/Auth')
              }
            }
          }}
        />)}

      {
        show && (
          <div className="origin-top-right absolute bottom-8 md:bottom-auto md:top-6 -right-3 md:right-0 mt-2 w-fit rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
            <TooltipLeft
                    content={
                      <a href={downloadLink.QuestionOnly} onClick={clickHandlerQuestion} target='_blank' className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-0" rel="noopener noreferrer">Quiz</a>

                    }
                    message={'Download only question'}
                />
            <TooltipLeft
                    content={
                      <a href={downloadLink.QuestionAnswer} onClick={clickHandlerRubric} target='_blank' className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-1" rel="noopener noreferrer">Rubric</a>

                    }
                    message={'Download question with answer.'}
                />
            <TooltipLeft
                    content={
                      <a href={downloadLink.QuestionOnly} onClick={clickHandlerXml} target='_blank' className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-2" rel="noopener noreferrer">XML</a>

                    }
                    message={'Download XML for upload to elearn.dkraftlearning.com'}
                />

            </div>
        )
      }
    </div>
  )
}
