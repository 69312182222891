import React from 'react'
import ImageTag from '../../atoms/ImageTag'
import ProfileIcon from '../ProfileComponent'
import HomeIcon from '../../../assets/images/Icons/HomeIcon.svg'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import HeadingSm from '../../atoms/Text/HeadingSm'

export default function PublishedQuizNavbar ({
  quizName
}) {
  const navigate = useNavigate()
  const userDetails = useSelector((state) => state.authReducer)

  return (
    <div className="border-b fixed top-0 left-0 w-full bg-white z-20 h-[119px] md:h-[52px]">
      <div className="flex flex-row flex-wrap justify-between items-center px-4 md:px-10 h-full">
        <div className="w-max">
          <ImageTag
            src={HomeIcon}
            className=" m-auto cursor-pointer hover:opacity-80"
            onClick={() => navigate(userDetails.isLoggedIn ? '/Dashboard' : '/')}
          />
        </div>
        <div className="w-full md:w-fit flex items-center ">
          <HeadingSm
            className={
              'text-app-dark-black font-medium bg-slate-200 placeholder:font-normal border-none outline-none text-xl w-full rounded-md px-2 text-left'
            }
            text={quizName}
          />
        </div>
        <div className='flex'><ProfileIcon /></div>
      </div>
    </div>
  )
}
