import React, { useEffect } from 'react'

const QuizTimerImproved = (props) => {
  const time = props.time
  const setTime = props.setTime

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (time.seconds > 0) {
        setTime((prevTime) => ({ ...prevTime, seconds: prevTime.seconds - 1 }))
      } else if (time.minutes > 0) {
        setTime((prevTime) => ({ ...prevTime, minutes: prevTime.minutes - 1, seconds: 59 }))
      } else if (time.hours > 0) {
        setTime({ hours: time.hours - 1, minutes: 59, seconds: 59 })
      } else {
        clearInterval(myInterval)
        props.onTimeUp()
      }
    }, 1000)

    return () => clearInterval(myInterval)
  }, [time])

  return <></>
}

// const QuizTimerImproved = (props) => {
//   const timerIntervalRef = useRef(null)
//   const time = props.time
//   const setTime = props.setTime

//   useEffect(() => {
//     timerIntervalRef.current = setInterval(() => {
//       if (time.seconds > 0) {
//         setTime((prevTime) => ({ ...prevTime, seconds: prevTime.seconds - 1 }))
//       } else if (time.minutes > 0) {
//         setTime((prevTime) => ({ ...prevTime, minutes: prevTime.minutes - 1, seconds: 59 }))
//       } else if (time.hours > 0) {
//         setTime({ hours: time.hours - 1, minutes: 59, seconds: 59 })
//       } else {
//         clearInterval(timerIntervalRef.current)
//         props.onTimeUp()
//       }
//     }, 1000)

//     return () => clearInterval(timerIntervalRef.current)
//   }, [time, setTime])

//   return <></>
// }

export default QuizTimerImproved
