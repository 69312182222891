import React, { useEffect, useState } from 'react'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
import GeneralTextHighlight from '../../_atomic-design/atoms/Text/GeneralTextHighlight'

import GeneralTextInput from '../../_atomic-design/molecules/Input/GeneralTextInput'
import Textinput from '../../_atomic-design/molecules/Input/TextInputGray'
// import GeneralHeading from '../../_atomic-design/atoms/Text/GeneralHeading'
// import ButtonPrimaryXs from '../../_atomic-design/atoms/Button/ButtonPrimaryXs'
import inputMarksValidation from '../../helpers/InputMarksValidation'
import { toast } from 'react-toastify'
// import { toast } from 'react-toastify'

export default function ResponseBlockSubjective ({ item, questionID, index, questionResponse, updateMarksHandler, instructorResponse, setInstructorResponse, readOnly, mlEvaluation, instructorEvaluation }) {
  const [icomment, setIcomment] = useState((instructorResponse[index] && instructorResponse[index].instructorComments) ? instructorResponse[index].instructorComments : '')

  useEffect(() => {
    const newScoreArray = [...instructorResponse]
    newScoreArray[index] = { ...instructorResponse[index], instructorComments: icomment }
    setInstructorResponse(newScoreArray)
  }, [icomment])

  const regex = /(<([^>]+)>)/ig
  questionResponse = questionResponse || { markSecured: 0, submittedAnswers: 'null', n_grammatical_errors: 0, keyphrase_highlights: [] }
  function marksChangeHandler (e) {
    e.preventDefault()
    const isValidMarks = inputMarksValidation(+e.target.value, 0, item.score)
    if (!isValidMarks) {
      toast('invalid marks')
      e.target.value = null
      return
    }
    const newScoreArray = [...instructorResponse]
    newScoreArray[index] = { questionID: questionID, markSecured: +e.target.value }
    setInstructorResponse(newScoreArray)
  }

  function commentsChangeHandler (e) {
    e.preventDefault()
    setIcomment(e.target.value)
  }

  const showQzkraftScore = !readOnly ? true : mlEvaluation && !instructorEvaluation
  /* this function needs to rewritten , it overwrites the newscore[index] with completely new value
  insted of using spread operator */
  function approve () {
    const newScoreArray = [...instructorResponse]
    newScoreArray[index] = { questionID: questionID, markSecured: questionResponse.markSecured, instructorComments: icomment }
    setInstructorResponse(newScoreArray)
  }
  let instResponse = (instructorResponse[index] && ((instructorResponse[index].markSecured !== undefined) && (instructorResponse[index].markSecured !== '') && (instructorResponse[index].markSecured !== null))) ? instructorResponse[index].markSecured : ''
  instResponse = (readOnly && instResponse === '') ? 0 : instResponse
  // const insComment = (instructorResponse[index] && instructorResponse[index].instructorComments) ? instructorResponse[index].instructorComments : ''
  return (<div className='p-4 sm:p-6 bg-[#EDEDED] my-6 rounded-lg'>
    <div className='rounded-lg p-4 sm:p-10 sm:pb-1 relative bg-white mx-auto '>
        <GeneralText text={`Question no.: ${index + 1}`} className='font-bold bg-gradient-to-r  from-[#3C38CD] to-[#CE8CDD] p-1 px-4 h-max w-max absolute -left-8 rounded-md text-white mr-2' />
        <GeneralText text={`Full Marks: ${item.score}`} className='font-bold bg-app-secondary p-1 px-4 h-max w-max absolute right-4 sm:right-10 top-4 rounded-md  text-white' />
        <GeneralText text={item.questionContent ? item.questionContent.replace(regex, '') : ''} className={'relative sm:left-24 top-8 sm:top-2 sm:w-4/5'}/>
      <div className='flex flex-col my-6 text-gray-700'>
        <div className='flex flex-col sm:flex-row my-2'>
          <GeneralText text={'Student\'s answer : '} className='font-bold text-app-primary w-40 whitespace-nowrap' />
          <GeneralTextHighlight className={'w-[90%]'} paragraph={questionResponse.submittedAnswers ? questionResponse.submittedAnswers.replace(regex, '') : ''} highlights= {questionResponse.keyphrase_highlights} />
        </div>
        <hr />
        <div className='flex flex-col sm:flex-row my-2'>
          <GeneralText text={'Correct answer : '} className='font-bold text-green-500 w-40 whitespace-nowrap' />
          <GeneralText className={'w-[90%]'} text={item.generatedAnswer[0] ? item.generatedAnswer[0].replace(regex, '') : ''} />
        </div>
      </div>
    </div>
    <div className='flex flex-col my-auto relative p-2 sm:p-10 sm:py-4 rounded-lg justify-between  bg-[#E4E4F3] font-bold'>
      <Textinput className={' w-full text-[#6B6B6B] relative mx-auto my-4 sm:my-0 sm:mx-0 sm:mb-0'} disabled={readOnly} label={'Comments :'} placeholder={'Instructor comments'} value={icomment} onChange={commentsChangeHandler} />
      <div className='flex flex-wrap mt-4 gap-2'>
        <div className=' text-app-primary flex flex-row my-auto w-max h-max p-2 px-4 relative whitespace-nowrap rounded-3xl text-center bg-white'>Grammatical Errors : {questionResponse.n_grammatical_errors || 0}</div>
        {showQzkraftScore && <div className='flex flex-row my-auto w-max h-max pl-4 relative whitespace-nowrap rounded-3xl text-center bg-white text-app-secondary'>
              <div className='my-auto flex flex-row py-2'>
                <div className='my-auto'>learnkraft.ai score : </div>
                <GeneralTextInput className=' my-auto w-[45px] sm:w-[60px] rounded-lg px-2 read-only:true ' value={questionResponse.markSecured.toFixed(2)} disabled />
              </div>
            </div>}
            <div className='flex flex-row my-auto w-max h-max pl-4 relative whitespace-nowrap rounded-3xl text-center bg-white text-app-secondary'>
              <div className='my-auto flex flex-row py-2'>
              {readOnly
                ? <div className='my-auto'>Score :</div>
                : <div className='my-auto'>Instructor Score :</div>}
                <div className='ml-1'><GeneralTextInput disabled={readOnly} className={(instResponse !== '') ? 'w-[60px] rounded-lg px-1 bg-app-secondary text-white ' : ' bg-app-red rounded-lg w-[60px]'} type='Number' min="0" step="0.1"
                max={item.score} value={instResponse} onChange={marksChangeHandler} /></div>
              </div>
              {/* {!readOnly && <button onClick={approve} className='flex flex-row ml-2 my-auto w-max h-max p-1 text-xs rounded-3xl bg-gradient-to-r  from-[#3C38CD] to-[#CE8CDD] text-white '><div className='my-auto mx-2 whitespace-nowrap'>Same as learnkraft.ai</div><img src={plusTopic} alt='+'></img></button>} */}
              {!readOnly && <button onClick={approve} className='flex flex-row ml-2 my-auto w-max h-max p-2 text-xs rounded-3xl bg-gradient-to-r  from-[#3C38CD] to-[#CE8CDD] text-white '><input type='checkbox' checked={instResponse === questionResponse.markSecured} readOnly/><div className='my-auto mx-2 whitespace-nowrap'>Same as learnkraft.ai</div></button>}
            </div>
      </div>
          {/* <div className='my-auto '><ButtonSecondarySm text={'update'} onClick={updateMarksHandler} /></div> */}
        </div>
    </div>
  )
}

// export default function ResponseBlockSubjective ({ item, questionID, index, questionResponse, updateMarksHandler, instructorResponse, setInstructorResponse, readOnly }) {
//   const regex = /(<([^>]+)>)/ig
//   questionResponse = questionResponse || { markSecured: 0, submittedAnswers: 'null', n_grammatical_errors: 0, keyphrase_highlights: [] }
//   function marksChangeHandler (e) {
//     e.preventDefault()
//     const isValidMarks = inputMarksValidation(+e.target.value, 0, item.score)
//     if (!isValidMarks) {
//       toast('invalid marks')
//       e.target.value = null
//       return
//     }
//     const newScoreArray = [...instructorResponse]
//     newScoreArray[index] = { questionID: questionID, markSecured: +e.target.value }
//     setInstructorResponse(newScoreArray)
//   }
//   function commentsChangeHandler (e) {
//     e.preventDefault()
//     const newScoreArray = [...instructorResponse]
//     newScoreArray[index] = { ...instructorResponse[index], instructorComments: e.target.value }
//     setInstructorResponse(newScoreArray)
//   }

//   function approve () {
//     const newScoreArray = [...instructorResponse]
//     newScoreArray[index] = { questionID: questionID, markSecured: questionResponse.markSecured }
//     setInstructorResponse(newScoreArray)
//   }
//   let instResponse = (instructorResponse[index] && ((instructorResponse[index].markSecured !== undefined) && (instructorResponse[index].markSecured !== '') && (instructorResponse[index].markSecured !== null))) ? instructorResponse[index].markSecured : ''
//   instResponse = (readOnly && instResponse === '') ? 0 : instResponse
//   const insComment = (instructorResponse[index] && instructorResponse[index].instructorComments) ? instructorResponse[index].instructorComments : ''
//   return (<div className='p-10 bg-[#EDEDED] my-10'>
//     <div className='rounded-lg  p-10 relative bg-white mx-auto my-10'>
//       <div className='absolute -left-8 flex flex-row items-end'>
//         <GeneralText text={`Question no.: ${index + 1}`} className='font-bold bg-app-secondary p-1 px-4 rounded-2xl  text-white mr-2' />
//         <GeneralText text={item.questionContent ? item.questionContent.replace(regex, '') : ''} />
//       </div>
//       <div className='flex flex-row justify-between'>
//         <div className='flex flex-row'>
//         <GeneralText text={index + 1} className='ml-2' />
//         </div>
//         <div className='flex border rounded-md px-2 py-1 bg-green-200  '>Full Marks : {item.score}</div>
//       </div>
//       {/* <GeneralHeading text={'Question'} className='font-bold' />
//       <GeneralText text={item.questionContent ? item.questionContent.replace(regex, '') : ''} /> */}
//       <div className='flex flex-row justify-between'>
//         <div className=''>
//         <GeneralText text={'Student\'s answer '} className='font-bold pt-2 text-red-500' />
//         </div>
//         <div className='flex border rounded-md px-2 py-1 bg-red-200  '>Gramatical Errors : {questionResponse.n_grammatical_errors}</div>
//       </div>
//       <GeneralTextHighlight className={'my-1 p-2 border rounded-lg shadow-md bg-red-100'} paragraph={questionResponse.submittedAnswers ? questionResponse.submittedAnswers.replace(regex, '') : ''} highlights= {questionResponse.keyphrase_highlights} />
//       {/* <GeneralText className={'my-1 p-2 border rounded-lg h-[100px] shadow-md'} text={questionResponse.submittedAnswers} /> */}
//       <hr />
//       <GeneralText text={'Correct answer '} className='font-bold pt-2 text-green-500' />
//       <GeneralText className={'my-1 p-2 border rounded-lg shadow-md bg-green-100'} text={item.generatedAnswer[0] ? item.generatedAnswer[0].replace(regex, '') : ''} />
//       <div>
//         <hr />
//         <div className='flex flex-row my-2'>
//       <div className=''>learnkraft.ai Score : <GeneralTextInput className='border rounded-lg p-1 w-[80px] ml-1 read-only:true ' value={questionResponse.markSecured.toFixed(2)} disabled /></div>
//           {!readOnly && <ButtonPrimaryXs className={' mx-4 '} text={'Copy'} onClick={approve} />}
//           <div className='ml-2'>Instructor Score : <GeneralTextInput disabled={readOnly} className={(instResponse !== '') ? 'border rounded-lg p-1 w-[80px] ml-1' : ' bg-red-500 border rounded-lg p-1 w-[80px] ml-1'} type='Number' min="0"
//         max={item.score} value={instResponse} onChange={marksChangeHandler} /></div>
//         </div>
//         <div className='flex flex-row justify-between'>
//           <Textinput disabled={readOnly} label={'comments :'} placeholder={'Instructor comments'} value={insComment} onChange={commentsChangeHandler} />
//           {/* <div className='my-auto '><ButtonSecondarySm text={'update'} onClick={updateMarksHandler} /></div> */}
//         </div>
//       </div>
//     </div>
//     </div>
//   )
// }
