import React from 'react'
function ModeSelector ({ modes, currentMode, changeMode }) {
  return (
    <div className="flex justify-center rounded-xl bg-white mb-8 mx-auto w-fit ">
      {modes.map((mode) => {
        return (
          <Mode
            details={mode}
            key={mode.id}
            active={mode.value === currentMode}
            changeMode={changeMode}
          />
        )
      })}
    </div>
  )
}

function Mode ({ details, active, changeMode, bgColor }) {
  return (
        <div
            className={`hidden text-center text-md py-2 cursor-pointer rounded-xl px-10  ${
                active
                    ? 'bg-app-primary text-white border-solid'
                    : 'bg-white text-black '
            }`}
            onClick={() => {
              changeMode(details.value)
            }}
        >
            {details.text}
        </div>
  )
}
export default ModeSelector
