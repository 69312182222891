import React from 'react'
import { HashLink } from 'react-router-hash-link'
import CreateQuizIcon from '../../../assets/images/Icons/CreateQuizIcon.svg'
// import CrossWhite from '../../../assets/images/Icons/CrossWhite.svg'
import ImageTag from '../../atoms/ImageTag'

export default function CreateQuizButton () {
  return (
    <div className='fixed sm:bottom-20 bottom-12 left-[50%] -translate-x-[50%] z-50 rounded-full bg-gradient-to-r from-[#3C38CD] to-[#CE8CDD] h-8 sm:h-12 w-fit px-4 flex flex-row justify-evenly items-center'>
        <HashLink to={'/#'} smooth className='flex sm:text-xl text-white font-semibold'> <ImageTag src={CreateQuizIcon} className="w-4 sm:w-5 mr-2" /> {'Create Quiz Now'}</HashLink>
        {/* <button>
            <ImageTag src={CrossWhite} className=' w-2 sm:w-4' />
        </button> */}
    </div>
  )
}
