import React from 'react'

export default function TooltipLeft ({ content, message, className }) {
  return (
        <div className={`relative group cursor-pointer ${className}`}>
            {content}
            <div
                className="absolute -top-4 -left-0 -translate-x-full -translate-y-1/2 w-64 shadow-lg cursor-pointer scale-0 group-hover:scale-100 opacity-0 transition-opacity duration-300 group-hover:opacity-100 bg-white border rounded-md p-3 border-gray-200 z-50"
            >
                <p className="text-sm text-gray-800">{message}</p>
            </div>
        </div>
  )
}
