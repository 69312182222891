import React, { useState, useEffect } from 'react'
import HeadingLarge from '../../_atomic-design/atoms/Text/HeadingLg'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'
import ButtonPrimaryLg from '../../_atomic-design/atoms/Button/ButtonPrimaryLg'
import { makeServerRequest } from '../../utils/helpers'
import TextInputGray from '../../_atomic-design/molecules/Input/TextInputGray'
import MainBackgroundSrc from '../../assets/images/MainBackground.svg'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'

export default function ResetPassword () {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const [password, setPassword] = useState('')
  const [confirmpassword, setConfirmPassword] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [errormsg, setErrormsg] = useState('')

  const navigate = useNavigate()

  const handlePassword = (e) => {
    setPassword(e.target.value)
  }
  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value)
  }

  const handleChangePassword = async () => {
    setDisabled(true)
    if (password === '' || password !== confirmpassword) {
      setErrormsg('Passwords do not match')
      setDisabled(false)
      return
    }

    if ((password.length < 8) || (password.length > 100)) {
      setErrormsg('Password Must be 8 characters at least and maximum 100 characters')
      setDisabled(false)
      return
    }
    const resData = await makeServerRequest(
      '/auth/ResetPassword', 'POST', { token, password }
    )
    if (resData.status === 200) {
      toast('Password changed successfully! Please login.')
      navigate('/Auth?mode=login', { replace: true })
      setErrormsg('')
    } else {
      setErrormsg('problem changing the password. Please try again later, or contact us at contact@dkraftlearning.com.')
    }
    setDisabled(false)
  }

  const bgStyle = {
    backgroundImage: `url(${MainBackgroundSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    boxShadow: '0 0 27px rgb(60, 56, 205, 0.15)'

  }
  useEffect(() => {
    document.title = 'ResetPassword'
  })

  return (
    <div className='w-full min-h-screen h-full flex justify-center items-center text-center' style={bgStyle}>
      <div className="w-min rounded-[20px] bg-white py-7 sm:py-10 px-6 sm:px-24 mx-3 flex flex-col items-center">
        <HeadingLarge
          className="text-4xl text-center m-2 mt-8"
          text="Reset Password"
        />
        <TextBody
          text="Type and confirm a secure new password for your account"
          className="m-3"
        />
        {
                errormsg !== '' &&
            <GeneralText
              className={'text-red-700 text-sm'}
              text={errormsg}
            />
            }
        <TextInputGray
            className="block w-full mt-4"
            type={'password'}
            placeholder="New Password"
            on
            onChange={handlePassword}
          />
          <TextInputGray
            className="block w-full my-4"
            type={'password'}
            placeholder="Confirm Password"
            on
            onChange={handleConfirmPassword}
          />
        <ButtonPrimaryLg
          text={'Reset Password'}
          color={'light-blue'}
          onClick={handleChangePassword}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
