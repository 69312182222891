import React, { useState, useEffect } from 'react'
import EvaluationForm from '../../containers/Evaluation/EvaluationForm'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { makeAuthenticatedServerGETRequest } from '../../utils/helpers'
import HeadingMd from '../../_atomic-design/atoms/Text/HeadingMd'
// import DashboardNavbar from '../../_atomic-design/molecules/Navbars/DashboardNavbar'
// import HomeNavbar from '../../_atomic-design/molecules/Navbars/HomeNavbar'
// import StudentDashboardNavbar from '../../_atomic-design/molecules/Navbars/StudentDashboardNavbar'
import { toast } from 'react-toastify'
import StudentDashboardNavbar from '../../_atomic-design/molecules/Navbars/StudentDashboardNavbar'
import userWhite from '../../assets/images/Icons/user_white.svg'
import emailWhite from '../../assets/images/Icons/emailWhite.svg'
import calendarWhite from '../../assets/images/Icons/calendarWhite.svg'
import CreateQuizIcon from '../../assets/images/Icons/CreateQuizIcon.svg'
import totalMarksWhite from '../../assets/images/Icons/totalMarksWhite.svg'
import ImageTag from '../../_atomic-design/atoms/ImageTag'

export default function Result () {
  const [searchParams] = useSearchParams()
  const authState = useSelector((state) => state.authReducer)
  const [quizId, setQuizId] = useState(null)
  const [studentId, setStudentId] = useState(null)
  const [atemptData, setAtemptData] = useState(null)
  const [questions, setQuestions] = useState(null)
  const [studentDetails, setStudentDetails] = useState(null)
  const [questionResponse, setQuestionResponse] = useState(null)
  const [instructorResponse, setInstructorResponse] = useState([])
  const [mlEvaluation, setMlEvaluation] = useState(false)
  const [instructorEvaluation, setInstructorEvaluation] = useState(false)
  // const marksObtained = instructorResponse.reduce((partialSum, a) => partialSum + (a.markSecured ? +a.markSecured : 0), 0)
  const marksObtained = instructorResponse.reduce((partialSum, a) => partialSum + ((a && a.markSecured && (!Number.isNaN(a.markSecured))) ? +a.markSecured : 0), 0)

  const navigate = useNavigate()

  useEffect(() => {
    const getQuizId = searchParams.get('quizId')
    if (searchParams.get('quizId')) {
      setQuizId(getQuizId)
    } else {
      setQuizId(null)
    }
    const studentId = searchParams.get('studentId')
    if (searchParams.get('studentId')) {
      setStudentId(studentId)
    } else {
      setStudentId(null)
    }
  })

  useEffect(async () => {
    if (quizId && studentId) {
      if (authState.isLoggedIn) {
        const quizatemptData = await makeAuthenticatedServerGETRequest('/quiz/quizatemptById/', {
          quizId: quizId,
          studentId: studentId
        })
        if (quizatemptData.status === 200) {
          quizatemptData.data.instructorResponse ? setInstructorResponse(quizatemptData.data.instructorResponse) : setInstructorResponse([])
          setQuestionResponse(quizatemptData.data.questionResponse)
          setStudentDetails(quizatemptData.data.attemptedById)
          setQuestions(quizatemptData.data.refQuiz.questions)
          setAtemptData(quizatemptData.data)
          setInstructorEvaluation(quizatemptData.data.instructorEvaluation)
          setMlEvaluation(quizatemptData.data.refQuiz.mlEvaluation)
        } else {
          toast('not attempted yet')
          navigate('/studentDashboard')
        }
      } else {
        toast('login first')
      }
    }
  }, [quizId])

  return <div className='bg-gray-100 pt-20 sm:pt-8'>
    <StudentDashboardNavbar selected='ContactUs' white />
    {atemptData && <div>
      <div className='flex flex-col rounded-lg shadow-lg w-[90%] sm:w-1/5 h-max bg-app-secondary mx-auto font-bold text-white sm:fixed sm:ml-4 mt-10 sm:mt-14 z-30'>
      <HeadingMd text={(atemptData.refQuiz.name || 'Untitled Quiz') + ' Result'} className={'flex mx-auto w-full bg-app-primary p-4 rounded-t-lg break-all'} />
      <div className='flex flex-col px-4 text-xs sm:text-base'>
        <div className='flex flex-col rounded-lg py-4 gap-2 sm:gap-4'>
          <DisplayTagCustom src={userWhite} keyText={'Student\'s name : '} valueText={studentDetails.firstName + ' ' + studentDetails.lastName} text={'Student\'s name : ' + studentDetails.firstName + ' ' + studentDetails.lastName}/>
          <DisplayTagCustom src={emailWhite} keyText={'Student\'s email : '} valueText={atemptData.attemptedById.email} className={' break-all '}/>
          {studentDetails.description && <DisplayTagCustom keyText={'Description : '} valueText={studentDetails.description} />}
        </div>
        <hr />
        <div className='flex flex-col rounded-lg py-4 gap-2 sm:gap-4'>
            <DisplayTagCustom src={calendarWhite} keyText={'Attempt Date : '} valueText={new Date(atemptData.updatedAt).toLocaleString()} />
            <DisplayTagCustom src={CreateQuizIcon} keyText={'Marks Obtained : '} valueText={`${marksObtained && marksObtained.toFixed(2)}`} />
            <DisplayTagCustom src={totalMarksWhite} keyText={'Total Marks : '} valueText={atemptData.totalMarks.toFixed(2)} />
        </div>
        </div>
      </div>
      <div className=' relative w-full sm:w-4/5 ml-auto mt-10'><EvaluationForm readOnly={true} questions={questions} questionResponse={questionResponse} quizId={quizId} instructorResponse={instructorResponse} setInstructorResponse={setInstructorResponse} studentId={studentDetails._id} atemptData={atemptData} mlEvaluation={mlEvaluation} instructorEvaluation={instructorEvaluation}/>
      </div>
      </div>}
  </div>
}

function DisplayTagCustom ({ text, keyText, valueText, src, className }) {
  return (
        <div
            className={'flex justify-center items-center w-fit py-1 text-white'}
        >
            {src ? <ImageTag src={src} className="w-6 h-6 mr-2" /> : null}
            <div>
              <p>{keyText}</p>
              <p className={className}>{valueText}</p>
            </div>
        </div>
  )
}
