import React from 'react'
import cross from '../../assets/images/Icons/cross.svg'
import { useSelector } from 'react-redux'
// import Timer from '../../components/Timer'
// import QuizTimer from '../../components/QuizTimer'
import HeadingSm from '../../_atomic-design/atoms/Text/HeadingSm'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import QuizTimerUI from '../../components/QuizTimerUI'

export default function QuestionPalleteVisible ({
  currentQuestion,
  response,
  markedForReview,
  quizSubmitButtonHandler,
  hideQuestionPallete,
  questionNumberClickHandler,
  onTimeUp,
  time,
  setTime,
  isSidePanelActive
}) {
  // convert response object to array of integers.
  const answeredQuestions = Object.keys(response).map(value => parseInt(value))
  // answeredQuestions => now contains array of answered question indices as integers.

  const quizInReduxStore = useSelector((state) => state.quizStudentReducer.quizRef)

  function clickHandler (e) {
    e.preventDefault()
    questionNumberClickHandler(e.target.value)
  }

  return <div className="flex justify-between sm:justify-around fixed top-0 right-0 sm:w-max w-[90vw] p-4 sm:relative flex-col  bg-white h-[90vh] z-10 mb-24">
        <button className=" absolute top-2  right-2 rounded-full sm:hidden" onClick={hideQuestionPallete}><img src={cross} width="50px" alt="cross" className='border-4 rounded-full bg-white'/></button>
        {/* <div className="flex m-auto items-center ">
            <Timer onTimeUp={onTimeUp} time={time} setTime={setTime} />
    </div> */}
    <div className=" mx-auto hidden sm:flex">
            {/* <QuizTimer onTimeUp={onTimeUp} time={time} setTime={setTime} /> */}
            <QuizTimerUI time={time} />
    </div>
    <div className='flex flex-col rounded-xl bg-white border-red-500 shadow-4sides max-w-sm'>
      <div className='bg-[#E8F2FF] p-5 rounded-t-xl'>
        <HeadingSm text={'Summary'} className={'mx-auto w-max '}/>
      </div>
      <div className='p-5'>
        <div className="grid w-max m-auto grid-cols-5 gap-x-3 sm:gap-x-5 gap-y-5 mx-auto">
            {quizInReduxStore.questions.map((item, index) => {
              return <button key={index} onClick={clickHandler}
                    value={index}
                    className={(currentQuestion.index === index
                      ? ' bg-app-secondary text-white '
                      : markedForReview.includes(index)
                        ? ' bg-app-red  text-white '
                        : answeredQuestions.includes(index)
                          ? ' bg-app-green text-white '
                          : ' bg-app-grey-light ') +
                        ' rounded-md w-10 h-10 '}>{index + 1}</button>
            })}
        </div>
        <div className="flex flex-wrap w-full m-auto my-4">
          <InfoRow bgColor={'bg-app-green'} text={`Answered : ${answeredQuestions.length}`} />
          <InfoRow bgColor={'bg-app-red'} text={`Marked : ${markedForReview.length}`} />
          <InfoRow bgColor={'bg-app-secondary'} text={'Current'} />
          <InfoRow bgColor={'bg-app-grey-light'} text={`Unanswered : ${quizInReduxStore.questions.length - answeredQuestions.length}`} />
      </div>
      </div>
    </div>
    <GeneralButton
      content={'End Test'}
      onClick={quizSubmitButtonHandler}
      className={'flex justify-center w-full rounded-xl p-4 text-xl font-semibold  text-white bg-app-green shadow-4-sides-green '}/>
    </div>
}

function InfoRow ({ bgColor, text }) {
  return <div className="flex flex-row h-max my-auto mx-2">
    <div className={` ${bgColor} my-auto mr-3 w-4 h-4 rounded-full shadow-md`} />
    <div className='flex whitespace-nowrap'>{text}</div></div>
}
