import React, { useEffect } from 'react'
import GeneralButton from '../../../_atomic-design/atoms/Button/GeneralButton'
// import more from '../../assets/images/Icons/more.svg'
import ImageTag from '../../../_atomic-design/atoms/ImageTag'
import ProfileIcon from '../../../_atomic-design/molecules/ProfileComponent'
// import HomeIcon from '../../../assets/images/Icons/HomeIcon.svg'
import HomeIcon from '../../../assets/images/Icons/carbon_home.svg'
import GeneralTextInput from '../../../_atomic-design/molecules/Input/GeneralTextInput'
// import saveQuizIcon from '../../../assets/images/Icons/save-quiz-icon.svg'
// import TooltipBottom from '../../../_atomic-design/molecules/Others/TooltipBottom'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { removeQuiz } from '../../../store/actions/quizActions/quizActions'
import arrowRightBlack from '../../../assets/images/Icons/arrowRightBlack.svg'

import ButtonPrimaryXs from '../../../_atomic-design/atoms/Button/ButtonPrimaryXs'
import { toast } from 'react-toastify'

export default function CreatedQuizNavbar ({
  quizName,
  setQuizName,
  Tabs,
  currentTab,
  setCurrentTab,
  needToSave,
  setNeedToSave,
  quizId,
  isQuizpublished,
  downloadLink,
  pageName,
  setDisableSetting,
  disableSetting
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userDetails = useSelector((state) => state.authReducer)
  const updateQuizName = async (e) => {
    setQuizName(e.target.value)
    setNeedToSave(true)
  }

  useEffect(() => {
    return () => {
      dispatch(removeQuiz({}))
    }
  }, [])

  return (
    <div className="border-b fixed top-0 left-0 w-full bg-white z-20 h-[119px] md:h-[52px]">
      <div className="flex flex-row flex-wrap justify-between items-center px-4 md:px-10 h-full">
        <div className="order-1 md:order-1 md:w-min md:hidden">
          <ImageTag
            src={HomeIcon}
            className=" m-auto cursor-pointer hover:opacity-80"
            onClick={() => navigate('/')}
          />
        </div>
        {(pageName === 'PublishedQuizOverview')
          ? (<div className='flex order-2 md:order-4'><ProfileIcon /></div>)
          : (<div className=" flex flex-row items-center order-2 md:order-4 md:w-fit ml-auto md:ml-0">
            {!userDetails.isLoggedIn
              ? (
                <Link to="/Auth?mode=login" className='min-w-full md:min-w-[106px]' id={'nav_login'}>
                  <ButtonPrimaryXs text={'Login'} className='mx-5' />

                </Link>
                )
              : (
                <ProfileIcon />
                )}
          </div>)}
        <div className="order-3 md:order-2 w-full md:w-fit flex items-center ">
          <ImageTag
            src={HomeIcon}
            className="h-6 mr-5 cursor-pointer hover:opacity-80 hidden md:block"
            onClick={() => navigate(userDetails.isLoggedIn ? '/Dashboard' : '/')}
          />
          <GeneralTextInput
            className={
              'text-app-dark-black font-medium placeholder:font-normal border-none outline-none text-xl w-full bg-violet-100 rounded-md px-2 text-left'
            }
            value={quizName}
            maxLength={30}
            onChange={updateQuizName}
            placeholder='Quiz name'
            id='Quiz_name_joyride'
          />
        </div>
        <div className="text-sm mx-auto sm:ml-auto sm:mx-4 md:text-base md:h-full flex items-center order-4 md:order-3 md:w-fit">
          {Tabs.map((tab, i) => (
            <div key={'heading_' + i} className='flex flex-row' >
              <GeneralButton
                key={'CreatedQuizNavbarty_' + i}
                id={`${tab}_joyride`}
                content={tab}
                className={` ${currentTab !== tab
                    ? 'text-app-grey cursor-default h-max my-auto'
                    : 'font-medium border-b border-app-primary text-app-primary h-max my-auto'
                  }`}
                onClick={() => {
                  if (userDetails.isLoggedIn) {
                    if (tab === 'Publish') {
                      // do not delete this console
                      console.log('Save the settings first, please.')
                    } else if (tab === 'Settings') {
                      if (!needToSave) {
                        setCurrentTab(tab)
                      } else {
                        // toast('Please first save the Question.')
                        // do not delete this console
                        console.log('Save the settings first, please.')
                      }
                    } else {
                      setCurrentTab(tab)
                    }
                  } else {
                    toast('A login is required for this feature.')
                  }
                }}
              />

              {(tab !== 'Publish') && (Tabs.length >= 3)
                ? (
                <span key={'CreatedQuizNavbar_span' + i} role='img' aria-label='Arrow right' className='flex text-3xl text-violet-600 justify-center items-center h-max my-auto mx-4'>
                <ImageTag src={arrowRightBlack} className={''}/>
              </span>
                  )
                : (
                    null
                  )}
            </div>
          ))}
        </div>

      </div>
    </div>
  )
}
