import React from 'react'

function TimerMock ({ time }) {
  return <div className="flex rounded-md border bg-white w-max h-10 ">
            <div className=" justify-start p-2 px-5 rounded-md disabled:opacity-100 bg-[#EDF0FF] text-app-primary">
                Time Remaining
            </div>
        <span className="text-black p-2 px-5">
        {time.minutes}:{time.seconds < 10 ? `0${time.seconds}` : time.seconds}
        </span>
    </div>
}

export default TimerMock
