import React, { useEffect } from 'react'

export default function TimerExtendedBig (props) {
  // const [time, setTime] = useState({hours:5, minutes: 10, seconds: 59 });
  /* This component displays time in HH:MM:SS . props.time must be in format {hours:5, minutes: 10, seconds: 59 } */
  const time = props.time
  const setTime = props.setTime

  function myNewFn (myInterval) {
    if (time.seconds > 0) {
      setTime({ hours: time.hours, minutes: time.minutes, seconds: time.seconds - 1 })
    }
    if (time.seconds === 0) {
      if (time.minutes === 0) {
        if (time.hours === 0) {
          clearInterval(myInterval)
          props.onTimeUp()
        } else {
          setTime({ hours: time.hours - 1, minutes: 59, seconds: 59 })
        }
      } else {
        setTime({ hours: time.hours, minutes: time.minutes - 1, seconds: 59 })
      }
    }
  }

  useEffect(() => {
    const myInterval = setInterval(() => { myNewFn(myInterval) }, 1000)
    return () => clearInterval(myInterval)
  }, [time])

  return <div className="flex flex-col rounded-md w-max h-10 ">
    <div className='flex flex-row gap-4'>
      <div className='flex flex-col border-4 border-red-400 w-24 sm:w-32  h-24 sm:h-32 justify-center items-center'>
        <div className=' text-3xl sm:text-5xl font-bold text-red-400 '>
        {time.hours < 10 ? `0${time.hours}` : time.hours}
        </div>
        <div className='text-xl sm:text-2xl font-semibold text-gray-600'>
          Hours
        </div>
      </div>
      <div className='flex flex-col border-4 border-red-400 w-24 sm:w-32 h-24 sm:h-32 justify-center items-center'>
        <div className='text-3xl sm:text-5xl font-bold text-red-400'>
        {time.minutes < 10 ? `0${time.minutes}` : time.minutes}
        </div>
        <div className='text-xl sm:text-2xl font-semibold text-gray-600'>
          Minutes
        </div>
      </div>
      <div className='flex flex-col border-4 border-red-400 w-24 sm:w-32 h-24 sm:h-32 justify-center items-center'>
        <div className='text-3xl sm:text-5xl font-bold text-red-400'>
        {time.seconds < 10 ? `0${time.seconds}` : time.seconds}
        </div>
        <div className=' text-xl sm:text-2xl font-semibold text-gray-600'>
          Seconds
        </div>
      </div>

    </div>
  </div>
}
