import React from 'react'
import QuestionText from '../General/QuestionText'
import { MAX_CHARACTER_LIMIT } from '../../config/config'
// import RenderLatex from '../../helpers/RenderLatex'

function QuestionContainerSub (props) {
  /* This useEffect for clearing the selected radio button every time current question changes
                          and setting the selected radio button of already answered questions             */

  const maxCharacterLimit = MAX_CHARACTER_LIMIT || 5000

  function inputChangeHandler (e) {
    e.preventDefault()
    const inputValue = e.target.value

    if (inputValue.length > maxCharacterLimit) {
      alert(`Word limit exceeded! Please keep your response within ${maxCharacterLimit} characters.`)
    }
    const limitedInput = inputValue.slice(0, maxCharacterLimit)

    props.inputChangeHandler(limitedInput)
    // props.inputChangeHandler(e.target.value)
  }
  // below code will remove any html tag from text
  const regex = /(<([^>]+)>)/ig

  /**
       * @description : Latex rendering throws error if equation not formatted properly as per latex,
       * RenderLatex used for handling errors thrown during rendering
       */
  return <div className={props.className}>
    <QuestionText questionNo={props.currQuestion.index + 1} score={props.currQuestion.question.score} className={' mx-auto mb-4'} questionText={props.currQuestion.question.questionContent.replace(regex, '')} questionImage={props.currQuestion.question.questionPicture} />
    <textarea id='messagesub' placeholder='Type your answer' value={props.response[props.currQuestion.index] ? props.response[props.currQuestion.index] : ''} onChange={inputChangeHandler} className=' resize-none content-center min-h-[180px] max-h-[180px] w-full p-4 h-7 sm:h-[90px] bg-app-grey-light rounded-xl text-black placeholder:text-app-dark-grey'></textarea>

    </div>
}

export default QuestionContainerSub
