import React from 'react'
import ArrowWhiteLeft from '../assets/images/Icons/ArrowWhiteLeft.svg'
import ArrowWhiteRight from '../assets/images/Icons/ArrowWhiteRight.svg'
import ButtonGeneralImage from '../_atomic-design/atoms/Button/ButtonGeneralImage'
// import MarkForReview from '../assets/images/Icons/markForReview.svg'
import GeneralButton from '../_atomic-design/atoms/Button/GeneralButton'
import ToggleInput from '../_atomic-design/molecules/Input/ToggleInput'

export default function BottomPanel ({ toggleMarkForReview, isMarkedForReview, onPrevious, onNext, quizSubmitButtonHandler, showSubmitTestButton }) {
  return <div className="flex  bg-white h-24 justify-between sm:w-full w-screen px-2">
    <div className="flex my-auto">
      {/* <ButtonGeneralImage imagePosition={'left'} onClick={markForReview} text={'Mark for Review'} image={MarkForReview} className={' my-auto mx-auto bg-app-red font-semibold shadow-4-sides-red  '} /> */}
      <ToggleInput toggleMarkForReview={toggleMarkForReview} isMarkedForReview={isMarkedForReview} textMain={'Mark for Review'} className='my-auto'/>
    </div>
    <div className="flex space-x-2">
      <ButtonGeneralImage imagePosition='left' onClick={onPrevious} text={'Previous'} image={ArrowWhiteLeft} className={' my-auto mx-auto bg-app-secondary font-semibold shadow-4-sides-secondary '}/>
      {showSubmitTestButton
        ? <GeneralButton
          content={'End Test'}
          onClick={quizSubmitButtonHandler}
          className={'flex justify-center w-max px-4 h-8 sm:h-12 items-center rounded-lg my-auto font-semibold  text-white bg-app-green shadow-4-sides-green '}
          />
        : <ButtonGeneralImage imagePosition='right' onClick={onNext} text={'Next'} image={ArrowWhiteRight} className={' my-auto mx-auto bg-app-secondary font-semibold shadow-4-sides-secondary  '} />
      }
    </div>
    </div>
}
