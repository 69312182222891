import React from 'react'
import ButtonPrimaryLg from '../../_atomic-design/atoms/Button/ButtonPrimaryLg'
import HeadingLg from '../../_atomic-design/atoms/Text/HeadingLg'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'

export default function PrivacyNPolicyModal ({ active, setActive }) {
  if (!active) return null
  return (
        <div className="fixed top-0 left-0 w-screen h-screen text-base bg-white/20 backdrop-blur-[20px] z-[100] flex justify-center items-center text-left ">
            <div className="w-fit md:w-1/2 mx-4 bg-white rounded-[20px] p-8 max-h-screen h-[80%] min-h-[90%] max-w-xl flex flex-col justify-center items-center " style={{ boxShadow: '0 0 27px rgb(60, 56, 205, 0.15)' }}>
                <HeadingLg className="text-center mb-5" text="Website Privacy Policy" />

                <div className="flex-1 overflow-y-scroll">
                    <TextBody text='Edtech Kraft is committed to protect your personal data in accordance with applicable privacy
                                    and data protection laws and regulations, including without limitation the European Union
                                    General Data Protection Regulation (GDPR), and other applicable local laws and regulations that
                                    regulate the storage, processing, access, and transfer of personal data.' />
                    <TextBody text={<>This Website Privacy Policy (this “<b>Privacy Policy</b>”) describes the ways in which Personal
                                    Information about individuals (“<b>Users</b>” or “<b>you</b>”) who register to, access, and or use this website
                                    (the <b>“Website”</b>) may be collected, stored and used by Edtech Kraft Private Limited
                                    Kuzhimattathil Chambers, Bld No. Vi/726 B2, Kochi, Kozhikode, Kerala, India, 682021
                                    (“<b>Edtech Kraft</b>”), the data controller.</>} />
                    <TextBody text={<>In order to comply with any new law or regulation in the sector, <b> this Privacy Policy may
                                    periodically change, be modified, or otherwise updated without prior notification </b>, therefore
                                    we invite Users to consult this Privacy Policy periodically.</>} />
                    <TextBody text='However, your Personal Information (as defined below) will always be handled in accordance
                                    with the Privacy Policy that was in effect at the time of collection.' />

                    <TextBody className='font-bold' text='1- Personal Information collected' />
                    <TextBody className='font-bold' text='Data provided voluntarily by the Users' />
                    <TextBody text={<>Edtech Kraft may collect Personal Information when you register to the Website, including your
                                    name, e-mail address, city and country of residence, and hospital affiliation (“<b>Personal
                                    Information</b>”). If you wish to receive information from Edtech Kraft/contact Edtech Kraft, these
                                    categories of Personal Information are mandatory.
                                    When you register to the Website, we clearly label which information is required for registration,
                                    and which information is optional and may be given at your discretion (as the case may be).
                                    This Personal Information directly identifies you.</>} />

                    <TextBody className='font-bold' text='Navigation data' />
                    <TextBody text='In the course of their normal operations, the information systems and software procedures used to
                                    make the Website function collect some personal data for which transmission is implicit when
                                    using Internet communication protocols.' />
                    <TextBody text='Such data include IP addresses, the type of browser used, the operating system, the domain name
                                    and the address of websites from/to which access/exit was made, information about the pages
                                    visited by users within the Website, the time of access, how long was spent on each page, an
                                    analysis of the internal path and other parameters relating to the operating system and IT
                                    environment of the user.' />
                    <TextBody text='Such technical/IT data is gathered and utilized exclusively on an aggregate and anonymous basis
                                    and may be utilized to determine responsibility in case of alleged IT/electronic offenses causing
                                    damage to the Website.' />
                    <TextBody text='This Personal Information does not directly identify you by name but could be used to identify
                                    that a specific computer or device has accessed the Website.' />

                    <TextBody className='font-bold' text='2- Use of Personal Information' />
                    <TextBody text='Your Personal Information will only be processed for the purposes of the legitimate interests
                                    pursued by Edtech Kraft, namely having a better interaction with you as well as making the
                                    Website more useful for you. More specifically, we may use your personal information for the
                                    following purposes:' />
                    <ul className='list-disc list-inside ml-5' >
                        <li>to facilitate the creation of User Registration;</li>
                        <li>to facilitate the creation of a User Contract;</li>
                        <li>to provide technical or other support to you;</li>
                        <li>to answer inquiries about our services, or to respond to a complaint;</li>
                        <li>to promote our other programs, products, or services that may be of interest to you (unless
                            you have opted out from such communications);</li>
                        <li>to allow for debugging, testing, and otherwise operate our platforms;</li>
                        <li>to conduct data analysis, research, and otherwise build and improve our platforms;</li>
                        <li>to comply with legal and regulatory obligations;</li>
                        <li>if otherwise permitted or required by law; or</li>
                        <li>for other purposes with your consent, unless you withdraw your consent for these
                            purposes.</li>
                    </ul>
                    <TextBody text='Your Personal Information will not be sold, shared, or otherwise distributed to third parties
                                    without your consent, except where we are required to do so because of applicable law, court
                                    order, or governmental regulation, or if such disclosure is otherwise necessary for the support of
                                    any criminal or other legal investigation or proceeding here or abroad.' />

                    <TextBody text='3- Personal Information recipients' />
                    <TextBody text='For the purposes described above, Edtech Kraft may share your Personal Information with the
                                    following authorised third parties:' />
                    <ul className='list-disc list-inside ml-5' >
                        <li><b>Edtech Kraft and its affiliates;</b></li>
                        <li><b>Selected suppliers, service providers or vendors</b> acting upon Edtech Kraft’s instructions for
                            website hosting, data analysis, order fulfilment, information technology and related
                            infrastructure provision, customer service, email delivery, auditing, cloud hosting, storage,
                            networking and related providers, etc.;</li>
                        <li><b>Legal or administrative authorities: </b>as required by applicable laws including laws outside
                            your country of residence;</li>
                        <li><b>Potential acquirers and other stakeholders</b> in the event of a merger, legal restructuring
                            operation such as, acquisition, joint venture, assignment, spin-off or divestitures.</li>
                    </ul>
                    <TextBody text='In any case, Edtech Kraft will require that such third parties:' />
                    <ul>
                        <li>Commit to comply with data protection laws and the principles of this Privacy Policy;</li>
                        <li>Will only process the Personal Information for the purposes described in this Privacy Policy;</li>
                        <li>Implement appropriate technical and organizational security measures designed to protect the
                            integrity and confidentiality of your Personal Information.</li>
                        <li></li>
                    </ul>

                    <TextBody text='4- Personal Information Transfers' />
                    <TextBody text='D.kraft Pte ltd is a global ed-tech group with affiliates (Edtech Kraft etc.), other entities, partners,
                                    and subcontractors located in other countries around the world.
                                    For that reason, Edtech Kraft may need to transfer your Personal Information in other
                                    jurisdictions, including from the European Economic Area to outside the European Economic
                                    Area, in countries that may not be regarded as providing the same level of protection as the
                                    jurisdiction you are based in.' />
                    <TextBody text='In cases that d.kraft/Edtech Kraft needs to transfer Personal Information outside the European
                                    Union, it shall ensure that adequate safeguards, as required under applicable data protection
                                    legislation, will be implemented (including, notably, the European Commission’s Standard
                                    Contractual Clauses, as applicable).' />

                    <TextBody text='5- Term of retention of Personal Information' />
                    <TextBody text='Your Personal Information is processed and kept for no more than three years from the latter: (i)
                                    its collection or (ii) the latest contact we have with you.' />

                    <TextBody text='6- Security' />
                    <TextBody text='Your Personal Information will be stored on secure servers and maintained strictly confidential.
                                    You are responsible for maintaining the secrecy of your unique password and account
                                    information.' />

                    <TextBody text='7- Right of access, correction, and objection' />
                    <TextBody text='You have the following rights regarding your information depending on the circumstances and
                                    applicable legislation:' />

                <table className=" my-5 mr-2">
                <thead>
                    <tr>
                    <th>Right</th>
                    <th>What does this mean?</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='bg-gray-100'>
                        <td className='p-1 font-bold w-1/3'>a. The right of access</td>
                        <td className='p-1 w-full' >You have the right to obtain access to the information processed by Edtech Kraft.</td>
                    </tr>
                    <tr className=''>
                        <td className='p-1 font-bold w-1/3'>b. The right to rectification</td>
                        <td className='p-1 w-full' >You are entitled to have your information corrected if it is inaccurate or incomplete.</td>
                    </tr>
                    <tr className='bg-gray-100'>
                        <td className='p-1 font-bold w-1/3'>c. The right to erasure</td>
                        <td className='p-1 w-full' >This is also known as ‘the right to be forgotten and, in
                                                simple terms, enables you to request the deletion or
                                                removal of your information where there is no compelling
                                                reason for Edtech Kraft to keep using it. This is not a
                                                general right to erasure; there are exceptions.</td>
                    </tr>
                    <tr className=''>
                        <td className='p-1 font-bold w-1/3'>d. The right to restrict processing</td>
                        <td className='p-1 w-full' >You have the right to ‘block’ or suppress further use of
                                                your information in certain circumstances. When
                                                processing is restricted, Edtech Kraft can still store your
                                                information, but may not use it further.</td>
                    </tr>
                    <tr className='bg-gray-100'>
                        <td className='p-1 font-bold w-1/3'>e. The right to data portability</td>
                        <td className='p-1 w-full' >You have rights to obtain and reuse your Personal
                                                Information in a structured, commonly used and machine-
                                                readable format in certain circumstances.</td>
                    </tr>
                    <tr className=''>
                        <td className='p-1 font-bold w-1/3'>f. The right to object</td>
                        <td className='p-1 w-full' >You have the right to object to certain types of processing,
                                                in certain circumstances.</td>
                    </tr>

                </tbody>
                </table>
                <TextBody text='If you are not satisfied with the response to your complaint or believe the processing of your
                                information does not comply with data protection law, you can make a complaint to the relevant
                                data supervisory authority.' />

                <TextBody className='font-bold' text='8- Redirect to external sites' />
                <TextBody text='To make it possible to incorporate Social Network functions directly within the Website (e.g. the
                                “Like” function on Facebook, and the “Follow” function on Twitter), some special tools named
                                Social plug-ins might be present on this Website.' />
                <TextBody text='All the social plug-ins are marked by the respective social network logo.
                                When you interact with a plug-in presented on the Website (e.g. by clicking the &quot;Like&quot; button) or
                                leave a comment, the corresponding information is sent by the browser directly to the social
                                network platform (in this example, Facebook) and memorized by it.' />
                <TextBody text='Please consult the privacy policy of the social network if you like to have more information about
                                the purpose, type, and method of personal data collection, processing, utilization, and storage by
                                the social network platform as well as for details on how to exercise your rights.' />
                <TextBody text='At this moment no social plug-ins is present on the Website.' />

                <TextBody className='font-bold' text='9- Links From/To third party websites' />
                <TextBody text='From the Website, you can use links to go to other websites, you may also be directed to the
                                Website by third parties.' />
                <TextBody text='The Data Controller declines all responsibility for any requests for and/or granting of personal
                                data to third-party websites and in relation to the management of the authentication credentials
                                issued by third-party entities.' />
                <TextBody text='You should review the privacy policies of any third-party site before providing any information.' />

                <TextBody className='font-bold' text='10- Technology utilized' />
                <TextBody className='Cookies' text='Cookies' />
                <TextBody text='This Website uses cookies and similar technologies (“cookies”), including third-party cookies.' />
                <TextBody text='Cookies are small data files which are placed on your computer, smartphone or other device.' />
                <TextBody text='Cookies are stored on the hard disk of your device without any risk of damaging it.' />
                <TextBody text='By entering the Website, you can either:' />
                <ul className='list-disc list-inside ml-5' >
                    <li>Accept the use of cookies; or</li>
                    <li>Refuse the use of cookies; or</li>
                    <li>Set your cookies preference.</li>
                </ul>
                <TextBody text='Please consult the Website Cookies Policy if you like to have more information.' />

                <TextBody className='font-bold' text='11- How to contact us' />
                <TextBody text={<>If you have any questions or comments about this Privacy Notice, please feel free to email Edtech
                                Kraft’s Data Protection Officer at  <a className='text-blue-500 underline' href="mailto:contact@dkraftlearning.com" >contact@dkraftlearning.com</a>.</>} />

                </div>
                <ButtonPrimaryLg className='mt-6' text='Close' onClick={setActive} />
            </div>
        </div>
  )
}
