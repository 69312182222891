import React from 'react'
import ImageTag from '../ImageTag'
import TooltipLeft from '../../molecules/Others/TooltipLeft'

export default function ButtonIconOnly ({ onClick, className, image, disabled, hidden, tooltipText }) {
  return (
    <TooltipLeft content={
      <button
            onClick={onClick}
            className={` ${hidden ? ' hidden ' : ' '}   ${className} ${disabled ? ' cursor-none ' : ' '} block `}
            disabled={disabled}
        ><ImageTag src={image} className={ 'w-full' } />
        </button>
    }
    message={tooltipText}
        />
  )
}
