import React from 'react'
import QuestionAddition from '../_atomic-design/molecules/Others/QuestionAddition'

// import FaqBack from '../assets/images/FaqBg.svg'
import HeadingXlg from '../_atomic-design/atoms/Text/HeadingXlg'
import questions from '../assets/data/faqData.js'

function AddQuestions () {
  const FAQs = questions
  const midpoint = Math.floor(FAQs.length / 2)
  const faqs1 = FAQs.slice(0, midpoint)
  const faqs2 = FAQs.slice(midpoint)
  //   const style = {
  //     backgroundImage: `url(${FaqBack})`,
  //     backgroundSize: 'cover',
  //     backgroundPosition: 'center',
  //     backgroundRepeat: 'no-repeat'
  //   }
  return (
        <div
            id="faq"
            className="min-h-screen p-10 sm:p-20 rounded-[20px] bg-[#F9E1FF] "
        >
            <div className="w-4/5 mx-auto text-center mb-10 sm:mb-10 px-3">
                <HeadingXlg
                    text="Answers to Your Curiosity"
                    className="mb-4 text-app-purple-heading text-[26px]"
                />
            </div>
            <div className="w-full flex flex-col sm:flex-row items-stretch mx-auto gap-4">
            <div className='flex flex-col justify-between gap-4 w-full sm:w-1/2'>
            {faqs1.map((ques, key) => {
              return (
                        <div
                            key={key}
                            className=" flex gap-4 bg-white/100 rounded-lg "
                        >
                            <QuestionAddition
                                key={key}
                                questionText={ques.questionText}
                                answerText={ques.answerText}
                            />
                        </div>
              )
            })}
            </div>
            <div className='flex flex-col justify-between gap-4 w-full sm:w-1/2'>
                {faqs2.map((ques, key) => {
                  return (
                        <div
                            key={key}
                            className=" flex gap-4 bg-white/100 rounded-lg "
                        >
                            <QuestionAddition
                                key={key}
                                questionText={ques.questionText}
                                answerText={ques.answerText}
                            />
                        </div>
                  )
                })}
            </div>

            </div>
        </div>
  )
}
export default AddQuestions
