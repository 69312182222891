import React, { useEffect, useState } from 'react'
import CreatedQuizNavbar from '../../_atomic-design/molecules/Navbars/CreatedQuizNavbar'
// import AddTopicBar from '../../containers/CreateQuiz/AddTopicBar'
// import RightMenu from '../../containers/CreateQuiz/RightMenu'
import EditQuizMainBody from '../../containers/CreateQuiz/EditQuizMainBody'
import PublishQuiz from '../../containers/Modals/PublishQuiz'
import SettingsTab from '../../containers/CreatedQuizes/SettingsTab'
import LogoLoader from '../../_atomic-design/molecules/Others/LogoLoader'

import { useDispatch, useSelector } from 'react-redux'
import Joyride from 'react-joyride'
import EditQuizJoyRide from '../../Joyride/EditQuizJoyRide'
import { toast } from 'react-toastify'
import {
  makeAuthenticatedServerGETRequest,
  makeAuthenticatedServerRequest,
  makeServerGETRequest,
  makeServerRequest
} from '../../utils/helpers'
import {
  cleanDeletedQuestions,
  cleanNewlyCreatedQuestions,
  removeQuiz,
  storeQuiz
} from '../../store/actions/quizActions/quizActions'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
// import { makeAuthenticatedServerDELETERequest } from '../../utils/helpers'
import { setCookieStorage, getCookies, removeCookies, getPublishingQuizID } from '../../utils/cookieStorage'
import TrialRibbon from '../../_atomic-design/molecules/Others/TrialRibbon'
// import HomeNavbar from '../../_atomic-design/molecules/Navbars/HomeNavbar'

function QuizViewPage () {
  const { search } = useLocation()
  const quizReducer = useSelector((state) => state.quizReducer)
  const [quizName, setQuizName] = useState(quizReducer.quizName)
  // eslint-disable-next-line no-unused-vars
  const [downloadLink, setDownloadLink] = useState({ QuestionOnly: '#', QuestionAnswer: '#' })

  // const Tabs = ['Questions']
  const Tabs = ['Questions', 'Settings', 'Publish']
  const [needToSave, setNeedToSave] = useState(true)
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [disableSetting, setDisableSetting] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const authState = useSelector((state) => state.authReducer)
  // const userRole = authState.subscription ? authState.subscription[authState.subscription.length - 1].name : null

  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const regex = /(<([^>]+)>)/ig

  const getQuizId = () => {
    if (searchParams.get('quizId')) {
      return searchParams.get('quizId')
    } else if (getPublishingQuizID().quizID) {
      return getPublishingQuizID().quizID
    } else {
      return undefined
    }
  }

  // get initial state value on component mounts
  // eslint-disable-next-line no-unused-vars
  const [quizIdParam, setQuizIdParam] = useState(getQuizId)

  const checkValidation = (allQuestions) => {
    try {
      for (let i = 0; i < allQuestions.length; i++) {
        const mcqquestion = allQuestions[i]
        if (mcqquestion.question_type === 'M') {
          const questionContent = mcqquestion.questionContent.replace(regex, '')

          if (questionContent.trim() === '') {
            toast.error('Question field can not be empty.')
            return true
          }
          const optionsArray = mcqquestion.questChoices
          if (optionsArray.length > 0) {
            for (let i = 0; i < optionsArray.length; i++) {
              const eachOptionText = optionsArray[i].replace(regex, '')
              if (eachOptionText.trim() === '') {
                toast.error('Option field can not be empty.')
                return true
              }
            }
          } else {
            toast.error('Please select an answer for MCQ Question.')
            return true
          }
          const mcqansarray = mcqquestion.generatedAnswer
          if (mcqansarray.length > 0) {
            let answercount = 0
            for (let i = 0; i < optionsArray.length; i++) {
              const eachOptionText = optionsArray[i].replace(regex, '')
              const answerText = mcqansarray[0].replace(regex, '')
              if (eachOptionText.trim() === answerText.trim()) {
                answercount = answercount + 1
              }
            }
            if (answercount === 0) {
              toast.error('Please select an answer for MCQ question.')
              return true
            }
          } else {
            toast.error('Please select an answer for MCQ Question.')
            return true
          }
        } else if (mcqquestion.question_type === 'S') {
          const questionContent = mcqquestion.questionContent.replace(regex, '')
          if (questionContent.trim() === '') {
            toast.error('Question field for subjective question can not be empty.')
            return true
          }
          const ansArray = mcqquestion.generatedAnswer
          if (ansArray.length > 0) {
            const subjectiveAnswer = ansArray[0]
            const subjectiveAnsContent = subjectiveAnswer.replace(regex, '')
            if (subjectiveAnsContent.trim() === '') {
              toast.error('Answer field for subjective question can not be empty.')
              return true
            }
          } else {
            toast.error('Please select an answer for subjective Question.')
            return true
          }
        }
      }
      return false
    } catch (error) {
      console.log(error)
      toast.error('Something not good in quiz questions.')
      return true
    }
  }

  const getQuizpublished = () => {
    /* this function will fetch quizid about to publish
    but some how cancl or redirected so this will open
    the modal responsible for publishing quiz */
    if (searchParams.get('quizId')) {
      if (searchParams.get('quizId') === getPublishingQuizID().quizID) {
        if (getPublishingQuizID().isQuizPublished) {
          return getPublishingQuizID().isQuizPublished
        } else {
          return false
        }
      }
    } else {
      if (getPublishingQuizID().isQuizPublished) {
        return getPublishingQuizID().isQuizPublished
      } else {
        return false
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  const [isQuizpublished, setIsQuizpublished] = useState(getQuizpublished)

  const isPublishAllow = () => {
    try {
      const googleState = new URLSearchParams(search).get('state')
      if (googleState === 'GOOGLE_LOGIN') {
        return 'Publish'
      }
      return 'Questions'
    } catch (error) {
      console.log(error)
      return 'Questions'
    }
  }
  const [currentTab, setCurrentTab] = useState(isPublishAllow)
  const validateAndNext = async () => {
    // validateAndSave()
    const res = await saveQuiz()
    if (res) {
      setCurrentTab('Settings')
    }
  }

  const validateAndSave = () => {
    if (!authState.isLoggedIn) {
      const saveQuizMessage = 'Please login to save the quiz'
      if (window.confirm(saveQuizMessage) === true) {
        navigate('/Auth?mode=login', { redirectBack: location })
      }
      return true
    }
    if ((quizName.trim() === '') || (quizName.toLowerCase() === 'untitled')) {
      toast('Please provide a valid quiz name')
      return true
    }

    // Getting all questions for checking the question validation
    const allQuestions = quizReducer.questions.filter(
      (question) => !question.isDeleted
    )
    // Making the API call to delete the deleted questions
    const validationError = checkValidation(allQuestions)
    if (validationError) {
      return true
    }
    return false
  }

  /**
* This function save the quiz by calling the save quiz API. This function first filters the newly created questions
  in 'newlyAddedQuestions' array and similary filters the deleted questions in 'deletedQuestions' array and
  updatedQuestions array. Then is first makes the API call to delete the deleted questions and then makes the
  API call for newly added questions and then makes the call for the updatedQuestions array as well. Then finally
  we fetch the quiz again and store it in redux store.
* @summary This function is called when user clicks on 'Save Quiz' button
*/
  const saveQuiz = async () => {
    const validationresult = validateAndSave()
    if (validationresult) {
      return false
    }

    // Getting newly created questions
    const newlyAddedQuestions = quizReducer.questions.filter(
      (question) => question.isNewlyCreated && !question.isDeleted
    )
    // Getting deleted questions
    const deletedQuestions = quizReducer.questions.filter(
      (question) => question.isDeleted && !question.isNewlyCreated
    )
    // Getting updated questions
    const updatedQuestions = quizReducer.questions.filter(
      (question) => !question.isNewlyCreated && !question.isDeleted
    )
    // Getting all questions for checking the question validation

    setLoading(true)
    if (deletedQuestions.length > 0) {
      /* eslint-disable no-unused-vars  */
      let deletedRes
      if (authState.isLoggedIn) {
        deletedRes = await makeAuthenticatedServerRequest('/question', 'DELETE', {
          quizId: quizReducer.quizId,
          questionId: deletedQuestions
        })
      } else {
        deletedRes = await makeServerRequest('/question', 'DELETE', {
          quizId: quizReducer.quizId,
          questionId: deletedQuestions
        })
      }
      if (deletedRes.status !== 200) {
        toast('Error in deleting questions')
        setLoading(false)
        return false
      }
      dispatch(cleanDeletedQuestions())
    }
    // Making the API call to add the newly created questions
    if (newlyAddedQuestions.length > 0) {
      let newlyAddedRes
      if (authState.isLoggedIn) {
        newlyAddedRes = await makeAuthenticatedServerRequest('/question', 'POST', {
          quizId: quizReducer.quizId,
          questions: newlyAddedQuestions
        })
      } else {
        newlyAddedRes = await makeServerRequest('/question', 'POST', {
          quizId: quizReducer.quizId,
          questions: newlyAddedQuestions
        })
      }
      if (newlyAddedRes.status !== 200) {
        toast('Error while saving the quiz')
        setLoading(false)
        return false
      }
      dispatch(cleanNewlyCreatedQuestions())
    }
    // Making the API call to update the updated questions
    if (updatedQuestions.length > 0) {
      let updatedRes
      if (authState.isLoggedIn) {
        updatedRes = await makeAuthenticatedServerRequest('/question', 'PUT', {
          quizId: quizReducer.quizId,
          questions: updatedQuestions
        })
      } else {
        updatedRes = await makeServerRequest('/question', 'PUT', {
          quizId: quizReducer.quizId,
          questions: updatedQuestions
        })
      }
      if (updatedRes.status !== 200) {
        toast('Error while saving the quiz')
        setLoading(false)
        return false
      }
    }

    // Making the API call to update the name of quiz
    let resNameUpdate
    if (authState.isLoggedIn) {
      resNameUpdate = await makeAuthenticatedServerRequest('/quiz/updateName', 'PUT', {
        quizId: quizReducer.quizId,
        name: quizName.trim()
      })
    } else {
      resNameUpdate = await makeServerRequest('/quiz/updateName', 'PUT', {
        quizId: quizReducer.quizId,
        name: quizName
      })
    }
    if (resNameUpdate.status !== 200) {
      toast('Error while saving the quiz name')
      setLoading(false)
      return false
    }

    let quizRes
    if (authState.isLoggedIn) {
      quizRes = await makeAuthenticatedServerGETRequest('/quiz/', {
        quizId: quizIdParam
      })
    } else {
      quizRes = await makeServerGETRequest('/quiz/', {
        quizId: quizIdParam
      })
    }
    if (quizRes.status !== 200) {
      // toast('Quiz not found')
      // navigate('/')
      setLoading(false)
      return false
    }
    // const totalScore = quizRes.data.quiz.questions ? quizRes.data.quiz.questions.reduce((acc, curr) => acc + curr.score, 0) : 0
    dispatch(
      storeQuiz({
        quizId: quizRes.data.quiz._id,
        quizName: quizRes.data.quiz.name,
        questions: quizRes.data.quiz.questions,
        settings: quizRes.data.quiz.settings
        // totalScore: totalScore
      })
    )
    toast('Quiz saved successfully!')
    setNeedToSave(false)
    setLoading(false)
    setIsQuizpublished(true)
    return true
  }

  /**
* Brief description of the function here.
* @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
* @param {ParamDataTypeHere} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
* @return {ReturnValueDataTypeHere} Brief description of the returning value here.
*/

  const createDownloadLink = async () => {
    const resd = await makeServerGETRequest(
      '/downloadQuiz/questionDownload',
      {
        quizId: quizIdParam
      }
    )
    return resd
  }

  useEffect(async () => {
    setPageLoading(true)
    document.title = 'EditQuiz'
    // Making request to download the quiz

    if (quizIdParam === null) {
      // toast('quiz not found')
      // navigate('/')
    }

    // Fetching the quiz
    let quizRes
    if (authState.isLoggedIn) {
      quizRes = await makeAuthenticatedServerGETRequest('/quiz/', {
        quizId: quizIdParam
      })
    } else {
      quizRes = await makeServerGETRequest('/quiz/', {
        quizId: quizIdParam
      })
    }
    if (quizRes.status !== 200) {
      // toast('Quiz not found')
      // navigate('/')
      setPageLoading(false)
      return
    } else {
      if (!authState.isLoggedIn) {
        setCookieStorage('lastQuizId', quizIdParam, 1 / 2)
      } else {
        const generatedLastQuizId = getCookies().lastQuizId
          ? getCookies().lastQuizId
          : null
        if (generatedLastQuizId) {
          removeCookies(['lastQuizId'])
        }
      }
    }
    setQuizName(quizRes.data.quiz.name)

    // const totalScore = quizRes.data.quiz.questions ? quizRes.data.quiz.questions.reduce((acc, curr) => acc + curr.score, 0) : 0
    dispatch(
      storeQuiz({
        quizId: quizRes.data.quiz._id,
        quizName: quizRes.data.quiz.name,
        questions: quizRes.data.quiz.questions,
        settings: quizRes.data.quiz.settings
        // totalScore: totalScore
      })
    )
    setPageLoading(false)

    return () => {
      dispatch(removeQuiz({}))
    }
  }, [])

  if (pageLoading) {
    return (
      <LogoLoader time={50} text='Loading..' />
    )
  }

  return (
    <>
    <TrialRibbon/>
      {(!authState.isLoggedIn)
        ? (<Joyride
              continuous
              hideCloseButton
              scrollToFirstStep
              showProgress
              showSkipButton
              run={true}
              steps={EditQuizJoyRide.STEPS}
              styles={{
                options: {
                  primaryColor: '#3C38CD'
                }
              }}
                />)
        : null}
      <div className=' bg-[#EFEFEF] min-h-full '>
        <CreatedQuizNavbar
          quizName={quizName}
          setQuizName={setQuizName}
          Tabs={Tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          saveQuiz={saveQuiz}
          needToSave={needToSave}
          setNeedToSave={setNeedToSave}
          quizId={quizIdParam}
          loading={loading}
          downloadLink={downloadLink}
          disableSetting = {disableSetting}
        />

        <div className='flex flex-row h-full md:mt-[52px]'>
          {/* <div className='pt-24'>
          <AddTopicBar className={'w-60 px-10 hidden md:block'} totalScore={quizReducer.totalScore} />
        </div> */}
          <div className='flex-1 flex justify-center w-full h-full overflow-auto px-6'>
            {(currentTab === 'Questions')
              ? (<EditQuizMainBody isQuizpublished={isQuizpublished} needToSave={needToSave} loading={loading} validateAndNext={validateAndNext} downloadLink={downloadLink} quizData={quizReducer} setNeedToSave={setNeedToSave} saveQuiz={saveQuiz} />)
              : (null)
            }
            {(currentTab === 'Settings')
              ? (<SettingsTab setCurrentTab={setCurrentTab} setDisableSetting = {setDisableSetting} disableSetting = {disableSetting} needToSave={needToSave} setIsQuizpublished={setIsQuizpublished} saveQuiz={saveQuiz}/>)
              : (null)
            }
            {(currentTab === 'Publish')
              ? (<PublishQuiz quizId={quizIdParam} downloadLink={downloadLink} />)
              : (null)
            }
          </div>
        </div>
      </div>
    </>
  )
}
export default QuizViewPage
