import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function RequireAuth ({ children }) {
  const authState = useSelector((state) => state.authReducer)
  const location = useLocation()

  if (!authState.isLoggedIn) {
    toast('Session expired. Please login again! error code 34')
    return <Navigate to='/Auth?mode=login' state={{ redirectBack: location }} replace />
  } else {
    return (
      <>
          {children}
      </>
    )
  }
}
