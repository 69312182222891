import React, { useEffect, useState } from 'react'
import Features from '../../components/Features'
// import Testimonials from '../../components/Testimonials'
// import TransformativeJourney from '../../components/TransformativeJourney'
import EndHomePage from '../../components/EndHomePage'
import PricePlans from '../../containers/PricePlans'
import FAQs from '../../components/FAQSection'
import CreateQuiz from '../../containers/CreateQuiz/CreateQuiz'
import HomeNavbar from '../../_atomic-design/molecules/Navbars/HomeNavbar'
import Joyride from 'react-joyride'
import HomePageJoyRide from '../../Joyride/HomePageJoyRide'
import { useSelector } from 'react-redux'
import TrialRibbon from '../../_atomic-design/molecules/Others/TrialRibbon'
import TransformativeJourneyNew from '../../containers/Home/TransformativeJourneyNew'
// import GettingStartedSection from '../../components/GettingStartedSection'

function HomePage () {
  const authState = useSelector((state) => state.authReducer)
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    document.title = "LearnKraft: Learn, Innovate, Transform: AI-Powered Solutions for Tomorrow's Challenges."
  })
  useEffect(() => {
    if (authState && authState.isLoggedIn) {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }
  }, [authState])
  return (
    <div>
      <TrialRibbon/>
      {(loggedIn)
        ? (<Joyride
              continuous
              hideCloseButton
              scrollToFirstStep
              showProgress
              showSkipButton
              run={true}
              steps={HomePageJoyRide.STEPS}
              styles={{
                options: {
                  primaryColor: '#3C38CD'
                }
              }}
                />)
        : null}

      <HomeNavbar />
      <CreateQuiz />
      <Features />
      {/* <GettingStartedSection /> */}
      {/* <TransformativeJourney/> */}
      <TransformativeJourneyNew />
      {(loggedIn)
        ? null
        : (<PricePlans />)}
      {/* <Testimonials /> */}
      <FAQs />
      <EndHomePage />
    </div>
  )
}
export default HomePage
