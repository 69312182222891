/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import instructor from '../assets/images/instructor.svg'
import Student from '../assets/images/Student.svg'
import HeadingLg from '../_atomic-design/atoms/Text/HeadingLg'
import { loginSucess } from '../store/actions/authActions/authActions'
import { setUserDetails } from '../utils/cookieStorage'
import { useDispatch, useSelector } from 'react-redux'
import HomepageBgShape from '../assets/images/Homepage_bg_shape.svg'
import HomePageBackground from '../assets/images/HomePageBackground.svg'
import LogoIcon from '../assets/images/Icons/learnkraft.svg'

export default function DashboardSelector () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userDetails = useSelector((state) => state.authReducer)
  const bgImage = {
    backgroundImage: `url(${HomePageBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat'
  }
  const bgShape = {
    position: 'absolute',
    right: '0',
    top: '0'
    // zIndex: '10'
  }

  function iClickHandler () {
    setUserDetails({ ...userDetails, isLoggedIn: true, userRoleType: '0' })
    dispatch(loginSucess({ ...userDetails, isLoggedIn: true, userRoleType: '0' }))
    if (!userDetails.detailsCompleted) {
      navigate('/AddMoreDetails')
    } else {
      navigate('/')
    }
  }
  function sClickhandler () {
    setUserDetails({ ...userDetails, isLoggedIn: true, userRoleType: '1' })
    dispatch(loginSucess({ ...userDetails, isLoggedIn: true, userRoleType: '1' }))
    if (!userDetails.detailsCompleted) {
      navigate('/AddMoreDetails')
    } else {
      navigate('/studentDashboard')
    }
  }

  return (
    <div id='home' className="min-h-screen h-full relative  rounded-bl-[20px] rounded-br-[20px] overflow-y-scroll z-0" style={bgImage}>
      <img className="w-40 mt-4 ml-4" src={LogoIcon} alt="LOGO" />
            <img src={HomepageBgShape} alt="" className='w-3/4 md:w-1/2 -z-50' style={bgShape} />
        <div className="bg-[#EFEFEF] bg-transparent min-h-full">
            <div className="w-7/8 sm:w-11/12 md:w-4/5 max-w-7xl mx-auto lg:pt-20">
              <HeadingLg text={'You are ...... '} className={'flex mx-auto pt-10 place-content-center'}/>
        <div className='flex flex-col lg:flex-row mx-auto '>
          <div className='flex flex-col mx-auto mt-10 p-10 w-3/4 max-w-md  border rounded-xl shadow-xl  bg-white   hover:cursor-pointer' onClick={iClickHandler}>
            <img className='w-[100%]flex' src={instructor} alt='instructor' />
            <HeadingLg text={'Instructor ?'} className={'flex mx-auto mt-10'}/>
          </div>
          <div className='flex flex-col mx-auto mt-10 p-10 w-3/4 max-w-md border rounded-xl shadow-xl  bg-white  hover:cursor-pointer' onClick={sClickhandler}>
            <img className='w-[100%] flex' src={Student} alt='student' />
            <HeadingLg text={'Student ?'} className={'flex mx-auto mt-10'}/>
          </div>
        </div>
            </div>
        </div>
    </div>
  )
}
