import { createSlice } from '@reduxjs/toolkit'

// const initialState = {
//     loading: false,
//     firstName: '',
//     lastName: '',
//     email: '',
//     isEmailVerified: false,
//     profilePicture: '',
//     detailsCompleted: false,
//     education: '',
//     profession: '',
//     language: '',
//     isLoggedIn: false,
//     error: ''

// }
const initialState = {
  quizInProgress: false,
  quizSubmitted: false,
  quizResponse: null,
  quizRef: null
}

export const quizStudentReducer = createSlice({
  name: 'quiz',
  initialState: initialState,
  reducers: {
    setReferenceQuiz (state, actions) {
      state.quizRef = actions.payload
    },
    quizStarted (state, actions) {
      state.quizInProgress = true
      state.quizRef = actions.payload
    },
    quizSubmitted (state) {
      state.quizSubmitted = true
      state.quizInProgress = false
    },
    saveQuizResponse (state, actions) {
      state.quizResponse = actions.payload
    }

  }
})

export const quizStudentActions = quizStudentReducer.actions
