import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addQuestion } from '../../store/actions/quizActions/quizActions'
import QuizQuestion from './QuizQuestion'
import RightFunctionalityBar from './RightFunctionalityBar'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'
import { useNavigate } from 'react-router-dom'
import QuestionSuggestionDropdown from '../../_atomic-design/molecules/Dropdown/QuestionSuggestionDropdown'
import { getCookies } from '../../utils/cookieStorage'
import ReactLoading from 'react-loading'
import ButtonPrimary from '../../_atomic-design/atoms/Button/ButtonPrimary'
import ImportQuestionsFromQuiz from '../../_atomic-design/molecules/Dropdown/ImportQuestionsFromQuiz'
import eyeHideLine from '../../assets/images/Icons/eye-hide-line.svg'
import importLine from '../../assets/images/Icons/import-line.svg'
import ButtonSecondaryXsImage from '../../_atomic-design/atoms/Button/ButtonSecondaryXsImage'
import GeneralHeading from '../../_atomic-design/atoms/Text/GeneralHeading'
import noData from '../../assets/images/noData.svg'
import ImageTag from '../../_atomic-design/atoms/ImageTag'

export default function EditQuizMainBody ({ className, downloadLink, quizData, setNeedToSave, validateAndNext, loading, needToSave, isQuizpublished, saveQuiz }) {
  const [topicText, setTopicText] = useState(getCookies().selectedTopicName ? JSON.parse(getCookies().selectedTopicName) : '')
  const [showImportQuestions, setShowImportQuestions] = useState(false)
  const [showImportBar, setShowImportBar] = useState(false)
  const dispatch = useDispatch()
  const createdQuiz = useSelector((state) => state.quizReducer)
  const authState = useSelector((state) => state.authReducer)
  const navigate = useNavigate()
  /**
  * This function creates new new empty question of type 'M' with other default values and dispatch an action to store it in redux store
  * @summary This function is called when user clicks on 'Add Question' button
  */
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    })
  }
  const createNewQuestion = () => {
    if (!authState.isLoggedIn) {
      const addNewQuestionMessage = 'Please login to add new question'
      if (window.confirm(addNewQuestionMessage) === true) {
        navigate('/Auth')
      }
      return
    }
    setShowImportBar(true)
    const newQuestion = {
      _id: Date.now(),
      quizId: createdQuiz.quizId,
      questionContent: '',
      questChoices: [''],
      question_type: 'M',
      finalAnswer: '',
      score: 1,
      generatedAnswer: [],
      isNewlyCreated: true
    }
    dispatch(addQuestion({ newQuestion: newQuestion }))
    scrollToTop()
    setNeedToSave(true)
  }

  let counter = 1
  // questioncount constant is responsible storing length of question after filtering the deleted one.
  const questioncount = quizData.questions.filter(function (element) {
    return element.isDeleted !== true
  }).length
  const totalscore = quizData.totalScore

  function toggleHideButtonHandler () {
    setShowImportQuestions(!showImportQuestions)
  }

  return (
    <div className='w-full sm:w-1/2 mx-auto relative '>
      <div className="mt-40 md:mt-10 pb-10 text-xs sm:text-sm md:text-base">
        {questioncount !== 0
          ? (<div className="  flex flex-row justify-center gap-4 items-center w-full m:auto">
            <TextBody className={'font-semibold'} text="Total Question" />
            <div id='totalscore_joyride' className="rounded-md border border-black w-10 h-7 flex items-center justify-center">
              {questioncount}
            </div>
            <TextBody className={'font-semibold'} text="Total Score" />
            <div id='totalscore_joyride' className="rounded-md border border-black w-10 h-7 flex items-center justify-center">
              {totalscore}
            </div>
          </div>)
          : null}

        <div className=" justify-between pb-2 flex-col md:flex-row gap-2 pr-10 md:pr-0">
          <div className="mx-4 w-11/12 justify-center">
          </div>
          <div className="hidden mx-4 w-full">
            <QuestionSuggestionDropdown setNeedToSave={setNeedToSave} topicText={topicText} />
          </div>
        </div>
        {!showImportQuestions
          ? <ButtonSecondaryXsImage text={'Import questions from Previous Quizzes'} onClick={toggleHideButtonHandler} image={importLine} className={`mx-auto w-full my-4 ${showImportBar ? '' : 'hidden'}`} />
          : <div className=' bg-white rounded-lg w-full max-w-auto md:max-w-7xl my-6 flex-col text-xs md:text-base'>
            <div className='shadow-lg rounded-lg max-w-auto  md:max-w-7xl'>
              <div className='bg-green-100 flex flex-row p-2 px-6 rounded-t-md w-full'>
                <GeneralHeading text={'Questions'} className={' text-app-green font-bold text-xl'} />
                <ButtonSecondaryXsImage text={'Hide'} onClick={toggleHideButtonHandler} image={eyeHideLine} className={'bg-app-red ml-auto pr-2'} />
              </div>
              <ImportQuestionsFromQuiz topicText={topicText} createdQuiz={createdQuiz} saveQuiz={saveQuiz} toggleHideButtonHandler={toggleHideButtonHandler} />
            </div>
          </div>
        }
        {questioncount === undefined || questioncount === 0
          ? <div className='h-max w-full justify-center content-center shadow-md rounded-md p-2 pb-6 flex bg-white'><ImageTag src={noData} /></div>
          : null }
        {
          createdQuiz.questions && createdQuiz.questions.map((question, key) => {
            return (
              !question.isDeleted && <QuizQuestion key={question._id} index={counter++} question={question} setNeedToSave={setNeedToSave} setTopicText={setTopicText} topicText={topicText} />
            )
          })
        }
        <div className="  flex flex-row justify-center items-center w-max my-1 md:my-1 fixed bottom-0 left-[50%] -translate-x-1/2">
          {loading
            ? (
              <ReactLoading type="spin" color="#916AF8" className='mx-5' height={40} width={35} />
              )
            : (<div className="container flex flex-col items-center">
              <ButtonPrimary text={'Save & Next'} className={`w-1/2 ${questioncount !== 0
                  ? ''
                  : 'hidden'
                }`} onClick={validateAndNext} />
            </div>
              )}

        </div>

      </div>
      <div className='absolute right-5 sm:-right-5'>
        <RightFunctionalityBar addNewQuestion={createNewQuestion} downloadLink={downloadLink} saveQuiz={saveQuiz} />
      </div>
    </div>
  )
}
