import React, { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { makeAuthenticatedServerRequest } from '../../utils/helpers'
import ReactLoading from 'react-loading'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
import { useDispatch } from 'react-redux'
import { userEmailVerificationSuccess } from '../../store/actions/authActions/authActions'
// import { getCookies, setUserDetails } from '../../utils/cookieStorage'
import { toast } from 'react-toastify'

export default function Redirecting () {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    const getdata = async () => {
      if (token && token.length > 0) {
        try {
          const res = await makeAuthenticatedServerRequest(
            `/auth/verifyEmail/?token=${token}`, 'POST', {}
          )
          if (res.status === 200) {
            dispatch(userEmailVerificationSuccess())
            // const userDetails = JSON.parse(getCookies().userDetails)
            // userDetails.isEmailVerified = true
            // setUserDetails(userDetails)
            navigate('/AddMoreDetails')
          } else {
            toast('Your verification link is expired')
            navigate('/')
          }
        } catch (error) {
          toast('Please verify again')
          navigate('/SendVerificationMail')
        }
      }
    }
    getdata()
  }, [token])
  return (
    <div className="bg-[#F3F0FA] w-full h-full">
      <div className="flex justify-center items-center h-full w-full">
        <div className="flex flex-col items-center justify-center">
          <ReactLoading type="spin" color="#916AF8" height={40} width={35} />
          <GeneralText text="Loading" className="text-lg " textColor="#8562D5" />
        </div>
      </div>
    </div>
  )
}
