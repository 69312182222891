import React from 'react'

import questionPalleteHidden from '../../assets/images/Icons/questionPalleteHidden.svg'

export default function QuestionPalleteHidden ({ showQuestionPallete }) {
  function clickHandler (e) {
    e.preventDefault()
    showQuestionPallete()
  }

  return <div className=" fixed right-4 bottom-40 p-2 rounded-full bg-white">
            <button onClick={clickHandler}><img src={questionPalleteHidden} className="w-20 overflow-clip" alt="question pallete" /></button>
    </div>
}
