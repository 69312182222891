import React, { useEffect, useState } from 'react'
import { makeAuthenticatedServerGETRequest, makeServerGETRequest } from '../../../utils/helpers'
import DefaultDropdown from '../Input/DefaultDropdown'
import { addQuestion } from '../../../store/actions/quizActions/quizActions'
import { useDispatch } from 'react-redux'
import ReactLoading from 'react-loading'
import ButtonSecondaryXsImage from '../../atoms/Button/ButtonSecondaryXsImage'
import Import from '../../../assets/images/Icons/Import.svg'
import questionMark from '../../../assets/images/Icons/questionMark.svg'
import ImageTag from '../../atoms/ImageTag'

export default function ImportQuestionsFromQuiz ({ saveQuiz, createdQuiz }) {
  const [selectedQuizId, setSelectedQuizId] = useState(null)
  const [optionsArray, setOptionsArray] = useState([{ text: 'null', value: null }])
  const [questionsFromServer, setQuestionsFromServer] = useState([])
  const [cart, setCart] = useState([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const getdata = async () => {
      const data = await makeAuthenticatedServerGETRequest(
        '/quiz/getByUserId'
      )
      if (data.status === 200) {
        setOptionsArray(data.data.quiz.reverse()
          .filter((data) => data._id !== createdQuiz.quizId)
          .map((data) => { return { text: data.name, value: data._id } }))
      }
    }
    getdata()
    document.title = 'Dashboard'
  }, [])

  useEffect(() => {
    setCart([])
    const getData = async () => {
      setLoading(true)
      const res = await makeServerGETRequest('/quiz', { quizId: selectedQuizId })
      // console.log(res)
      if (res.status === 200) {
        setQuestionsFromServer(res.data.quiz.questions)
      }
      setLoading(false)
    }
    if (selectedQuizId === null) {
      return
    }
    getData()
  }, [selectedQuizId])

  function importAll () {
    questionsFromServer.map((question, index) => {
      return dispatch(addQuestion({ newQuestion: { ...question, _id: Date.now() + index, quizId: createdQuiz.quizId, isNewlyCreated: true } }))
    })
    alert(`Imported ${questionsFromServer.length} Questions`)
  }

  function importSelection () {
    questionsFromServer
      .filter((question) => cart.includes(question._id))
      .map((question, index) => {
        return dispatch(addQuestion({ newQuestion: { ...question, _id: Date.now() + index, quizId: createdQuiz.quizId, isNewlyCreated: true } }))
      })
    alert(`Imported ${cart.length} Questions`)
    setCart([])
  }

  function toggleSelection (id) {
    let newCart = [...cart]
    if (cart.includes(id)) {
      newCart = cart.filter((item) => item !== id)
    } else {
      newCart.push(id)
    }
    setCart(newCart)
  }

  const regex = /(<([^>]+)>)/ig

  return (optionsArray.length > 0 && < div className='my-2 p-6 flex flex-col w-full '>
    <DefaultDropdown options={optionsArray} onChange={(e) => setSelectedQuizId(e.target.value)} className={'w-full my-2'}/>
    {loading
      ? <ReactLoading type="spin" color="#916AF8" className='mx-auto' height={40} width={35} />
      : questionsFromServer.map((question) => {
        return <div key={question._id} className=' '>
          <div className='flex'>
            <ImageTag src={questionMark} />
          <label className='mr-auto ml-2 my-auto pr-4'>{question.questionContent ? question.questionContent.replace(regex, '') : question.questionContent}</label>
          <input type='checkbox' value={question.questionContent} name={question.questionContent} onClick={() => toggleSelection(question._id)} className=''></input>
          </div>
          <hr className='my-4'/>
        </div>
      })
  }
  <div className='flex flex-row space-x-2 my-2 ml-auto'>
    <ButtonSecondaryXsImage image={Import} className={'w-max sm:px-2'} text={'Import All'} onClick={importAll} disabled={questionsFromServer.length === 0} />
    <ButtonSecondaryXsImage image={Import} className={'w-max sm:px-2'} text={`Import Selection (${cart.length})`} onClick={importSelection} disabled={cart.length === 0} />
  </div>
    </div >)
}
