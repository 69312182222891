import React, { useState } from 'react'
import PrivacyNPolicyModal from '../../../containers/Modals/PrivacyNPolicyModal'
import TermsNConditionsModal from '../../../containers/Modals/TermsNConditionsModal'
import GeneralButton from '../../atoms/Button/GeneralButton'

export default function TermsAndConditionsText ({ className }) {
  const [terms, setTerms] = useState(false)
  const [privacy, setPrivacy] = useState(false)
  const handleTerms = () => {
    setTerms(!terms)
  }
  const handlePrivacy = () => {
    setPrivacy(!privacy)
  }
  return (
        <div className={`${className}`} >
          <TermsNConditionsModal active={terms} setActive={handleTerms} />
          <PrivacyNPolicyModal active={privacy} setActive={handlePrivacy} />
            <p >
              By clicking “Get started”, you agree to{' '}
              <GeneralButton content={'Terms of Services'} className="text-app-primary underline cursor-pointer" onClick={handleTerms} />
              {' and '}
              <GeneralButton content={'Privacy Notice.'} className="text-app-primary underline cursor-pointer" onClick={handlePrivacy} />
              {' and '}
          </p>
        </div>
  )
}
