import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
import DisplayTag from '../../_atomic-design/molecules/Others/DisplayTag'
import Profile from '../../assets/images/Icons/Profile.svg'
// import CalendarSrc from '../../assets/images/Icons/Calendar.svg'
// import AverageScoreIcon from '../../assets/images/Icons/AverageScoreIcon.svg'
// import TwoUserSrc from '../../assets/images/Icons/TwoUser.svg'
// import TimeSrc from '../../assets/images/Icons/Time.svg'
// import TimeIcon from '../../assets/images/Icons/time-icon.svg'
import attemptDate from '../../assets/images/Icons/attemptDate.svg'
// import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
// import TextSm from '../../_atomic-design/atoms/Text/TextSm'
// import ButtonSecondaryXs from '../../_atomic-design/atoms/Button/ButtonSecondaryXs'
// import EvaluationStatusBox from '../Evaluation/EvaluationStatusBox'
// import ButtonSecondaryXs from '../../_atomic-design/atoms/Button/ButtonSecondaryXs'
import ButtonSecondaryXsImage from '../../_atomic-design/atoms/Button/ButtonSecondaryXsImage'
// import HeadingMd from '../../_atomic-design/atoms/Text/HeadingMd'
// import HeadingSm from '../../_atomic-design/atoms/Text/HeadingSm'
// import ButtonPrimarySm from '../../_atomic-design/atoms/Button/ButtonPrimarySm'
import ArrowReload from '../../assets/images/Icons/ArrowReload1.svg'
import { toast } from 'react-toastify'
import { makeAuthenticatedServerRequest } from '../../utils/helpers'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import TooltipLeft from '../../_atomic-design/molecules/Others/TooltipLeft'

export default function StudentCardNew ({ data, quiz, studentQuiz }) {
  const navigate = useNavigate()
  const studentData = studentQuiz.length > 0 ? studentQuiz[0] : { marksObtained: 0, createdAt: '' }
  const [loading, setLoading] = useState(false)

  const retakeHandler = async () => {
    const saveQuizMessage = 'Are you sure that if you reset the attempted data of the student, it will be lost?'
    if (window.confirm(saveQuizMessage) === false) {
      return
    }
    setLoading(true)
    const res = await makeAuthenticatedServerRequest('/quiz/retakeQuiz', 'POST', {
      studentId: data._id,
      quizId: quiz._id
    })
    if (res) {
      if (res.status === 200) {
        toast('Reset successfull.')
        setTimeout(() => {
          navigate(0)
        }, 2000)
      }
    } else {
      toast('Reinitiate  error something happened wrong.')
    }
    setLoading(false)
  }

  return (
    <div className="w-full overflow-auto lg:h-auto max-w-7xl mb-4 px-5 p-4 shadow-4sides flex flex-col rounded-[10px] bg-white ">
      <div className=" flex flex-col sm:flex-row flex-wrap">
        <div className='flex flex-row items-end'>
          <ImageTag src={Profile} className={'my-auto mr-2'}/>
          <GeneralText text={data.firstName + ' ' + (data.lastName ? data.lastName : '') } className=' text-app-primary font-bold text-xs sm:text-sm md:text-base lg:text-lg my-auto' />
          <GeneralText text={data.email} className=' ml-5 font-medium text-[#5E5E5E] my-auto' />
        </div>
        <hr className='my-2 sm:hidden'/>
        <div className="flex flex-row gap-2 ml-auto">
          {/* <DisplayTag src={TimeIcon} text={'Time Taken : ' + (studentData.createdAt === '' ? 'NA' : (studentData.timeTakenByUser.minutes + ' m ' + studentData.timeTakenByUser.seconds + ' s ')) } rounded /> */}
          <DisplayTag src={attemptDate} text={'Attempt Date : ' + (studentData.createdAt === '' ? 'not attempted' : new Date(studentData.createdAt).toLocaleString())} />
        </div>
      </div>
      <hr className='my-2'/>
      <div className='flex flex-col sm:flex-row '>
        {/* <EvaluationStatusBox statusMl={quiz.mlEvaluation} scoreMl={studentData.marksGivenByMl} statusInstructor={studentData.instructorEvaluation} scoreInstructor={studentData.marksGivenByInstructor}/> */}
        <EvaluationStatusTable statusMl={quiz.mlEvaluation} scoreMl={studentData.marksGivenByMl} statusInstructor={studentData.instructorEvaluation} scoreInstructor={studentData.marksGivenByInstructor}/>
        {/* <ButtonSecondaryXsImage text={'Evaluate'} image={ArrowReload} className={'my-auto mx-auto'} disabled={!quiz.mlEvaluation || studentData.createdAt === ''} onClick={() => { navigate(`/EvaluateQuiz?quizId=${quiz._id}&&studentId=${data._id}`) }} /> */}
        <div className='flex flex-row my-2 sm:my-auto gap-2 ml-auto w-max h-max'>
        <ButtonSecondaryXsImage text={'Evaluate'} image={ArrowReload} className={''} disabled={studentData.createdAt === ''} onClick={() => { navigate(`/EvaluateQuiz?quizId=${quiz._id}&&studentId=${data._id}`) }} />
        {studentData.createdAt === ''
          ? (null)
          : (
              loading
                ? (
                <ButtonSecondaryXsImage text={'Reseting...'} image={ArrowReload} className={''} disabled={true} />
                  )
                : (
                  <TooltipLeft
                  content={<ButtonSecondaryXsImage text={'Reset'} image={ArrowReload} className={''} disabled={studentData.createdAt === '' || new Date() > new Date(quiz.settings.endDate)} onClick={retakeHandler} />}
                  message={'This will erase the answers submitted by the student. This will also allow the student to re-attempt the exam if student attempts within valid time frame.'}
                  />
                  )

            )
        }
        </div>
        </div>
  </div>
  )
}

function CompletedStatus ({ isCompleted, className }) {
  return (
    <GeneralButton content={isCompleted ? 'Completed' : 'In Progress'} className={`${className} ${isCompleted ? 'bg-green-500' : 'bg-red-500'} w-20 sm:w-28 text-white rounded cursor-text `} />
  )
}

// function SmallBox ({ groupName, status, score }) {
//   return (
//     <div className='flex flex-col border rounded-md shadow-md p-1 w-[180px] h-[90px] mx-auto my-auto'>
//       <TextSm text={groupName} className="font-bold" />
//       <hr className='my-1'/>
//       <div className='flex flex-row '><TextSm text={'Status : '} /><CompletedStatus isCompleted={status} className="ml-2"/></div>
//       <div className='flex flex-row '><TextSm text={'Score : '} /><TextSm text={score === undefined ? 'NA' : score} className="text-app-primary ml-2" /></div>
//     </div>
//   )
// }

function EvaluationStatusTable ({ statusMl, scoreMl, statusInstructor, scoreInstructor }) {
  return <div className=' flex flex-col w-full sm:w-max min-w-max font-bold border rounded-lg'>
    <div className='flex flex-row  justify-between content-start h-1/3 p-2 bg-[#EFEFF4]'>
      <div className='flex w-22 sm:w-40 '><p>Evaluation</p></div>
      <div className='flex w-22 sm:w-60 '><p className='w-max mx-auto'>learnkraft.ai</p></div>
      <div className='flex w-22 sm:w-60'><p className='w-max mx-auto'>Instructor</p></div>
    </div>
    <div className='flex flex-row justify-between h-1/3 p-2'>
        <div className='flex w-22 sm:w-40'><p>Status</p></div>
        <div className='flex w-22 sm:w-60 '><div className='w-max mx-auto'><CompletedStatus isCompleted={statusMl}/></div></div>
        <div className='flex w-22 sm:w-60'><div className='w-max mx-auto'><CompletedStatus isCompleted={statusInstructor}/></div></div>
    </div>
      <hr />
    <div className='flex flex-row justify-between h-1/3 p-2'>
        <div className='flex w-22 sm:w-40'><p>Score</p></div>
        <div className='flex w-22 sm:w-60 '><p className='w-max mx-auto'>{scoreMl === undefined ? 'NA' : scoreMl.toFixed(2)}</p></div>
        <div className='flex w-22 sm:w-60'><p className='w-max mx-auto'>{scoreInstructor === undefined ? 'NA' : scoreInstructor.toFixed(2)}</p></div>
    </div>
  </div>
}
