import React from 'react'
import CustomizedTextArea from '../_atomic-design/molecules/Input/CustomizedTextArea'

export default function SubjectiveQuestion ({ answer, changeAnswer, subjectiveQuestionId }) {
  // const [currSelected, setCurrent] = useState('')
  return (
    <div className="SubjectiveQuestionId_joyride" id={`SubjectiveQuestionId_joyride_${subjectiveQuestionId}`}>
      <p className='text-xs md:text-base font-semibold text-app-primary' >Answer</p>
      <CustomizedTextArea className='text-xs md:text-base pb-1 md:pb-3' defaultValue={answer} onChange={changeAnswer} border />
      {/* <p className='text-1[#5E5E5E] text-xs my-2' >Generated by AI</p> */}
    </div>
  )
}
