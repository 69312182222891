import React from 'react'
import DesktopImg from '../assets/images/DesktopImg.png'
import EndpageEllipseRight from '../assets/images/EndpageEllipseRight.svg'
import EndpageEllipseLeft from '../assets/images/EndpageEllipseLeft.svg'
import ImageTag from '../_atomic-design/atoms/ImageTag'
import GeneralHeading from '../_atomic-design/atoms/Text/GeneralHeading'
import GeneralText from '../_atomic-design/atoms/Text/GeneralText'
import GeneralButton from '../_atomic-design/atoms/Button/GeneralButton'

import { HashLink } from 'react-router-hash-link'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function EndHomePage () {
  const authState = useSelector((state) => state.authReducer)
  const navigate = useNavigate()
  const handlePrivacy = () => {
    navigate('/privacy-policies')
  }
  const handleTermsNConditions = () => {
    navigate('/terms-and-conditions')
  }
  return (
    <div className="mt-5 lg:mt-10 h-max pt-24 ">
      <div className="h-72 lg:h-72  flex flex-col rounded-tr-[37px] rounded-tl-[37px] lg:rounded-tr-[114px] lg:rounded-tl-[114px] bg-gradient-to-b from-[#403ACE] to-[#8763D5] lg:to-[#AE7ADA] z-0 relative ">
        <div className="h-max lg:h-48 w-[90%] overflow-hidden rounded-[20px] lg:rounded-[28px] px-2 lg:px-10 z-30 flex flex-col sm:flex-row justify-center items-center absolute -translate-y-[20%] sm:-translate-y-[50%] left-[50%] -translate-x-[50%]  sm:bg-[#F8F7FE]">
          <ImageTag src={EndpageEllipseRight} className='hidden lg:block absolute right-0 bottom-0 -z-10' />
          <ImageTag src={EndpageEllipseLeft} className='hidden lg:block absolute left-0 bottom-0 h-full -z-10' />
          <div className="sm:ml-20 order-2 sm:order-1 sm:mr-auto">
            <GeneralHeading text={<>One app to <br className='hidden sm:block '/> create all quiz.</>} className="text-xl lg:text-[44px] leading-10 text-transparent bg-clip-text text-white sm:text-app-primary font-bold" />
            <GeneralText text={<>Create <b>multiple types of quiz</b> in one app</>} className="text-xs lg:text-xl my-1 text-white sm:text-black w-max mx-auto" />
            <HashLink className='cursor-pointer py-3  font-bold' to='/#' smooth >
            <GeneralButton content='Explore' className='bg-gradient-to-r to-[#3C38CD] from-[#CE8CDD] text-white text-xs block lg:hidden lg:text-base h-7 w-24 lg:h-14 lg:w-56 rounded-full drop-shadow-xl mx-auto' />
            </HashLink>
          </div>
          <ImageTag src={DesktopImg} className="lg:h-full w-1/2 sm:w-auto order-1 sm:order-2" />
          <HashLink className='cursor-pointer py-3 h-max font-bold order-3 sm:ml-auto sm:mr-20' to='/#' smooth >
          <GeneralButton content='Explore' className='hidden lg:block bg-gradient-to-r from-[#3C38CD] to-[#CE8CDD] text-white text-base h-14 w-56 rounded-full drop-shadow-xl' />
          </HashLink>
        </div>
      {/* <Testimonials /> */}
      {(authState && authState.isLoggedIn)
        ? (<GeneralText className={' text-black text-sm absolute bottom-1 z-50'} text='*' />
          )
        : null}
        <div className='text-white text-xs lg:text-base mx-auto mb-4 mt-auto h-max text-center'>©2024 | Edtech Kraft Pvt. Ltd. | &nbsp;
          {<GeneralButton content={'Privacy Policies'} className="text-white cursor-pointer" onClick={handlePrivacy} />}
          &nbsp; | &nbsp;
          {<GeneralButton content={'Terms and Conditions'} className="text-white cursor-pointer" onClick={handleTermsNConditions} />}

        </div>
      </div>
    </div>
  )
}
