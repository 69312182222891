import React from 'react'
import RenderLatex from '../../helpers/RenderLatex'
import OptionPicture from '../Common/OptionPicture'

function RadioButtonBox ({ text, image, inputChangeHandler, index, className, disabled, checked, readOnly }) {
  return <div key={text} className={` ${className}  w-max flex text-start hover:opacity-50 transition-opacity border rounded-lg bg-[#F3F0FA] p-2`}>
        <input type="radio" disabled={disabled} readOnly={readOnly} checked={checked} className=" mt-1 mb-auto checked:bg-green-500 " onClick={inputChangeHandler} name="selectedChoice" value={index} />
    <label htmlFor={text} className="ml-2">
      <RenderLatex>{text}</RenderLatex>
      {/* <ImageTag src={image} /> */}
      {image && (
                 <div className='rounded-lg w-max justify-center items-center' >
              <OptionPicture optionImage={image} setOptionImage={(picUrl) => {}} handleOptionImageChange={(picUrl) => {}} uploadEnabled={false}/>
            </div>)}
    </label>
            </div>
}

export default RadioButtonBox
