/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import HeadingSm from '../../_atomic-design/atoms/Text/HeadingSm'
import ButtonSecondarySm from '../../_atomic-design/atoms/Button/ButtonSecondarySm'
import { setUserDetails } from '../../utils/cookieStorage'
import { makeAuthenticatedServerRequest } from '../../utils/helpers'
import { useSelector, useDispatch } from 'react-redux'
import { UserInfo } from '../../store/actions/authActions/authActions'
import HomePageBackground from '../../assets/images/MainBackgroundNew.png'
import HomepageBgShape from '../../assets/images//Homepage_bg_shape.svg'
import HomeNavbar from '../../_atomic-design/molecules/Navbars/HomeNavbar'
import CreateQuizButton from '../../_atomic-design/molecules/Buttons/CreateQuizButton'
import { toast } from 'react-toastify'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
import ReactLoading from 'react-loading'

export default function Subscription2 (route, navigation) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const authState = useSelector((state) => state.authReducer)
  const [descSubscription, setDescSubscription] = useState(false)
  const [activeSubscriber, setActiveSubscriber] = useState(false)
  const [loading, setLoading] = useState(true)
  const style = {
    backgroundImage: `url(${HomePageBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat'
  }

  /* eslint-disable no-unused-vars */
  const updateuserdata = async () => {
    setLoading(true)
    const userDetails = await makeAuthenticatedServerRequest('/profile', 'GET')
    setLoading(false)
    if (userDetails && (userDetails.status === 200)) {
      dispatch(UserInfo({ ...userDetails.data.user }))
      // Store user details in cookies so no need to make API call everytime
      setUserDetails(userDetails.data.user)
      return userDetails.data.user
    } else {
      toast('Something went wrong')
      navigate('/Auth')
    }
  }
  const bgImage = {
    backgroundImage: `url(${HomePageBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat'
  }
  const bgShape = {
    position: 'absolute',
    right: '0',
    top: '0'
    // zIndex: '10'
  }

  useEffect(async () => {
    document.title = 'subscription'
    if (!authState.isLoggedIn) {
      setTimeout(() => {
        navigate('/Auth?mode=login')
      }, 3000)
    }
    const userdata = await updateuserdata()
    const subscriptionList = userdata.subscription
    if (userdata.activeSubscription === true) {
      setActiveSubscriber(true)
    }

    if (subscriptionList.length > 0) {
      setDescSubscription(subscriptionList.sort((a, b) => a - b).reverse())
    }
  }, [])

  return (
    <>
    <HomeNavbar />
    <div className="  font-sans font-semibold min-h-screen h-full relative pt-16 overflow-x-hidden bg-gradient-to-b from-[#E096DF] to-[#403ACE]" style={bgImage}>
    <img src={HomepageBgShape} alt="" className='w-3/4 sm:w-1/2' style={bgShape} />
      <div className="relative rounded-2xl item-center sm:py-10 mx-auto grid h-screen place-items-center  break-normal ">
          {loading
            ? (<ReactLoading type="spin" color="#916AF8" height={40} width={35} />)
            : (descSubscription
                ? (
            <div className='w-[90vw] sm:w-3/4 text-xs '>
              <div className="w-full mx-auto my-4 text-center">
                  <div>
                    {activeSubscriber
                      ? (
                  <div className="relative overflow-x-auto shadow-md rounded-lg w-full sm:w-max mx-auto bg-white">
                    <GeneralText text={'Your current subscribed plan'} className={'bg-app-grey text-app-secondary text-lg sm:text-2xl font-bold sm:px-8 p-2'} />
                    <div className='flex flex-col sm:flex-row p-4 justify-between text-sm gap-4'>
                        <div className='flex'>{'Your Plan: '}<p className='text-app-primary ml-2'>{descSubscription[0].name ? descSubscription[0].name : 'NA'}</p></div>
                        <div className='flex'>{'Your Next Bill: '}<p className='text-app-primary ml-2'>{descSubscription[0].endDate ? descSubscription[0].endDate.split('T')[0] : 'NA'}</p></div>
                    </div>
                  </div>
                        )
                      : (
                      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <HeadingSm text="Your expired plan" className="bg-app-blue-2xlight"/>
                            <p>Your subscription expired on</p>
                            <p>Plan Name</p>
                            <p>Subscribe here</p>
                        <p>{descSubscription[0].endDate ? descSubscription[0].endDate.split('T')[0] : 'NA'}</p>
                        <p>{descSubscription[0].name ? descSubscription[0].name : 'NA'}</p>
                        <p>
                        <Link to={'/'} >
                            <ButtonSecondarySm text={'Suscribe here'} className='font-medium' />
                          </Link>
                        </p>
                  </div>)}
                  </div>
              </div>

              <div className=" shadow-md rounded-xl mx-auto my-4 p-4 text-center bg-white w-full ">
              <GeneralText text={'Subscription History'} className={'text-app-secondary py-1 sm:pb-4 text-lg sm:text-2xl text-start sm:text-center'}/>
              <div className='border-0 sm:border   rounded-md'>
              <div className='hidden sm:flex w-full content-center bg-gray-400 rounded-t-md text-sm'>
              <p className='w-1/6  p-2'>Date</p>
              <p className='w-1/6  p-2'>Description</p>
              <p className='w-1/6  p-2'>Service Period</p>
              <p className='w-1/6  p-2'>Plan Amount</p>
              <p className='w-1/6  p-2'>Amount after discount</p>
              <p className='w-1/6  p-2'>Total amount paid</p>
              <p className='w-1/6  p-2'>Invoice</p>
              </div>
              {descSubscription.map((item, index) => (
                      <div key={index} className='w-full'>
                      <SCard date = {item.startDate.split('T')[0]} description={item.name} servicePeriod= {`${item.startDate.split('T')[0]} to ${item.endDate.split('T')[0]}`} planAmount={item.planAmount ? (item.planAmount * 1) : 0} amountAfterDiscount={item.paidamount ? (item.paidamount * 1) : 0} amountPaid={item.paidamount ? (item.paidamount * 1) : 0} invoice={<a href={item.invoice ? (item.invoice) : 'Not paid'} target='_blank' className={`text-blue-700 block px-4 py-2 text-sm ${item.invoice ? '' : 'hidden'}`} role="menuitem" tabIndex="-1" id="menu-item-0" rel="noopener noreferrer" >Download </a>}/>
                      <hr></hr>
                    </div>
              ))}
              </div>
              </div>
            </div>
                  )
                : (
              <div className="relative overflow-x-auto shadow-md rounded-lg">
              <table className="w-fit text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className='text-xs text-gray-700 uppercase bg-gray-300 dark:bg-gray-700 dark:text-gray-400'>
                  <tr>
                    <th scope="col" className="px-6 py-3">You have no subscription yet</th>
                    <th scope="col" className="px-6 py-3">
                         <Link to={'/'} >
                            <ButtonSecondarySm text={'Suscribe here'} className='font-medium' />
                          </Link>
                    </th>
                  </tr>
                </thead>
                <tbody>
              </tbody>
             </table>
          </div>
                  ))
              }
        </div>
      </div>
      <CreateQuizButton />
    </>
  )
}

function SCard ({ date, description, servicePeriod, planAmount, amountAfterDiscount, amountPaid, invoice }) {
  return <div className='flex text-start w-full flex-col sm:flex-row text-xs sm:text-sm gap-2 sm:gap-0 my-4'>
    <div className='flex w-max sm:w-1/6 p-2 text-center justify-center'><p className='font-semibold sm:hidden'>{'Date: '}</p><p className='text-gray-600 ml-2'>{date}</p></div>
    <div className='flex w-max sm:w-1/6 p-2 text-center justify-center'><p className='font-semibold sm:hidden'>{'Description: '}</p><p className='text-gray-600 ml-2'>{description}</p></div>
    <div className='flex w-max sm:w-1/6 p-2 text-center justify-center'><p className='font-semibold sm:hidden'>{'Service Period: '}</p><p className='text-gray-600 ml-2'>{servicePeriod}</p></div>
    <div className='flex w-max sm:w-1/6 p-2 text-center justify-center'><p className='font-semibold sm:hidden'>{'Plan Amount: '}</p><p className='text-gray-600 ml-2'>{planAmount}</p></div>
    <div className='flex w-max sm:w-1/6 p-2 text-center justify-center'><p className='font-semibold sm:hidden'>{'Amount after discount: '}</p><p className='text-gray-600 ml-2'>{amountAfterDiscount}</p></div>
    <div className='flex w-max sm:w-1/6 p-2 text-center justify-center'><p className='font-semibold sm:hidden'>{'Total amount paid: '}</p><p className='text-gray-600 ml-2'>{amountPaid}</p></div>
    <div className='flex w-max sm:w-1/6 p-2 text-center justify-center'><p className='font-semibold sm:hidden'>{'Invoice: '}</p>{invoice}</div>
  </div>
}
