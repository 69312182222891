import React from 'react'
// import crossSrc from '../../assets/images/Icons/cross.svg'
import LateQuizGeneration from '../../assets/images/lateQuizGeneration.svg'
import ButtonPrimaryLg from '../../_atomic-design/atoms/Button/ButtonPrimaryLg'
// import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
// import HeadingLg from '../../_atomic-design/atoms/Text/HeadingLg'
import { useNavigate } from 'react-router-dom'

export default function ShowDashboardQuiz ({ className, active, setActive }) {
  const navigate = useNavigate()
  const goToDashBoard = () => {
    navigate('/Dashboard')
  }
  if (!active) return null
  return (
    <div className='fixed top-10 left-0 w-screen h-screen bg-white/20 backdrop-blur-[20px] z-50 flex justify-center items-center py-[5%]' >
        <div className="w-fit md:w-1/2 mx-4 bg-white rounded-[20px] max-h-screen max-w-xl flex flex-col justify-center items-center m-8 p-8 pt-2" style={{ boxShadow: '0 0 27px rgb(60, 56, 205, 0.15)' }}>
        {/* {
          active && <ImageTag src={crossSrc} onClick={setActive} className='h-5 mb-4 ml-auto cursor-pointer ' />
        } */}
        <h1 className="font-bold text-[32px] md:text-3xl text-[#6042D1] md:mb-5 mt-4">Your Quiz will be ready soon.</h1>
        <ImageTag src={LateQuizGeneration} className='w-1/3 h-1/3 max-w-xs ' />
        <div className='justify-left mt-2'>
        <GeneralText
                      text={'It will take a longer time to generate your quiz than expected. We will notify you once your quiz is ready via your registered email.'}
                      className=" text-[15px] md:text-base font-medium"
                  />
        </div>
        <br/>
        <ButtonPrimaryLg className='' text='Go back to Dashboard' onClick={goToDashBoard}/>
        </div>
    </div>
  )
}
