import React, { useEffect, useState } from 'react'
import HeadingLarge from '../../_atomic-design/atoms/Text/HeadingLg'
import verifyEmail from '../../assets/images/Icons/verifyEmail.svg'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'
// import ButtonPrimaryLg from '../../_atomic-design/atoms/Button/ButtonPrimaryLg'
import { makeAuthenticatedServerRequest } from '../../utils/helpers'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import { toast } from 'react-toastify'
export default function VerifyYourEmail () {
  const navigate = useNavigate()
  const User = useSelector((state) => state.authReducer)
  const [disable, setDisable] = useState(false)

  const sendVerificationToken = async () => {
    setDisable(true)
    const res = await makeAuthenticatedServerRequest('/auth/sendVerificationToken', 'POST')
    if (res.status === 200) {
      toast('Verification email sent')
    } else {
      toast('Something went wrong')
    }
    setDisable(false)
  }
  const navigateToHome = () => {
    navigate('/')
  }

  useEffect(() => {
    document.title = 'SendVerificationMail'
    if (User.isEmailVerified) {
      toast('Email already verified')
      if (!User.detailsComplete) {
        navigate('/AddMoreDetails')
      } else {
        navigate('/')
      }
    }

    return () => {

    }
  }, [User])

  return (
    <div className='fixed w-screen h-screen bg-white/20 backdrop-blur-[20px] z-50 flex justify-center items-center gap-2'>
      <div className="w-fit mx-4 bg-white rounded-[20px] p-8 max-h-screen max-w-xl flex flex-col justify-center items-center " style={{ boxShadow: '0 0 27px rgb(60, 56, 205, 0.15)' }}>
        <ImageTag
          src={verifyEmail}
          className="flex justify-center items-center m-4"
        />
        <HeadingLarge
          className="font-medium text-center m-2"
          text="Verify Your Email"
        />
        <TextBody
          text={<><b>Check your email and click the link</b> to activate your account </>}
          className="mb-5 text-center"
        />
        <GeneralButton
                      content='Go to home'
                      onClick={navigateToHome}
                      className="w-4/5 h-11 rounded-[5px] bg-app-primary text-white text-xl"
                      disabled={disable}
        />
        <div className="text-app-primary pt-8 cursor-pointer gap-2" onClick={sendVerificationToken}>
            Resend verification link
        </div>
      </div>
    </div>
  )
}
