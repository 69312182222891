import React from 'react'
import RenderLatex from '../../helpers/RenderLatex'
// import NegativeLabel from '../../_atomic-design/atoms/Labels/NegativeLabel'
import PositiveLabel from '../../_atomic-design/atoms/Labels/PositiveLabel'
import HeadingSm from '../../_atomic-design/atoms/Text/HeadingSm'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'
import AddQuizPicture from '../Common/AddQuizPicture'

function QuestionText ({ questionText, questionImage, questionNo, className, score }) {
  const handleQuestionPictureChange = (picUrl) => {
    return 0
  }

  return <div className={`${className} flex flex-col w-full bg-white `}>
    <div className='flex flex-row justify-between'>
      <HeadingSm text={`Question ${questionNo}`} />
      {/* <NegativeLabel className="flex" text="-1 Mark" /> */}
      <PositiveLabel className="flex " text={score + ' marks'} />
    </div>
    <hr className='my-2'/>
    <TextBody text={<RenderLatex>{questionText}</RenderLatex>} className={' text-app-dark-grey '} />
    {questionImage && (
                 <div className='rounded-lg w-1/2 justify-center items-center' >
              <AddQuizPicture quizPicture={questionImage} setQuizPicture={handleQuestionPictureChange} handleQuestionPictureChange={handleQuestionPictureChange} uploadenable={false}/>
            </div>)}
  </div>
}

export default QuestionText
