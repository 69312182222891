import React from 'react'
import ImageCard from '../../_atomic-design/molecules/AboutUs/ImageCard'
import about1 from '../../assets/images/About1.png'
import HeadingLg from '../../_atomic-design/atoms/Text/HeadingLg'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'
import { about4 } from '../../assets/data/aboutUsData'
import parse from 'html-react-parser'

// const textData = 'Lorem ipsum dolor sit amet consectetur. Massa sit sed a phasellus. A tellus nisl tincidunt tincidunt vitae ipsum. Amet euismod in curabitur scelerisque. Porta feugiat faucibus adipiscing sit in lacus eget nisl. Gravida ultrices praesent in tempus eget consequat elementum in ultrices. Suspendisse ante elit odio venenatis ac varius. Sed purus aliquam feugiat morbi aenean tincidunt. Facilisis tellus posuere nisi consequat orci ut amet sapien velit. Cras fermentum diam eget velit. Aliquam in fringilla egestas integer nunc vulputate quis massa ut. Urna metus enim eleifend nec mus proin vestibulum vitae imperdiet. Convallis semper eget turpis dui mattis nulla leo. Consequat purus habitasse leo a lacus a dui turpis. Pellentesque sed proin morbi eget. Mattis dignissim dictum fusce euismod ornare diam.'

export default function About () {
  return <div className='grid grid-cols-1 sm:grid-cols-2 p-10 bg-white'>
  <ImageCard image={about1} className={'mx-auto block '}/>
  <div className='my-auto'>
    <HeadingLg text={'About learnkraft.ai'} className={'text-center sm:text-start my-2'}/>
    <TextBody text={ parse(about4) } className={'text-center sm:text-start'}/>
  </div>
  </div>
}
