import React, { useEffect, useState } from 'react'
import LeftBar from './LeftMenu'
import HeadingSm from '../../_atomic-design/atoms/Text/HeadingSm'
import { makeServerGETRequest } from '../../utils/helpers'
import { useSearchParams } from 'react-router-dom'
import ReactLoading from 'react-loading'
import { toast } from 'react-toastify'

export default function OverallTab ({ className, quiz, studentQuiz }) {
  const [pdfLink, setPdfLink] = useState()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    setLoading(true)
    const res = await makeServerGETRequest(
      '/downloadQuiz/questionDownload',
      {
        quizId: searchParams.get('quizId')
      }
    )
    if (res.status === 200) {
      setPdfLink(res.data.QuestionAnswer)
    } else {
      toast('error in fetching data')
    }
    setLoading(false)
  }, []
  )

  return (
    <div className="flex flex-col lg:flex-row pt-32 sm:pt-20  lg:pt-44 h-full">
        <div className="px-3 sm:px-14 ">
        <LeftBar quiz={quiz} studentQuiz={studentQuiz} />
        </div>
        <div className="flex-1 pt-10 mt-5 lg:mt-0 bg-app-gradient-setting overflow-auto">
            <div className={`w-full h-[90%] px-10 ${className}`}>
          <HeadingSm text={'Overview'} />
          {loading && <ReactLoading type="spin" color="#916AF8" className='mx-auto my-auto' height={80} width={80} />}
          {!loading &&
          <embed src={pdfLink} width="100%" height="100%"/>}
        </div>
        </div>
        </div>
  )
}
