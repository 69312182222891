import React, { useState, useRef, useEffect } from 'react'
import plusIcon from '../../../assets/images/Icons/plusIcon.svg'
import minus from '../../../assets/images/Icons/minus.svg'
import Image from '../../atoms/ImageTag'
import GeneralHeading from '../../atoms/Text/GeneralHeading'
import parse from 'html-react-parser'

function QuestionAddition ({ questionText, answerText }) {
  const [showAnswer, setShowAnswer] = useState(false)
  const answerRef = useRef(null)

  const handleClick = () => {
    setShowAnswer(!showAnswer)
  }

  useEffect(() => {
    if (showAnswer) {
      answerRef.current.style.maxHeight = '500px'
    } else {
      answerRef.current.style.maxHeight = '0px'
    }

    return () => {}
  }, [showAnswer])

  return (
        <div className="p-3 sm:p-6 py-5 mb-1 w-full rounded-xl bg-white cursor-pointer" onClick={handleClick}>
            <div className="flex justify-between items-center w-full">
                <GeneralHeading
                    text={questionText}
                    className="text-sm sm:text-lg font-medium"
                />
                <Image
                    src={showAnswer ? minus : plusIcon}
                    className="w-3 sm:w-4 ml-2 cursor-pointer"
                />
            </div>
            <div
                className="md:w-11/12 max-h-0 overflow-hidden  "
                ref={answerRef}
            >
                <div className="w-full text-sm sm:text-base">

                { parse(answerText) }
                </div>
            </div>
        </div>
  )
}
export default QuestionAddition
