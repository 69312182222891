import React, { useEffect, useState } from 'react'
// import ButtonPrimary from '../../_atomic-design/atoms/Button/ButtonPrimary'
import { toast } from 'react-toastify'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
// import crossCircle from '../../assets/images/Icons/crossCircle.svg'

import crossCircle from '../../assets/images/Icons/CrossGreenCircular.svg'
import TextInputGray2 from '../../_atomic-design/molecules/Input/TextInputGray2'

export default function PreviewName ({ setEmails, emailWithName, setEmailWithName }) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isValidEntry, setIsValidEntry] = useState(false)

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email)
  }

  function AddNewHandler () {
    if (!isValidEmail(email)) {
      toast('email not valid')
      return
    }
    if (name.length === 0) {
      toast('empty name field')
      return
    }
    const newEntry = { name, email }
    setEmailWithName((prev) => {
      return [...prev, newEntry]
    })
    // setEmails((prev) => prev + ',' + email)
    setName('')
    setEmail('')
  }

  function RemoveHandler (id) {
    console.log('removing')
    const newList = emailWithName.filter((item, index) => { return id !== index })
    setEmailWithName(newList)
  }

  useEffect(() => {
    setIsValidEntry(isValidEmail(email) && name.length > 0)
  }, [name, email])

  return <div className='w-full mx-auto my-2 border rounded-md overflow-scroll'>
<table className='text-xs sm:text-sm mx-auto w-full '>
    <tbody>
      <tr className='flex flex-row justify-start p-2 bg-[#EFEFF4]'>
        <th className='w-[30px] '>No.</th>
        <th className='w-[130px] sm:w-[250px]  text-start'>Name</th>
        <th className='w-[130px] sm:w-[250px]  text-start'>Email</th>
        {/* <th className='w-[30px]'>X</th> */}
        {/* <th className='w-[100px]'>Description</th> */}
      </tr>
    {emailWithName.map((item, index) => {
      return <tr key={index + item.name + item.email} className='flex flex-row border-b justify-start p-2'>
        <td className='w-[30px] text-center '>{index + 1}.</td>
        <td className='w-[130px] sm:w-[250px] text-start '>{item.name}</td>
        <td className='w-[130px] sm:w-[250px] text-start '>{item.email}</td>
        {/* <td className='w-[100px]  text-center border'>{item.description}</td> */}
        <td className='ml-auto'>
          <ImageTag
                    src={crossCircle}
                    className="w-3 ml-2 cursor-pointer justify-self-end rounded-full"
                    onClick={() => RemoveHandler(index)}
                />
          </td>
      </tr>
    })}
      <tr className='flex flex-row items-center justify-start p-1'>
        <td className='w-[30px] text-center '></td>
        <td className='w-[130px] sm:w-[250px] text-start'><TextInputGray2 value={name} onChange={(e) => { setName(e.target.value) }} className={' '} placeholder='Name' label='' /></td>
        <td className='w-[130px] sm:w-[250px] text-start'><TextInputGray2 value={email} onChange={(e) => { setEmail(e.target.value) }} className={''} placeholder='Email'/></td>
        {/* <ButtonPrimary onClick={AddNewHandler} text={'Add new'} className={'w-max h-max p-1 ml-auto text-sm'}/> */}
        <button onClick={AddNewHandler} disabled={!isValidEntry} className={ ' w-max h-max p-1 px-2 ml-auto text-sm block rounded-md text-white  bg-app-primary disabled:bg-app-grey  cursor-default'}> Add </button>
      </tr>
    </tbody>
    </table>
  </div>
}
