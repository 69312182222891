import React from 'react'

function QuizRadioInput ({ id, name, onChange, selected, value }) {
  const regex = /(<([^>]+)>)/ig
  const trimedValue = value ? value.replace(regex, '').trim() : 'NA'
  const trimedselected = selected ? selected.replace(regex, '').trim() : 'NA'
  // console.log('radio', trimedValue, trimedselected, (trimedValue === trimedselected), value)
  return (
        <div className="relative w-5 h-5">
            <input
                type="radio"
                name={name}
                className=" MCQ_joyride_radio mr-2 cursor-pointer absolute left-1 top-1 opacity-0 z-20"
                onChange={() => onChange(id, value)}
                checked={trimedselected === trimedValue}
            />
            <span
                className={` absolute left-0 top-0 border rounded-full w-5 h-5 bg-white after:content-[''] after:absolute after:h-3 after:w-3 after:bg-[#3C38CD] after:rounded-full after:left-1 after:top-1 ${
                    trimedselected === trimedValue ? 'after:block' : 'after:hidden'
                } `}
            ></span>
        </div>
  )
}
export default QuizRadioInput
