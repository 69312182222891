import React from 'react'
import AppRoute from './routes/AppRoute'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import PermanentRedirect from './containers/Modals/PermanentRedirect'
// open below line for chatbot
// import './chatbotScript/testscript2'

function App () {
  return (
    <div className="App">
      {/* <PermanentRedirect active={true}/> */}
      <AppRoute />
      <ToastContainer autoClose={2000}/>
    </div>
  )
}

export default App
