// import React from 'react'
const HomePageJoyRide = {
  STEPS: [
    {
      title: 'Step 1: upload file',
      content: 'Drag and drop your file from where you want to generate quiz questions.',
      target: '#dfdsfsdfs',
      placement: 'bottom'
    },
    {
      title: 'Step 2:subjective question',
      content: 'select the number of subjective question',
      target: '#subjective_question_number',
      placement: 'left'
    },
    {
      title: 'Step 3:objective question',
      content: 'select the number of objective question',
      target: '#objective_question_number',
      placement: 'left'
    },
    {
      title: 'Step 4:create quiz',
      content: 'After uploading the file and selecting subjective and objective questions, click here to generate the quiz.',
      target: '#create_quiz_button',
      placement: 'left'
    }

  ]
}

export default HomePageJoyRide
