import React from 'react'

export default function ButtonSecondaryXsImage ({ text, onClick, className, disabled, image }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
            // eslint-disable-next-line no-undef
      className={` ${disabled ? 'cursor-not-allowed bg-app-grey' : ' bg-app-primary'} w-max h-8 block rounded-md text-white text-xs md:text-base ${className}`} >
      <div className='flex flex-row px-2 w-max'><img src={image} alt='ic' className='h-4/5 aspect-square mr-2 my-auto'></img><div>{text}</div></div>
        </button>
  )
}
