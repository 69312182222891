import React from 'react'

export default function ToggleInput ({ toggleMarkForReview, textMain, isMarkedForReview }) {
  const toggleSwitch = () => {
    toggleMarkForReview()
  }

  return <div className=''>
    <label className="flex items-center">
      <div className={`flex w-12 h-6 rounded-full ${isMarkedForReview ? 'bg-red-200' : 'bg-blue-200'} `}>
        <input
          type="checkbox"
          className="hidden"
          checked={ isMarkedForReview }
          onChange={ toggleSwitch }
        />
        {isMarkedForReview
          ? <div className=" ml-auto my-auto w-6 h-6 bg-app-red rounded-full "></div>
          : <div className=" mr-auto my-auto w-6 h-6 bg-app-primary rounded-full "></div>}
      </div>
      <div className="ml-3 text-gray-700 font-medium">{textMain}</div>
    </label>
  </div>
}
