/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import GeneralHeading from '../../_atomic-design/atoms/Text/GeneralHeading'
import QuestionWrapper from '../../_atomic-design/molecules/CreateQuiz/QuestionWrapper'
import CustomizedTextArea from '../../_atomic-design/molecules/Input/CustomizedTextArea'
import { makeAuthenticatedServerRequest } from '../../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import { TbCameraPlus, TbCameraMinus } from 'react-icons/tb'

import SubjectiveQuestion from '../SubjectiveQuestion'
// import MultipleCorrectQuestion from './MultipleCorrectQuestion'
// import QuestionField from './QuestionField'
import SingleCorrectQuestion from './SingleCorrectQuestion'
import { updateQuestion, updateTotalScore } from '../../store/actions/quizActions/quizActions'
import EditQuestionLowerDiv from './EditQuestionLowerDiv'
// import TooltipRight from '../../_atomic-design/molecules/Others/TooltipRight'
// import AddQuizPicture from '../Common/AddQuizPicture'
// import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import ImageViewBox from '../../_atomic-design/molecules/Images/ImageViewBox'
import ButtonAddPicture from '../../_atomic-design/atoms/Button/ButtonAddPicture'
// import SetTopicName from '../Modals/SetTopicName'
// import { getCookies } from '../../utils/cookieStorage'

export default function QuizQuestion ({ className, index, question, setNeedToSave, topicText, setTopicText }) {
  const dispatch = useDispatch()
  const [Question, setQuestion] = useState(question)
  // const [showTopicModal, setShowTopicModal] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const createdQuiz = useSelector((state) => state.quizReducer)
  const authState = useSelector((state) => state.authReducer)
  const navigate = useNavigate()
  const [options, setOptions] = useState([])
  const [filterOptions, setFilterOptions] = useState([])
  const [quizPicture, setQuizPicture] = useState(question.questionPicture ? question.questionPicture : '')
  // const [imageAdded, setImageAdded] = useState(!!question.questionPicture)
  const handleQuestionTextChange = (text) => {
    setDropdownOpen(false)
    updateTextsearch(text)
    setQuestion({ ...Question, questionContent: text })
    setNeedToSave(true)
  }
  const handleQuestionPictureChange = (picUrl) => {
    setQuizPicture(picUrl)
    setQuestion({ ...Question, questionPicture: picUrl })
    setNeedToSave(true)
  }
  const handleTextclick = () => {
    // checkIfTopicNameSet()
    // console.log('dummy')
    return 0
  }
  // const addImageHandler = () => {
  //   // checkIfTopicNameSet()
  //   setImageAdded(true)
  // }
  const removeImageHandler = () => {
    setQuizPicture('')
    setQuestion({ ...Question, questionPicture: '' })
    // setImageAdded(false)
  }

  // const checkIfTopicNameSet = () => {
  //   const topicNameSet = getCookies().selectedTopicName
  //     ? JSON.parse(getCookies().selectedTopicName)
  //     : null
  //   if (topicNameSet) {
  //     setTopicText(topicNameSet)
  //   } else {
  //     setShowTopicModal(true)
  //   }
  // }

  const addNewOption = () => {
    setQuestion({ ...Question, questChoices: (Question.questChoices ? [...Question.questChoices, ''] : [' ']), options_details: (Question.options_details ? [...Question.options_details, { optioni: Question.options_details.length, text: '', img_url: '', code: '' }] : [{ optioni: 0, text: '', img_url: '', code: '' }]) })
    setNeedToSave(true)
  }
  const updateOption = (index, newOption) => {
    const newQuestChoices = Question.questChoices
    newQuestChoices[index] = newOption
    const newOptionDetails = Question.options_details ? Question.options_details : []
    newOptionDetails[index] = { ...newOptionDetails[index], text: newOption }
    setQuestion({ ...Question, questChoices: newQuestChoices, options_details: newOptionDetails })
    setNeedToSave(true)
  }
  const deleteOption = (index) => {
    const newChoices = Question.questChoices.filter((item, key) => key !== index)
    const newOptionDetails = Question.options_details.filter((item, key) => key !== index)
    setQuestion({ ...Question, questChoices: newChoices, options_details: newOptionDetails })
    setNeedToSave(true)
  }

  const deleteQuestion = () => {
    // dispatch(removeQuestion({ question: Question }))
    setQuestion({ ...Question, isDeleted: true })
    dispatch(updateTotalScore({}))
    setNeedToSave(true)
  }

  const changeQuestionType = (e) => {
    // setQuestion({ ...Question, questionType: type })
    const type = e.target.value

    if (type === 'S') {
      setQuestion({ ...Question, question_type: 'S' })
    } else if (type === 'M') {
      setQuestion({ ...Question, question_type: 'M' })
      // setQuestion({ ...Question, question_type: 'M', questChoices: [''] })
    }
    setNeedToSave(true)
  }

  const changeGeneratedAnswer = (value) => {
    setQuestion({ ...Question, generatedAnswer: [value] })
    setNeedToSave(true)
  }
  const changeScore = (e) => {
    if (e.target.value === '' || isNaN(e.target.value) || e.target.value < 0 || e.target.value === null) {
      setQuestion({ ...Question, score: parseInt(1) })
      return
    }
    setQuestion({ ...Question, score: parseInt(e.target.value) })
    setNeedToSave(true)
  }

  useEffect(() => {
    dispatch(updateQuestion({ question: Question }))
    dispatch(updateTotalScore({}))
  }, [Question])

  const updateTextsearch = async (inputValue) => {
    const x = 0
    if (x === 0) {
      return
    }
    const inputsearchtext = inputValue.replace(/(<([^>]+)>)/ig, '')
    if (inputsearchtext === 'dummy_blockrgtr567') {
      const resQuestionData = await makeAuthenticatedServerRequest('/suggestion/getQuestion', 'POST', {
        topicName: topicText,
        seacrchtext: inputsearchtext
      })
      if (resQuestionData.data) {
        setOptions(resQuestionData.data.questionData)
      }
      const filteredvalue = options.filter(
        (option) =>
          option.question.toLowerCase().includes(inputsearchtext.toLowerCase()) &&
          option.question !== inputsearchtext
      )
      setFilterOptions(filteredvalue)
    } else {
      setFilterOptions([])
      setOptions([])
    }
  }

  const handleOptionClick = async (option) => {
    setInputValue(option.question)
    createNewQuestion(option)
    setDropdownOpen(false)
  }
  const handleDropdownClick = async () => {
    if (!dropdownOpen) {
      const resQuestionData = await makeAuthenticatedServerRequest('/suggestion/getQuestion', 'POST', {
        topicName: topicText,
        seacrchtext: inputValue
      })
      if (resQuestionData.data) {
        setOptions(resQuestionData.data.questionData)
      }
    }
    setDropdownOpen(!dropdownOpen)
  }
  const createNewQuestion = (option) => {
    if (!authState.isLoggedIn) {
      const addNewQuestionMessage = 'Please login to add new question'
      if (window.confirm(addNewQuestionMessage) === true) {
        navigate('/Auth')
      }
      return
    }
    const newQuestion = {
      _id: Date.now(),
      quizId: createdQuiz.quizId,
      questionContent: option.question,
      questChoices: option.options,
      question_type: 'M',
      finalAnswer: '',
      score: 1,
      generatedAnswer: option.answer,
      isNewlyCreated: true,
      isDeleted: false
    }
    // dispatch(addQuestion({ newQuestion: newQuestion }))
    dispatch(updateQuestion({ question: newQuestion }))
    setQuestion(
      {
        ...Question,
        quizId: createdQuiz.quizId,
        questionContent: option.question,
        questChoices: option.options,
        question_type: 'M',
        finalAnswer: '',
        score: 1,
        generatedAnswer: option.answer,
        isNewlyCreated: true,
        isDeleted: false
      })
    setNeedToSave(true)
  }

  function handleOptionPictureChange (picUrl, index) {
    const newOptionDetails = Question.options_details
    newOptionDetails[index] = { ...newOptionDetails[index], img_url: picUrl }
    setQuestion({ ...Question, options_details: newOptionDetails })
    setNeedToSave(true)
  }

  function addOptionDetailsToOldQuestions () {
    const newOptionDetails = Question.questChoices.map((text, index) => { return { optioni: index, text: text, img_url: '', code: '' } })
    return newOptionDetails
  }

  useEffect(() => {
    if (Question.questChoices && !Question.options_details) {
      const newOptionDetails = addOptionDetailsToOldQuestions()
      setQuestion({ ...Question, options_details: newOptionDetails })
      setNeedToSave(true)
    }
  }, [])

  return (
    <div className='bg-white rounded-xl shadow-4-sides-secondary'>
    {/* <GeneralButton
    content={!imageAdded ? (<TbCameraPlus className="rounded-lg text-white bg-app-primary w-[30px] h-[20px] sm:w-[40px] sm:h-[30px] float-right" />) : (<TbCameraMinus className="rounded-lg text-white bg-red-400 w-[30px] h-[20px] sm:w-[40px] sm:h-[30px] float-right" />)}
    className= { !imageAdded ? 'm-2 rounded-lg text-white bg-app-primary  float-right ' : ' m-2 rounded-lg text-white bg-red-400 float-right '}
    onClick={!imageAdded ? addImageHandler : removeImageHandler }
    /> */}
      <QuestionWrapper className={className} >
        <div className="flex flex-row m-auto gap-4">
          {/* <div className="mr-2 md:mr-6 rounded-full bg-app-primary w-8 h-8 justify-center flex">
            <GeneralHeading className="text-xs md:text-base text-white font-bold h-max my-auto" text={index} />
          </div> */}
          <div className="flex-1 max-w-full">
            <div className="relative items-center w-full" onClick={handleTextclick} >
              <div className='flex flex-row'>
                <div className="mr-2 rounded-full bg-app-primary w-10 h-8 justify-center flex">
                  <GeneralHeading className="text-xs md:text-base text-white font-bold h-max w-max my-auto" text={index} />
                </div>
                <CustomizedTextArea id={`question_joy_ride_${index}`} className={'mb-6 text-xs md:text-base pb-1 md:pb-3 w-full mr-2'} border defaultValue={Question.questionContent} onChange={handleQuestionTextChange} />
                <ButtonAddPicture picture={quizPicture} setPicture={(picUrl) => handleQuestionPictureChange(picUrl)} className={' w-max ml-auto '} />
              </div>
              <ImageViewBox image={quizPicture} onRemove={removeImageHandler} className={'w-[300px] h-[300px] max-w-full'}/>
              {dropdownOpen && (
                <ul className="absolute z-10 mt-1 w-full rounded-lg shadow-md bg-white border-8">
                  {filterOptions.map((option, index) => (
                    <li
                      key={index}
                      className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                      onClick={() => handleOptionClick(option)}
                    >
                      {option.question}
                    </li>
                  ))}
                </ul>
              )}
              <button
                className="absolute right-[-10px] top-0 mt-3 mr-1 focus:outline-none"
                onClick={handleDropdownClick}
              >
                {/* <svg
                  className="h-6 w-6 fill-current text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg> */}
              </button>
            </div>
            {/* {imageAdded && (
                 <div className='rounded-lg w-1/2 justify-center items-center' >
            <TooltipRight
            className='w-fit'
            content={
              <AddQuizPicture quizPicture={quizPicture} setQuizPicture={setQuizPicture} handleQuestionPictureChange={handleQuestionPictureChange} uploadenable={true}/>
            }
            message={'Add or change question picture'}
          />
            </div>)} */}
            {/* {showTopicModal && <SetTopicName topicText={topicText} setTopicText={setTopicText} closeModal={() => setShowTopicModal(false)} />} */}
            {
              Question.question_type === 'M'
                ? (
                  <SingleCorrectQuestion MCQuestionId={index} question={Question} addNewOption={addNewOption} deleteOption={deleteOption} updateOption={updateOption} changeAnswer={changeGeneratedAnswer} handleOptionPictureChange={handleOptionPictureChange} />
                  )
                : Question.question_type === 'S'
                  ? (
                    <SubjectiveQuestion subjectiveQuestionId={index} answer={Question.generatedAnswer} changeAnswer={changeGeneratedAnswer} />
                    )
                  : null}
            <EditQuestionLowerDiv className={'mt-8 border-t pt-4 border-[#F0F0F0]'} qType={Question.question_type} onDelete={deleteQuestion} changeQuestionType={changeQuestionType} score={Question.score} changeScore={changeScore} questionIndex={index} />
          </div>
        </div>
      </QuestionWrapper>
    </div>
  )
}
