import React from 'react'
import ButtonSecondaryLg from '../_atomic-design/atoms/Button/ButtonSecondaryLg'
import ButtonPrimaryLg from '../_atomic-design/atoms/Button/ButtonPrimaryLg'
import HeadingLarge from '../_atomic-design/atoms/Text/HeadingLg'
import TextBody from '../_atomic-design/atoms/Text/TextBody'
import Confetti from 'react-confetti'
import TextSm from '../_atomic-design/atoms/Text/TextSm'
import dangerCircle from '../assets/images/Icons/dangerCircle.svg'
import ImageTag from '../_atomic-design/atoms/ImageTag'
// import TextSm from "../_atomic-design/atoms/Text/TextSm"

export default function Congrats () {
  return (
    <div className="fixed top-0 left-0 min-h-screen w-screen flex justify-center items-center overflow-auto">
      <div className="absolute top-0 left-0 h-full w-full bg-app-blue-background opacity-90"></div>
      <div className="w-fit rounded-xl bg-white px-3 py-5 sm:p-20 z-10 text-center">
        <HeadingLarge text={'Congrats!'} />
        <TextBody
          className={'mt-5 mb-6'}
          text={'You have completed the quiz'}
        />
        <div className="flex items-center justify-center mb-8">
          <TextSm text="Score" />
          <TextSm
            text="40/40"
            className="border border-black rounded-md w-fit p-3 mx-4"
          />
          <ImageTag src={dangerCircle} />
        </div>
        <ButtonPrimaryLg className={'mb-2'} text={'View Results'} />
        <ButtonSecondaryLg text={'Back to dashboard'} />
      </div>
      <Confetti className="mx-auto" width={window.innerWidth * 0.9} height={window.innerHeight * 0.9} />
    </div>
  )
}
