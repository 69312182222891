import React from 'react'
import ImageTag from '../_atomic-design/atoms/ImageTag'
import SearchGray from '../assets/images/Icons/SearchGray.svg'
import GeneralTextInput from '../_atomic-design/molecules/Input/GeneralTextInput'
// import TextBody from '../../_atomic-design/atoms/Text/TextBody'
// import GeneralDropdown from '../../_atomic-design/molecules/Input/GeneralDropdown'

export default function SearchBarCustom ({ className, search, setSearch }) {
  return (
      <div className={` ${className} flex flex-col sm:flex-row rounded-xl`}>
          <div className="flex flex-row w-full">
            {/* <GeneralTextInput className={'ml-4 border-none outline-none w-full border bg-transparent'} value={search} onKeyDown={(e) => { if (e.key === 'Enter') setSearch(e.target.value) }} placeholder={'search'} /> */}
        <GeneralTextInput className={'border-none outline-none w-full border bg-transparent'} value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder={'Search'} />
        <ImageTag src={SearchGray} className="w-3" />
          </div>
      </div>
  )
}
