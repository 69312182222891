import React from 'react'
import HeadingMd from '../_atomic-design/atoms/Text/HeadingMd'
import HeadingSm from '../_atomic-design/atoms/Text/HeadingSm'
import ImageTag from '../_atomic-design/atoms/ImageTag'
import clockLine from '../assets/images/Icons/clock-line.svg'

export default function QuizTimerUI ({ time }) {
  // const [time, setTime] = useState({hours:5, minutes: 10, seconds: 59 });
  /* This component displays time in HH:MM:SS . props.time must be in format {hours:5, minutes: 10, seconds: 59 } */
  const HH = time.hours < 10 ? `0${time.hours}` : time.hours
  const MM = time.minutes < 10 ? `0${time.minutes}` : time.minutes
  const SS = time.seconds < 10 ? `0${time.seconds}` : time.seconds

  return <div className="flex rounded-xl shadow-4sides h-max w-max max-w-sm mx-auto p-4 sm:px-2 bg-white">
    <ImageTag src={clockLine} className={'mx-1'} />
    <HeadingSm text={'Time Remaining : '} className={' text-lg  sm:text-xl my-auto'}/>
    <HeadingMd text={ `${HH} : ${MM} : ${SS}` } className={' text-xl sm:text-3xl ml-2 my-auto text-app-red whitespace-nowrap'}/>
    </div>
}
