import React from 'react'
import ImageTag from '../../atoms/ImageTag'

export default function DisplayTag ({ text, src }) {
  return (
        <div
            className={'flex justify-center items-center w-fit py-1 '}
        >
            {src ? <ImageTag src={src} className="w-6 h-6 mr-2" /> : null}
            <span
                className={'text-[#5E5E5E]'}
            >
                {text}
            </span>
        </div>
  )
}
