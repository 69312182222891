import React from 'react'
import { fileUploadRequest } from '../../../utils/helpers'
import ImageTag from '../ImageTag'
import HiddenInput from '../Home/HiddenInput'
import AddPicture from '../../../assets/images/Icons/AddPicture.svg'
import TextXs from '../Text/TextXs'

export default function ButtonAddPicture ({ picture, setPicture, className }) {
  const uploadFiles = async (file) => {
    // setDisabled(true)
    const formData = new FormData()
    formData.append('image', file)
    const res = await fileUploadRequest('/upload/quiz-photo', formData)
    setPicture(res.data.url)

    // setDisabled(false)
  }
  const onFileDrop = async (e) => {
    uploadFiles(e.target.files[0])
  }

  return (
        <div className={` ${className} `} >
            <div className="relative bg-app-green text-white p-2 text-xs rounded-full overflow-hidden w-[36px] h-[36px] hover:w-max transition-all flex justify-start items-center  hover:bg-app-pink duration-500 " >
                <div className='flex flex-row gap-2'>
                  <ImageTag src={AddPicture} className='w-[18px] h-[18px] my-auto mr-1' />
                  <TextXs text={picture ? 'Change Image' : 'Add Image'} className={'block my-auto whitespace-nowrap'}/>
                </div>
                <HiddenInput
                    onChange={onFileDrop}
                    acceptFileType="image/png, image/jpeg"
                />
            </div>
        </div>
  )
}
