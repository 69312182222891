import React from 'react'
import addQuestionIcon from '../../../assets/images/Icons/add-question.svg'
import ImageTag from '../../atoms/ImageTag'

export default function AddQuestionButton ({ addNewQuestion, className }) {
  return (
    <div className={className}>
        <ImageTag
          className={'w-5 cursor-pointer'}
          src={addQuestionIcon}
          onClick={addNewQuestion}
        />
    </div>
  )
}
