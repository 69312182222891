/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import HeadingSm from '../../_atomic-design/atoms/Text/HeadingSm'
import FilterBar from './FilterBar'
import cross from '../../assets/images/Icons/cross.svg'
import openLeftBar from '../../assets/images/Icons/questionPalleteHidden.svg'
// import ImageTag from '../../_atomic-design/atoms/ImageTag'
import LeftBar from './LeftMenu'
import StudentCard from './StudentCard'
import { makeAuthenticatedServerRequest, makeServerGETRequest } from '../../utils/helpers'
import { toast } from 'react-toastify'
import ButtonSecondarySm from '../../_atomic-design/atoms/Button/ButtonSecondarySm'
import TooltipLeft from '../../_atomic-design/molecules/Others/TooltipLeft'
import StudentCardNew from './StudentCardNew'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import ButtonIconOnly from '../../_atomic-design/atoms/Button/ButtonIconOnly'
import { Button } from '../../routes/Admin/shared/Button'
import SearchBar from '../../components/SearchBar'

export default function IndividualsTab ({ className, quiz, studentQuiz }) {
  const [participantsData, setParticipantsData] = useState(quiz.participants)
  const [search, setSearch] = useState('')
  const [creatorEvaluationStatus, setCreatorEvaluationStatus] = useState(false)
  const [isLeftBarOpen, setIsLeftBarOpen] = useState(true)

  const filteredParticipantsData = useMemo(() => {
    const data = participantsData
    return data
  }, [participantsData])

  async function approveAllClickHandler () {
    const ans = window.confirm('On clicking yes, All the score generated by learnkraft.ai will be copied to final score of each Student. However, the students for which you already have submitted scores will not be affected.')

    if (ans) {
      const res = await makeAuthenticatedServerRequest(
        `/quiz/approveAll/?quizId=${quiz._id}`, 'PUT', {}
      )
      if (res.status === 200) {
        toast('Approved all learnkraft.ai generated scores')
      } else if (res.status === 404) {
        toast('Quiz not found')
      } else if (res.status === 403) {
        toast('learnkraft.ai evaluation still pending. evaluation will be start after the end date and time of the quiz.')
      } else {
        toast('Unknown error occured')
      }
    }
    setTimeout(() => {
      window.location.reload() // to reload page so that all status variables are updated with delay for toast
    }, 4000)
  }

  async function resultPublishClickHandler () {
    const today = new Date()
    const quizEndDate = new Date(quiz.settings.endDate)
    const expiryStatus = today - quizEndDate > 0 // quiz end date crossed if true
    console.log(today, quizEndDate, expiryStatus)
    if (expiryStatus) {
      const confirmPublish = window.confirm('Publish result will notify all the students that result has been declared. Are you sure you want to proceed?')
      if (!confirmPublish) {
        return
      }
      const res = await makeAuthenticatedServerRequest(
        '/quiz/PublishQuizResult', 'POST', { quiz_id: quiz._id }
      )
      if (res.status === 200) {
        toast('Result Published')
      } else {
        toast('Something went wrong err code.98213')
      }
      window.location.reload() // to reload page so that all status variables are updated
    } else {
      toast('Please wait for the quiz\'s end date and time.')
    }
  }

  /** This Function reloads the page every 2 minutes.  */
  useEffect(() => {
    const interval = setInterval(() => {
      // Reload the page
      window.location.reload()
    }, 120000) // 2 minutes in milliseconds

    return () => {
      // Clean up the interval when the component unmounts
      clearInterval(interval)
    }
  }, [])

  useEffect(async () => {
    const res = await makeServerGETRequest('/quiz/creatorEvaluationStatus', { quizId: quiz._id })
    if (res.status === 200) {
      setCreatorEvaluationStatus(res.data.createrEvaluation)
    }
  }, [])

  return (
    <div className="flex flex-row w-full pt-28 sm:pt-1  lg:pt-1 h-full overflow-auto">
      {isLeftBarOpen && <div className="fixed sm:relative z-10 sm:flex flex-col w-[90%] h-screen sm:h-full sm:w-1/4 bg-[#FAFAFA] mr-4 sm:mt-16 p-2 shadow-xl">
        <div className='flex justify-between p-2'><HeadingSm text={'Test Summary'} className={'text-lg '}/><ButtonIconOnly image={cross} className={'my-auto h-max w-8 sm:hidden'} onClick={() => setIsLeftBarOpen(!isLeftBarOpen)}/></div>
        <LeftBar quiz={quiz} studentQuiz={studentQuiz} />
      </div>}
      <ButtonIconOnly image={openLeftBar} onClick={() => setIsLeftBarOpen(!isLeftBarOpen)} className={'fixed bottom-32 right-5 w-16 sm:hidden'}/>
      <div className="flex-1 w-full sm:w-3/4 mt-16 bg-app-gradient-setting">
        <div className={`w-full h-full px-10 ${className} sm:pt-8`}>
          <div className='flex flex-col sm:flex-row  items-center gap-2 pt-2 pr-2'>
            {/* <HeadingSm text={'Individuals'} /> */}
            <div className='flex w-full'>
            {/* <FilterBar search={search} setSearch={setSearch} className={'w-full'}/> */}
            <SearchBar search={search} setSearch={setSearch} className={'w-full'}/>
            </div>

            <div className='flex flex-row w-max justify-center  sm:justify-end gap-2 '>
              <TooltipLeft
                content={
                  <ButtonSecondarySm text={'Approve All'} onClick={approveAllClickHandler} />

                }
                message={'Click to approve all marks given by learnkraft.ai to the students responses. This will evaluate all students responses.'}
              />
              <TooltipLeft
                content={
                  // <ButtonSecondarySm text={quiz.resulPublished ? 'Result Published' : 'Publish Result'} className={''} onClick={resultPublishClickHandler} disabled={quiz.resulPublished} />
                  <GeneralButton
                    content={quiz.resulPublished ? 'Result Published' : 'Publish Result'}
                    className="w-32 h-8 rounded-[5px] border border-app-primary text-app-primary text-sm"
                    onClick={resultPublishClickHandler} disabled={quiz.resulPublished || !creatorEvaluationStatus}
                />
                }
                message={'After publishing, the student will get an email notification, and then they can check their result from their dashboard.'}
              />
            </div>
          </div>
          {/* <FilterBar search={search} setSearch={setSearch} /> */}
          <div className='mt-2 sm:h-[90%]'>
            {filteredParticipantsData.map((data, index) => {
              const studentData = studentQuiz.filter((item) => {
                return (item.attemptedById === data._id)
              })
              // if (data.firstName && data.firstName.toLowerCase().includes(search.toLowerCase())) {
              //  return (
              //             <StudentCard key={data._id + index} data={data} quiz={quiz} studentQuiz={studentData} />
              //   )
              // }
              // if (!data.firstName) {
              //  data.firstName = data.email
              // }
              const searchString = data.firstName + data.lastName + data.email
              if (searchString.toLowerCase().includes(search.toLowerCase())) {
                return (
                  <StudentCardNew key={data._id + index} data={data} quiz={quiz} studentQuiz={studentData} />
                )
              }
              return null
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

// function TickComp () {
//   return <ImageTag src={TickBlack} className="w-3 ml-2" />
// }

// <StudentCard key={data._id + index} data={data} quiz={quiz} studentQuiz={studentData} />
