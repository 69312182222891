import React from 'react'
import DownloadDropdown from '../../_atomic-design/molecules/CreateQuiz/DownloadDropdown'
import AddQuestionButton from '../../_atomic-design/molecules/EditQuizPage/AddQuestionButton'
import TooltipLeft from '../../_atomic-design/molecules/Others/TooltipLeft'

export default function RightFunctionalityBar ({
  addNewQuestion,
  downloadLink,
  setNeedToSave,
  saveQuiz
}) {
  return (
        <>
            <div className="fixed w-fit flex flex-col justify-center items-center gap-16 h-32 p-2 sm:ml-3 sm:top-32 top-48 sm:py-8 bg-white  shadow-md sm:flex sm:flex-col gap-y-5 gap-x-2 rounded-lg border-2 border-violet-300">
                <TooltipLeft
                    content={
                        <AddQuestionButton addNewQuestion={addNewQuestion} className='joyride_addquestion'/>
                    }
                    message={'Add Question'}
                />

                        <DownloadDropdown downloadLink={downloadLink} saveQuiz={saveQuiz}/>

            </div>
        </>
  )
}
