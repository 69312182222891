import React, { useEffect, useState } from 'react'
import GeneralHeading from '../../atoms/Text/GeneralHeading'
import { useSelector } from 'react-redux'

export default function TrialRibbon () {
  const authState = useSelector((state) => state.authReducer)
  const [loggedIn, setLoggedIn] = useState(false)
  useEffect(() => {
    if (authState && authState.isLoggedIn) {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }
  }, [authState])
  return (
    <div>
    {(loggedIn)
      ? (<div className='fixed bottom-6 -left-16 z-50 rounded-full h-8 sm:h-12 w-fit px-4 flex flex-row justify-evenly items-center'>
    <GeneralHeading
      text="Beta Version"
      className="animate-pulse text-white font-bold text-lg rotate-45 bg-green-500 rounded-lg px-10"
    />
  </div>)
      : null}
      </div>

  )
}
