import React from 'react'
import Latex from 'react-latex'
import { ErrorBoundary } from 'react-error-boundary'

/**
 * @description : renders latex equation with package "react-latex".
 * ErrorBoundary : catches any error during rendering of equations, such as wrong syntax
 * FallbackComponent : renders equation or string without Latex formatting.
 *
 * As per react docs : "try / catch is great but it only works for imperative code:"
 */

function RenderLatex (props) {
  return (
    <ErrorBoundary FallbackComponent={props.children}>
      <Latex>{props.children}</Latex>
    </ErrorBoundary>)
}

export default RenderLatex
