
module.exports = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  GOOGLE_LOGIN: process.env.REACT_APP_GOOGLE_LOGIN,
  FACEBOOK_LOGIN: process.env.REACT_APP_FACEBOOK_LOGIN,
  LINKEDIN_LOGIN: process.env.REACT_APP_LINKEDIN_LOGIN,
  RAZOR_KEY: process.env.REACT_APP_RAZORPAY_CREDENTIAL,
  COMPANY_NAME: process.env.REACT_APP_RAZORPAY_CLIENT_NAME,
  FREE_PLAN_ID: process.env.REACT_APP_FREE_PLAN_ID,
  GTM_ID: process.env.REACT_APP_GTM_ID,
  MIN_MESSAGE_LENGTH: process.env.REACT_APP_CONTACT_US_MIN_MESSAGE_LENGTH,
  MAX_CHARACTER_LIMIT: process.env.REACT_APP_MAX_CHARACTER_LIMIT
}
