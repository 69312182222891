import React, { useEffect } from 'react'
import QuestionText from '../General/QuestionText'
import RadioButtonBox from '../General/RadioButtonBox'
// import RenderLatex from '../../helpers/RenderLatex'

export default function QuestionContainerMCQ (props) {
  /* This useEffect for clearing the selected radio button every time current question changes
                          and setting the selected radio button of already answered questions             */
  useEffect(() => {
    const ele = document.getElementsByName('selectedChoice')
    for (let i = 0; i < ele.length; i++) {
      ele[i].checked = false
    }

    if (props.response[props.currQuestion.index] !== undefined) {
      ele[props.response[props.currQuestion.index]].checked = true
    }
  }, [props.currQuestion, props.response])

  // some options are empty strings. we need to filter that before showing to users.
  const validOptions = props.currQuestion.question.questChoices.filter((item) => item !== '')

  function inputChangeHandler (e) {
    props.inputChangeHandler(e.target.value)
  }
  const regex = /(<([^>]+)>)/ig

  /**
       * @description : Latex rendering throws error if equation not formatted properly as per latex,
       * RenderLatex used for handling errors thrown during rendering
       */
  return <div className={'pr-2 ' + props.className}>

        <QuestionText questionNo={props.currQuestion.index + 1} score={props.currQuestion.question.score} className={' mx-auto mb-4'} questionText={props.currQuestion.question.questionContent.replace(regex, '')} questionImage={props.currQuestion.question.questionPicture}/>
        <div className='flex flex-col sm:flex-row'>
        {validOptions.map((a, index) => {
          return <RadioButtonBox text={(a.toString()).replace(regex, '')} key={index} inputChangeHandler={inputChangeHandler} image={props.currQuestion.question.options_details && props.currQuestion.question.options_details[index].img_url} index={index} className="m-2" />
        })}
        </div>
        {/* <QuestionText questionNo={props.currQuestion.index + 1} className={"mx-auto"} questionText={props.currQuestion.question.question} />
            {props.currQuestion.question.options.map((a, index) => {
                return  <RadioButtonBox text={<RenderLatex>{a}</RenderLatex>} inputChangeHandler={inputChangeHandler} index={index} className="mx-auto my-2"/>
            })} */}
    </div>
}
