import React from 'react'
import JourneyCard from '../../components/JourneyCard'
import HeadingXlg from '../../_atomic-design/atoms/Text/HeadingXlg'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
import JourneyInstructor from '../../assets/data/JourneyInstructor'
import JourneyStrudent from '../../assets/data/JourneyStudents'
import JourneyInstructorImg from '../../assets/images/Icons/JourneyInstructor1.png'
import JourneyStudentImg from '../../assets/images/Icons/JourneyStudent1.png'

export default function TransformativeJourneyNew () {
  return <div className='w-full bg-[#F9E1FF] py-10 sm:p-20 '>
  <div className="w-4/5 sm:w-full mx-auto text-center mb-10 sm:mb-20 px-3">
        <HeadingXlg
          text="A Transformative Learning Journey"
          className="mb-4 text-app-purple-heading text-[26px]"
        />
        <GeneralText
          text={<>Building <strong> powerful interactions </strong> between <strong>educators and learners</strong></>}
          className="text-[16px] sm:text-lg w-full"
        />
    </div>
    <div className='flex flex-col gap-4 sm:gap-0 p-2 justify-around items-stretch'>
        <JourneyCard heading={'For Instructors'} bulletPoints={JourneyInstructor} imgSrc={JourneyInstructorImg} imgPos={'left'}/>
        <JourneyCard heading={'For Students'} bulletPoints={JourneyStrudent} imgSrc={JourneyStudentImg} imgPos={'right'}/>
    </div>
  </div>
}
