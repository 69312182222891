import React from 'react'

export default function CustomHrAuth () {
  return (
        <div className={'flex justify-between items-center my-3 sm:my-6'}>
            <div className="h-px w-3/5 sm:w-11/12 bg-app-grey"></div>
            <span className="w-full text-sm text-black text-center">or do it via E-mail</span>
            <div className="h-px w-3/5 sm:w-11/12 bg-app-grey"></div>
        </div>
  )
}
