import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
import DisplayTag from '../../_atomic-design/molecules/Others/DisplayTag'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import AverageScoreIcon from '../../assets/images/Icons/MarksGraph.svg'
import CalendarSrc from '../../assets/images/Icons/Calendar.svg'
import ButtonPrimarySm from '../../_atomic-design/atoms/Button/ButtonPrimarySm'
import TimeSrc from '../../assets/images/Icons/Time1.svg'
import ReactLoading from 'react-loading'
import { calculateTimeDifference } from '../../utils/calculateTimeDifference'
import TimerExtended from '../../components/TimerExtended'
import { toast } from 'react-toastify'

export default function QuizCardStudent ({ data, attemptedsDetails }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const today = new Date()
  const quizStartDate = new Date(data.quizId.settings.startDate)
  const quizEndDate = new Date(data.quizId.settings.endDate)
  const expiryStatus = today - quizEndDate > 0
  const studentsResult = attemptedsDetails.filter((item) => item.refQuiz === data.quizId._id)
  const instructorStatus = studentsResult.length > 0 ? studentsResult[0].instructorEvaluation : false
  const mlEvaluationStatus = data.quizId.mlEvaluation

  const [remainigTime, setRemainingTime] = useState(calculateTimeDifference(today, quizStartDate))

  let quizLiveStatus = 'Expired'
  if (today - quizEndDate < 0 && today - quizStartDate > 0) {
    quizLiveStatus = 'Live'
  } else if (today - quizStartDate < 0) {
    quizLiveStatus = 'Upcoming'
  }
  const QuizWaitingHandler = async (e) => {
    setLoading(true)
    e.preventDefault()
    if (quizLiveStatus === 'Upcoming') {
      navigate('/wait-for-quiz', { state: { data, attemptedsDetails } })
    } else if (quizLiveStatus === 'Live') {
      navigate('/start-quiz', { state: { data, attemptedsDetails } })
    } else toast('unknown error occured')
    setLoading(false)
  }

  async function resultButtonHandler (e) {
    const studentId = attemptedsDetails[0].attemptedById
    navigate(`/Result?quizId=${data.quizId._id}&&studentId=${studentId}`)
  }

  return (
    <div className="w-full  max-w-7xl mb-4 shadow-lg flex flex-col justify-between rounded-[10px] bg-white overflow-auto text-xs sm:text-sm md:text-base"
    >
        <div className='flex flex-col sm:flex-row bg-app-secondary p-4 text-white justify-between'>
            <GeneralText text={data.quizId.name} className='text-lg font-bold mt-auto' />
            {quizLiveStatus === 'Upcoming' && <TimerExtended onTimeUp={() => { window.location.reload() }} time={remainigTime} setTime={setRemainingTime} />}
        </div>
        <div className='p-5'>
          <div className="flex flex-wrap gap-4">
          <Marksecured
            studentsResult={studentsResult}
            instructorStatus={instructorStatus}
            data={data}
          />
          {/* <DisplayTag src={TwoUserSrc} text={data.attempted} rounded /> */}
          <DisplayTag src={CalendarSrc} text={'Start Date : ' + quizStartDate.toLocaleDateString() + ' | ' + quizStartDate.toLocaleTimeString()} />
          <DisplayTag src={CalendarSrc} text={'Last Date : ' + quizEndDate.toLocaleDateString() + ' | ' + quizEndDate.toLocaleTimeString()} />
          <DisplayTag src={TimeSrc} text={'Duration : ' + data.quizId.settings.duration + ' minutes '} rounded />
          {/* {data.comments > 0
                    ? (
                      <DisplayTag src={CommentSrc} text={data.comments} />
                      )
                    : null} */}
          </div>
          <hr className='my-4'/>
          <div className='flex flex-col lg:flex-row'>
            <AttemptStatus isAttempted={data.isAttempted} className='m-1 text-sm rounded-full ' />
            <ExpiryStatus isExpired={expiryStatus} quizLiveStatus={quizLiveStatus} className='m-1 text-sm rounded-full ' />
            {data.isAttempted && <InstucturEvaluation isAttempted={instructorStatus} className='m-1 text-sm rounded-full ' />}
            {loading
              ? <ReactLoading type="spin" color="#916AF8" className='ml-auto' height={40} width={35} />
              : (<div className='my-auto ml-auto shadow-lg'>
                    {instructorStatus
                      ? <ButtonPrimarySm text={'Result'} onClick={resultButtonHandler} />
                      : mlEvaluationStatus
                        ? <ButtonPrimarySm text={'learnkraft.ai Score'} onClick={resultButtonHandler} disabled={!data.isAttempted}/>
                        : <ButtonPrimarySm text={(quizLiveStatus === 'Upcoming') ? 'Wait Quiz' : 'Start Quiz'} onClick={QuizWaitingHandler} disabled={quizLiveStatus === 'Expired' || data.isAttempted}/>}
                  </div>)
            }
          </div>
        </div>
    </div>
  )
}

function AttemptStatus ({ isAttempted, className }) {
  return (
    <GeneralButton content={isAttempted ? 'Attempted' : 'Not Attempted'} className={`${className} ${isAttempted ? 'bg-app-primary' : 'bg-app-red'} w-max py-1 px-4 font-normal text-white rounded `} disabled />
  )
}

function ExpiryStatus ({ isExpired, quizLiveStatus, className }) {
  let color = ' bg-app-primary '
  switch (quizLiveStatus) {
    case 'Expired': color = 'bg-app-primary'
      break
    case 'Live': color = ' bg-app-green '
      break
    case 'Upcoming': color = ' bg-app-pink '
      break
  }
  return (
    <GeneralButton content={quizLiveStatus} className={`${className} ${color} w-max py-1 px-4 font-normal text-white rounded `} disabled />
  )
}
function InstucturEvaluation ({ isAttempted, className }) {
  return (
    <GeneralButton content={isAttempted ? 'Result Published' : 'Result Pending'} className={`${className} ${isAttempted ? 'bg-app-primary' : 'bg-app-red'} w-max py-1 px-4 font-normal text-white rounded `} disabled />
  )
}

function Marksecured ({ studentsResult, instructorStatus, data }) {
  if (instructorStatus) {
    return (
      <DisplayTag
        text={'mark Secured: ' + studentsResult[0].marksGivenByInstructor + ' / ' + data.quizId.totalScore}
        src={AverageScoreIcon}
      />)
  } else {
    return (
      <DisplayTag
        text={'Maximum Score: ' + data.quizId.totalScore}
        src={AverageScoreIcon}
      />)
  }
}
