import React from 'react'
import { Link } from 'react-router-dom'
import TextXs from '../../../_atomic-design/atoms/Text/TextXs'
import LogoIcon from '../../../assets/images/Icons/learnkraft.svg'

export default function AdminNavbar ({
  Tabs,
  currentTab,
  setCurrentTab
}) {
  return (
        <div className="fixed top-0 left-0 h-[52px] w-full bg-white z-20">
            <div className="w-7/8 sm:w-11/12 md:w-4/5 max-w-7xl mx-auto flex flex-row justify-between items-center h-full">
                <div className="flex items-center">
                <Link to={'/'} className="md:text-3xl font-bold text-[#3C38CD] ">
                        <img className="w-32" src={LogoIcon} alt="LOGO" />
                    </Link>
                </div>
                <div className="my-4">
                    {Tabs && Tabs.map((tab, key) => (
                        <TextXs
                            key={key}
                            text={tab}
                            className={` inline mx-3 cursor-pointer ${
                                currentTab !== tab
                                    ? 'text-app-grey-light'
                                    : 'font-semibold'
                            }`}
                            onClick={() => setCurrentTab(tab)}
                        />
                    ))}
                </div>
                <div className=" flex flex-row items-center my-4">
                    <strong> Admin</strong>
                </div>
            </div>
        </div>
  )
}
