
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addQuestion } from '../../../store/actions/quizActions/quizActions'
import { makeAuthenticatedServerRequest } from '../../../utils/helpers'
import { useNavigate } from 'react-router-dom'
const QuestionSuggestionDropdown = ({ setNeedToSave, topicText }) => {
  const [inputValue, setInputValue] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [filterOptions, setFilterOptions] = useState([])
  const dispatch = useDispatch()
  const createdQuiz = useSelector((state) => state.quizReducer)
  const authState = useSelector((state) => state.authReducer)
  const navigate = useNavigate()

  useEffect(async () => {
    if (inputValue) {
      const resQuestionData = await makeAuthenticatedServerRequest('/suggestion/getQuestion', 'POST', {
        topicName: topicText,
        seacrchtext: inputValue
      })
      if (resQuestionData.data) {
        setOptions(resQuestionData.data.questionData)
      }
      const filteredvalue = options.filter(
        (option) =>
          option.question.toLowerCase().includes(inputValue.toLowerCase()) &&
          option.question !== inputValue
      )
      setFilterOptions(filteredvalue)
    } else {
      setFilterOptions([])
      setOptions([])
    }
  }, [inputValue])

  const createNewQuestion = (option) => {
    if (!authState.isLoggedIn) {
      const addNewQuestionMessage = 'Please login to add new question'
      if (window.confirm(addNewQuestionMessage) === true) {
        navigate('/Auth')
      }
      return
    }
    const newQuestion = {
      _id: Date.now(),
      quizId: createdQuiz.quizId,
      questionContent: option.question,
      questChoices: option.options,
      question_type: 'M',
      finalAnswer: '',
      score: 1,
      generatedAnswer: option.answer,
      isNewlyCreated: true
    }
    dispatch(addQuestion({ newQuestion: newQuestion }))
    setNeedToSave(true)
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
    setDropdownOpen(true)
  }

  const handleDropdownClick = async () => {
    if (!dropdownOpen) {
      const resQuestionData = await makeAuthenticatedServerRequest('/suggestion/getQuestion', 'POST', {
        topicName: topicText,
        seacrchtext: inputValue
      })
      if (resQuestionData.data) {
        setOptions(resQuestionData.data.questionData)
      }
    }
    setDropdownOpen(!dropdownOpen)
  }

  const handleOptionClick = async (option) => {
    setInputValue(option.question)
    createNewQuestion(option)
    setDropdownOpen(false)
  }

  return (
    <div className="relative items-center w-full">
      <input
        type="text"
        className="w-full px-4 py-2 rounded-lg shadow-md focus:outline-none focus:shadow-outline"
        placeholder="Search question"
        value={inputValue}
        onChange={handleInputChange}
      />
      {dropdownOpen && (
        <ul className="absolute z-10 mt-1 w-full rounded-lg shadow-md bg-white">
          {filterOptions.map((option, index) => (
            <li
              key={index}
              className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
              onClick={() => handleOptionClick(option)}
            >
              {option.question}
            </li>
          ))}
        </ul>
      )}
      <button
        className="absolute right-0 top-0 mt-3 mr-3 focus:outline-none"
        onClick={handleDropdownClick}
      >
        <svg
          className="h-6 w-6 fill-current text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </button>
    </div>
  )
}

export default QuestionSuggestionDropdown
