import React, { useEffect, useState } from 'react'
// import ScoreAndTimeSettings from './ScoreAndTimeSettings'
import RulesAndRegulations from './RulesAndRegulations'
import OrderAndPresentationSetting from './OrderAndPresentationSetting'
import { makeAuthenticatedServerGETRequest, makeAuthenticatedServerRequest } from '../../utils/helpers'
import { useLocation, useSearchParams } from 'react-router-dom'
import ReactLoading from 'react-loading'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
import ScoreAndTimeSettingsTimeZone from './ScoreAndTimeSettingsTimeZone'
import ArrowWhiteLeft from '../../assets/images/Icons/ArrowWhiteLeft.svg'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import ButtonSecondaryXsImage from '../../_atomic-design/atoms/Button/ButtonSecondaryXsImage'

export default function SettingsTab ({ setIsQuizpublished, needToSave, setDisableSetting, disableSetting, saveQuiz, setCurrentTab }) {
  // const date = new Date().toISOString()
  const sDate = new Date()
  const eDate = new Date(sDate)
  eDate.setDate(sDate.getDate() + 1)
  const scoreAndTimeInitialState = { duration: '10', availibily: '', endDate: eDate.toISOString(), startDate: sDate.toISOString() }
  const [scoreAndTimeFromServer, setScoreAndTimeFromServer] = useState(scoreAndTimeInitialState)
  const orderAndPresentationInitialState = { orderOfQuestions: 'sequential' }
  const [scoreAndTime, setScoreAndTime] = useState(scoreAndTimeFromServer)
  const [orderAndPresentation, setOrderAndPresentation] = useState(orderAndPresentationInitialState)
  const [searchParams] = useSearchParams()
  const [quizId, setQuizId] = useState()
  const [loading, setLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [isPublished, setIsPublished] = useState(false)
  const location = useLocation()
  const [notes, setNotes] = useState([])
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [timeZone, setTimeZone] = useState(browserTimeZone) // make consistent and persistent
  const [startDateError, setStartDateError] = useState('')
  const [durationError, setDurationError] = useState('')
  const [rulesError, setRulesError] = useState('Please add some Instructions')

  const userDetails = useSelector((state) => state.authReducer)

  const handlerPublishQuiz = (tab) => {
    let plandetails = null
    if (userDetails && userDetails.activeSubscription) {
      plandetails = userDetails.subscription[userDetails.subscription.length - 1]
      if (plandetails && (plandetails.name !== 'Free')) {
        setCurrentTab(tab)
      } else {
        toast('your plan doesn\'t allow to publish, please upgrade your plan')
      }
    } else {
      toast('your plan does not allow to publish, please upgrade your plan')
    }
  }
  const handelback = () => {
    window.location.reload()
  }

  useEffect(async () => {
    setLoading(true)
    const getQuizId = searchParams.get('quizId')
    setQuizId(getQuizId)
    const res = await makeAuthenticatedServerGETRequest('/quiz/', { quizId: getQuizId })
    if (res.status === 200) {
      const startDate = res.data.quiz.settings.startDate || sDate
      const endDate = res.data.quiz.settings.endDate || eDate
      setScoreAndTimeFromServer((prev) => { return { ...prev, duration: res.data.quiz.settings.duration, availibily: res.data.quiz.settings.availibily, endDate: endDate, startDate: startDate } })
      setIsPublished(res.data.quiz.published)
      setNotes(res.data.quiz.settings.rulesAndRegulations ? res.data.quiz.settings.rulesAndRegulations : [])
      setTimeZone(res.data.quiz.settings.timeZone ? res.data.quiz.settings.timeZone : browserTimeZone)
    } else {
      toast('error')
    }
    setLoading(false)
  }, [isPublished])

  useEffect(() => {
    setScoreAndTime(scoreAndTimeFromServer)
  }, [scoreAndTimeFromServer])

  async function saveButtonHandler (e) {
    e.preventDefault()
    if (scoreAndTime.duration === undefined || scoreAndTime.duration < 1) {
      // scoreAndTime.duration = '10'
      toast('Please enter quiz duration')
      return
    } else {
      scoreAndTime.duration = scoreAndTime.duration.toString()
    }
    const startDate = new Date(scoreAndTime.startDate)
    const endDate = new Date(scoreAndTime.endDate)
    if (startDate > endDate) {
      toast('End time cannot be earlier than start time')
      return
    } else if (endDate - startDate < +(scoreAndTime.duration * 60 * 1000)) {
      toast('End time should be greater than start time + duration')
      return
    } else if (startDate - sDate < 1000) {
      setStartDateError('Start time cannot be earlier present time')
      return
    }
    if (notes.length === 0) {
      setRulesError('Please add some Rules and regulations.')
      return
    }
    setIsSaving(true)
    const savequizData = await saveQuiz()
    if (savequizData === false) {
      setIsSaving(false)
      return
    }
    const response = await makeAuthenticatedServerRequest('/quiz/saveSettings', 'POST', { scoreAndTime, orderAndPresentation, quizId, rulesAndRegulations: notes, timeZone })
    if (response.status === 200) {
      setDisableSetting(true)
      handlerPublishQuiz('Publish')
      setIsSaving(false)
    } else {
      toast('some error occured')
      setDisableSetting(false)
      setIsSaving(false)
    }
  }

  useEffect(() => {
    if (scoreAndTime.duration === 0 || scoreAndTime.duration === undefined) {
      setDurationError('Please enter duration.')
    }
  }, [])

  useEffect(() => {
    if (scoreAndTime.startDate === undefined) {
      return
    }
    const savedStartDate = new Date(scoreAndTime.startDate)
    if (savedStartDate < sDate) {
      setStartDateError('Please select a future start date.')
    }
  }, [scoreAndTime])

  return (
    <div className=" flex flex-row pt-32 sm:pt-28 w-full h-full justify-center items-center border-spacing-9 text-xs sm-text-sm md:text-base">
      {loading
        ? (<ReactLoading type="spin" color="#916AF8" className='mt-20' height={50} width={50} />)
        : (!loading && <div className="w-11/12 md:w-2/3 h-full mx-auto mb-10 ">
          {/* <ButtonPrimary text={'Back'} className={'w-1/2'} onClick={handelback} /> */}
          <ButtonSecondaryXsImage text={'Back'} image={ArrowWhiteLeft} className={'w-1/2'} onClick={handelback}/>
        {/* <ScoreAndTimeSettings scoreAndTime={scoreAndTime} setScoreAndTime={setScoreAndTime} /> */}
        <ScoreAndTimeSettingsTimeZone scoreAndTime={scoreAndTime} setScoreAndTime={setScoreAndTime} timeZone={timeZone} startDateError={startDateError} setStartDateError={setStartDateError} durationError={durationError} setDurationError={setDurationError}/>
        <RulesAndRegulations notes={notes} setNotes ={setNotes } rulesError={rulesError} setRulesError={setRulesError}/>
        <OrderAndPresentationSetting orderAndPresentation={orderAndPresentation} setOrderAndPresentation={setOrderAndPresentation} />
        {location.pathname !== '/PublishedQuizOverview'
          ? (
            <div className="flex justify-center items-center  ">
            {(isSaving && (location.pathname !== '/PublishedQuizOverview'))
              ? (
                          <ReactLoading type="spin" color="#916AF8" className='mx-5' height={40} width={35} />
                )
              : (<div className="container my-2 flex flex-col items-center">
              {/* <ButtonPrimary text={'Save Setting'} className={'w-1/2'} onClick={saveButtonHandler} disabled={durationError || startDateError || rulesError}/> */}
              <GeneralButton content={'Save Setting'} onClick={saveButtonHandler} disabled={durationError || startDateError || rulesError} className={` hover:opacity-70 transition-opacity duration-300 rounded-full text-white p-2 px-6 ${durationError || startDateError || rulesError ? ' bg-app-grey cursor-not-allowed ' : ' bg-gradient-to-r from-[#3C38CD] to-[#CF8DDE] cursor-pointer '}`} />
            </div>
                )}
            </div>
            )
          : (null)}
        </div>)
      }
    </div>
  )
}
