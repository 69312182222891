import React, { useEffect } from 'react'
import ReactLoading from 'react-loading'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { setCookieStorage, setUserDetails } from '../../utils/cookieStorage'
import { makeAuthenticatedServerRequest, refreshAuthToken } from '../../utils/helpers'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { loginSucess } from '../../store/actions/authActions/authActions'

export default function OAuthRedirect () {
  const [params] = useSearchParams()
  const token = params.get('token')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(async () => {
    document.title = 'OAuthRedirecting'
    if (token) {
      setCookieStorage('refreshToken', token, 1 / 2)
      const res = await refreshAuthToken()
      if (res && res.status === 200) {
        const userDetails = await makeAuthenticatedServerRequest('/profile', 'GET')
        if (userDetails && userDetails.status === 200) {
          const usertype = userDetails.data.user.preferedRoleType ? userDetails.data.user.preferedRoleType : 0
          // confirm to go to student page otherwise cancel
          const userData = userDetails.data.user
          switch (usertype) {
            case 1:
              setUserDetails({ ...userData, isLoggedIn: true, userRoleType: '1' })
              dispatch(loginSucess({ ...userData, isLoggedIn: true, userRoleType: '1' }))
              if (!userData.detailsCompleted) {
                navigate('/AddMoreDetails')
              } else {
                navigate('/studentDashboard')
              }
              return
            case 0:
              setUserDetails({ ...userData, isLoggedIn: true, userRoleType: '0' })
              dispatch(loginSucess({ ...userData, isLoggedIn: true, userRoleType: '0' }))
              if (!userData.detailsCompleted) {
                navigate('/AddMoreDetails')
              } else {
                navigate('/Dashboard')
              }
              return
            default:
              navigate('/DashboardSelector')
          }
        } else {
          toast('Login Failed')
          navigate('/Auth')
        }
      } else {
        toast('Error in refreshing token')
        navigate('/Auth')
      }
    } else {
      navigate('/Auth?mode=login')
    }
  }, [])

  return (
    <div className="h-screen bg-[#F3F0FA] w-full">
      <div className="flex justify-center items-center h-full w-full">
        <div className="flex flex-col items-center justify-center">
          <ReactLoading type="spin" color="#916AF8" height={40} width={35} />
          <GeneralText text="Redirecting" className="text-lg " textColor="#8562D5" />
        </div>
      </div>
    </div>
  )
}
