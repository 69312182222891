import React from 'react'

export default function ButtonSecondaryLg ({ text, onClick, type, className, disabled }) {
  return (
        <button
            onClick={onClick}
            className={`${className} w-[220px] sm:w-[392px] h-14 sm:h-[52px] text-xl rounded-md bg-white text-app-primary border border-app-primary hover:bg-app-primary hover:text-white duration-500 `}
            type={type}
            disabled={disabled}
        >
            {text}
        </button>
  )
}
