import React from 'react'

export default function TextInputGray2 ({
  id,
  defaultValue,
  value,
  label,
  placeholder,
  onChange,
  type,
  className,
  required,
  disabled
}) {
  return (
        <div className={className}>
            <label className="block text-xs sm:text-base" htmlFor={id}>
                {label}
            </label>
            <input
                type={type || 'text'}
                id={id}
                rows="2"
                placeholder={placeholder}
                onChange={onChange}
                className="bg-[#F4F7F9] rounded-md w-full p-4 h-7 border placeholder:text-xs sm:placeholder:text-sm"
                defaultValue={defaultValue}
                value={value}
                required={required}
                disabled={disabled}
            />
        </div>
  )
}
