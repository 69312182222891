import React from 'react'
import MainBackgroundSrc from '../../assets/images/MainBackground.svg'
import AuthComponent from './AuthComponent'
import HomeNavbar from '../../_atomic-design/molecules/Navbars/HomeNavbar'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import BgRectangle from '../../assets/images/BgRectangle.svg'
import BgRectanglePhone from '../../assets/images/BgRectanglePhone.svg'

export default function AuthRoute () {
  const bgStyle = {
    backgroundImage: `url(${MainBackgroundSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'right'
  }
  return (
    <div className='w-full min-h-screen h-full relative flex flex-col justify-center items-center pt-2 sm:pt-20' style={bgStyle}>
      <ImageTag src={BgRectangle} className='w-full hidden sm:block absolute bottom-0 left-0' />
      <ImageTag src={BgRectanglePhone} className='w-full sm:hidden absolute bottom-0 left-0' />
      <HomeNavbar selected='none' white />
      <AuthComponent className={'z-20 mx-auto relative'} />
    </div>
  )
}
