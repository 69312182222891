/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import MainBackgroundSrc from '../../assets/images/MainBackground.svg'
import HomeNavbar from '../../_atomic-design/molecules/Navbars/HomeNavbar'
import PrivacyNPolicyModal from '../../containers/Modals/PrivacyNPolicyModal'
import { makeServerRequest } from '../../utils/helpers'
import ButtonPrimary from '../../_atomic-design/atoms/Button/ButtonPrimary'
import { useNavigate } from 'react-router-dom'
import { getCookies, removeCookies } from '../../utils/cookieStorage'
import { useDispatch, useSelector } from 'react-redux'
import { signoutSuccess } from '../../store/actions/authActions/authActions'
import { toast } from 'react-toastify'
import TrialRibbon from '../../_atomic-design/molecules/Others/TrialRibbon'

export default function Feedback () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [currentRating, setCurrentRating] = useState(5)
  const [isHelpFull, setIsHelpFull] = useState(true)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(false)
  const [agree, setAgree] = useState(false)
  const [showPrivacyNPolicy, setShowPrivacyNPolicy] = React.useState(false)
  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree)
    // Don't miss the exclamation mark
  }
  useEffect(async () => {
    document.title = 'Feedback'
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setDisabled(true)
    const res = await makeServerRequest('/feedback/create', 'POST', {
      feedback: message,
      rating: currentRating,
      useful: isHelpFull
    })
    if (res.status === 200) {
      toast('Your feedback has been sent successfully!')
      //   removeCookies(['accessToken', 'refreshToken', 'userDetails'])
      //   dispatch(signoutSuccess())
      navigate('/auth?mode=login')
    } else {
      console.log('error code 43567', res)
      toast('Something went wrong. Please try again later.')
      setDisabled(false)
    }
  }
  const handleSkipSubmit = async (e) => {
    // removeCookies(['accessToken', 'refreshToken', 'userDetails'])
    // dispatch(signoutSuccess())
    navigate('/auth?mode=login')
  }

  const handleRadio = async (e) => {
    const radioValue = (e.currentTarget.value === 'true')
    setIsHelpFull(radioValue)
  }

  const bgStyle = {
    backgroundImage: `url(${MainBackgroundSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
  return (<>
  <TrialRibbon/>
        <PrivacyNPolicyModal active={showPrivacyNPolicy} setActive={() => setShowPrivacyNPolicy(!showPrivacyNPolicy)} />
        <div
            className="w-full min-h-screen pt-28 overflow-hidden"
            style={bgStyle}
        >
            <HomeNavbar selected='ContactUs' white />
            <div className=" bg-white w-11/12 sm:w-4/5 mx-auto overflow-hidden mb-8 rounded-2xl">
                <div className=" max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                    <div className="bg-gray-50 py-2 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                        <div className="max-w-lg mx-auto">
                            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                                Rate Us
                            </h2>
                            <dl className="mt-2 text-base text-gray-500">
                                <div >
                                    {/* <dt className="text-black font-bold mb-3">
                                        Our Corporate Address
                                    </dt> */}
                                    <dd>
                                        <p>
                                            Your feed back is very important to us.
                                        </p>
                                        <p>
                                            we use it to improve our user experience.
                                        </p>
                                        <p>
                                            please try to give us a detailed feed back with page link.
                                        </p>
                                        <p>Thank you</p>
                                        <p><strong>learnkraft.ai</strong></p>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white py-2 sm:py-4 px-2 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12 ">
                        <div className="max-w-lg lg:max-w-none ">
                            <form
                                className="grid grid-cols-1 gap-y-2"
                                onSubmit={handleSubmit}
                            >
                                <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                                    Give your feedback
                                </h2>
                                <div className="mt-1 sm:mt-4 ">
                                    <span className="text-gray-700">Did you find it useful ?</span>
                                    <div className="mt-2">
                                        <label className="inline-flex items-center">
                                            <input type="radio" className="form-radio" name="ishelpfull" value="true" checked={isHelpFull === true} onChange={handleRadio} />
                                            <span className="ml-2">Yes</span>
                                        </label>
                                        <label className="inline-flex items-center ml-6">
                                            <input type="radio" className="form-radio" name="ishelpfull" value="false" checked={isHelpFull === false} onChange={handleRadio} />
                                            <span className="ml-2">No</span>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="message"
                                        className="sr-only"
                                    >
                                        What went wrong?
                                    </label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows="4"
                                        className="block w-full shadow-sm bg-[#F4F7F9] rounded-lg py-3 px-4 outline-none focus:border focus:border-blue-600 border"
                                        placeholder="Message"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        required
                                    ></textarea>
                                </div>
                                <div className="flex items-center">
                                    <svg aria-hidden="true" className={'w-8 h-8 ' + ((currentRating > 0) ? 'text-yellow-400' : 'text-gray-300')} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" onClick={() => setCurrentRating(1)}><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                    <svg aria-hidden="true" className={'w-8 h-8 ' + ((currentRating > 1) ? 'text-yellow-400' : 'text-gray-300')} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" onClick={() => setCurrentRating(2)}><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                    <svg aria-hidden="true" className={'w-8 h-8 ' + ((currentRating > 2) ? 'text-yellow-400' : 'text-gray-300')} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" onClick={() => setCurrentRating(3)}><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                    <svg aria-hidden="true" className={'w-8 h-8 ' + ((currentRating > 3) ? 'text-yellow-400' : 'text-gray-300')} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" onClick={() => setCurrentRating(4)}><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                    <svg aria-hidden="true" className={'w-8 h-8 ' + ((currentRating > 4) ? 'text-yellow-400' : 'text-gray-300')} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" onClick={() => setCurrentRating(5)}><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                    <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">{currentRating} out of 5</p>
                                </div>
                                <div className=''>
                                    <input type="checkbox" id="agree" onChange={checkboxHandler} className='mr-1' />
                                    <label htmlFor="agree"> I agree to <b className='cursor-pointer text-blue-500 ' onClick={() => setShowPrivacyNPolicy(!showPrivacyNPolicy)} >Privacy Policy</b></label>
                                </div>
                                <div className='flex flex-row gap-2 w-full'>
                                    <ButtonPrimary className='mx-auto md:ml-0' text="Submit" disabled={!agree} />
                                    <ButtonPrimary className='mx-auto md:ml-0' text="Skip" disabled={false} onClick={handleSkipSubmit} />
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
