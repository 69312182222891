import React from 'react'

function HighlightedParagraph ({ paragraph, highlights }) {
  const highlightText = (text, startIdx, endIdx) => {
    return (
      <mark className='text-white bg-indigo-500' key={`${startIdx}-${endIdx}`}>
        {text.substring(startIdx, endIdx + 1)}
      </mark>
    )
  }

  const renderHighlightedParagraph = () => {
    const highlightedParagraph = []
    let index = 0
    for (let i = 0; i < highlights.length; i++) {
      const startIdx = highlights[i][0]
      const endIdx = highlights[i][1]
      if (index < startIdx) {
        highlightedParagraph.push(
          <span key={index}>{paragraph.substring(index, startIdx)}</span>
        )
      }
      highlightedParagraph.push(
        highlightText(paragraph, startIdx, endIdx)
      )
      index = endIdx + 1
    }
    highlightedParagraph.push(
      <span key={index}>{paragraph.substring(index)}</span>
    )
    return highlightedParagraph
  }

  return <p>{renderHighlightedParagraph()}</p>
}

// Render HighlightedParagraph component
const GeneralTextHighlight = ({ paragraph, highlights, className }) => {
  // <HighlightedParagraph paragraph={'This is a sample paragraph for text highlighting.'} highlights={[[5, 7], [18, 23]]} />
  return (
      <div className={className} >
      <HighlightedParagraph paragraph={paragraph} highlights={highlights || [] } />
      </div>
  )
}

export default GeneralTextHighlight
