import React, { useEffect, useState } from 'react'
// import GeneralTextInput from '../../_atomic-design/molecules/Input/GeneralTextInput'
import GeneralDropdown from '../../_atomic-design/molecules/Input/GeneralDropdown'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'

export default function OrderAndPresentationSetting ({ orderAndPresentation, setOrderAndPresentation }) {
  const orderArray = [{ text: 'Sequential', value: 'sequential' }]
  // const optionsArray = [{ text: 'One question per page', value: 1 }, { text: 'Two question per page', value: 2 }]
  // eslint-disable-next-line no-unused-vars
  const [orderOfQuestions, setOrderOfQuestions] = useState(orderAndPresentation.orderOfQuestions)
  // const [questionPerPage, setQuestionPerPage] = useState(orderAndPresentation.questionPerPage)
  // eslint-disable-next-line no-unused-vars
  const [questionsPerPage, setQuestionsPerPage] = useState(orderAndPresentation.questionsPerPage)
  // eslint-disable-next-line no-unused-vars
  const [RevealAnswers, setRevealAnswers] = useState('')

  useEffect(() => {
    setOrderAndPresentation((prev) => {
      return { ...prev, orderOfQuestions: orderOfQuestions, questionsPerPage: questionsPerPage }
    })
  }, [orderOfQuestions, questionsPerPage])

  return (
      <div className="w-full bg-white rounded-3xl justify-between my-4 shadow-2xl hidden">
        <div className='bg-green-100 p-4 text-green-500 text-lg rounded-t-3xl'>Order and presentation</div>
        <div className="w-full px-8 p-2 flex flex-col md:flex-row justify-between items-center my-2">
        <TextBody text="Order of Questions" className={'font-medium'} />
        <div className=" pb-4">
          <GeneralDropdown
              options={orderArray}
              className="outline-none bg-transparent text-sm text-right pr-2"
              onChange={(e) => {
                setOrderOfQuestions(e.target.value)
              }}
            />
        </div>
      </div>

       {/* <div className="w-full bg-white p-6 rounded-md justify-between ">
        <div className="flex justify-between w-full my-1">
          <TextSm text="Questions Per Page" />
          <div className="flex ">
            <GeneralDropdown
              options={optionsArray}
              className="outline-none bg-transparent text-sm text-right pr-2"
              onChange={(e) => {
                setQuestionsPerPage(e.target.value)
              }}
            />
          </div>
        </div>
      </div> */}
       {/* <div className="flex justify-between w-full mt-3">
          <TextSm text="" />
          <div className="flex ">
            <GeneralDropdown
              label={'Reveal all answers'}
              options={TimeArr}
              className="outline-none bg-transparent text-sm text-right pr-2"
              onChange={(e) => {
                setRevealAnswers(e.target.value)
              }}
            />
          </div>
        </div>
      </div> */}
    </div>
  )
}
