function calculateTimeTaken (totalTime, timeRemaining) {
  const totalTimeInSeconds = totalTime.minutes * 60 + totalTime.seconds
  const timeRemainingInSeconds = timeRemaining.minutes * 60 + timeRemaining.seconds
  const timeDiff = totalTimeInSeconds - timeRemainingInSeconds

  const mm = Math.floor(timeDiff / 60)
  const ss = timeDiff - (mm * 60)
  return { minutes: mm, seconds: ss }
}

export default calculateTimeTaken
