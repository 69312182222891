import React from 'react'

export default function CustomHr ({ text }) {
  return (
        <div className={'flex justify-between items-center my-3 '}>
            <div className="h-px w-full bg-app-grey"></div>
            <span className="w-max mx-4 rounded-full text-sm text-white bg-gray-500 p-2 text-center">{text}</span>
            <div className="h-px w-full bg-app-grey"></div>
        </div>
  )
}
