import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import AuthRoute from './CreateAccount/AuthRoute.js'
import AddMoreDetails from './CreateAccount/AddMoreDetails'
import FinishQuiz from '../containers/FinishQuiz'
import Congrats from '../containers/Congrats'
import Home from './CreateQuiz'
import GeneratingQuiz from './CreateQuiz/GeneratingQuiz.js'
import PublishedQuizOverview from './CreatedQuizes/PublishedQuizOverview'
import EditQuiz from './CreateQuiz/EditQuiz'
import Dashboard from './CreatedQuizes/Dashboard'
import DashboardSelector from './DashboardSelector.js'
import ForgotPassword from './CreateAccount/ForgotPassword'
import VerifyYourEmail from './CreateAccount/VerifyYourEmail.js'
import Redirecting from './CreateAccount/Redirecting.js'
import ResetPassword from './CreateAccount/ResetPassword.js'
import OAuthRedirect from './CreateAccount/OAuthRedirect.js'
// import Subscription from './CreatePayment/Subscription.js'
import Pricing from './CreatePayment/Pricing.js'
import Plans from '../containers/Plans.js'
import { useDispatch } from 'react-redux'
import { loginSucess } from '../store/actions/authActions/authActions.js'
import RequireAuth from './RequireAuth'
import { getCookies } from '../utils/cookieStorage.js'
import ContactUs from './Contact/ContactUs.js'
import AboutUs2 from './Contact/AboutUs2.js'
import TermsNConditions from './Policy/TermsNConditions.js'

import PrivacyNPolicy from './Policy/PrivacyNPolicy.js'
import Feedback from './Feedback/Feedback.js'
import Admin from './Admin/Admin.js'
import FeedBackReport from './Admin/FeedBackReport.js'
import DashboardStudent from './Student/DashboardStudent.js'
import WaitingStudent from './Student/WaitingStudent.js'

import EvaluationPage from './EvaluateQuiz/EvaluationPage.js'
import RequireAuthStudent from './RequireAuthStudent.js'
// import DenyAccessStudent from './DenyAccessStudent'

import QuizInProgress from '../containers/Pages/QuizInProgress'
import Result from './Student/Result.js'
import StartQuizPage from './Student/StartQuizPage.js'
import Subscription2 from './CreatePayment/Subscription2.js'
// import RequireAuthInstructor from './RequireAuthInstructor.js'

function AppRoute () {
  const dispatch = useDispatch()
  const userDetails = getCookies().userDetails
    ? JSON.parse(getCookies().userDetails)
    : null

  if (userDetails) {
    dispatch(loginSucess({ ...userDetails }))
  }

  return (
        <BrowserRouter>
            <Routes>
                {/* <Route exact path="" element={<RenderComponent component={Home}/>}/> */}

                <Route exact path="" element={

                    <Home />

                } />
                <Route
                    exact
                    path="GeneratingQuiz"
                    element={

                        <GeneratingQuiz />

                    }
                />
                <Route
                    exact
                    path="Dashboard"
                    element={
                        <RequireAuth>
                            <Dashboard/>
                        </RequireAuth>
                    }
              />
              <Route
                    exact
                    path="DashboardSelector"
                    element={
                            <RenderComponent component={DashboardSelector} />
                    }
                />
                <Route
                    exact
                    path="PublishedQuizOverview"
                    element={
                        <RequireAuth>
                        <PublishedQuizOverview />
                        </RequireAuth>
                       }
                />
                <Route exact path="Auth" element={<AuthRoute />} />
                <Route
                    exact
                    path="ForgotPassword"
                    element={<ForgotPassword />}
                />
                <Route exact path="ResetPassword" element={<ResetPassword />} />
                <Route
                    exact
                    path="AddMoreDetails"
                    element={
                        <RequireAuth>
                            <AddMoreDetails />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="studentDashboard"
                    element={
                        <DashboardStudent />
                    }
                />
                <Route
                    exact
                    path="wait-for-quiz"
                    element={
                        <WaitingStudent />
                    }
                />
                <Route
                    exact
                    path="start-quiz"
                    element={
                        <StartQuizPage />
                    }
                />
                <Route exact path="FinishQuiz" element={<FinishQuiz />} />
                <Route
                    exact
                    path="SendVerificationMail"
                    element={
                            <VerifyYourEmail />
                    }
                />
                <Route
                    exact
                    path="OAuthRedirecting"
                    element={<OAuthRedirect />}
                />
                <Route
                    exact
                    path="VerifyEmail"
                    element={<RenderComponent component={Redirecting} />}
                />
                <Route
                    exact
                    path="EditQuiz"
                    element={<RenderComponent component={EditQuiz} />}
                />
                <Route exact path="Congrats" element={<Congrats />} />
                <Route
                    exact
                    path="GeneratingQuiz"
                    element={<RenderComponent component={GeneratingQuiz} />}
                />
                <Route
                    exact
                    path="/"
                    element={<RenderComponent component={Home} />}
                />
                <Route
                    exact
                    path="Plans"
                    element={<RenderComponent component={Plans} />}
                />
                <Route
                    exact
                    path="subscription"
                    element={<RenderComponent component={Subscription2} />}
                />
                <Route
                    exact
                    path="pricing"
                    element={<RenderComponent component={Pricing} />}
                />
                <Route exact path="ContactUs" element={<ContactUs />} />
                <Route exact path="about-us" element={<AboutUs2 />} />
                <Route exact path="privacy-policies" element={<PrivacyNPolicy />} />
                <Route exact path="terms-and-conditions" element={<TermsNConditions />} />
                <Route exact path="Feedback" element={<Feedback />} />
                <Route exact path="FeedBackReport" element={<FeedBackReport />} />
              <Route exact path="Admin" element={<Admin />} />
              <Route exact path='EvaluateQuiz' element={<EvaluationPage />} />
              <Route path="/QuizInProgress" element={
                <QuizInProgress />
            } />
              <Route path='/Result'
              element={
              <RequireAuthStudent>
                  <Result />
              </RequireAuthStudent>} />
                {/* <Route path="*" component = {PageNotFound}/> */}
            </Routes>
        </BrowserRouter>
  )
}

function RenderComponent ({ component: Component }) {
  return (
        <div className="h-screen w-full">
            <Component />
        </div>
  )
}

export default AppRoute
