import React from 'react'
import LogoIcon from '../../../assets/images/Icons/favicon.ico'

export default function LogoLoader ({ text, time }) {
  return (
        <div className="h-full w-full flex justify-center items-center">
                <div className="flex flex-col gap-2 justify-center items-center">
                <img className="w-16 animate-spin origin-[50%_44%]" src={LogoIcon} alt="LOGO" />

                    <div className="w-[200%] h-2 bg-gray-200 rounded-full">
                      <div className='h-full bg-app-blue rounded-full bg-gradient-to-r from-[#3C38CD] to-[#CE8CDD]' style={{ width: time + '%' }}></div>
                    </div>

                      <p className="text-app-primary text-2xl font-semibold justify-center items-center">
                          {text}
                      </p>
                    <br></br>
                    {/* <p className="text-app-primary text-center text-base md:text-2xl font-semibold">
                        Quiz will be generated  in less than 2 minutes.
                    </p> */}
                </div>
            </div>
  )
}
