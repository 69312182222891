// import React from 'react'
const EditQuizJoyRide = {
  STEPS: [
    {
      title: 'Change the quiz name here.',
      content: 'Change the quiz name here. to recognise it latter',
      target: '#Quiz_name_joyride',
      placement: 'right'
    },
    {
      title: 'Settings',
      content: 'Here you can add quiz time, start date, end date, etc.',
      target: '#Settings_joyride',
      placement: 'left'
    },
    {
      title: 'Questions',
      content: 'At the time of editing the question please use this tab',
      target: '#Questions_joyride',
      placement: 'left'
    },
    {
      title: 'Total score',
      content: 'Here you can see the total score while quiz editing.',
      target: '#totalscore_joyride',
      placement: 'left'
    },
    {
      title: 'Question text',
      content: 'Here you can edit the question.',
      target: '#question_joy_ride_1',
      placement: 'auto'
    },
    {
      title: 'Subjective answer',
      content: 'Here you can edit subjective answer',
      target: '.SubjectiveQuestionId_joyride',
      placement: 'left'
    },
    {
      title: 'Choose question type.',
      content: 'here you can easily togle between MCQ and subjective question',
      target: '#questionType_joyride_1',
      placement: 'left'
    },
    {
      title: 'Choose each question score.',
      content: 'here you can easily togle between MCQ and subjective question.',
      target: '#questionScore_joyride_1',
      placement: 'left'
    },
    {
      title: 'Delete a question.',
      content: 'Here you can delete a particular question.',
      target: '#questionDelete_joyride_1',
      placement: 'left'
    },
    {
      title: 'MCQ question',
      content: 'Here you edit mcq question',
      target: '.MCQuestionId_joyride',
      placement: 'auto'
    },
    {
      title: 'MCQ question select answer',
      content: 'Select one and make it the answer to that MCQ question.',
      target: '.MCQ_joyride_radio',
      placement: 'left'
    },
    {
      title: 'Add new question',
      content: 'Click to add a new question.',
      target: '.joyride_addquestion',
      placement: 'left'
    },
    {
      title: 'Download quiz',
      content: 'Use this button to download a different format of the quiz.',
      target: '.joyride_download_quiz',
      placement: 'left'
    },
    {
      title: 'Save quiz',
      content: 'After editing you need to save the quiz before publishing',
      target: '.joyride_save',
      placement: 'auto'
    },
    {
      title: 'Download quiz',
      content: 'After saving the quiz and its settings you can publish here',
      target: '.joyride_publish',
      placement: 'auto'
    }

  ]
}

export default EditQuizJoyRide
