import React from 'react'
import update from '../../../assets/images/Icons/update.svg'
// import CrossWhite from '../../../assets/images/Icons/CrossWhite.svg'
import ImageTag from '../../atoms/ImageTag'

export default function UpdateMarksButton ({ onClick }) {
  return (
    <div className='fixed sm:bottom-5 bottom-8 left-[50%] -translate-x-[50%] z-50 rounded-full bg-app-primary h-8 sm:h-12 w-fit px-4 flex flex-row justify-evenly items-center'>
        <button className='flex sm:text-xl text-white font-semibold' onClick={onClick}> <ImageTag src={update} className="w-4 sm:w-5 mr-2 my-auto" /> {'Update Marks'}</button>

        {/* <button>
            <ImageTag src={CrossWhite} className=' w-2 sm:w-4' />
        </button> */}
    </div>
  )
}
