import React from 'react'

export default function ButtonSecondarySm ({ text, onClick, className }) {
  return (
        <button
            onClick={onClick}
            // eslint-disable-next-line no-undef
            className={` w-28 text-sm block h-8 rounded-md bg-app-primary text-white ${className}`} > {text}
        </button>
  )
}
