/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
// import MainBackgroundSrc from '../../assets/images/MainBackground.svg'
import MainBackgroundSrc from '../../assets/images/quizWaitingPage.png'
import HeadingSm from '../../_atomic-design/atoms/Text/HeadingSm'
import { useLocation, useNavigate } from 'react-router-dom'
import { calculateTimeDifference } from '../../utils/calculateTimeDifference'
import TimerExtendedBig from '../../components/TimerExtendedBig'
import LogoIcon from '../../assets/images/Icons/favicon.ico'
import StartQuiz from '../../assets/images/StartQuiz.svg'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import TickRedCircle from '../../assets/images/Icons/TickRedCircle.svg'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'

export default function WaitingStudent () {
  const bgStyle = {
    backgroundImage: `url(${MainBackgroundSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
  const { state } = useLocation()
  const { data, attemptedsDetails } = state
  const navigate = useNavigate()
  const today = new Date()
  const quizStartDate = new Date(data.quizId.settings.startDate)
  const quizEndDate = new Date(data.quizId.settings.endDate)

  const [remainigTime, setRemainingTime] = useState(calculateTimeDifference(today, quizStartDate))
  const [notes, setNotes] = useState(data.quizId.settings.rulesAndRegulations ? data.quizId.settings.rulesAndRegulations : [])

  /** This Function reloads the page every 30s.  */

  /** This Function reloads the page every 30s.  */
  useEffect(() => {
    const interval = setInterval(() => {
      // Reload the page
      window.location.reload()
    }, 30000) // 30 seconds in milliseconds

    return () => {
      // Clean up the interval when the component unmounts
      clearInterval(interval)
    }
  }, [])

  let quizLiveStatus = 'Expired'
  if (today - quizEndDate < 0 && today - quizStartDate > 0) {
    quizLiveStatus = 'Live'
  } else if (today - quizStartDate < 0) {
    quizLiveStatus = 'Upcoming'
  }
  useEffect(async () => {
    document.title = 'quiz-in-progress'
  }, [])

  const startQuizButtonHandler = async () => {
    navigate('/start-quiz', { state: { data, attemptedsDetails } })
  }
  return (
    <div
      className="w-full min-h-screen flex flex-col md:flex-row justify-center items-center"
      style={bgStyle}
    >
      <div className="flex flex-col md:flex-row justify-center items-center gap-2  text-xs sm:text-sm md:text-base rounded-3xl p-2 sm:p-10 w-[90vw] sm:w-max">
        <div className='flex flex-col sm:flex-row gap-8 w-full'>
          <div className="flex flex-col justify-center items-center w-full sm:w-1/2">
            <div className='flex flex-row gap-4 items-center justify-center'>
              <div><img className="w-12 " src={LogoIcon} alt="LOGO" /></div>
              <div className='pb-2'> <HeadingSm text={data.quizId.name} /></div>
            </div>
            <div><img className="w-auto " src={StartQuiz} alt="StartQuiz Image" /></div>
            <div className='font-semibold w-2/3 text-center'>Your exam will start soon. Please read the instructions before proceeding to the start page.</div>
          </div>
          <div className="flex flex-col  justify-center items-center mx-auto w-full sm:w-1/2 gap-4 rounded-3xl ">
          <div className="flex flex-col justify-center w-2/3">
            <div className='mx-auto'><HeadingSm text="Exam rules" /></div>
            <div className='mx-auto text-start'>
              {notes.map((note, index) => (
                <div className=" flex flex-row justify-between gap-2 px-8 p-2" key={index}>
                  <div className='flex flex-row gap-2  '>
                    <ImageTag
                      src={TickRedCircle}
                      className="w-4 sm:w-5 mr-2"
                    />
                    <TextBody text={index + 1 + '. ' + note.text} className={'font-medium'} />
                  </div>
                </div>
              ))}
            </div>
            </div>
            <div className='pl-6'>
              <div className='font-semibold text-center text-4xl'>Remaining time</div>
              </div>
            <div className="h-36" >
                  <TimerExtendedBig onTimeUp={startQuizButtonHandler} time={remainigTime} setTime={setRemainingTime} />
            </div>
            <div className='font-semibold w-2/3 text-center text-xl'>Once the time up your exam will start</div>

          </div>
        </div>
      </div>
    </div>
  )
}
