/* Packs all the reducers into one root reducer */

/*
import xx from 'xx'
You can rename your reducer in combineReducers yy:xx
*/
import { combineReducers } from 'redux'
import { authReducer } from './authReducer'
import { quizReducer } from './quizReducer'
import { authStudentReducer } from './authStudentReducer'
import { quizStudentReducer } from './quizStudentReducer'

const reducers = combineReducers({
  authReducer,
  quizReducer,
  authStudentReducer: authStudentReducer.reducer,
  quizStudentReducer: quizStudentReducer.reducer
})

export default reducers
