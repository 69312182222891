import React, { useEffect, useRef, useState } from 'react'
import UploadComponent from './UploadComponent'
// import HomePageBackground from '../../assets/images/HomePageBackground.svg'
// import MainBackgroundNew from '../../assets/images/MainBackgroundNew.png'
import HomepageBgShape from '../../assets/images/Homepage_bg_shape.svg'
// import purpleDownCaretSrc from '../../assets/images/Icons/purpleDownCaret.svg'
import StartLearningButton from '../../_atomic-design/molecules/Buttons/StartLearningButton'
import CreateQuizButton from '../../_atomic-design/molecules/Buttons/CreateQuizButton'
import QuizLimitationModal from '../Modals/QuizLimitationModal'

export default function CreateQuiz () {
  const navRef = useRef(null)
  const [isButtonVisible, setIsButtonVisible] = useState()
  const [showLimitationmodel, setShowLimitationmodel] = useState(false)
  const bgImage = {
    // backgroundImage: `url(${MainBackgroundNew})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat'
  }
  const bgShape = {
    position: 'absolute',
    right: '0',
    top: '0'
    // zIndex: '10'
  }
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 500) {
        if (isButtonVisible !== true) {
          setIsButtonVisible(true)
        }
      } else {
        if (isButtonVisible !== false) {
          setIsButtonVisible(false)
        }
      }
    })
    return () => {
      window.removeEventListener('scroll', navRef)
    }
  }, [])
  return (
        <div id='home' className="min-h-screen h-full relative pt-24 md:pt-32 pb-24 rounded-bl-[20px] rounded-br-[20px] overflow-hidden bg-[#ffefff]" style={bgImage}>
            <img src={HomepageBgShape} alt="" className='w-3/4 md:w-1/2' style={bgShape} />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-8 md:gap-y-12 mx-auto w-11/12 h-full">
                    <div className="flex flex-col justify-between items-start md:items-center  h-full order-2 md:order-1 w-11/12 text-center md:text-left gap-4">
                    {/* <p className="md:text-3xl text-2xl text-gray-500 font-bold">
                                <b className='text-[#6042D1]'>learnkraft.ai, </b>an environment to empower the instructors to minimize
                                 administrative burden and maximize educators impact.
                                 <br /> <br />
                                 <b className='text-green-400'> In our Beta version, we demonstrate the Assessment Environment</b>
                            </p> */}
                        <div className="ml-5 my-auto z-10 w-fit pb-16 ">
                        <div className="font-bold text-[32px] md:text-6xl text-[#6042D1] md:mb-5 flex flex-col">
                                <p>Create a Quiz</p>
                                <div >
                                  <span>in a </span>
                                  <span> Whiz.</span><span className='text-app-green text-[16px] md:text-3xl '>[Beta Version]</span></div>
                            </div>
                            <p className="md:text-xl text-lg mb-5">
                                A game-changing <b>AI-powered Quiz Generator</b> that helps
                                you create and launch your <b>customized quizzes, polls, and
                                notes.</b> <br /> <br />
                                <b id='draga_and_drop'>Drag & drop</b> your PDF or Doc files to create your quiz.
                            </p>
                            <StartLearningButton />
                        </div>
                    </div>
                    <div className="h-full flex justify-center items-center w-full mx-auto order-1 md:order-2 ">
                        <UploadComponent setShowLimitationModel={setShowLimitationmodel} />
                    </div>
                </div>
                { isButtonVisible
                  ? (<CreateQuizButton />)
                  : null
                }
                <QuizLimitationModal
                active={showLimitationmodel}
                setActive={() => {
                  setShowLimitationmodel(!showLimitationmodel)
                }

                }
            />
                {/* <div className="absolute bottom-0 mx-auto w-full mb-5" >
                    <div className="cursor-pointer mx-auto w-fit flex flex-col justify-center items-center ">
                        <p className='text-app-primary font-bold mb-2' >Scroll</p>
                        <img src={purpleDownCaretSrc} className="w-5" alt="" />
                    </div>
                </div> */}
        </div>
  )
}
