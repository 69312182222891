import React from 'react'
import ButtonSecondaryLg from '../_atomic-design/atoms/Button/ButtonSecondaryLg'
import ButtonPrimaryLg from '../_atomic-design/atoms/Button/ButtonPrimaryLg'
import HeadingLarge from '../_atomic-design/atoms/Text/HeadingLg'
import TextBody from '../_atomic-design/atoms/Text/TextBody'

export default function FinishQuiz () {
  return (
        <div className="fixed top-0 left-0 min-h-screen w-screen flex justify-center items-center overflow-auto">
            <div className="absolute top-0 left-0 h-full w-full bg-app-blue-background opacity-90"></div>
                <div className="w-fit rounded-xl bg-white p-6 sm:p-20 mx-3 z-10 text-center">
                    <HeadingLarge text={'Finish Quiz'} />
                    <TextBody className={'mt-5 mb-12'} text={'You still have 10 questions left to answer.'} />
                    <ButtonPrimaryLg className={'mb-2'} text={'Finish Quiz'} />
                    <ButtonSecondaryLg text={"Don't Finish Quiz"} />
                </div>
        </div>
  )
}
