import React, { useState } from 'react'
import QuizRadioInput from '../../_atomic-design/molecules/Input/QuizRadioInput'
import CustomizedTextArea from '../../_atomic-design/molecules/Input/CustomizedTextArea'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import plus from '../../assets/images/Icons/plusBlue.svg'
import DeleteOptionSrc from '../../assets/images/Icons/deleteWhite.svg'
// import DeleteOptionSrc from '../../assets/images/Icons/DeleteRedIcon2.svg'
// import crossCircleSrc from '../../assets/images/Icons/crossCircle.svg'
// import crossCircleSrc from '../../assets/images/Icons/CrossGreenCircular.svg'

import ImageTag from '../../_atomic-design/atoms/ImageTag'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'
// import TooltipRight from '../../_atomic-design/molecules/Others/TooltipRight'
import TooltipLeft from '../../_atomic-design/molecules/Others/TooltipLeft'
// import OptionPicture from '../Common/OptionPicture'
import ButtonAddPicture from '../../_atomic-design/atoms/Button/ButtonAddPicture'
import ImageViewBox from '../../_atomic-design/molecules/Images/ImageViewBox'

export default function SingleCorrectQuestion ({
  className,
  question,
  Editable,
  addNewOption,
  deleteOption,
  updateOption,
  changeAnswer,
  MCQuestionId,
  handleOptionPictureChange
}) {
  const [selected, setSelected] = useState(question.generatedAnswer[0])

  function onChange (id, value) {
    changeAnswer(value)
    setSelected(value)
  }

  return (
        <div className="MCQuestionId_joyride" id={`MCQuestionId_joyride_${MCQuestionId}`}>
            {question.questChoices &&
                question.questChoices.map((item, key) => {
                  return (
                        <div
                            className="flex flex-col my-5 bg-[#F3F0FA] rounded-md p-2"
                            key={key}
                        >
                        <div className='flex flex-row '>
                            <QuizRadioInput
                                id={question._id + key}
                                selected={selected}
                                onChange={onChange}
                                value={item}
                            />
                            <div className="ml-4 w-full">
                                <CustomizedTextArea
                                    className='text-xs md:text-base pb-1 md:pb-3'
                                    border
                                    defaultValue={item}
                                    onChange ={ (text) => {
                                      updateOption(key, text)
                                    }}
                                />
                            </div>
                            <ButtonAddPicture className={'my-auto mx-2 '} picture={(question.options_details && question.options_details[key] && question.options_details[key].img_url) || ''} setPicture={(picUrl) => handleOptionPictureChange(picUrl, key)}/>
                            <TooltipLeft content={
                                <GeneralButton
                                    className={'cursor-pointer my-auto hover:opacity-50 transition-opacity bg-app-red rounded-full p-2'}
                                    content={
                                        <ImageTag
                                            src={DeleteOptionSrc}
                                            className=" w-[20px] h-[20px] "
                                        />
                                    }
                                    onClick={() => deleteOption(key)}
                                />}
                                message={'Delete this option'}
                                className={'my-auto flex w-12 h-12'}
                            />
                        </div>
                            <div className='flex ml-6 mt-4'>
                            <ImageViewBox className={'w-[300px] h-[300px]'} image={(question.options_details && question.options_details[key] && question.options_details[key].img_url) || ''} onRemove={() => handleOptionPictureChange('', key)} />
                                {/* <TooltipRight
                                className='w-fit'
                                // content={<AddQuizPicture quizPicture={question.option_details[key].img_url} setQuizPicture={(imgUrl) => setOptionPictureArray((prev) => { prev[key] = imgUrl; return prev })} handleQuestionPictureChange={(picUrl) => handleOptionPictureChange(picUrl, key)} uploadenable={true}/>}
                                content={<OptionPicture optionImage={(question.options_details && question.options_details[key] && question.options_details[key].img_url) || ''} setOptionImage={ () => {}} handleOptionImageChange={(picUrl) => handleOptionPictureChange(picUrl, key)} uploadEnabled={true}/>}
                                message={'Add option image'}
                                />
                                <TooltipLeft
                                content={<GeneralButton
                                    className={'cursor-pointer mb-auto flex disabled:hidden '}
                                    content={
                                        <ImageTag
                                            src={crossCircleSrc}
                                            className="w-4 h-4 hover:opacity-50 transition-opacity"
                                        />
                                    }
                                    onClick={() => handleOptionPictureChange('', key)}
                                    disabled={!question.options_details || !question.options_details[key] || !question.options_details[key].img_url || question.options_details[key].img_url === ''}
                                />}
                                message={'Remove image from option'}
                                /> */}
                            </div>
                        </div>
                  )
                })}

            <GeneralButton className="cursor-pointer hover:opacity-50  transition-opacity" content={<AddOption />} onClick={addNewOption} />
        </div>
  )
}

function AddOption () {
  return (
        <div className="flex flex-row items-center ">
            <span className="h-6 w-6 mr-3 flex justify-center items-center text-base border border-app-blue rounded-full">
                <ImageTag className="h-2 w-2" src={plus} />
            </span>
            <TextBody className="text-app-blue" text="Add Option" />
        </div>
  )
}
