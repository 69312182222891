import React from 'react'
import bgRegistration from '../../assets/images/bgRegistration.svg'
import HeadingLg from '../../_atomic-design/atoms/Text/HeadingLg'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import { registrationText } from '../../assets/data/aboutUsData'
import { useNavigate } from 'react-router-dom'

export default function Registration () {
  const bgStyle = {
    background: `url(${bgRegistration}), #3C38CD`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  }
  const navigate = useNavigate()
  function getStarted () {
    navigate('/Auth?mode=register')
  }

  function contactUs () {
    navigate('/contactus')
  }
  return <div className='w-4/5 my-20 mx-auto rounded-xl px-10 sm:px-20 p-20 flex flex-col items-center justify-center text-white ' style={bgStyle}>
        <HeadingLg text={'Get Your Free Registration'} className={'text-center font-bold'}/>
        <p className='my-2 text-center'>{registrationText}</p>
        <div className='gap-4 flex flex-col sm:flex-row'>
            <GeneralButton onClick={getStarted} className={'p-2 px-10 rounded-full bg-white text-black'} content={'Get Started'}/>
            <GeneralButton onClick={contactUs} className={'p-2 px-10 rounded-full bg-gradient-to-r from-[#FFBFFF] to-[#796FDA]'} content={'Contact Us'}/>
        </div>
       </div>
}
