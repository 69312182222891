/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import AdminNavbar from '../../_atomic-design/molecules/Navbars/AdminNavbar'
import FilterBar from '../../containers/CreatedQuizes/FilterBar'
import QuizCard from '../../containers/CreatedQuizes/QuizCard'
import { makeServerGETRequest } from '../../utils/helpers'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import HeadingSm from '../../_atomic-design/atoms/Text/HeadingSm'
// import AddButtonCircular from '../../_atomic-design/molecules/Others/AddButtonCircular'
import plusIcon from '../../assets/images/Icons/plusRoundPurple.svg'
import Table, { AvatarCell, SelectColumnFilter, StatusPill } from './Table' // new
import AdminDropdown from './AdminDropdown'
import Admin from './Admin'

export default function FeedBackReport () {
  const data = [
    {
      _id: '62d7cf38a67fb371612da655',
      feedback: 'sdfdsfsdfsd',
      rating: 3,
      useful: false,
      __v: 0,
      firstName: 'pritesh',
      lastName: 'maharana',
      email: 'pmpmaharana97@gmail.com',
      profilePicture: 'https://lh3.googleusercontent.com/a-/AFdZucp2U2sOnhI3x8d2UwFiPqjqtrB6HTGcK0afpkJNxkU=s96-c'
    },
    {
      _id: '62d7cf38a67fb371612da655',
      feedback: 'sdfdsfsdfsd',
      rating: 3,
      useful: false,
      __v: 0,
      firstName: 'pritesh',
      lastName: 'maharana',
      email: 'pmpmaharana97@gmail.com',
      profilePicture: 'https://lh3.googleusercontent.com/a-/AFdZucp2U2sOnhI3x8d2UwFiPqjqtrB6HTGcK0afpkJNxkU=s96-c'
    },
    {
      _id: '62d7cf38a67fb371612da655',
      feedback: 'sdfdsfsdfsd',
      rating: 3,
      useful: false,
      __v: 0,
      firstName: 'pritesh',
      lastName: 'maharana',
      email: 'pmpmaharana97@gmail.com',
      profilePicture: 'https://lh3.googleusercontent.com/a-/AFdZucp2U2sOnhI3x8d2UwFiPqjqtrB6HTGcK0afpkJNxkU=s96-c'
    },
    {
      _id: '62d7cf38a67fb371612da655',
      feedback: 'sdfdsfsdfsd',
      rating: 3,
      useful: false,
      __v: 0,
      firstName: 'pritesh',
      lastName: 'maharana',
      email: 'pmpmaharana97@gmail.com',
      profilePicture: 'https://lh3.googleusercontent.com/a-/AFdZucp2U2sOnhI3x8d2UwFiPqjqtrB6HTGcK0afpkJNxkU=s96-c'
    },
    {
      _id: '62d7cf38a67fb371612da655',
      feedback: 'sdfdsfsdfsd',
      rating: 3,
      useful: false,
      __v: 0,
      firstName: 'pritesh',
      lastName: 'maharana',
      email: 'pmpmaharana97@gmail.com',
      profilePicture: 'https://lh3.googleusercontent.com/a-/AFdZucp2U2sOnhI3x8d2UwFiPqjqtrB6HTGcK0afpkJNxkU=s96-c'
    },
    {
      _id: '62d7cf38a67fb371612da655',
      feedback: 'sdfdsfsdfsd',
      rating: 3,
      useful: true,
      __v: 0,
      firstName: 'pritesh',
      lastName: 'maharana',
      email: 'pmpmaharana97@gmail.com',
      profilePicture: 'https://lh3.googleusercontent.com/a-/AFdZucp2U2sOnhI3x8d2UwFiPqjqtrB6HTGcK0afpkJNxkU=s96-c'
    }
  ]

  const [quizData, setQuizData] = useState(data)

  const columns = React.useMemo(() => [
    {
      Header: 'Name',
      accessor: 'firstName',
      Cell: AvatarCell,
      imgAccessor: 'profilePicture',
      emailAccessor: 'email',
      lastNameAccessor: 'lastName'
    },
    {
      Header: 'Feedback',
      accessor: 'feedback'
    },
    {
      Header: 'Is Helpfull',
      accessor: 'useful',
      Cell: StatusPill
    },
    {
      Header: 'Rating',
      accessor: 'rating'
    },
    {
      Header: 'Is Modified',
      accessor: '__v',
      Filter: SelectColumnFilter,
      filter: 'rating'
    }
  ], [])

  const addMissingKey = (value, index, array) => {
    if (!('_id' in value)) {
      value._id = '62d7cf38a67fb371612da655'
    }
    if (!('feedback' in value)) {
      value.feedback = 'NA'
    }
    if (!('rating' in value)) {
      value.rating = 0
    }
    if (!('useful' in value)) {
      value.useful = true
    }
    if (!('__v' in value)) {
      value.__v = 0
    }
    if (!('firstName' in value)) {
      value.firstName = 'NA'
    }
    if (!('lastName' in value)) {
      value.lastName = 'NA'
    }
    if (!('email' in value)) {
      value.email = 'NA'
    }
    if (!('profilePicture' in value)) {
      value.profilePicture = 'https://lh3.googleusercontent.com/a-/AFdZucp2U2sOnhI3x8d2UwFiPqjqtrB6HTGcK0afpkJNxkU=s96-c'
    }
    return value
  }

  useEffect(() => {
    const getdata = async () => {
      const resultdata = await makeServerGETRequest(
        '/feedback/all',
        {
          limit: 100,
          page: 1
        }
      )
      if (resultdata.status === 200) {
        const responseFeedback = resultdata.data.resultFeedback
        const feedbackData = responseFeedback.map(addMissingKey)
        setQuizData(feedbackData)
      }
    }
    getdata()
  }, [])

  return (
        <Admin>
                <Table columns={columns} data={quizData} />
        </Admin>
  )
}
