import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import DefaultDropdown from '../../_atomic-design/molecules/Input/DefaultDropdown'
import TextInputGray from '../../_atomic-design/molecules/Input/TextInputGray'
import HeadingLarge from '../../_atomic-design/atoms/Text/HeadingLg'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'
import TermsAndConditionsText from '../../_atomic-design/molecules/Others/TermsAndConditionsText'
import AddProfilePicture from '../../containers/Common/AddProfilePicture'
import { makeAuthenticatedServerRequest } from '../../utils/helpers'
import TooltipRight from '../../_atomic-design/molecules/Others/TooltipRight'
import { useDispatch, useSelector } from 'react-redux'
import { UserInfo } from '../../store/actions/authActions/authActions'
import { setUserDetails, getCookies } from '../../utils/cookieStorage'
import data from '../../assets/data/addMoreDetailsData'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import MainBackgroundSrc from '../../assets/images/MainBackground.svg'
import { toast } from 'react-toastify'

export default function Index () {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const User = useSelector((state) => state.authReducer)
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const mode = searchParams.get('mode') || 'register'

  const [firstName, setFirstName] = useState('')
  const [departmentName, setDepartmentName] = useState('')
  const [lastName, setLastName] = useState('')
  const [education, setEducation] = useState('')
  const [language, setLanguage] = useState('')
  const [profession, setProfession] = useState('')
  const [profilePicture, setprofilePicture] = useState('')

  function handleFirstName (e) {
    setFirstName(e.target.value)
  }
  function handleDepartmentName (e) {
    setDepartmentName(e.target.value)
  }
  function handleLastName (e) {
    setLastName(e.target.value)
  }
  function handleEducation (e) {
    setEducation(e.target.value)
  }
  function handleProfession (e) {
    setProfession(e.target.value)
  }
  function handleLanguage (e) {
    setLanguage(e.target.value)
  }
  const handleSubmit = (e) => {
    e.preventDefault()

    addDetailsToDatabase()
  }

  const addDetailsToDatabase = async () => {
    if (firstName === '') {
      toast('Please fill first name')
      return
    } else if (education === '') {
      toast('Please select education')
      return
    } else if (language === '') {
      toast('Please select language')
      return
    } else if (profession === '') {
      toast('Please select profession')
      return
    } else if (departmentName === '') {
      toast('Please enter departmentName')
      return
    }
    const res = await makeAuthenticatedServerRequest('/profile', 'PUT', {
      firstName,
      lastName,
      education,
      language,
      profilePicture,
      profession,
      departmentName
    })
    if (res.status === 200) {
      const userDetails = getCookies().userDetails
        ? JSON.parse(getCookies().userDetails)
        : null
      dispatch(UserInfo({ ...userDetails, ...res.data }))
      setUserDetails({ ...userDetails, ...res.data })

      if (!res.data.detailsCompleted) {
        toast('Please complete your profile')
        return
      }
      toast('Successfully added details')
      switch (User.userRoleType) {
        case '1':
          navigate('/studentDashboard')
          return
        case '0':
          navigate('/')
          return
        default:
          navigate('/DashboardSelector')
      }
    } else {
      toast('Name field error code 5495')
    }
  }

  useEffect(async () => {
    document.title = 'AddMoreDetails'
    if (!User.isLoggedIn) {
      navigate('/Auth?mode=login', { replace: true })
      return
    }
    if (!User.isEmailVerified) {
      navigate('/SendVerificationMail', { replace: true })
    }

    if (User.profilePicture) {
      setprofilePicture(User.profilePicture)
    }
    if (User.firstName) {
      setFirstName(User.firstName)
    }
    if (User.lastName) {
      setLastName(User.lastName)
    }
    if (User.education) {
      setEducation(User.education)
    }
    if (User.language) {
      setLanguage(User.language)
    }
    if (User.profession) {
      setProfession(User.profession)
    }
    if (User.departmentName) {
      setDepartmentName(User.departmentName)
    }

    return () => {

    }
  }, [User])

  const educationList = data.education.map((item) => ({ value: item, text: item }))
  const languageList = data.languages.map((item) => ({ value: item, text: item }))
  const professionList = data.profession.map((item) => ({ value: item, text: item }))
  const departmentList = data.departments.map((item) => ({ value: item, text: item }))

  const bgStyle = {
    backgroundImage: `url(${MainBackgroundSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom'
  }

  return (
    <div className=" min-h-screen bg-app-blue-background flex justify-center items-center overflow-auto" style={bgStyle}>
      <div className="w-min bg-white rounded-2xl py-8 sm:py-10 px-6 sm:px-24 my-10 shadow-lg">

        <HeadingLarge
          text={'Tell us a bit more about yourself'}
          className={'font-bold mb-10 text-center'}
        />
        <TextBody text="Drop your profile picture"/>
        <form onSubmit={handleSubmit} >
          <TooltipRight
            className='w-fit'
            content={
              <AddProfilePicture profilePicture={profilePicture} setprofilePicture={setprofilePicture}/>
            }
            message={'Add or change profile picture'}
          />
          <TextInputGray
            label={'What is your name?'}
            placeholder={'First name'}
            value={firstName}
            onChange={handleFirstName}
            required
          />
          <TextInputGray
            className={'mt-3'}
            placeholder={'Last name'}
            value={lastName}
            onChange={handleLastName}
            required
          />
          <DefaultDropdown
            className={'my-8'}
            options={educationList}
            label={'What is your highest level of education?'}
            onChange={handleEducation}
            defaultSelected={education}
            required
          />
          <DefaultDropdown
            className={'mb-8'}
            options={languageList}
            label={'What is your native language?'}
            onChange={handleLanguage}
            defaultSelected={language}
            required
          />
          <DefaultDropdown
            className={'mb-8'}
            options={professionList}
            label={'What best describes you?'}
            onChange={handleProfession}
            defaultSelected={profession}
            required
          />
          <DefaultDropdown
            className={'mb-8'}
            label={'What is your department?'}
            options={departmentList}
            onChange={handleDepartmentName}
            defaultSelected={departmentName}
            required
          />
            <GeneralButton
              content={mode !== 'edit' ? 'Get Started' : 'Update'}
              className="mt-5 mb-5 w-72 sm:w-96 mx-auto h-12 rounded-lg text-white bg-app-primary"
              onClick={handleSubmit}
            />
        </form>
        {mode !== 'edit' && <TermsAndConditionsText className={'mt-5 text-center text-xs'} />}
      </div>
    </div>
  )
}
