/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import AdminNavbar from '../../_atomic-design/molecules/Navbars/AdminNavbar'
import FilterBar from '../../containers/CreatedQuizes/FilterBar'
import QuizCard from '../../containers/CreatedQuizes/QuizCard'
import { makeServerGETRequest } from '../../utils/helpers'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import HeadingSm from '../../_atomic-design/atoms/Text/HeadingSm'
// import AddButtonCircular from '../../_atomic-design/molecules/Others/AddButtonCircular'
import plusIcon from '../../assets/images/Icons/plusRoundPurple.svg'
import AdminDropdown from './AdminDropdown'
import MainBackgroundSrc from '../../assets/images/MainBackground.svg'

export default function Admin ({ children }) {
  const bgStyle = {
    backgroundImage: `url(${MainBackgroundSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
  return (<>
      <div
          className="w-full min-h-screen pt-28 overflow-hidden"
          style={bgStyle}
      >
      <AdminNavbar/>
      <div className="grid grid-cols-6 gap-2 content-between">
      <div className="col-span-1"><AdminDropdown/></div>
      <div className="col-span-5 px-8 ">{children}</div>
      </div>
        </div>
        </>
  )
}
