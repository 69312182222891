import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeAuthenticatedServerRequest, makeServerRequest } from '../../utils/helpers'
import ResponseBlockObjective from './ResponseBlockObjective'
import ResponseBlockSubjective from './ResponseBlockSubjective'
import { toast } from 'react-toastify'
import UpdateMarksButton from '../../_atomic-design/molecules/Buttons/UpdateMarksButton'

export default function EvaluationForm ({ quizId, questions, questionResponse, studentId, instructorResponse, setInstructorResponse, readOnly, mlEvaluation, instructorEvaluation }) {
  const dummyAnswerSubjective = { markSecured: 0, submittedAnswers: '', n_grammatical_errors: 0, keyphrase_highlights: [] }
  const dummyAnswerObjective = { markSecured: 0, submittedAnswers: '' }
  const navigate = useNavigate()
  const [isValid, setIsValid] = useState(false)
  useEffect(() => {
    const valid = instructorResponse.filter((item) => { return item.questionID !== null })
    if (valid.length !== questions.length) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [instructorResponse])

  useEffect(() => {
  }, [instructorResponse])

  // setQuiz(questions)
  function updateMarksHandler (e) {
    if (!isValid) {
      toast('incomplete')
      return
    }
    e.preventDefault()
    makeServerRequest('saveQuizResponse', 'POST').then((data) => {
      navigate('publishquiz')
    })
  }

  function updateInstructorResponse (e) {
    if (!isValid) {
      toast('give marks for all answers')
      return
    }
    makeAuthenticatedServerRequest('/quiz/saveEvaluation', 'POST', { instructorResponse: instructorResponse, quizId: quizId, studentId }).then((data) => {
      toast('Saving...')
      navigate('/PublishedQuizOverview?quizId=' + quizId)
    })
  }

  return <div className='text-xs sm:text-sm md:text-base w-[90%] mx-auto'>
      {questions.map((question, index) => {
        return (question.question_type === 'S'
          ? <div key={index}>
            <ResponseBlockSubjective
              index={index} item={question}
              questionResponse={questionResponse ? ((question._id in questionResponse) ? questionResponse[question._id] : dummyAnswerSubjective) : dummyAnswerSubjective}
              questionID={question._id}
              instructorResponse={instructorResponse}
              setInstructorResponse={setInstructorResponse}
              updateMarksHandler={updateMarksHandler}
              readOnly={readOnly}
              mlEvaluation={mlEvaluation}
              instructorEvaluation={instructorEvaluation}
              />
          </div>
          : <div key={index}>
            <ResponseBlockObjective
              index={index} item={question}
              questionID={question._id}
              questionResponse={questionResponse ? ((question._id in questionResponse) ? questionResponse[question._id] : dummyAnswerObjective) : dummyAnswerObjective}
              instructorResponse={instructorResponse}
              setInstructorResponse={setInstructorResponse}
              updateMarksHandler={updateMarksHandler}
              readOnly={readOnly}
              mlEvaluation={mlEvaluation}
              instructorEvaluation={instructorEvaluation}
              />
          </div>)
      })}
    {/* {!readOnly && <ButtonPrimarySm text='update' onClick={updateInstructorResponse} className='mx-auto' />} */}
    {!readOnly && <UpdateMarksButton text='update' onClick={updateInstructorResponse} className='mx-auto' />}
    <div className='p-4'>
    </div>
  </div>
}
