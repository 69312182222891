import React from 'react'

export default function GeneralDropdown ({
  id,
  name,
  options,
  className,
  defaultSelected,
  label,
  onChange
}) {
  return (
        <select id={id} className={className} name={name} onChange={onChange} value={defaultSelected}>
            {label && <option value="">{label}</option>}
            {
            options && options.map((item, index) => (
                <option key={index} value={item.value} className='text-left'>
                    {item.text}
                </option>
            ))}
        </select>
  )
}
