import React, { useEffect, useState } from 'react'
import TextInputGray from '../../_atomic-design/molecules/Input/TextInputGray'
import GContactButton from '../../_atomic-design/atoms/Button/GContactButton'
import { makeAuthenticatedServerRequest, makeServerGETRequest } from '../../utils/helpers'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
// import ButtonPrimary from '../../_atomic-design/atoms/Button/ButtonPrimary'
import TooltipRight from '../../_atomic-design/molecules/Others/TooltipRight'
import PreviewName from '../CreateQuiz/PreviewNameAndEmail'
import { read, utils } from 'xlsx'
import { isValidEmail } from '../../utils/checkValidEmail'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import CustomHr from '../../_atomic-design/molecules/Others/CustomHr'

export default function PublishQuiz ({ className, closeModal, quizId, downloadLink }) {
  const [emails, setEmails] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [disabled, setDisabled] = useState(false)
  const navigate = useNavigate()
  const [emailWithName, setEmailWithName] = useState([])
  // const navigate = useNavigate()
  const [uploadedFile, setUploadedFile] = useState('')
  const buttonDisabled = disabled || message === '' || subject === ''

  useEffect(() => {
    async function fetchParticipants () {
      const res = await makeServerGETRequest('/quiz/getParticipants', { quizId })
      if (res.status === 200) {
        console.log(res.data)
        setEmailWithName(res.data.participants)
      }
    }
    fetchParticipants()
  }, [])

  const handleEmailChange = (e) => {
    setEmails(e.target.value)
  }

  const handleSubjectChange = (e) => {
    setSubject(e.target.value)
  }

  const handleMessageChange = (e) => {
    setMessage(e.target.value)
  }

  const submitForm = async (e) => {
    e.preventDefault()
    if (emailWithName.length < 1) {
      toast('No email found please import first')
      return
    }
    if (emailWithName.length > 400) {
      toast('You can only add 100 students.')
      return
    }
    if (subject === '') {
      toast('Email Subject field is empty.')
      return
    }
    if (message === '') {
      toast('Email body field is empty')
      return
    }

    const proceed = window.confirm('After publishing this quiz, you won\'t be able to add or modify any students. Do you want to continue?')
    if (!proceed) {
      return
    }
    // Import
    setDisabled(true)
    const res = await makeAuthenticatedServerRequest('/quiz/share', 'POST', {
      quizId: quizId,
      emails,
      emailWithName,
      subject,
      message
    })
    setDisabled(false)
    if (res.status !== 200) {
      toast('error publishing quiz')
      return
    }
    const saveQuizMessage = 'Quiz published succesfully'
    toast(saveQuizMessage)
    navigate('/Dashboard')
    // if (window.confirm(saveQuizMessage) !== 'true') {
    // navigate(`/EditQuiz?quizId=${quizId}`, { replace: true })
    // }
  }
  const submit = (filedata) => {
    const file = filedata || uploadedFile
    const reader = new FileReader()

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result)
      const workbook = read(data, { type: 'array' })
      const sheetName = workbook.SheetNames[0] // always read first sheet in the workbook
      const sheet = workbook.Sheets[sheetName]
      const jsonData = utils.sheet_to_json(sheet, { header: 'A' })
      const nameAndEmails = jsonData
        .filter((row) => { return isValidEmail(row.B) })
        .map((row) => { return { name: row.A, email: row.B } })
      nameAndEmails.length > 0 ? toast('Import Successfull') : toast('Valid emails not found')
      setEmailWithName(nameAndEmails)
    }

    reader.readAsArrayBuffer(file)
  }
  // const processCSV = (str, delim = ',') => {
  //   const headers = str.slice(0, str.indexOf('\n')).split(delim)
  //   const rows = str.slice(str.indexOf('\n') + 1).split('\n')

  //   const newArray = rows.map(row => {
  //     try {
  //       const values = row.split(delim)
  //       const eachObject = headers.reduce((obj, header, i) => {
  //         header = header.replace(/[\r\n]/gm, '')
  //         i = values[i].replace(/[\r\n]/gm, '')
  //         obj[header] = i
  //         return obj
  //       }, {})
  //       return eachObject
  //     } catch (error) {
  //       toast('file format error')
  //       return []
  //     }
  //   })
  //   if (newArray.length > 0) {
  //     const commaSeparated = Array.prototype.map.call(newArray, function (item) { return item.email }).join(',')
  //     setEmails(commaSeparated)
  //     setEmailWithName((prev) => [...prev, ...newArray])
  //     toast('Import Successfull')
  //   }
  // }

  function handleFileUpload (e) {
    setUploadedFile(e.target.files[0])
    submit(e.target.files[0])
  }
  function onclickHandler (e) {
  }
  return (
    <div className="flex flex-col justify-center items-center p-4 w-screen h-fit gap-2">
      <div
        className='flex flex-col gap-2 w-screen h-5/6 md:max-w-2xl md:max-h-2xl rounded-2xl bg-white shadow-lg shadow-violet-100 p-4 sm:p-10 mt-24 md:mt-1'
      >
        <GeneralText text="Publish Quiz" className="text-app-primary text-2xl font-bold text-center " />
        <div className="">
          <Link target='_blank' className="text-app-secondary text-sm underline font-semibold items-left " to="https://pdf-reader-dkraft.s3.us-east-2.amazonaws.com/emailsample+(7).csv" download>* sample csv recomended google sheet </Link>
        </div>
        <div className='flex flex-col gap-1 bg-violet-100 rounded-lg p-4'>
        <label className="block text-xs sm:text-base mb-2" htmlFor="csv_bar">Import name and email from file *</label>
        <div className="flex flex-col justify-center items-center gap-1" id='csv_bar'>
          <div className="flex w-full">
            <input accept='.csv, .xls, .xlsx, .ods' id='csvFile' onChange={handleFileUpload} onClick={onclickHandler} className="block w-full text-xs sm:text-lg p-1 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50  focus:outline-none " type="file"></input>
            </div>
            <p className='mr-auto text-sm text-red-700'>Supported file types .xls, .xlsx, .ods, .csv </p>
        </div>
        {/* <GeneralText text="OR" className="text-app-primary text-lg font-bold text-center" /> */}
        <CustomHr text={'OR'} />
        <label className="block text-xs sm:text-base" htmlFor="csv_bar">Import email from google contacts</label>
        <TooltipRight
          content={
            <GContactButton text='G' className='w-full' quizId={quizId} setEmails={setEmails} setEmailWithName={setEmailWithName} />
          }
          message={'Import contacts from google'}
        />
        </div>
        {emailWithName && <PreviewName emails={emails} setEmails={setEmails} emailWithName={emailWithName} setEmailWithName={setEmailWithName} />}
        <textarea value={emails} onChange={handleEmailChange} className='bg-[#F4F7F9] text-xs sm:text-lg rounded-lg min-h-[100px] w-full h-7 sm:h-[50px] placeholder:text-xs sm:placeholder:text-base mb-1 resize-none p-2 hidden' placeholder='imported emails, comma separated' type='textarea' label='Send to' readOnly={true} />

        <TextInputGray onChange={handleSubjectChange} className='' placeholder='Email Subject' label='' />
        <textarea id='message' placeholder='Email body message' value={message} onChange={handleMessageChange} className='bg-[#F4F7F9] rounded-lg min-h-[80px] w-full p-4 h-7 sm:h-[50px] placeholder:text-xs sm:placeholder:text-base  resize-none'></textarea>
        <div className="container my-2 flex flex-col items-center">
        </div>
        {/* {emailWithName && <PreviewName emails={emails} setEmails={setEmails} emailWithName={emailWithName} setEmailWithName={setEmailWithName} />} */}
      </div>
      {/* <ButtonPrimary text='Publish' className={' w-max px-5  '} onClick={submitForm} disabled={disabled || message === '' || subject === ''} /> */}
      <GeneralButton onClick={submitForm} disabled={buttonDisabled} className={` hover:opacity-70 transition-opacity duration-300 rounded-full text-white p-2 px-6 ${buttonDisabled ? ' bg-app-grey cursor-not-allowed ' : ' bg-gradient-to-r from-[#3C38CD] to-[#CF8DDE] cursor-pointer '}`} content={'Publish'}/>
    </div>
  )
}
