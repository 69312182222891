import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { quizStudentActions } from '../../store/reducers/quizStudentReducer'
import BottomPanel from '../../components/BottomPanel'

import QuestionPalleteHidden from '../Quiz/questionPalleteHidden'
import QuestionPalleteVisible from '../Quiz/questionPalleteVisible'

import QuestionContainerSub from '../Quiz/QuestionContainerSub'
// import Timer from '../../components/Timer'
import calculateTimeTaken from '../../helpers/calculateTimeTaken'
import SubmitQuiz from '../../routes/SubmitQuiz'
import clearRadioButtonSelection from '../../helpers/clearRadioButtonSelection'
import { useNavigate } from 'react-router-dom'
import popUp from '../../assets/images/Icons/popUp.svg'
import timeup from '../../assets/images/timeup.svg'
import EnforceFullScreen from './EnforceFullScreen'
import { calculateTimeDifference } from '../../utils/calculateTimeDifference'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import QuestionContainerMCQ from '../Quiz/QuestionContainerMCQ'
// import QuizTimer from '../../components/QuizTimer'
import PopUpEndQuiz from '../General/PopUpEndQuiz'
import QuizTimerUI from '../../components/QuizTimerUI'
import syncTimeWithServer from '../../utils/syncTimeWithServer'
import QuizTimerImproved from '../../components/QuizTimerImproved'
import useOnlineStatus from '../../customHooks/useOnlineStatus'
// import StudentDashboardNavbar from '../../_atomic-design/molecules/Navbars/StudentDashboardNavbar'

export default function QuizInProgress (props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isOnline = useOnlineStatus()

  const quizInReduxStore = useSelector((state) => state.quizStudentReducer.quizRef)
  const totalQuestions = quizInReduxStore.questions.length
  const now = new Date()
  const endDate = new Date(quizInReduxStore.settings.endDate)
  // const timeDiff = Math.floor((endDate - now) / 1000)
  // const remainingMinutes = Math.floor(timeDiff / 60)
  // const remainingSeconds = Math.floor(timeDiff - (remainingMinutes * 60))
  // const initialTimeState = { minutes: 10, seconds: 0 } // set total time of quiz.
  // const initialTimeState = { minutes: quizInReduxStore.settings.duration || 10, seconds: 0 }
  // const initialTimeState = { minutes: remainingMinutes || 10, seconds: remainingSeconds }

  // eslint-disable-next-line no-unused-vars
  const [initialTimeState, setInitialTimeState] = useState(calculateTimeDifference(now, endDate))
  const [time, setTime] = useState(calculateTimeDifference(now, endDate))
  const [response, setResponse] = useState({})
  const [studentAnswer, setstudentAnswer] = useState({})
  const [markedForReview, setMarkedForReview] = useState([])
  const [isSidePanelActive, setIsSidePanelActive] = useState(true)
  const [isPopupModalOpen, setIsPopupModalOpen] = useState(false)
  const [popupType, setPopupType] = useState('submit')
  const [submitPopupText, setSubmitPopupText] = useState('')
  const [istabswitched, setIstabswitched] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState({
    question: quizInReduxStore.questions[0],
    index: 0
  })
  const [isWaiting, setIsWaiting] = useState(false)

  history.pushState(null, null, location.href)
  window.onpopstate = function (event) {
    history.go(1)
  }

  /* this function sets markedForReview on every current question change.
        this is required because once user visits marked question, the question
        should automatically become unmarked.
    */
  // useEffect(() => {
  //   const a = markedForReview
  //   const arr = a.filter((item) => item !== currentQuestion.index)
  //   setMarkedForReview(arr)
  //   // setVisited([...visited, currentQuestion.index]);
  // }, [currentQuestion])

  function showQuestionPallete () {
    setIsSidePanelActive(true)
  }

  function hideQuestionPallete () {
    setIsSidePanelActive(false)
  }

  /*     save user response in Response state        */
  function inputChangeHandler (e) {
    if (istabswitched) {
      changeTabSubmit()
      return
    }
    const newResponse = { [currentQuestion.index]: e }
    const newStudentResponse = { [currentQuestion.question._id]: e }
    setResponse({ ...response, ...newResponse })
    setstudentAnswer({ ...studentAnswer, ...newStudentResponse })
  }

  /* this function gets triggered on every question number click in question panel on the right */
  function questionNumberClickHandler (e) {
    e = parseInt(e)
    setCurrentQuestion({ question: quizInReduxStore.questions[e], index: e })
  }

  // function markForReviewButtonHandler () {
  //   // sort and remove duplicates.
  //   const tempMarked = new Set(
  //     [...markedForReview, currentQuestion.index].sort()
  //   )
  //   setMarkedForReview([...tempMarked])
  //   // nextButtonHandler()
  // }

  function toggleMarkForReview () {
    if (markedForReview.includes(currentQuestion.index)) {
      const a = markedForReview
      const arr = a.filter((item) => item !== currentQuestion.index)
      setMarkedForReview(arr)
    } else {
      const tempMarked = new Set(
        [...markedForReview, currentQuestion.index].sort()
      )
      setMarkedForReview([...tempMarked])
    }
  }

  function nextButtonHandler () {
    if (currentQuestion.index >= quizInReduxStore.questions.length - 1) {
      setCurrentQuestion({ question: quizInReduxStore.questions[0], index: 0 })
    } else {
      setCurrentQuestion({
        question: quizInReduxStore.questions[currentQuestion.index + 1],
        index: currentQuestion.index + 1
      })
    }
  }

  function previousButtonHandler () {
    if (currentQuestion.index <= 0) {
      setCurrentQuestion({
        question:
          quizInReduxStore.questions[quizInReduxStore.questions.length - 1],
        index: quizInReduxStore.questions.length - 1
      })
    } else {
      setCurrentQuestion({
        question: quizInReduxStore.questions[currentQuestion.index - 1],
        index: currentQuestion.index - 1
      })
    }
  }

  // clear selection when user presses this button and remove the answered question from response object
  function clearSelectionButtonHandler (e) {
    clearRadioButtonSelection()
    const newResponse = { ...response }
    delete newResponse[currentQuestion.index]
    setResponse({ ...newResponse })
    const newStudentAnswer = { ...studentAnswer }
    delete newStudentAnswer[currentQuestion.question._id]
    setstudentAnswer({ ...newStudentAnswer })
  }

  function timeUpHandler () {
    setSubmitPopupText('Time Up!! This test is now being auto-submitted')
    setPopupType('timeup')
    submitQuiz()
    openPopupModal()
  }

  function fullScreenTimeoutHandler () {
    setSubmitPopupText('Time Upp!! This test is now being auto-submitted')
    setPopupType('timeup')
    submitQuiz()
    openPopupModal()
  }

  function changeTabSubmit () {
    setSubmitPopupText('This quiz is now auto-submitted as visibility changed.or you have gone to another tab.')
    setPopupType('timeup')
    submitQuiz()
    openPopupModal()
  }

  function quizSubmitButtonHandler () {
    const unAnsweredQuestion = quizInReduxStore.questions.length - Object.keys(response).length
    const popuptext = unAnsweredQuestion > 0
      ? (markedForReview.length > 0
          ? `You have ${unAnsweredQuestion} unanswered questions and ${markedForReview.length} questions marked for review. Do you want to submit this quiz?`
          : `You have ${unAnsweredQuestion} questions unanswered. Do you want to submit this quiz?`)

      : (markedForReview.length > 0
          ? `You have ${markedForReview.length} questions marked for review. Do you want to submit this test?`
          : 'You have answered all questions. Do you want to submit this quiz now?'
        )
    setSubmitPopupText(popuptext)
    setPopupType('submit')
    openPopupModal()
  }

  async function submitQuiz () {
    setIsWaiting(true)
    const timeTakenByUser = calculateTimeTaken(initialTimeState, time)
    const quizRef = quizInReduxStore
    const submitStatus = await SubmitQuiz(response, studentAnswer, timeTakenByUser, quizRef)
    if (submitStatus) {
      dispatch(quizStudentActions.saveQuizResponse(response))
      dispatch(quizStudentActions.quizSubmitted())
      alert('quiz submitted')
      navigate('/studentDashboard', { replace: true })
    } else {
      alert('submit failed, try again')
    }
    setIsWaiting(false)
  }

  function openPopupModal () {
    setIsPopupModalOpen(true)
  }

  function closePopupModal () {
    setIsPopupModalOpen(false)
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        setIstabswitched(true)
      } else {
        console.log('outside the page')
      }
    })
  }, [])

  useEffect(() => {
    const myInterval = setInterval(async () => {
      const diff = await syncTimeWithServer(endDate)
      if (diff) setTime(diff)
    }, 30 * 1000)
    return () => clearInterval(myInterval)
  }, [])

  return (
    <div id="quiz" className="flex flex-col sm:flex-row h-screen sm:space-x-4 sm:w-screen px-2 sm:px-10 pt-5 text-xs sm:text-sm md:text-base ">
      <EnforceFullScreen quizInProgress={true} onTimeoutExpiration={ () => fullScreenTimeoutHandler()} />
      {isOnline
        ? <p className='fixed top-0 left-0 bg-white p-1 rounded-md font-semibold text-app-green '>Online</p>
        : <p className='fixed top-0 left-0 bg-white p-1 rounded-md font-semibold text-app-red '>Offline</p>}
      {/* <QuizTimer onTimeUp={timeUpHandler} time={time} setTime={setTime} /> */}
      <QuizTimerImproved onTimeUp={timeUpHandler} time={time} setTime={setTime} />
      <div className="flex mx-auto h-max w-max sm:hidden">
        <QuizTimerUI time={time} />
      </div>
      <div className="flex flex-col w-full bottom-8 p-0 sm:p-8 mt-5 rounded-xl sm:rounded-t-xl shadow-4sides border overflow-scroll mb-24">
        <div className="w-auto h-fit flex flex-col ">
          {(currentQuestion.question.question_type === 'S')
            ? (<QuestionContainerSub
            currQuestion={currentQuestion}
            response={response}
            inputChangeHandler={inputChangeHandler}
            className=" w-full p-4"
          />)
            : (<QuestionContainerMCQ
            currQuestion={currentQuestion}
            response={response}
            inputChangeHandler={inputChangeHandler}
            className=" w-full p-4"
          />)}
          <div className=" p-4 pt-2 sm:pt-8 flex flex-row justify-between space-x-4 w-full sm:w-max">
            <GeneralButton
              content={'Clear Response'}
              onClick={clearSelectionButtonHandler}
              className={' w-max rounded-lg h-8 sm:h-12 px-6 my-auto font-semibold  text-white bg-app-red disabled:bg-app-grey disabled:cursor-not-allowed  shadow-lg'}
              disabled={!Object.prototype.hasOwnProperty.call(studentAnswer, currentQuestion.question._id)}
              />
          </div>
        </div>

        <div className={'mt-auto w-full fixed bottom-0 left-0 z-20'}>
          <hr />
          <BottomPanel
            toggleMarkForReview={toggleMarkForReview}
            isMarkedForReview={markedForReview.includes(currentQuestion.index)}
            onNext={nextButtonHandler}
            onPrevious={previousButtonHandler}
            showSubmitTestButton={currentQuestion.index === totalQuestions - 1}
            quizSubmitButtonHandler={quizSubmitButtonHandler}
          />
        </div>
      </div>
      <div className="w-max">
      {isSidePanelActive
        ? (
        <QuestionPalleteVisible
          quizSubmitButtonHandler={quizSubmitButtonHandler}
          response={response}
          currentQuestion={currentQuestion}
          markedForReview={markedForReview}
          hideQuestionPallete={hideQuestionPallete}
          questionNumberClickHandler={questionNumberClickHandler}
          onTimeUp={timeUpHandler}
          time={time}
          setTime={setTime}
          isSidePanelActive={isSidePanelActive}
        />
          )
        : (
        <QuestionPalleteHidden showQuestionPallete={showQuestionPallete} />
          )}
          </div>
      {isPopupModalOpen && <div className=" fixed top-0 left-0 w-screen h-screen flex items-center justify-center  bg-blue-100 bg-opacity-50 backdrop-blur-sm z-50">
        {popupType === 'submit'
          ? <PopUpEndQuiz imgSrc={popUp} textMain={submitPopupText} onProceed={submitQuiz} onCancel={closePopupModal} isLoading={isWaiting}/>
          : <PopUpEndQuiz imgSrc={timeup} textMain={submitPopupText} onProceed={submitQuiz} onCancel={() => {}} isLoading={isWaiting}/>
        }
      </div>}
      </div>
  )
}
