import React from 'react'
// import ImageTag from '../../_atomic-design/atoms/ImageTag'
// import sherytestimonial from '../../assets/images/sherytestimonial.svg'
// import pawantestimonial from '../../assets/images/pawantestimonial.svg'
// import ImageTag from '../../_atomic-design/atoms/ImageTag'
import TestimonialCard from '../../_atomic-design/molecules/AboutUs/TestimonialCard'
import ButtonIconOnly from '../../_atomic-design/atoms/Button/ButtonIconOnly'
import arrowLeft from '../../assets/images/Icons/ArrowWhiteLeft.svg'
import arrowRight from '../../assets/images/Icons/ArrowWhiteRight.svg'
import { testimonials } from '../../assets/data/aboutUsData'

// const testimonials = [
//   {
//     image: sherytestimonial,
//     paraText: 'Writing case studies was a daunting task for us. We didn’t know where to begin or what questions to ask, and clients never seemed to follow through when we asked.',
//     location: 'Sherrey Chng, Co-Founder of d.Kraft',
//     name: 'Sherrey Chng',
//     bgcolor: '#111111',
//     id: 1
//   },
//   {
//     image: pawantestimonial,
//     paraText: 'Their work on our website and Internet marketing has made a significant different to our business. We’ve seen a 425% increase in quote requests from the website which has been pretty remarkable – but I’d always like to see more!',
//     location: 'Pawan Bahuguna, Entreprenuer',
//     name: 'Pawan Bahuguna',
//     bgcolor: '#E8F2FF',
//     id: 2
//   },
//   {
//     image: pawantestimonial,
//     paraText: 'Their work on our website and Internet marketing has made a significant differen425% increase in quote requests from the website which has been pretty remarkable – but I’d always like to see more!',
//     location: 'John Smith, Entreprenuer',
//     name: 'John Smith',
//     bgcolor: '#E8F2FF',
//     id: 3
//   }]
export default function TestimonialCarouselDouble () {
  // We will start by storing the index of the current image in the state.
  const [currentImage, setCurrentImage] = React.useState(0)

  // We are using react ref to 'tag' each of the images. Below will create an array of
  // objects with numbered keys. We will use those numbers (i) later to access a ref of a
  // specific image in this array.
  const refs = testimonials.reduce((acc, val, i) => {
    acc[i] = React.createRef()
    return acc
  }, {})

  const scrollToImage = i => {
    // First let's set the index of the image we want to see next
    setCurrentImage(i)
    // Now, this is where the magic happens. We 'tagged' each one of the images with a ref,
    // we can then use built-in scrollIntoView API to do eaxactly what it says on the box - scroll it into
    // your current view! To do so we pass an index of the image, which is then use to identify our current
    // image's ref in 'refs' array above.
    refs[i].current.scrollIntoView({
      //     Defines the transition animation.
      behavior: 'smooth',
      //      Defines vertical alignment.
      block: 'nearest',
      //      Defines horizontal alignment.
      inline: 'start'
    })
  }

  // Some validation for checking the array length could be added if needed
  const totalImages = testimonials.length

  // Below functions will assure that after last image we'll scroll back to the start,
  // or another way round - first to last in previousImage method.
  const nextImage = () => {
    if (currentImage >= totalImages - 1) {
      scrollToImage(0)
    } else {
      scrollToImage(currentImage + 1)
    }
  }

  const previousImage = () => {
    if (currentImage === 0) {
      scrollToImage(totalImages - 1)
    } else {
      scrollToImage(currentImage - 1)
    }
  }

  const testimonialElements = []
  for (let i = 0; i < testimonials.length; i++) {
    const testimonial1 = testimonials[i]
    const testimonial2 = testimonials[i + 1 === testimonials.length ? 0 : i + 1]
    testimonialElements.push(
    <div className={'w-full flex-shrink-0 flex flex-row rounded-[50px] justify-between '} key={testimonial1.img} ref={refs[i]}>
      <TestimonialCard paraText={testimonial1.paraText} name={testimonial1.name} location={testimonial1.location} image={testimonial1.image} />
      <TestimonialCard paraText={testimonial2.paraText} name={testimonial2.name} location={testimonial2.location} image={testimonial2.image} className={'hidden sm:flex'}/>
    </div>
    )
  }

  return (
  // Images are placed using inline flex. We then wrap an image in a div
  // with flex-shrink-0 to stop it from 'shrinking' to fit the outer div.
  // Finally the image itself will be 100% of a parent div. Outer div is
  // set with position relative, so we can place our cotrol buttons using
  // absolute positioning on each side of the image.
    <div className="py-8 flex flex-col justify-center w-4/5 mx-auto items-center">
    <div className='flex mx-auto my-4 w-full'>
        <div className='text-center sm:text-start'>
            <p className='text-lg font-semibold'>Testimonials</p>
            <p className='text-3xl font-bold'>What Our Users Have To Say?</p>
        </div>
        <div className=' gap-2 my-auto ml-auto hidden sm:flex'>
            <ButtonIconOnly tooltipText={'Previous'} onClick={previousImage} image={arrowLeft} className={'bg-app-primary w-10 h-10 p-2 rounded-full'}/>
            <ButtonIconOnly tooltipText={'Next'} onClick={nextImage} image={arrowRight} className={'bg-app-primary w-10 h-10 p-2  rounded-full'}/>
        </div>
    </div>
      <div className="relative w-full">
        <div className="carouseltestimonial w-full">
          {testimonialElements}
        </div>
        <div className='flex gap-2 my-4 mx-auto w-max sm:hidden'>
            <ButtonIconOnly tooltipText={'Previous'} onClick={previousImage} image={arrowLeft} className={'bg-app-primary w-10 h-10 p-2 rounded-full'}/>
            <ButtonIconOnly tooltipText={'Next'} onClick={nextImage} image={arrowRight} className={'bg-app-primary w-10 h-10 p-2  rounded-full'}/>
        </div>
      </div>
    </div>
  )
}
