import React from 'react'
import { HashLink } from 'react-router-hash-link'
import GeneralButton from '../../atoms/Button/GeneralButton'
import RightPurpleArrow from '../../atoms/Icon/RightPurpleArrow'

export default function StartLearningButton ({ className }) {
  return (
    <HashLink to='/#features' smooth>
      <GeneralButton className={`${className} flex justify-center items-center gap-3 mx-auto md:ml-0 border border-app-primary rounded-full h-7 md:h-11 w-44 md:w-[292px] px-2 md:px-5 text-xs md:text-xl text-app-primary font-medium hover:bg-white/40  transition-all duration-300`} content={
          <>
          <span>Explore the benefits</span>
          <RightPurpleArrow className='w-3 md:w-5' />
          </>
      } />
    </HashLink>
  )
}
