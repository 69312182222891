import React from 'react'
import crossSrc from '../../assets/images/Icons/CrossWhite.svg'
// import PaymentSuccessfull from '../../assets/images/PaymentSuccessfull.svg'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import { useNavigate } from 'react-router-dom'
// import ButtonSecondary from '../../_atomic-design/atoms/ButtonMock/ButtonSecondary'
// import ButtonPrimary from '../../_atomic-design/atoms/ButtonMock/ButtonPrimary'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'

export default function QuizLimitationModal ({ className, active, setActive }) {
  // const message = 'Register and subscribe to learnkraft.ai to unlock the power of unlimited questions!'
  const message = 'Register to unlock the power of unlimited questions!'
  const navigate = useNavigate()
  const goLogin = () => {
    navigate('/Auth')
  }
  if (!active) return null
  return (
    <div className='fixed left-0 top-0 w-screen h-screen rounded-[20px] bg-white/20 backdrop-blur-lg z-50 flex justify-center items-center' >
        <div className="relative w-fit md:h-max mx-4 bg-white rounded-[20px] p-8 max-h-screen max-w-xl flex flex-col justify-center gap-4 items-center " style={{ boxShadow: '0 0 27px rgb(60, 56, 205, 0.15)' }}>
        {
          active && <ImageTag src={crossSrc} onClick={setActive} className='absolute top-4 right-4 h-8 mb-6 ml-auto cursor-pointer rounded-full bg-gradient-to-b from-[#3C38CD] to-[#CF8DDE]  p-2' />
        }
        <p className="text-center text-base pb-2 mt-8 font-bold">{message}</p>
        {/* <ImageTag src={PaymentSuccessfull} className='w-full max-w-xs h-auto' /> */}
        <div className='flex flex-col sm:flex-row gap-6'>
        {/* <ButtonPrimary text="Sign up" onClick={goLogin} className="bg-gradient-to-r from-[#3C38CD] to-[#CF8DDE] rounded-full" />
        <ButtonSecondary text="Continue limited access" onClick={setActive} className="" /> */}
        <GeneralButton onClick={goLogin} className="bg-gradient-to-r from-[#3C38CD] to-[#CF8DDE] hover:opacity-70 transition-opacity duration-300 rounded-full text-white p-2 px-6" content={'Sign Up / Log In'}/>
        <GeneralButton onClick={setActive} className="border border-app-primary hover:bg-blue-100 transition-colors duration-300 rounded-full p-2 px-6 text-app-primary" content={'Continue limited access'} />

        </div>
        </div>
    </div>
  )
}
