import React from 'react'
import ImageTag from '../_atomic-design/atoms/ImageTag'
import GeneralHeading from '../_atomic-design/atoms/Text/GeneralHeading'
import GeneralText from '../_atomic-design/atoms/Text/GeneralText'

export default function FeatureCardCompact ({ imgSrc, textHeading, textBody, bgColor, className }) {
  return <div className={`flex flex-row rounded-xl overflow-hidden shadow-4-sides-secondary w-full max-w-[700px] min-h-[100px] h-max sm:h-[200px] mx-auto text-white ${bgColor} ${className} `}>
    <div className='flex rounded-r-full w-1/5 bg-white '>
        <ImageTag src={imgSrc} className='w-1/3 h-auto mx-auto my-auto'/>
    </div>
    <div className='h-max my-auto w-4/5 p-2'>
        <GeneralHeading text={textHeading} className={'font-semibold text-base sm:text-2xl text-gray-700'}/>
        <GeneralText text={textBody} className={'text-xs sm:text-sm md:text-base text-gray-600'}/>
    </div>
  </div>
}
