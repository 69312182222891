import React from 'react'

export default function TooltipRight ({ content, message, className }) {
  return (
          <div className={`relative group cursor-pointer ${className}`}>
              {content}
              <div
                  className="absolute p-4 top-0 -left-32 translate-x-full -translate-y-1/2 w-max shadow-lg cursor-pointer scale-0 group-hover:scale-100 opacity-0 transition-opacity duration-500 group-hover:opacity-100 bg-white border rounded-md border-gray-200 z-50"
              >
                  <p className="text-xs text-gray-800">{message}</p>
              </div>
          </div>
  )
}
