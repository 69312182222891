import React, { useState, useEffect } from 'react'
import PublishedQuizNavbar from '../../_atomic-design/molecules/Navbars/PublishedQuizNavbar'
import IndividualsTab from '../../containers/CreatedQuizes/IndividualsTab'
import OverallTab from '../../containers/CreatedQuizes/OverallTab'
import SettingsTab from '../../containers/CreatedQuizes/SettingsTab'
import { makeAuthenticatedServerGETRequest } from '../../utils/helpers'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import TrialRibbon from '../../_atomic-design/molecules/Others/TrialRibbon'

export default function PublishedQuizOverview () {
  // eslint-disable-next-line no-unused-vars
  const [quizName, setQuizName] = useState('Elementary Quiz')
  const Tabs = ['Individuals']
  const [currentTab, setCurrentTab] = useState('Individuals')
  const [quiz, setQuiz] = useState(null)
  const [searchParams] = useSearchParams()
  const authState = useSelector((state) => state.authReducer)
  const [quizId, setQuizId] = useState(null)
  const [studentQuiz, setStudentQuiz] = useState([])

  useEffect(() => {
    const getQuizId = searchParams.get('quizId')
    if (searchParams.get('quizId')) {
      setQuizId(getQuizId)
    } else {
      setQuizId(null)
    }
  })

  // const [quizIdParam, setQuizIdParam] = useState(getQuizId)

  useEffect(async () => {
    if (quizId) {
      if (authState.isLoggedIn) {
        const quizdata = await makeAuthenticatedServerGETRequest('/quiz/getQuizIndividual/', {
          quizId: quizId
        })
        // if (quizdata.status !== 200) {
        //   toast('Quiz not found')
        // } else {
        setQuiz(quizdata.data.quiz)
        setStudentQuiz(quizdata.data.attempteds)
        setQuizName(quizdata.data.quiz.name)
      } else {
        toast('login first')
      }
    }
  }, [quizId])

  // useEffect(() => {
  //   const quizId = '63086caeba025a96ecf85fb6'
  //   const url = '/quiz?quizId=' + quizId
  //   makeServerGETRequest(url).then((quizdata) => {
  //     setQuiz(quizdata.data.quiz)
  //   }).catch(err => { console.log('error', err) })
  // }, [])

  return (<>
  <TrialRibbon/>
        <div className="h-screen bg-[#EFEFEF] text-xs sm:text-sm md:text-base">
            <PublishedQuizNavbar
                quizName={quizName}
                Tabs={Tabs}
                currentTab={currentTab}
                quizId = {quizId}
                setCurrentTab={setCurrentTab}
                isQuizpublished={quiz && quiz.published}
                pageName='PublishedQuizOverview'
            />
            {quiz && currentTab === 'Overview'
              ? (
            <OverallTab quiz={quiz} studentQuiz={studentQuiz} />
                )
              : currentTab === 'Individuals'
                ? (quiz &&
                <IndividualsTab quiz={quiz} studentQuiz={studentQuiz} />
                  )
                : currentTab === 'Settings'
                  ? (
              <SettingsTab quiz={quiz} />
                    )
                  : null}
        </div>
        </>
  )
}
