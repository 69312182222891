import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { removeCookies } from '../utils/cookieStorage'
import { signoutSuccess } from '../store/actions/authActions/authActions'
import { toast } from 'react-toastify'

export default function RequireAuthStudent ({ children }) {
  const authState = useSelector((state) => state.authReducer)
  const location = useLocation()
  const dispatch = useDispatch()

  function logoutHandler () {
    removeCookies(['accessToken', 'refreshToken', 'userDetails'])
    dispatch(signoutSuccess())
  }

  if (!authState.isLoggedIn) {
    toast('Session expired. Please login again! error code 45672')
    return <Navigate to='/Auth?mode=login' state={{ redirectBack: location }} replace />
  } else if ((authState.userRoleType !== '1')) {
    logoutHandler()
    toast('Please login in the student portal!')
    return <Navigate to='/Auth?mode=login' state={{ redirectBack: location }} replace />
  } else {
    return (
      <>
          {children}
      </>
    )
  }
}
