import React from 'react'
import LogoIcon from '../../../assets/images/Icons/favicon.ico'

export default function TipsLoader ({ text, time }) {
  return (
        <div className="h-full w-full flex justify-center items-center">
                <div className="flex flex-col gap-2 justify-center items-center">
                <img className="w-16 animate-spin origin-[50%_44%]" src={LogoIcon} alt="LOGO" />

                    <div className="w-11/12 h-2 bg-gray-200 rounded-full px-2">
                      <div className='h-full bg-app-blue rounded-full bg-gradient-to-r from-[#3C38CD] to-[#CE8CDD]' style={{ width: time + '%' }}></div>
                    </div>

                      <p className="text-app-primary text-2xl font-semibold justify-center items-center">
                      {text}
                      </p>
                    <br></br>
                    <p className="text-app-primary text-left text-base md:text-base font-semibold p-8">
                    <b> Hints for editing the text</b><br></br>
1. To manually add a new question, click the plus symbol.<br></br>
2. Select an appropriate response to an objective question.<br></br>
3. Always respond to the introspective query.<br></br>
4. To go to the save settings page, click the next button.<br></br>
5. Consistently include the exams start and end dates.<br></br>
                    </p>
                </div>
            </div>
  )
}
