import React, { useEffect } from 'react'
import About from '../../containers/AboutUs/About'
// import Goals from '../../containers/AboutUs/Goals'
import Registration from '../../containers/AboutUs/Registration'
// import Bottom from '../containers/AboutUs/Bottom'
import HeadingLg from '../../_atomic-design/atoms/Text/HeadingLg'
// import image4 from '../../assets/images/MainBackgroundNew.png'
import EndHomePage from '../../components/EndHomePage'
import TestimonialCarouselDouble from '../../containers/Home/TestimonialCarouselDouble'
import CreateQuizButton from '../../_atomic-design/molecules/Buttons/CreateQuizButton'
// import HomeNavbar from '../../_atomic-design/molecules/Navbars/HomeNavbar'
import AboutUsNavbar from '../../_atomic-design/molecules/Navbars/AboutUsNavbar'
import { useSelector } from 'react-redux'

export default function AboutUs2 () {
  // const bgStyle = {
  //   background: `url(${image4})`,
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'bottom',
  //   backgroundRepeat: 'no-repeat'
  // }

  const authState = useSelector((state) => state.authReducer)

  useEffect(() => {
    document.title = 'About Us'
  }, [])

  return <div>
    <AboutUsNavbar selected={'/about-us'} />
    <div className='w-full h-[80px] sm:h-[100px] flex' >
        <HeadingLg text={''} className={'h-max my-auto mx-auto'}/>
    </div>
    <About />
    {/* <Goals /> */}
    { authState && authState.isLoggedIn ? null : <Registration />}
    <TestimonialCarouselDouble />
    <EndHomePage/>
    <CreateQuizButton />
  </div>
}
