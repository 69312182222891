import React from 'react'

export default function ButtonPrimaryLg ({ text, onClick, className, disabled }) {
  return (
        <button
            onClick={onClick}
            className={` ${className} ${disabled ? 'bg-app-button-primary-disabled' : 'bg-app-primary'} w-[220px] sm:w-[392px] h-7 sm:h-11 text-xs sm:text-xl block rounded-md text-white`}
            disabled={disabled}
        >
            {text}
        </button>
  )
}
