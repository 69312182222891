import React from 'react'
// import GeneralText from '../../atoms/Text/GeneralText'
// import ImageTag from '../../atoms/ImageTag'
import GeneralHeading from '../../atoms/Text/GeneralHeading'
import { useNavigate } from 'react-router-dom'
import GeneralButton from '../../atoms/Button/GeneralButton'
import { useSelector } from 'react-redux'
import TextBodyLg from '../../atoms/Text/TextBodyLg'
import TickGreenCircular from '../../../assets/images/Icons/TickGreenCircular.svg'
import CrossGreenCircular from '../../../assets/images/Icons/CrossGreenCircular.svg'
import ImageTag from '../../atoms/ImageTag'

function Card ({ keydata, card, className, onClick, currentId, setCurrentId, currentPlan }) {
  const navigate = useNavigate()
  const authState = useSelector((state) => state.authReducer)

  return (
    <div key={keydata} className={`${className} flex flex-col min-w-full sm:min-w-0 flex-1 max-w-[350px] rounded-xl border-x-4 border-b-4 ${card.hText === 'Personal' ? 'scale-100' : 'scale-90'}`}>

      <div className={`pb-4 rounded-xl  h-full ${currentId === card._id ? 'bg-[#F8F7FE] border border-app-primary ' : 'bg-white'}`}>

        {card.hText === 'Free'
          ? (<div
            className='text-center bg-gray-900 text-white py-2 font-semibold rounded-t-xl'>
          </div>)
          : (null)
        }

        {card.hText === 'Personal'
          ? (<div
            className=' relative text-center bg-green-500 text-white py-2 font-semibold rounded-t-xl'>
              {/* {(card.hText === 'Personal') && (card.type === 'Monthly')
                ? (<GeneralHeading
                  text="Trial"
                  className="hidden absolute text-white font-bold text-lg -rotate-45 bg-green-500 rounded-lg px-6 top-6 -right-2"
              />)
                : (null)} */}
          </div>)
          : (null)
        }
        {card.hText === 'Enterprise'
          ? (<div
            className='text-center bg-purple-600 text-white py-2 font-semibold rounded-t-xl'>
          </div>)
          : (null)
        }

        <div
          className={'h-full px-1 overflow-hidden flex flex-col items-center gap-8'}
          onClick={onClick}
        >
          <div className='flex flex-col justify-center items-center gap-2 h-1/5'>
          <TextBodyLg
            text={card.displayName }
            className="text-gray-500 font-bold mb-1"
          />
          <TextBodyLg
            text={card.priceText}
            className="text-app-primary font-bold mb-1"
          />
          <GeneralHeading text={card.priceMethod} className="flex mb-1 text-center text-gray-600 " />

          </div>
          {/* <ImageTag src={card.imgSrc} className="w-full mx-auto my-2" /> */}
          <div className=" flex  h-4/5 ">
            <ul className="">
              {card.features.map((feature, key) => {
                return <li key={key + keydata} className='flex flex-row justify-between items-center gap-8 '>
                      <span className='text-sm font-medium p-1 text-gray-700'>{feature}</span>
                      <div className='p-1 pl-6'>
                      <FeatureValue card = {card} indexValue={key} />
                      </div>
                </li>
              })}
            </ul>
          </div>
          {card.hText === 'Free'
            ? (<GeneralButton
            onClick={() => {
              // setCurrentId(card._id)
              // if (currentPlan === 'Let\'s Connect') {
              //   navigate('/contactus')
              // } else if (currentPlan === 'Upgrade' || currentPlan === 'Subscribe') {
              //   if (authState && authState.isLoggedIn) {
              //     navigate('/contactus')
              //   } else {
              //     navigate('/auth?mode=login')
              //   }
              // }
            }}
            content={currentPlan}
            className='cursor-text h-11 w-4/5 m-auto mb-2 flex items-center text-white justify-center rounded-lg bg-gray-300 font-bold text-xl'
          />)
            : (null)
        }

        {card.hText === 'Personal'
          ? (<GeneralButton
            onClick={() => {
              setCurrentId(card._id)
              if (currentPlan === 'Let\'s Connect') {
                navigate('/contactus')
              } else if (currentPlan === 'Upgrade' || currentPlan === 'Subscribe') {
                if (authState && authState.isLoggedIn) {
                  navigate(`/Plans?planId=${card.togelPlan}`)
                } else {
                  const loginConfirmation = 'You are not logged in; please login to upgrade or subscribe to the plan.'
                  if (window.confirm(loginConfirmation) === true) {
                    navigate('/auth?mode=login')
                  }
                }
              }
            }}
            content={currentPlan}
            className={`${(currentPlan === 'Current Plan') ? 'cursor-text' : ''} h-11 w-4/5 m-auto mb-2 flex items-center justify-center rounded-md bg-green-500 text-white font-bold text-xl`}
          />)
          : (null)
        }
        {card.hText === 'Enterprise'
          ? (<GeneralButton
            onClick={() => {
              setCurrentId(card._id)
              if (currentPlan === 'Let\'s Connect') {
                navigate('/contactus')
              } else if (currentPlan === 'Upgrade' || currentPlan === 'Subscribe') {
                if (authState && authState.isLoggedIn) {
                  navigate(`/Plans?planId=${card._id}`)
                } else {
                  const loginConfirmation = 'you are not login please login to upgrade/subscribe the plan.'
                  if (window.confirm(loginConfirmation) === true) {
                    navigate('/auth?mode=login')
                  }
                }
              }
            }}
            content={currentPlan}
            className='h-11 w-4/5 m-auto  mb-2 flex items-center justify-center rounded-md bg-purple-600 text-white font-bold text-xl'
          />)
          : (null)
        }
        </div>

      </div>

    </div>
  )
}
export default Card

function FeatureValue ({ card, indexValue }) {
  if (card.featuresValue === undefined) {
    return null
  }
  if (card.featuresValue[indexValue] === true) {
    return (<ImageTag
      src={TickGreenCircular}
      className="w-5 sm:w-6"
  />)
  } else if (card.featuresValue[indexValue] === false) {
    return (<ImageTag
      src={CrossGreenCircular}
      className="w-5 sm:w-6"
  />)
  } else if (typeof (card.featuresValue[indexValue]) === 'string') {
    return (<span className='font-medium text-sm text-gray-700'>{card.featuresValue[indexValue]}</span>)
  }
}
