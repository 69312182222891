import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import {
  makeAuthenticatedServerRequest,
  makeServerRequest
} from '../../utils/helpers'
import {
  removeQuiz,
  storeQuiz
} from '../../store/actions/quizActions/quizActions'
import { useNavigate } from 'react-router-dom'
// import { useNavigate, Link } from 'react-router-dom'
import HomeNavbar from '../../_atomic-design/molecules/Navbars/HomeNavbar'
// import TextXs from '../../_atomic-design/atoms/Text/TextXs'
import SignupQuizGeneration from '../../containers/Modals/SignupQuizGeneration'
import ShowDashboardQuiz from '../../containers/Modals/ShowDashboardQuiz'
import ZeroQuizModal from '../../containers/Modals/ZeroQuizModal'
import ErrorQuizGeneration from '../../containers/Modals/ErrorQuizGeneration'
import WaitingModal from '../../containers/Modals/WaitingModal'
import TipsLoader from '../../_atomic-design/molecules/Others/TipsLoader'

export default function GeneratingQuiz () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const quizObj = useSelector((state) => state.quizReducer)
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn)
  const [showSignupQuiz, setShowSignupQuiz] = useState(false)
  const [showDashBoardQuiz, setShowDashBoardQuiz] = useState(false)
  const [showZeroQuizModal, setShowZeroQuizModal] = useState(false)
  const [showErrorQuizGeneration, setShowErrorQuizGeneration] = useState(false)
  const [showWaitingModel, setShowWaitingModel] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [waitingTime, setWaitingTime] = useState(0)
  const [queueNo, setQueueNo] = useState(0)
  const [time, setTime] = useState(0)
  const [fileErrorData, setFileErrorData] = useState({})

  useEffect(() => {
    startTimer()
    fetchQuizTesting()
    document.title = 'GeneratingQuiz'

    return () => {}
  }, [])

  //  Making request to create a new quiz by sending the files recieved from the user
  const fetchQuizTesting = async () => {
    if (quizObj.urls.length === 0) {
      toast('Please upload atleast one file')
      navigate('/', { replace: true })
      return
    }
    // const res = await makeServerGETRequest('/quiz/', { quizId: '627822bc9ff08dcc0e0a2b3e' })
    let res
    if (isLoggedIn) {
      res = await makeAuthenticatedServerRequest('/quiz/create', 'POST', {
        urls: quizObj.urls,
        subQues: quizObj.subQues,
        objQues: quizObj.objQues
      })
    } else {
      res = await makeServerRequest('/quiz/create', 'POST', {
        urls: quizObj.urls,
        subQues: quizObj.subQues,
        objQues: quizObj.objQues
      })
    }
    console.log('response_ from_ml', res)
    if (res === undefined) {
      if (isLoggedIn) {
        setShowDashBoardQuiz(true)
      } else {
        setShowSignupQuiz(true)
      }
      return 0
    }

    if (res.status === 200) {
      // storing the quiz in redux store
      dispatch(
        storeQuiz({
          urls: [],
          quizId: res.data.quiz._id,
          quizName: res.data.quiz.name,
          questions: res.data.quiz.questions,
          settings: res.data.quiz.settings,
          quizTopics: res.data.quiz.tags
        })
      )

      // navigating to the quiz page
      if (res.data.quiz === null) {
        navigate('/')
        return 0
      } else {
        navigate(`/EditQuiz?quizId=${res.data.quiz._id}`, { replace: true })
        return 0
      }
    } else if (res.status === 410) {
      toast(
        'Sorry you have reached the maximum number of quizzes. Please upgrade your account to create more quizzes.'
      )
      dispatch(removeQuiz({}))
      navigate('/')
    } else if (res.status === 500) {
      setShowErrorQuizGeneration(true)
    } else if (res.status === 415) {
      setFileErrorData(res.data.message ? res.data.message : {})
      setShowErrorQuizGeneration(true)
    } else if (res.status === 409) {
      setWaitingTime(res.data.timeStr)
      setQueueNo(res.data.queueNo)
      setShowWaitingModel(true)
    } else if (res.status === 408 || res.status === 204) {
      setShowZeroQuizModal(true)
    } else {
      if (isLoggedIn) {
        setShowDashBoardQuiz(true)
      } else {
        setShowSignupQuiz(true)
      }
    }
  }
  // Starting the timer
  const startTimer = () => {
    const timer = setInterval(() => {
      setTime((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1))
    }, 2900)
    return () => {
      clearInterval(timer)
    }
  }

  return (
        <div className="h-screen bg-[#F3F0FA]">
          < SignupQuizGeneration
                active={showSignupQuiz}
                setActive={() =>
                  setShowSignupQuiz(!showSignupQuiz)
                }
            />
            < ShowDashboardQuiz
                active={showDashBoardQuiz}
                setActive={() =>
                  setShowDashBoardQuiz(!showDashBoardQuiz)
                }
            />
            < ZeroQuizModal
                active={showZeroQuizModal}
                setActive={() =>
                  setShowZeroQuizModal(!showZeroQuizModal)
                }
            />
            < ErrorQuizGeneration
                active={showErrorQuizGeneration}
                fileErrorData= {fileErrorData}
                setActive={() =>
                  setShowErrorQuizGeneration(!showErrorQuizGeneration)
                }
            />
            < WaitingModal
                active={showWaitingModel}
                setActive={() =>
                  setShowWaitingModel(!showWaitingModel)
                }
                waitingTime = {waitingTime}
                queueNo = {queueNo}
                isLogedIn = {isLoggedIn}
            />
            <HomeNavbar white />
            <TipsLoader time={time} text='Generating...'/>
            {/* <UpgradeLimit className='absolute bottom-5 left-2/4 -translate-x-1/2' /> */}
        </div>
  )
}

// function UpgradeLimit ({ className }) {
//   return (
//     <div className={`flex justify-center items-center h-10 w-1/4 sm:w-2/4 mx-auto rounded-full bg-white ${className}`}>
//       <TextXs text={'You can generate '} />
//       <TextXs className={'mx-2'} text={<b> 5 free quizzes.</b>} />
//       <Link to='/' className='text-app-primary text-xs underline font-semibold'>Upgrade Limit</Link>
//     </div>
//   )
// }
