/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'
import GeneralTextInput from '../../_atomic-design/molecules/Input/GeneralTextInput'
import TextSm from '../../_atomic-design/atoms/Text/TextSm'
import TooltipLeft from '../../_atomic-design/molecules/Others/TooltipLeft'
import DateTimePicker from 'react-datetime-picker'
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'

export default function ScoreAndTimeSettingsTimeZone ({ scoreAndTime, setScoreAndTime, timeZone, startDateError, setStartDateError, durationError, setDurationError }) {
  const TimeArr = [{ text: '10 minutes', value: 10 }, { text: '15 minutes', value: 15 }, { text: '30 minutes', value: 30 }, { text: '1 hr', value: 60 }]
  const [duration, setDuration] = useState(scoreAndTime.duration || 10)
  const [endDate, setEndDate] = useState(scoreAndTime.endDate)
  const [startDate, setStartDate] = useState(scoreAndTime.startDate)
  const [examWindow, setExamWindow] = useState('')
  const [durationExtended, setDurationExtended] = useState({ hh: 0, mm: 0, ss: 0 })

  const handleDuration = () => {
    const distance = (new Date(endDate).getTime()) - (new Date(startDate).getTime())
    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    setExamWindow(`${days} days ${hours} hours ${minutes} minutes`)
  }

  const startDateHandler = (e) => {
    if (e < new Date()) {
      setStartDateError('Start date cannot be in the past')
    } else {
      setStartDateError('')
    }
    setStartDate(e)
    handleDuration()
  }
  const endDateHandler = (e) => {
    setEndDate(e)
    handleDuration()
  }
  const durationHandler = (e) => {
    // const duration = e.target.value
    const value = e.target.value
    const parsedValue = parseInt(value, 10)
    if (!isNaN(parsedValue) && Number.isInteger(parsedValue)) {
      if (parsedValue < 1) {
        setDurationError('Duration cannot be less than 1 minute')
        return
      } else if (parsedValue > 180) {
        setDurationError('Duration cannot be greater than 180 Minutes')
        return
      } else {
        setDurationError('')
      }
    } else {
      setDurationError('Please enter a valid duration')
    }
    setDuration(parsedValue)
  }

  useEffect(() => {
    const tempDuration = duration || 0
    const hh = Math.floor(tempDuration / 60)
    const mm = (tempDuration % 60)
    const ss = 0
    setDurationExtended({ hh, mm, ss })
  }, [duration])

  // useEffect(() => {
  //   setDuration(scoreAndTime.duration)
  //   setEndDate(scoreAndTime.endDate)
  //   setStartDate(scoreAndTime.startDate)
  // }, [scoreAndTime])

  // useEffect(() => {
  //   setScoreAndTime((prev) => {
  //     return { ...prev, duration: duration, endDate: endDate, startDate: startDate }
  //   })
  // }, [duration, endDate, startDate])
  useEffect(() => {
    const sd = new Date(startDate)
    const ed = new Date(startDate)
    ed.setMinutes(sd.getMinutes() + (+duration || 0))
    setEndDate(ed)
  }, [duration, startDate])

  useEffect(() => {
    setScoreAndTime((prev) => {
      return { ...prev, duration: duration, endDate: endDate, startDate: startDate }
    })
  }, [endDate])

  const eDate = new Date(endDate)
  const hh = eDate.getHours() < 10 ? ('0' + eDate.getHours()) : eDate.getHours()
  const mm = eDate.getMinutes() < 10 ? ('0' + eDate.getMinutes()) : eDate.getMinutes()
  return (
    <div className="">
      {/* <div className="w-full bg-white p-8 rounded-[8px] flex justify-between my-2">
        <TextBody text="Scoring Points for every question" className={'font-medium'} />
        <div className="flex ">
          <GeneralTextInput
            className="w-10 mx-2 text-center outline-none border-b-2 border-app-gradient-setting text-sm"
            id="1"
            type='Number'
            value={ScoringPoints}
            onChange={(e) => {
              setScoringPoints(e.target.value)
            }}
          />
          <TextBody text="points" />
        </div>
      </div> */}

      <div className="w-full bg-white rounded-3xl justify-between my-4 shadow-2xl">
        <div className='bg-violet-100 p-4 text-app-primary text-lg rounded-t-3xl font-bold'>Score and time <span className='text-sm text-app-red'>{timeZone}</span></div>
        <div className="w-full bg-white px-4 p-2 flex flex-col md:flex-row justify-between items-center my-2 gap-2 border-b-2">
        <TextBody text="Time to complete Quiz" className={'font-medium'} />
        { /** Hidden HH:MM */ }
        <div className=' flex-row gap-2 rounded-full bg-red-100 px-4 p-1 hidden'>
          <div className='flex flex-row'>
          {durationExtended.hh < 10 ? <p> 0{durationExtended.hh}:</p> : <p>{durationExtended.hh}:</p>}
          {durationExtended.mm < 10 ? <p> 0{durationExtended.mm}</p> : <p>{durationExtended.mm}</p>}
          {/* {durationExtended.ss < 10 ? <p> 0{durationExtended.ss}</p> : <p>{durationExtended.ss}</p>} */}
          </div>
        </div>
        <div className='flex flex-col'>
        {durationError && <p className='text-app-red'>{durationError}</p>}
        <div className='flex flex-row w-max ml-auto'>
        <GeneralTextInput type="number"
            default={duration}
            placeholder={'Duration'}
            value={duration}
            min={1}
            onChange={durationHandler} className=" bg-gray-100 border rounded-lg text-right p-2 w-24  mx-2"
          />
          <p className='m-auto font-'>Minutes</p>
        </div>
        </div>
      </div>
        <div className="flex justify-between w-full my-1 px-6 ">
          <TextBody text="Quiz availability" className={'font-medium'} />
          {startDateError && <p className=' text-app-red '>{startDateError}</p>}
        </div>

        <div className="flex justify-between items-center w-full px-6 font-bold text-gray-500">
          <TooltipLeft
            content={
              <TextSm text="Start Date" />
            }
            message={'The student will be allowed to appear for the exam after this start date and time.'}
          />
          <div className=" p-2">
            <DateTimePicker onChange={startDateHandler} value={startDate} calendarIcon={null} clearIcon={null} className={'text-app-secondary font-bold'} />
          </div>
        </div>

        {/* Remove hidden class & add flex to make end date selector visible */}
        <div className=" justify-between items-center w-full px-6 font-bold text-gray-500 hidden">
          <TooltipLeft
            content={
              <TextSm text="End date" />
            }
            message={'After the end date and time, quizkraft evaluation will start, and no student will be allowed to appear for the exam.'}
          />
          <div className=" p-2">
            <DateTimePicker onChange={endDateHandler} value={endDate} disabled calendarIcon={null} clearIcon={null} className={'text-app-secondary font-bold'} />
          </div>
        </div>
            <hr className='w-[90%] mx-auto'/>
        {/* <div className="flex justify-between w-full my-4">
          <TextSm text="Exam date window" />
          <div className="flex ">
          <TextSm text={examWindow} />

          </div>
        </div> */}
      </div>
    </div>
  )
}
