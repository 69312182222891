import React from 'react'
import ImageTag from '../_atomic-design/atoms/ImageTag'
import bgJourney from '../assets/images/Icons/BgJourney1.png'
import GeneralHeading from '../_atomic-design/atoms/Text/GeneralHeading'
import TickSquareBlue from '../assets/images/Icons/TickSquareBlue.svg'
import GeneralText from '../_atomic-design/atoms/Text/GeneralText'

export default function JourneyCard ({ heading, imgSrc, imgPos, bulletPoints }) {
  const bgStyle = {
    backgroundImage: `url(${bgJourney})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat'

  }

  const bgStyleImage = {
    backgroundImage: `url(${imgSrc})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat'
  }

  return <div className='flex flex-col sm:flex-row mx-auto overflow-hidden shadow-4-sides-secondary bg-white w-full rounded-xl sm:rounded-none'>
     <div className={`w-full min-h-[200px] sm:w-1/2 order-1 ${imgPos === 'left' ? '' : 'sm:order-2'} `} style={bgStyleImage}>
      {/* <ImageTag src={imgSrc} className='w-full h-full' /> */}
    </div>
    <div style={bgStyle} className={`p-8 sm:py-20 my-auto h-full w-full sm:w-1/2 order-2 ${imgPos === 'left' ? '' : 'sm:order-1'} `} >
      <GeneralHeading className={'font-semibold text-xl text-app-primary'} text={heading}/>
      {bulletPoints.map((item, index) => <CustomBulletPoint text={item} key={index}/>)}
    </div>
    {/* { imgPos === 'right' && <div className={' w-full sm:w-1/3 '} >
      <ImageTag src={imgSrc} className='w-full h-full' />
    </div>} */}
  </div>
}

function CustomBulletPoint ({ text }) {
  return <div className='flex flex-row p-2'>
    <ImageTag src={TickSquareBlue} className='mr-2 mb-auto mt-1' />
    <GeneralText text={text}/>
  </div>
}
