import { actionTypes } from '../../actionTypes'

export const storeQuiz = (payload) => {
  return {
    type: actionTypes.STORE_QUIZ,
    payload: payload
  }
}

export const updateQuizName = (payload) => {
  return {
    type: actionTypes.UPDATE_QUIZ_NAME,
    payload: payload
  }
}

export const addQuestion = (payload) => {
  return {
    type: actionTypes.ADD_QUESTION,
    payload: payload
  }
}

export const removeQuestion = (payload) => {
  return {
    type: actionTypes.REMOVE_QUESTION,
    payload: payload
  }
}

export const updateQuestion = (payload) => {
  return {
    type: actionTypes.UPDATE_QUESTION,
    payload: payload
  }
}

export const removeQuiz = (payload) => {
  return {
    type: actionTypes.REMOVE_QUIZ,
    payload: payload
  }
}

export const cleanDeletedQuestions = (payload) => {
  return {
    type: actionTypes.CLEAN_DELETED_QUESTIONS,
    payload: payload
  }
}

export const cleanNewlyCreatedQuestions = (payload) => {
  return {
    type: actionTypes.CLEAN_NEWLY_CREATED_QUESTIONS,
    payload: payload
  }
}

export const saveQuiz = (payload) => {
  return {
    type: actionTypes.SAVE_QUIZ,
    payload: payload
  }
}

export const updateQuestionCount = (payload) => {
  return {
    type: actionTypes.UPDATE_QUESTION_COUNT,
    payload: payload
  }
}

export const addTopicToQuiz = (payload) => {
  return {
    type: actionTypes.ADD_TOPIC_TO_QUIZ,
    payload: payload
  }
}

export const removeTopicFromQuiz = (payload) => {
  return {
    type: actionTypes.REMOVE_TOPIC_FROM_QUIZ,
    payload: payload
  }
}

export const updateTotalScore = (payload) => {
  return {
    type: actionTypes.UPDATE_TOTAL_SCORE,
    payload: payload
  }
}

export const uploadFile = (payload) => {
  return {
    type: actionTypes.UPLOAD_FILE,
    payload: payload
  }
}

export const deleteFile = (payload) => {
  return {
    type: actionTypes.DELETE_FILE,
    payload: payload
  }
}
