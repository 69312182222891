export const actionTypes = {
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  USER_LOGIN_FAIL: 'USER_LOGIN_FAIL',
  USER_SIGNOUT_SUCCESS: 'USER_SIGNOUT_SUCCESS',
  USER_EMAIL_VERIFICATION_SUCCESS: 'USER_EMAIL_VERIFICATION_SUCCESS',
  USER_ROLE_TYPE_UPDATE: 'USER_ROLE_TYPE_UPDATE',
  USER_INFO: 'USER_INFO',

  // Quiz actions
  UPLOAD_FILE: 'UPLOAD_FILE',
  DELETE_FILE: 'DELETE_FILE',
  STORE_QUIZ: 'STORE_QUIZ',
  SAVE_QUIZ: 'SAVE_QUIZ',
  REMOVE_QUIZ: 'REMOVE_QUIZ',
  ADD_QUESTION: 'ADD_QUESTION',
  REMOVE_QUESTION: 'REMOVE_QUESTION',
  UPDATE_QUESTION: 'UPDATE_QUESTION',
  UPDATE_QUIZ_NAME: 'UPDATE_QUIZ_NAME',
  CLEAN_DELETED_QUESTIONS: 'CLEAN_DELETED_QUESTIONS',
  CLEAN_NEWLY_CREATED_QUESTIONS: 'CLEAN_NEWLY_CREATED_QUESTIONS',
  UPDATE_QUESTION_COUNT: 'UPDATE_QUESTION_COUNT',
  STORE_CURRENT_QUIZ: 'STORE_CURRENT_QUIZ',
  REMOVE_CURRENT_QUIZ: 'REMOVE_CURRENT_QUIZ',
  ADD_TOPIC_TO_QUIZ: 'ADD_TOPIC_TO_QUIZ',
  REMOVE_TOPIC_FROM_QUIZ: 'REMOVE_TOPIC_FROM_QUIZ',
  UPDATE_TOTAL_SCORE: 'UPDATE_TOTAL_SCORE'
}
