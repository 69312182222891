import React from 'react'
// import crossSrc from '../../assets/images/Icons/cross.svg'
import LateQuizGeneration from '../../assets/images/lateQuizGeneration.svg'
import ButtonPrimaryLg from '../../_atomic-design/atoms/Button/ButtonPrimaryLg'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
// import HeadingLg from '../../_atomic-design/atoms/Text/HeadingLg'
import { useNavigate } from 'react-router-dom'

export default function WaitingModal ({ className, active, setActive, waitingTime, queueNo, isLogedIn }) {
  const navigate = useNavigate()
  const goLogIn = () => {
    navigate('/auth?mode=login')
  }
  const goToHome = () => {
    navigate('/')
  }
  const goRegister = () => {
    navigate('/Auth?mode=register')
  }
  if (!active) return null
  return (
    <div className='fixed top-10 left-0 w-screen h-screen bg-white/20 backdrop-blur-[20px] z-50 flex justify-center items-center py-[20%]' >
        <div className="w-fit md:w-1/2 mx-4 bg-white rounded-[20px] max-h-screen max-w-xl flex flex-col justify-center items-center  m-8 p-8 pt-20" style={{ boxShadow: '0 0 27px rgb(60, 56, 205, 0.15)' }}>
        {/* {
          active && <ImageTag src={crossSrc} onClick={setActive} className='h-5 mb-4 ml-auto cursor-pointer ' />
        } */}
        <h1 className="font-bold text-[32px] md:text-3xl text-[#6042D1] md:mb-5">Your Quiz generation In a queue now !!</h1>
        <ImageTag src={LateQuizGeneration} className='w-1/3 h-1/3 max-w-xs' />
        <div className='justify-left gap-2'>
        <GeneralText
                      text={'Your Quiz is in queue number- ' + queueNo + '. The approximate waiting time is ' + waitingTime + ' (hh:mm:ss).Kindly bear us till then.'}
                      className=" text-[15px] md:text-base font-medium"
                  />
                  <br/>
        <GeneralText
            text={isLogedIn ? 'We will notify you once your quiz is ready via your registered email' : 'Please Login so that we can notify you.'}
            className="text-[15px] md:text-base font-medium"
        />
        <br/>

        <GeneralText
                    text={ isLogedIn ? '' : ' If you don’t have an account, please register first.' }
                    className=" text-[15px] md:text-base font-medium"
                />
        </div>
        <br/>
        {isLogedIn
          ? (
        <>
        <ButtonPrimaryLg className='' text='Home ' onClick={goToHome}/>
        </>
            )
          : (
        <>
        <ButtonPrimaryLg className='' text='Login ' onClick={goLogIn}/>
        <GeneralButton className='text-blue-600 mt-4' content='Register an account' onClick={goRegister} />
        </>
            )}
        </div>
    </div>
  )
}
