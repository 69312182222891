import React, { useEffect } from 'react'
import HomePageBackground from '../../assets/images/MainBackground.svg'
import HomepageBgShape from '../../assets/images//Homepage_bg_shape.svg'
import PricePlans from '../../containers/PricePlans'
import HomeNavbar from '../../_atomic-design/molecules/Navbars/HomeNavbar'
import TrialRibbon from '../../_atomic-design/molecules/Others/TrialRibbon'

export default function Subscription (route, navigation) {
  const bgImage = {
    backgroundImage: `url(${HomePageBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat'
  }
  const bgShape = {
    position: 'absolute',
    right: '0',
    top: '0'
    // zIndex: '10'
  }

  useEffect(async () => {
    document.title = 'pricing'
  }, [])

  return (
    <>
    <TrialRibbon/>
    <HomeNavbar white />
    <div className=" font-sans font-semibold] min-h-screen h-full relative pt-32 pb-24 overflow-x-hidden bg-gradient-to-b from-[#E096DF] to-[#403ACE]" style={bgImage}>
    <img src={HomepageBgShape} alt="" className='w-3/4 sm:w-1/2' style={bgShape} />
    <div className="relative rounded-2xl item-center opacity-[1] sm:py-10 sm:px-24 w-auto ">
    <PricePlans />
    </div>
      </div>
    </>
  )
}
