import React from 'react'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'

export default function PopUpStartQuiz ({ imgSrc, textMain, onProceed, onCancel }) {
  return <div className="flex flex-col sm:flex-row mx-8 p-6 w-fit h-fit rounded-3xl shadow-2xl shadow-blue-300 bg-white">
        <div className="p-3 m-auto">
            <img src={imgSrc} alt="popUp" className='w-[200px] sm:w-[400px]'/>
        </div>
        <div className="m-auto max-w-[400px]">
            <p className="text-center text-base p-2 ">{textMain}</p>
            <div className="flex p-3 gap-2 justify-between" >
                <GeneralButton content="Proceed" onClick={onProceed} className=" w-28 sm:w-40 h-10 rounded-md text-white bg-app-primary" />
                <GeneralButton content="Cancel" onClick={onCancel} className=" w-28 sm:w-40 h-10 rounded-md text-app-primary border-app-primary border bg-white" />
            </div>
        </div>
    </div>
}
