import React, { useState, useEffect } from 'react'
import IconNonLink from '../../molecules/Others/IconNonLink'
import google from '../../../assets/images/Icons/google.png'
import { makeAuthenticatedServerRequest, makeServerGETRequest } from '../../../utils/helpers'
import { setPublishingQuizID } from '../../../utils/cookieStorage'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
// import DefaultDropdown from '../../molecules/Input/DefaultDropdown'
// assets/images/Icons/google.png

export default function GContactButton ({ className, onClick, redirectUrl, text, quizId, setEmails, setEmailWithName }) {
  const [disabled, setDisabled] = useState(false)
  const [searchParams] = useSearchParams()
  const [groupName, setGroupName] = useState('Import Contact from Google Contacts')
  const [groupList, setGroupList] = useState([])
  const [showDropdown, setShowDropdown] = useState(false)
  const [gTokens, setGTokens] = useState({})

  const getGparm = () => {
    if (searchParams.get('code')) {
      return searchParams.get('code')
    } else {
      return false
    }
  }
  // eslint-disable-next-line no-unused-vars
  const [gparm, setGparam] = useState(getGparm)

  const fetchContact = async () => {
    if (searchParams.get('dummy')) {
      return 0
    } else {
      setDisabled(true)
      const res = await makeAuthenticatedServerRequest('/contacts/auth', 'GET', {
        quizId: quizId
      })
      setDisabled(false)
      if (res) {
        if (res.status !== 200) {
          toast('contact importing error')
          return
        }
      } else {
        toast('contact importing error')
        return 0
      }
      setPublishingQuizID({ quizID: quizId, isQuizPublished: true })
      window.open(String(res.data.url), '_self', 'noopener,noreferrer')
    }
  }
  function handleGroup (e) {
    setGroupName(e.target.value)
  }

  const dropdownSelect = async (e) => {
    setGroupName(e.target.innerHTML)
    setShowDropdown(false)
    const resPeopleData = await makeAuthenticatedServerRequest('/contacts/groupContact', 'POST', {
      gTokens: gTokens,
      groupName: String(e.target.value)
    })
    if (resPeopleData.status === 200) {
      if (resPeopleData.data.emailAddresses.length > 0) {
        const newArray = resPeopleData.data.emailAddresses
        const commaSeparated = Array.prototype.map.call(newArray, function (item) { return item.email }).join(',')
        setEmails(commaSeparated)
        setEmailWithName(newArray)
      } else {
        toast('no contact in this group')
      }
    } else {
      toast('Not permited by google contact or group is empty')
    }
  }

  function dropdownHandle (e) {
    if (groupList.length < 1) {
      const saveQuizMessage = 'you dont have any group in google contact  or you have not provided any permission.Click ok to retry.'
      if (window.confirm(saveQuizMessage) === true) {
        fetchContact()
        setShowDropdown(true)
      }
    } else {
      setShowDropdown(true)
    }
  }
  useEffect(async () => {
    if (gparm) {
      const resContactData = await makeServerGETRequest('/contacts/', {
        gcode: gparm
      })
      if (resContactData.data.groupsdata) {
        const resgroup = resContactData.data.groupsdata
        const tokens = resContactData.data.tokens
        setGTokens(tokens)
        const grouplist = resgroup.filter(function (item) {
          if (item.groupType === 'USER_CONTACT_GROUP') {
            return true
          }
          return false
        }).map((item) => ({ value: item.resourceName, text: item.name }))
        setGroupList(grouplist)
      } else {
        const saveQuizMessage = 'Import error'
        console.log(saveQuizMessage)
        // if (window.confirm(saveQuizMessage) === true) {
        //   window.location.reload(true)
        // }
      }
    }
    return () => {
      console.log(searchParams, 'searchParams')
    }
  }, [gparm])

  return (
    <>
      <form className={className}>
      <div className="flex text-gray-900">
          <label htmlFor="search-dropdownsadsad" className="mb-2 text-sm font-medium sr-only ">Your Email</label>
          <button id="dropdown-button" data-dropdown-toggle="dropdown" className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 " type="button" onClick={dropdownHandle}>All Group <svg aria-hidden="true" className="ml-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></button>
          <div id="dropdown" className=" z-50 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700" data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="bottom" style={{ position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(0px, 10px)' }} >
              <ul className={` ${showDropdown ? '' : 'hidden'} text-sm text-gray-700 dark:text-gray-200`} aria-labelledby="dropdown-button">
              {groupList &&
                groupList.map((item, index) => (
                    <li key={index}>
                        <button type="button" className="inline-flex py-2 px-4 w-full hover:bg-gray-100 " value={item.value} onClick={dropdownSelect}>{item.text}</button>
                   </li>
                ))}

              </ul>
          </div>
          <div className=" w-full flex flex-row ">
              <input type="search" id="search-dropdownasdasdsa" className="block p-2.5 w-full z-20 text-xs sm:text-sm bg-gray-50 rounded-r-lg focus:ring-blue-500 focus:border-blue-500 " placeholder={groupName} value={groupName} onChange={handleGroup} required="" />
              <div className="ml-1 " onClick={fetchContact} disabled={disabled} >
                    <IconNonLink className={ 'bg-white' } src={google} rel="noreferrer" />
                  </div>
          </div>
      </div>
      </form>

    </>
  )
}
