/* eslint-disable no-unused-vars */
import freePlanImg from '../assets/images/Icons/freePlanImg.svg'
import personalImg from '../assets/images/Icons/personalPlanImg.svg'
import enterprizeImg from '../assets/images/Icons/enterprisePlanImg.svg'

const PlansDataNew = [
  {
    _id: '628a1f99015cb4b2ebc6c96d',
    planValue: 0,
    amount: 0,
    priceMethod: 'Forever',
    commonFeatures: '',
    features: [
      'Documents upload (Max. 2)',
      'Maximum File Size 5 MB',
      'Quiz creation (Max. 5)',
      'Question Modifications',
      'Answer Modification',
      'Question paper Download',
      'Unlimited no. of questions',
      'Answer paper Download'
    ],
    hText: 'Free',
    imgSrc: freePlanImg,
    pText: 'This is for personal use max user 1',
    priceText: 'US $0/mth',
    type: 'Monthly',
    duration: 30,
    maxDocuments: 2,
    maxStudents: 5,
    quizPerMonth: 5
  }, {
    _id: '628a1f99015cb4b2ebc6c971',
    planValue: 1,
    amount: 0,
    priceMethod: 'Forever',
    commonFeatures: '',
    features: [
      'Documents upload (Max. 2)',
      'Maximum File Size 5 MB',
      'Quiz creation (Max. 5)',
      'Question Modifications',
      'Answer Modification',
      'Question paper Download',
      'Unlimited no. of questions',
      'Answer paper Download'
    ],
    hText: 'Free',
    imgSrc: freePlanImg,
    pText: 'This is for personal use max user 1',
    priceText: 'US $0/annum',
    type: 'Annually',
    duration: 364,
    quizPerMonth: 5,
    maxDocuments: 2,
    maxStudents: 5
  }, {
    _id: '628a1f99015cb4b2ebc6c96e',
    amount: 8.47,
    planValue: 2,
    priceMethod: 'Billed annually or $9.99 month-to-month',
    commonFeatures: 'Everything included in Starter, plus...',
    features: [
      'Documents upload (Max. 3)',
      'Maximum File Size 15 MB',
      'Quiz creation ( max 20/month )',
      'Question & Answer modifications',
      'Question & Answer paper download',
      'Modify Quiz Settings',
      'Student Registration',
      'One-click Quiz publishes',
      'Student Access to quiz',
      'Auto grading',
      'Grade modification',
      'Report Analytics & feedback',
      'Report Publish'
    ],
    hText: 'Personal',
    imgSrc: personalImg,
    pText: 'This is for personal use max user 1',
    priceText: 'US$ 9.99/month',
    type: 'Monthly',
    duration: 95,
    quizPerMonth: 2000,
    maxDocuments: 3,
    maxStudents: 15,
    razorpayPlanId: 'plan_LSYBOKV2q0mLvk'
  }, {
    _id: '628a1f99015cb4b2ebc6c972',
    amount: 91.42,
    priceMethod: 'Annual billing only',
    commonFeatures: 'Everything included in freeplan, plus...',
    features: [
      'Documents upload (Max. 3)',
      'Maximum File Size 15 MB',
      'Quiz creation (max 25/month)',
      'Question & Answer modifications',
      'Question & Answer paper download',
      'Modify Quiz Settings',
      'Student Registration',
      'One-click Quiz publishes',
      'Student Access to quiz',
      'Auto grading',
      'Grade modification',
      'Report Analytics & feedback',
      'Report Publish'
    ],
    hText: 'Personal',
    imgSrc: personalImg,
    pText: 'This is for personal use max user 1',
    priceText: 'US$ 8.99*/month',
    type: 'Annually',
    duration: 364,
    quizPerMonth: 25,
    maxDocuments: 3,
    maxStudents: 15,
    planValue: 3
  }, {
    _id: '628a1f99015cb4b2ebc6c970',
    amount: 10000,
    priceMethod: 'Annual billing only',
    commonFeatures: 'Everything included in personal plan, plus...',
    features: [
      'Documents upload (Max. 3)',
      'Maximum File Size 15 MB',
      'Quiz creation (max 25/month)',
      'Question & Answer modifications',
      'Question & Answer paper download',
      'Modify Quiz Settings',
      'Student Registration',
      'One-click Quiz publishes',
      'Student Access to quiz',
      'Auto grading',
      'Grade modification',
      'Report Analytics & feedback',
      'Report Publish'
    ],
    hText: 'Enterprise',
    imgSrc: enterprizeImg,
    pText: 'This is for Enterprise use max user 50/100/200',
    priceText: 'US $125/mth',
    type: 'Monthly',
    duration: 29,
    quizPerMonth: 100,
    maxDocuments: 3,
    maxStudents: 15,
    planValue: 4
  },
  {
    _id: '628a1f99015cb4b2ebc6c974',
    amount: 10000,
    priceMethod: 'Annual billing only',
    commonFeatures: 'Everything included in personal plan, plus...',
    features: [
      'Documents upload (Max. 3)',
      'Maximum File Size 15 MB',
      'Quiz creation (max 25/month)',
      'Question & Answer modifications',
      'Question & Answer paper download',
      'Modify Quiz Settings',
      'Student Registration',
      'One-click Quiz publishes',
      'Student Access to quiz',
      'Auto grading',
      'Grade modification',
      'Report Analytics & feedback',
      'Report Publish'
    ],
    hText: 'Enterprise',
    imgSrc: enterprizeImg,
    pText: 'This is for Enterprise use max user 50/100/200',
    priceText: 'Let’s connect',
    type: 'Annually',
    duration: 364,
    quizPerMonth: 100,
    maxDocuments: 3,
    maxStudents: 15,
    planValue: 5
  }]

export default PlansDataNew
