import React, { useEffect, useState } from 'react'
import Card from '../_atomic-design/molecules/Others/PlanCard'
// import PlansCarousel from './Home/PlansCarousel'
import PlanCardsConfigData from './PlansDataNew'
// import { getCookies } from '../utils/cookieStorage'
// import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { makeServerRequest } from '../utils/helpers'
import { toast } from 'react-toastify'

function PlanCards ({ selectedPlanType }) {
  const [currentId, setCurrentId] = useState(1)
  const [plans, setPlans] = useState(PlanCardsConfigData)

  const getPlanDetails = () => {
    const capturePlans = makeServerRequest(
      '/plans/activePlans',
      'POST',
      {}
    )
    capturePlans.then((plansRes) => {
      if (plansRes.status === 200) {
        const plansData = plansRes.data.plans
        setPlans(plansData)
      } else {
        toast('plan data fetching wrong')
      }
    })
  }

  useEffect(async () => {
    getPlanDetails()
  }, [])

  return (
  // <PlansCarousel count={2} currentId={currentId} setCurrentId={setCurrentId} selectedPlanType={selectedPlanType} >
  // </PlansCarousel>
      <div className="flex flex-col md:flex-row justify-center items-center  gap-x-6 gap-y-8 px-8 sm:px-10 md:px-16 relative">
        <RenderPlanCards currentId={currentId} setCurrentId={setCurrentId} selectedPlanType={selectedPlanType} PlanCardsConfig={plans} />
      </div>
  )
}

const RenderPlanCards = ({ currentId, setCurrentId, selectedPlanType, PlanCardsConfig }) => {
  const elementsToRender = []
  const userDetails = useSelector((state) => state.authReducer)

  const getPlan = () => {
    if (userDetails && userDetails.activeSubscription) {
      return userDetails.subscription[userDetails.subscription.length - 1]
    } else {
      return false
    }
  }
  const getButtonStatus = (card, planData) => {
    const planAmount = parseFloat(card.amount.$numberDecimal ? card.amount.$numberDecimal : card.amount)
    if (planData) {
      if (card._id === planData.planId) {
        return 'Current Plan'
      }
      if (card.hText === planData.name) {
        return 'Current Plan'
      }
      if (card.hText === 'Enterprise') {
        return 'Let\'s Connect'
      }
      if (card.planValue > planData.planValue) {
        return 'Upgrade'
      }
      if (card.planValue < planData.planValue) {
        return 'Downgrade'
      }
      if (planAmount === 0 && card.selectedPlanType !== 'Monthly') {
        return 'Let\'s Connect'
      }

      return 'Downgrade'
    } else {
      switch (card.hText) {
        case 'Enterprise':
          return 'Let\'s Connect'
        case 'Free':
          return 'Let\'s Connect'
        default:
          return 'Subscribe'
      }
    }
  }
  // const ContentSet = (card) => {
  //   let content
  //   if (location.pathname === '/pricing') {
  //     content = getButtonStatus(card)
  //     return content
  //   } else {
  //     content = (card.hText === 'Enterprise') ? 'Contact Us' : 'Subscribe'
  //     return content
  //   }
  // }
  let buttonStatusText = 'Subscribe'
  const planData = getPlan()
  for (let i = 0; i < PlanCardsConfig.length; i++) {
    buttonStatusText = getButtonStatus(PlanCardsConfig[i], planData)
    elementsToRender.push(
      PlanCardsConfig[i].type === selectedPlanType
        ? (
                    <Card
                        key={i}
                        keydata={i.toString + PlanCardsConfig[i]._id}
                        onClick={() => {
                          setCurrentId(PlanCardsConfig[i]._id)
                        }}
                        currentId={currentId}
                        setCurrentId={setCurrentId}
                        card={PlanCardsConfig[i]}
                        currentPlan={buttonStatusText}
                    />
          )
        : (null)
    )
  }
  return elementsToRender
}

export default PlanCards
