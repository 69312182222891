import React from 'react'
import DeleteQuestionSrc from '../../assets/images/Icons/deleteWhite.svg'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
import TextXs from '../../_atomic-design/atoms/Text/TextXs'
import GeneralDropdown from '../../_atomic-design/molecules/Input/GeneralDropdown'
import GeneralTextInput from '../../_atomic-design/molecules/Input/GeneralTextInput'

export default function EditQuestionLowerDiv ({ className, qType, onDelete, changeQuestionType, score, changeScore, questionIndex }) {
  return (
    <div className={`flex flex-row justify-between items-center ${className}`}>
        <GeneralDropdown id = {`questionType_joyride_${questionIndex}`} className="outline-none cursor-pointer text-xs sm:text-sm bg-white border rounded-md p-1" options={[{ value: 'M', text: 'Objective' }, { value: 'S', text: 'Subjective' }]} defaultSelected={qType} onChange={changeQuestionType} />

        <div className="flex flex-row items-center">
            <TextXs text={'Score'} />
            <GeneralTextInput id = {`questionScore_joyride_${questionIndex}`} className="border border-black rounded md:rounded-md p-1 w-8 h-6  md:w-12 md:h-6 text-xs md:text-sm ml-1 " type="number" min={1} value={score} onChange={changeScore} />
        </div>
        <div className="">
          <GeneralButton id = {`questionDelete_joyride_${questionIndex}`} className="cursor-pointer hover:opacity-80 transition-all text-xs md:text-sm p-2 bg-[#656565] hover:bg-app-red duration-500 rounded-md" content={<DeleteQuestion onDelete={onDelete} />} />
        </div>

    </div>
  )
}

function DeleteQuestion ({ onDelete }) {
  return (
        <div className="flex flex-row items-center px-2" onClick={onDelete}>
            <ImageTag className=" w-4 md:w-5 mr-1 md:mr-2" src={DeleteQuestionSrc} />
            <GeneralText className="text-white" text="Delete" />
        </div>
  )
}
