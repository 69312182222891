/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import FilterBar from '../../containers/CreatedQuizes/FilterBar'
import { makeAuthenticatedServerGETRequest, makeServerGETRequest } from '../../utils/helpers'
import HeadingSm from '../../_atomic-design/atoms/Text/HeadingSm'
import QuizCardStudent from '../../containers/CreatedQuizes/QuizCardStudent'
import StudentDashboardNavbar from '../../_atomic-design/molecules/Navbars/StudentDashboardNavbar'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
import ReactLoading from 'react-loading'

export default function DashboardStudent () {
  const [currentTag, setCurrentTag] = useState('All')
  const [quizData, setQuizData] = useState([])
  const [attemptedsDetails, setAttemptedsDetails] = useState([])
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const formattedQuizData = useMemo(() => {
    const data = quizData.map((item) => {
      const date1 = new Date(item.createdAt)
      item.date = date1.toDateString().substring(4)
      const date2 = new Date(item.updatedAt)
      const diffTime = Math.abs(date2 - date1)
      const diffDays = Math.ceil(diffTime / (1000 * 60))
      item.duration = `${diffDays}m`
      item.avg_score = ' _ / _ '
      item.score = item.score || 0
      item.attempted = ' _ / _ '
      const today = new Date()
      item.isExpired = today - (item.settings ? item.settings.endDate : today + 1) > 0 // if endDate not present, then quiz is not expired
      return item
    })
    return data
  }, [quizData])

  const formattedFilteredQuizData = useMemo(() => {
    const data = formattedQuizData.filter((item) => {
      return currentTag === 'All' || item.quizId.tags.includes(currentTag)
    })
    return data
  }, [formattedQuizData, currentTag])

  const tags = useMemo(() => {
    const arr = ['All']
    try {
      formattedQuizData.forEach((item) => {
        const topicList = item.quizId.tags
        topicList.forEach((topic) => {
          if (!arr.includes(topic)) {
            arr.push(topic)
          }
        })
      })
      return arr
    } catch (error) {
      return arr
    }
  }, [formattedQuizData])

  const statusArr = [
    { text: 'S1', value: 'S1' },
    { text: 'S2', value: 'S2' },
    { text: 'S3', value: 'S3' }
  ]
  const [currentStatus, setCurrentStatus] = useState('')
  useEffect(async () => {
    const getdata = async () => {
      const dataQuizs = await makeAuthenticatedServerGETRequest(
        '/quiz/getStudentAssignedQuiz'
      )
      if (dataQuizs.status === 200) {
        setQuizData(dataQuizs.data.assignedquizs.quizs.reverse()) // reverse method to show latest quiz on top
        setAttemptedsDetails(dataQuizs.data.attemptedsDetails)
      }
    }
    setLoading(true)
    await getdata().catch(() => { })
    setLoading(false)
    document.title = 'Student\'s Dashboard'
  }, [])
  return (
        <div className="bg-[#EFEFEF] min-h-screen h-max">
            <div className="w-11/12 md:w-4/5 max-w-7xl mx-auto pt-20">
            <StudentDashboardNavbar selected='/studentDashboard' white />
                <div className="flex justify-start items-center">
                    <HeadingSm text={'Your Quizzes'} />
                </div>
                <FilterBar
                    currentTag={currentTag}
                    setCurrentTag={setCurrentTag}
                    setSearch={setSearch}
                    // statusArr={statusArr}
                    // setCurrentStatus={setCurrentStatus}
                />
                {loading
                  ? (<ReactLoading type="spin" className='m-auto pt-8' color="#916AF8" height={40} width={35} />)
                  : (<div className=" mt-8">
          {formattedFilteredQuizData.map((quizData) => {
            try {
              if (quizData.quizId.name.toLowerCase().includes(search.toLowerCase())) {
                return (
                        <QuizCardStudent key={quizData._id} data={quizData} attemptedsDetails={attemptedsDetails}/>
                )
              }
              return null
            } catch (error) {
              return null
            }
          })}
          {(formattedFilteredQuizData.length < 1)
            ? (<div className="w-full  max-w-7xl mb-4 p-5 shadow-lg flex flex-col lg:flex-row justify-center items-center rounded-[10px] bg-red-200 overflow-auto text-xs sm:text-sm md:text-base"
            >
              <div className='flex flex-col'>
                <div className='flex flex-col lg:flex-row'>
                  <div className=" flex">
                    <GeneralText text="You don't have any quizzes to attempt." className='text-base font-bold' />
                  </div>
                  </div>
                  </div>
                  </div>)
            : null}

      </div>)}

            </div>
        </div>
  )
}
