import React from 'react'
import ImageTag from '../../atoms/ImageTag'
import GeneralHeading from '../../atoms/Text/GeneralHeading'
import GeneralText from '../../atoms/Text/GeneralText'
import quote from '../../../assets/images/Icons/quote.svg'

// import ImageTag from '../_atomic-design/atoms/ImageTag'
export default function TestimonialCard ({ paraText, name, location, image, className }) {
  return (
    <div className={`${className} rounded-xl overflow-hidden w-full h-full max-w-[532px] p-6 sm:p-10 min-h-[168px] flex flex-col justify-between items-center sm:items-start shadow-lg bg-[#F9ECF9]`} >
    <GeneralHeading text={paraText} className='text-xs md:text-xl font-bold mb-4 text-[#4B4B4B] italic' />
    <div className='flex justify-between w-full'>
        <div className="  flex justify-center items-center  ">
          <ImageTag src={image} className='w-10 md:w-16 h-10 md:h-16 mr-4 rounded-xl' />
          <div className='flex flex-col gap-2 sm:gap-4 h-max'>
            <GeneralHeading text={name} className='text-xs md:text-2xl font-bold ' />
            <GeneralText text={location} className='text-[13px] md:text-base font-medium text-app-grey' />
          </div>
        </div>
        <ImageTag src={quote} className='w-10 md:w-16  h-10 md:h-16 mt-auto' />
    </div>
  </div>
  )
}
