const data = {
  education: [
    'Postdoctorate',
    'Doctorate',
    'Post Graduate (PG)',
    'Under Graduate (UG)',
    'Others'
  ],
  profession: [
    'Academic-Instructors',
    'Academic-Students',
    'Industry-Person'
  ],
  departments: [
    'Computer Science',
    'Electrical Engineering',
    'Mechanical Engineering',
    'Civil Engineering',
    'Chemical Engineering',
    'Aerospace Engineering',
    'Biomedical Engineering',
    'Industrial Engineering',
    'Environmental Engineering',
    'Materials Science and Engineering',
    'Biology',
    'Chemistry',
    'Physics',
    'Mathematics',
    'Statistics',
    'Economics',
    'Psychology',
    'Sociology',
    'Political Science',
    'History',
    'Anthropology',
    'Geography',
    'Linguistics',
    'English Literature',
    'Foreign Languages',
    'Philosophy',
    'Religious Studies',
    'Art History',
    'Fine Arts',
    'Music',
    'Theater and Performing Arts',
    'Film Studies',
    'Communication Studies',
    'Journalism',
    'Media Studies',
    'Business Administration',
    'Marketing',
    'Finance',
    'Accounting',
    'Human Resources',
    'International Business',
    'Entrepreneurship',
    'Supply Chain Management',
    'Information Systems',
    'Education',
    'Special Education',
    'Early Childhood Education',
    'Elementary Education',
    'Secondary Education',
    'Higher Education Administration',
    'Environmental Science',
    'Geology',
    'Meteorology',
    'Oceanography',
    'Forestry',
    'Environmental Policy',
    'Public Health',
    'Nursing',
    'Medicine',
    'Dentistry',
    'Pharmacy',
    'Public Administration',
    'Social Work',
    'Criminal Justice',
    'Law',
    'Library Science',
    'Counseling Psychology',
    'Clinical Psychology',
    'Industrial-Organizational Psychology',
    'Social Psychology',
    'Cognitive Science',
    'Neuroscience',
    'Biochemistry',
    'Genetics',
    'Microbiology',
    'Zoology',
    'Botany',
    'Nutrition',
    'Kinesiology',
    'Sports Science',
    'Exercise Physiology',
    'Rehabilitation Sciences',
    'Occupational Therapy',
    'Physical Therapy',
    'Speech-Language Pathology',
    'Social Services',
    'Family Studies',
    'Gender Studies',
    "Women's Studies",
    'LGBTQ+ Studies',
    'Cultural Studies',
    'Ethnic Studies',
    'Conflict Resolution',
    'Peace Studies',
    'Development Studies',
    'Urban Planning',
    'Real Estate',
    'Archaeology',
    'Comparative Literature',
    'Bioinformatics',
    'Computational Biology',
    'Electronics and Communication Engineering',
    'Social Sciences and Humanities',
    'Human-Centered Design',
    'Computer Science and Engineering',
    'Electrical and Electronics Engineering',
    'Information Technology',
    'Bio Technology',
    'Bio Medical Engineering',
    'Artificial Intelligence and Data Science',
    'Management Studies',
    'Computer Applications',
    'English'
  ],
  languages: [
    'Mandarin (entire branch)',
    'Spanish',
    'English',
    'Hindi[b]',
    'Arabic',
    'Portuguese',
    'Bengali',
    'Russian',
    'Japanese',
    'Punjabi',
    'German',
    'Javanese',
    'Wu(inc. Shanghainese)',
    'Malay(inc. Indonesian and Malaysian)',
    'Telugu',
    'Vietnamese',
    'Korean',
    'French',
    'Marathi',
    'Tamil',
    'Urdu',
    'Turkish',
    'Italian',
    'Yue (inc. Cantonese)',
    'Thai',
    'Gujarati',
    'Jin',
    'Southern Min (inc. Hokkien and Teochew)',
    'Persian',
    'Polish',
    'Pashto',
    'Kannada',
    'Xiang',
    'Malayalam',
    'Sundanese',
    'Hausa',
    'Odia (Oriya)',
    'Burmese',
    'Hakka',
    'Ukrainian',
    'Bhojpuri',
    'Tagalog (Filipino)',
    'Yoruba',
    'Maithili',
    'Uzbek',
    'Sindhi',
    'Amharic',
    'Fula',
    'Romanian',
    'Oromo',
    'Igbo',
    'Azerbaijani',
    'Awadhi',
    'Gan',
    'Cebuano (Visayan)',
    'Dutch',
    'Kurdish',
    'Serbo-Croatian',
    'Malagasy',
    'Saraiki',
    'Nepali',
    'Sinhala',
    'Chittagonian',
    'Zhuang',
    'Khmer',
    'Turkmen',
    'Assamese',
    'Madurese',
    'Somali',
    'Marwari',
    'Magahi',
    'Haryanvi',
    'Hungarian',
    'Chhattisgarhi',
    'Greek',
    'Chewa',
    'Deccan',
    'Akan',
    'Kazakh',
    'Northern Min[disputed – discuss]',
    'Sylheti',
    'Zulu',
    'Czech',
    'Kinyarwanda',
    'Dhundhari',
    'Haitian Creole',
    'Eastern Min (inc. Fuzhou dialect)',
    'Ilocano',
    'Quechua',
    'Kirundi',
    'Swedish',
    'Hmong',
    'Shona',
    'Uyghur',
    'Hiligaynon/Ilonggo (Visayan)',
    'Mossi',
    'Xhosa',
    'Belarusian',
    'Balochi',
    'Konkani'
  ]
}
export default data
