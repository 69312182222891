import { makeServerGETRequest } from './helpers'
import { calculateTimeDifference } from './calculateTimeDifference'

export default async function syncTimeWithServer (endDate) {
  // const [time, setTime] = useState({hours:5, minutes: 10, seconds: 59 });
  /* This component displays time in HH:MM:SS . props.time must be in format {hours:5, minutes: 10, seconds: 59 } */
  try {
    const timeWhenMakingRequest = Date.now()
    const response = await makeServerGETRequest('/getServerTime', {})
    if (response.status === 200) {
      const timeWhenReceivedRequest = Date.now()
      const delay = timeWhenReceivedRequest - timeWhenMakingRequest
      console.log(delay)
      const now = new Date(response.data.serverTime + delay)
      const diff = calculateTimeDifference(now, endDate)
      return diff
    } else {
      return false
    }
  } catch (error) {
    console.log(error)
  }
}
