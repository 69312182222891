import React, { useEffect } from 'react'

export default function TimerExtended (props) {
  // const [time, setTime] = useState({hours:5, minutes: 10, seconds: 59 });
  /* This component displays time in HH:MM:SS . props.time must be in format {hours:5, minutes: 10, seconds: 59 } */
  const time = props.time
  const setTime = props.setTime

  function myNewFn (myInterval) {
    if (time.seconds > 0) {
      setTime({ hours: time.hours, minutes: time.minutes, seconds: time.seconds - 1 })
    }
    if (time.seconds === 0) {
      if (time.minutes === 0) {
        if (time.hours === 0) {
          clearInterval(myInterval)
          props.onTimeUp()
        } else {
          setTime({ hours: time.hours - 1, minutes: 59, seconds: 59 })
        }
      } else {
        setTime({ hours: time.hours, minutes: time.minutes - 1, seconds: 59 })
      }
    }
  }

  useEffect(() => {
    const myInterval = setInterval(() => { myNewFn(myInterval) }, 1000)
    return () => clearInterval(myInterval)
  }, [time])

  return <div className="flex rounded-md border bg-white w-max h-10 ">
            <div className="flex justify-start px-5 rounded-md disabled:opacity-100 bg-[#EDF0FF] text-app-primary">
                <p className='my-auto h-max'>Time Remaining</p>
            </div>
        <span className="text-black my-auto px-5">
          { time.hours < 10 ? `0${time.hours}` : time.hours} : {time.minutes < 10 ? `0${time.minutes}` : time.minutes} : {time.seconds < 10 ? `0${time.seconds}` : time.seconds}
        </span>
    </div>
}
