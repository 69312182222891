/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
// import MainBackgroundSrc from '../../assets/images/MainBackground.svg'
import MainBackgroundSrc from '../../assets/images/quizWaitingPage.png'
import { enterFullScreen } from '../../utils/fullScreen'
import { toast } from 'react-toastify'
import { makeAuthenticatedServerRequest } from '../../utils/helpers'
import { quizStudentActions } from '../../store/reducers/quizStudentReducer'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading'
import LogoIcon from '../../assets/images/Icons/favicon.ico'
import StartQuiz from '../../assets/images/StartQuiz.svg'
import HeadingSm from '../../_atomic-design/atoms/Text/HeadingSm'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'
import ButtonPrimaryLg from '../../_atomic-design/atoms/Button/ButtonPrimaryLg'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import TickRedCircle from '../../assets/images/Icons/TickRedCircle.svg'
import popUp from '../../assets/images/Icons/popUp.svg'
import PopUpStartQuiz from '../../containers/General/PopUpStartQuiz'

export default function StartQuizPage () {
  const bgStyle = {
    backgroundImage: `url(${MainBackgroundSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { state } = useLocation()
  const { data, attemptedsDetails } = state
  const navigate = useNavigate()
  const today = new Date()
  const quizStartDate = new Date(data.quizId.settings.startDate)
  const quizEndDate = new Date(data.quizId.settings.endDate)
  const studentsResult = attemptedsDetails.filter((item) => item.refQuiz === data.quizId._id)
  const instructorStatus = studentsResult.length > 0 ? studentsResult[0].instructorEvaluation : false
  const [notes, setNotes] = useState(data.quizId.settings.rulesAndRegulations ? data.quizId.settings.rulesAndRegulations : [])
  const [isModalOpen, setIsModalOpen] = useState(false)
  let quizLiveStatus = 'Expired'
  if (today - quizEndDate < 0 && today - quizStartDate > 0) {
    quizLiveStatus = 'Live'
  } else if (today - quizStartDate < 0) {
    quizLiveStatus = 'Upcoming'
  }
  useEffect(async () => {
    document.title = 'quiz-in-progress'
  }, [])

  function startQuizButtonHandler () {
    setIsModalOpen(true)
  }

  const startQuiz = async () => {
    // e.preventDefault()
    // const alertMessage = 'Once you start quiz you can not switch tab or open a new tab, other wise your session will be expired.'
    // if (window.confirm(alertMessage) === false) {
    //   return
    // } else {
    //   enterFullScreen()
    // }
    enterFullScreen()
    setLoading(true)
    await makeAuthenticatedServerRequest('/quiz/getStudentQuizById', 'POST', {
      qid: data.quizId._id
    }).then((response) => {
      if (response.status === 200) {
        dispatch(quizStudentActions.setReferenceQuiz(response.data))
        navigate('/QuizInProgress')
      } else if (response.status === 404) {
        toast(
          'no quizzes found of selected topic or you have already attempted all'
        )
      } else toast('unknown error occured')
    })
    setLoading(false)
    setIsModalOpen(false)
  }
  return (
    <div
      className="w-full min-h-screen flex flex-col md:flex-row justify-center items-center"
      style={bgStyle}
    >
      <div className="flex flex-col md:flex-row justify-center items-center gap-2  text-xs sm:text-sm md:text-base rounded-3xl p-2 sm:p-10 w-[90vw] sm:w-max">
        <div className='flex flex-col sm:flex-row gap-8 w-full'>
          <div className="flex flex-col justify-center items-center w-full sm:w-1/2">
            <div className='flex flex-row gap-4 items-center justify-center'>
              <div><img className="w-12 " src={LogoIcon} alt="LOGO" /></div>
              <div className='pb-2'> <HeadingSm text={data.quizId.name} /></div>
            </div>
            <div><img className="w-auto " src={StartQuiz} alt="StartQuiz Image" /></div>
            <div className='font-semibold w-2/3 text-center'>While attempting the examinations, do not try to open a new tab in the browser or exit full-screen mode.</div>
          </div>
          <div className="flex flex-col  justify-center items-center mx-auto w-full sm:w-1/2 gap-4 rounded-3xl ">
            <div className='pl-6'><HeadingSm text="Exam rules" /></div>
            <div>
              {notes.map((note, index) => (
                <div className=" flex flex-row justify-between gap-2 px-8 p-2" key={index}>
                  <div className='flex flex-row gap-2  '>
                    <ImageTag
                      src={TickRedCircle}
                      className="w-4 sm:w-5 mr-2"
                    />
                    <TextBody text={index + 1 + '. ' + note.text} className={'font-medium'} />
                  </div>
                </div>
              ))}
            </div>
            <div>{loading
              ? (
                <ReactLoading type="spin" color="#916AF8" className='mx-5' height={40} width={35} />
                )
              : (<div className='w-fit p-16'>
                {!instructorStatus && <ButtonPrimaryLg text={'Start Exam'} onClick={startQuizButtonHandler} disabled={quizLiveStatus !== 'Live' || data.isAttempted} />}</div>)}
            </div>

          </div>
        </div>
      </div>
      {isModalOpen && <div className=" absolute w-screen h-screen flex items-center justify-center  bg-blue-100 bg-opacity-50 backdrop-blur-sm z-20">
      {loading
        ? <ReactLoading type="spin" color="#916AF8" className='mx-5' height={80} width={70} />
        : <PopUpStartQuiz imgSrc={popUp} onProceed={startQuiz} onCancel={() => setIsModalOpen(false)} textMain={'Once you start quiz you can not switch tab or open a new tab, other wise your session will be expired.'}/>}</div>}
    </div>
  )
}
