import React from 'react'

export default function ButtonPrimaryXs ({ className, onClick, text, disabled }) {
  return (
        <button
            onClick={onClick}
            // eslint-disable-next-line no-undef
            className={` ${className} w-[72px] h-8 text-xs block rounded-md ${disabled ? 'bg-app-button-primary-disabled' : 'bg-app-primary'}  text-white`}
            disabled={disabled}
            >
            {text}
        </button>
  )
}
