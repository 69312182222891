import React from 'react'
import GeneralText from '../../atoms/Text/GeneralText'
import ImageTag from '../../atoms/ImageTag'
import TextBody from '../../atoms/Text/TextBody'
import GeneralHeading from '../../atoms/Text/GeneralHeading'
import personalImg from '../../../assets/images/Icons/freePlanImg.svg'
function SelectedCard ({ card, className, onClick, currentId, setCurrentId }) {
  return (
        <div className={`${className} flex flex-col min-w-full flex-1 max-w-md rounded-[20px]` }>
            <div
                className={`h-full px-6 py-8 rounded-[20px]  cursor-pointer ${
                    currentId === card.id ? 'bg-[#F8F7FE] border border-app-primary ' : 'bg-white'
                }  `}
                onClick={onClick}
            >
                <GeneralHeading
                    text={card.hText}
                    className="text-app-primary md:text-[28px] font-bold mb-1"
                />
                <TextBody text={card.pText} className="flex mb-1 text-[8px] md:text-base " />
                <GeneralText
                    text={card.priceText}
                    className="text-app-primary text-xs md:text-2xl font-bold mb-1"
                />
                <ImageTag src={personalImg} className="w-full mx-auto my-2" />
                <div className=" text-[8px] md:text-base">
                    <ul className="list-none mx-4 px-4">
                        {card.features.map((feature, key) => {
                          return <li key={key} className='list-disc text-sm'> {feature} </li>
                        })}

                    </ul>

                </div>
                {(card.type === 'Annually')
                  ? (
                          <ul key={'safsdfsdfdshj'} className='list-disc text-sm'> <b>*</b>  </ul>
                    )
                  : (
                    <ul key={'safsdfsdfdshl'} className='list-disc text-sm'> <b>*</b>  </ul>
                    )}
            </div>
        </div>
  )
}
export default SelectedCard
