/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import DashboardNavbar from '../../_atomic-design/molecules/Navbars/DashboardNavbar'
import HomeNavbar from '../../_atomic-design/molecules/Navbars/HomeNavbar'
import FilterBar from '../../containers/CreatedQuizes/FilterBar'
import QuizCard from '../../containers/CreatedQuizes/QuizCard'
import { makeAuthenticatedServerGETRequest, makeServerRequest } from '../../utils/helpers'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import HeadingSm from '../../_atomic-design/atoms/Text/HeadingSm'
// import AddButtonCircular from '../../_atomic-design/molecules/Others/AddButtonCircular'
import addCircularBlue from '../../assets/images/Icons/addCircularBlue.svg'
import addCircularWhite from '../../assets/images/Icons/addCircularWhite.svg'
import { useDispatch } from 'react-redux'
import { storeQuiz } from '../../store/actions/quizActions/quizActions'
import { toast } from 'react-toastify'
import TrialRibbon from '../../_atomic-design/molecules/Others/TrialRibbon'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
import ButtonGeneralImage from '../../_atomic-design/atoms/Button/ButtonGeneralImage'
import ButtonGeneralImageColorless from '../../_atomic-design/atoms/Button/ButtonGeneralImageColorless'
import ReactLoading from 'react-loading'
import SearchBarCustom from '../../components/SearchBarCustom'

export default function Dashboard () {
  const [currentTag, setCurrentTag] = useState('All')
  const [quizData, setQuizData] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formattedQuizData = useMemo(() => {
    const data = quizData.map((item) => {
      const date1 = new Date(item.createdAt)
      item.date = date1.toDateString().substring(4)
      const date2 = new Date(item.expiresOn)
      const diffTime = Math.abs(date2 - date1)
      const diffDays = Math.ceil(diffTime / (1000 * 60))
      item.duration = `${diffDays}m`
      item.avg_score = ' _ / _ '
      item.score = item.score || 0
      item.attempted = ' _ / _ '
      return item
    })
    return data
  }, [quizData])

  const formattedFilteredQuizData = useMemo(() => {
    const data = formattedQuizData.filter((item) => {
      return currentTag === 'All' || item.tags.includes(currentTag)
    })
    return data
  }, [formattedQuizData, currentTag])

  const tags = useMemo(() => {
    const arr = ['All']
    formattedQuizData.forEach((item) => {
      const topicList = item.tags
      topicList.forEach((topic) => {
        if (!arr.includes(topic)) {
          arr.push(topic)
        }
      })
    })
    return arr
  }, [formattedQuizData])

  const statusArr = [
    { text: 'S1', value: 'S1' },
    { text: 'S2', value: 'S2' },
    { text: 'S3', value: 'S3' }
  ]
  const [currentStatus, setCurrentStatus] = useState('')

  const createEmptyQuiz = async () => {
    const res = await makeServerRequest('/quiz/createEmptyQuiz', 'POST')
    if (res.status === 200) {
      dispatch(
        storeQuiz({
          urls: [],
          quizId: res.data.quiz._id,
          quizName: res.data.quiz.name,
          questions: res.data.quiz.questions,
          settings: res.data.quiz.settings,
          quizTopics: res.data.quiz.tags
        }))
      navigate(`/EditQuiz?quizId=${res.data.quiz._id}`)
    } else {
      toast('Some error occured')
    }
  }

  useEffect(async () => {
    const getdata = async () => {
      const data = await makeAuthenticatedServerGETRequest(
        '/quiz/getByUserId'
      )
      if (data.status === 200) {
        setQuizData(data.data.quiz.reverse()) // to show latest quizzes on top
      }
    }

    setLoading(true)
    await getdata()
    setLoading(false)
    document.title = 'Dashboard'
  }, [])
  return (<>
    <TrialRibbon />
    <div className="bg-[#EFEFEF] max-h-full min-h-screen py-8">
      <div className="w-full md:w-4/5 max-w-7xl mx-auto pt-20 px-4">
        <HomeNavbar selected='/Dashboard' white />
        <div className="flex flex-col sm:flex-row gap-4 items-center">
          <HeadingSm text={'Created Quizzes'} />
          {/* <AddButtonCircular className={'ml-3 w-7 cursor-pointer'}  /> */}
          {/* <ImageTag src={plusIcon} className='ml-3 w-7 cursor-pointer' onClick={() => navigate('/')} /> */}
          <div className='ml-auto flex'>
          <ButtonGeneralImage text={'Create ML Quiz'} image={addCircularWhite} imagePosition={'left'} className={' mr-2 mb-2 hover:bg-[#3b9fce] transition-all duration-300'} onClick={() => navigate('/')}/>
          <ButtonGeneralImageColorless text={'Create Blank Quiz'} image={addCircularBlue} imagePosition={'left'} className={'  text-app-primary border border-app-primary hover:bg-white hover:border-app-grey transition-all duration-300 '} onClick={createEmptyQuiz}/>
          </div>
          {/* <ButtonPrimary text={'Create Blank Quiz'} onClick={createEmptyQuiz} className={'mr-0 ml-2'} /> */}
        </div>
        {/* <FilterBar
          currentTag={currentTag}
          setCurrentTag={setCurrentTag}
          setSearch={setSearch}
          search={search}
        // statusArr={statusArr}
        // setCurrentStatus={setCurrentStatus}
        /> */}
        <SearchBarCustom
          currentTag={currentTag}
          setCurrentTag={setCurrentTag}
          setSearch={setSearch}
          search={search}
          className={' p-4 bg-white text-gray-600 '}
        />
        {loading
          ? (<ReactLoading type="spin" className='m-auto pt-8' color="#916AF8" height={40} width={35} />)
          : (<div className=" mt-8">
          {formattedFilteredQuizData.map((quizData) => {
            if (quizData.name.toLowerCase().includes(search.toLowerCase())) {
              return (
                <QuizCard key={quizData._id} data={quizData} />
              )
            }
            return null
          })}
          {(formattedFilteredQuizData.length < 1)
            ? (<div className="w-full  max-w-7xl mb-4 p-5 shadow-lg flex flex-col lg:flex-row justify-center items-center rounded-[10px] bg-red-200 overflow-auto text-xs sm:text-sm md:text-base"
            >
              <div className='flex flex-col'>
                <div className='flex flex-col lg:flex-row'>
                  <div className=" flex">
                    <GeneralText text="You have not created any quizzes right now." className='text-base font-bold' />
                  </div>
                </div>
              </div>
            </div>)
            : null}
        </div>)
        }
      </div>
    </div>
  </>
  )
}
