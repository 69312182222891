import React, { useEffect, useState } from 'react'
import { Editor, EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import 'draft-js/dist/Draft.css'

export default function CustomizedTextArea ({
  defaultValue,
  onChange,
  onBlur,
  border,
  className,
  id
}) {
  const [editorState, setEditorState] = useState(() => EditorState.createWithContent(
    ContentState.createFromBlockArray(
      convertFromHTML('<div>' + defaultValue + '</div>')
    )
  ))
  // eslint-disable-next-line no-unused-vars

  useEffect(() => {
    const changedText = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // console.log('editorState', stateToHTML(this.state.editorState.getCurrentContent()))
    onChange(changedText)
  }, [editorState])

  return (
    <div className={` ${className} border-b border-indigo-200 mb-3`} id={id}><Editor
    editorState={editorState} onChange={setEditorState} className='z-auto'
  /></div>
  )
}
