import React from 'react'
import GeneralText from '../_atomic-design/atoms/Text/GeneralText'
// import FeaturesCarousel from '../containers/Home/FeaturesCarousel'
import HeadingXlg from '../_atomic-design/atoms/Text/HeadingXlg'
import FeatureList from '../containers/Home/FeaturesList'

function Features () {
  return (
    <div id='features' className="w-full py-10 sm:p-20">
      <div className="w-4/5 sm:w-full mx-auto text-center mb-10 sm:mb-20 px-3">
      <HeadingXlg
          text="Why is everyone excited?"
          className="mb-4 text-app-purple-heading text-[26px]"
        />
      <GeneralText className='text-[16px] sm:text-lg w-full'
        text={<>A revolutionary platform that <strong>accelerates and enhances</strong> the <strong>learning journey  </strong></>}
      />

      </div>

      <FeatureList />
      {/* <FeaturesCarousel /> */}

    </div>
  )
}
export default Features
