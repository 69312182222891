import { actionTypes } from '../../actionTypes'

export const loginSucess = (payload) => {
  return {
    type: actionTypes.USER_LOGIN_SUCCESS,
    payload: payload
  }
}

export const signoutSuccess = () => {
  return {
    type: actionTypes.USER_SIGNOUT_SUCCESS
  }
}
export const logoutsuccess = (payload) => {
  return {
    type: actionTypes.USER_LOGIN_FAILURE,
    payload: payload
  }
}

export const UserInfo = (payload) => {
  return {
    type: actionTypes.USER_INFO,
    payload: payload
  }
}

export const userEmailVerificationSuccess = (payload) => {
  return {
    type: actionTypes.USER_EMAIL_VERIFICATION_SUCCESS,
    payload: payload
  }
}
export const userRoleTypeUpdated = (payload) => {
  return {
    type: actionTypes.USER_ROLE_TYPE_UPDATE,
    payload: payload
  }
}
