import React, { useState, useRef, useEffect } from 'react'
import ImageTag from '../atoms/ImageTag'
// import dropdownArrowSrc from '../../assets/images/dropdownArrow.svg'
import TextBody from '../atoms/Text/TextBody'
import TextXs from '../atoms/Text/TextXs'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import GeneralButton from '../atoms/Button/GeneralButton'
import { getCookies, removeCookies, setUserDetails } from '../../utils/cookieStorage'
import { useOutsideAlerter } from '../../utils/componentHelpers'
import { useDispatch, useSelector } from 'react-redux'
import { UserInfo, signoutSuccess } from '../../store/actions/authActions/authActions'
import { makeAuthenticatedServerRequest } from '../../utils/helpers'
import { toast } from 'react-toastify'
import arrowReload from '../../assets/images/Icons/ArrowReload.svg'
import logout from '../../assets/images/Icons/logout.svg'

export default function ProfileComponent ({ text, className }) {
  const authState = useSelector((state) => state.authReducer)

  const [showBox, setShowBox] = useState(false)

  const profileComponent = useRef(null)

  // To add event listener for outside click of the component
  useOutsideAlerter(profileComponent, () => setShowBox(false))

  return (
        <div
            ref={profileComponent}
            className={` ${className} float-left`}
            onClick={() => setShowBox(!showBox)}
        >
            <div className="flex flex-col justify-center items-center m-1 h-8 w-8 cursor-pointer ">
                <ProfileIcon authState={authState} />
                {<ProfileDropdown authState={authState} showBox={showBox} />}
            </div>
        </div>
  )
}

function ProfileIcon ({ authState, className }) {
  const text = authState.firstName[0] ? authState.firstName[0] : '' + authState.lastName[0] ? authState.lastName[0] : ''
  return (
      <>
        {authState.profilePicture
          ? (
            <ImageTag
                className={
                    'w-8 h-8 aspect-square object-cover rounded-full ' + className
                }
                src={authState.profilePicture}
                alt='profile picture'
            />
            )
          : <div className={'aspect-square rounded-full flex justify-center items-center font-bold  text-[#1A1A1A] text-sm bg-[#E9F4FF] w-full h-full border ' + className}>
              {text ? text.toUpperCase() : ''}
          </div>
        }
      </>
  )
}

function ProfileDropdown ({ authState, showBox }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [dropdownClass, setDropdownClass] = useState('top-[0px] right-[50px] sm:top-[60px] sm:right-[30px] ')
  const navigate = useNavigate()
  const refreshPlan = async () => {
    const userDetails = getCookies().userDetails
      ? JSON.parse(getCookies().userDetails)
      : null
    if (userDetails) {
      // Store user details in cookies so no need to make API call everytime
      // setCookieStorage(userDetails.data.user)
      // dispatch(UserInfo({ ...userDetails }))
    }
  }
  const signOut = () => {
    // removeCookies(['accessToken', 'refreshToken', 'userDetails'])
    // dispatch(signoutSuccess())
    const confirmation = window.confirm('Are you sure you want to logout?')
    if (!confirmation) {
      return
    }
    removeCookies(['accessToken', 'refreshToken', 'userDetails'])
    dispatch(signoutSuccess())
    navigate('/feedback', { replace: true })
  }
  const switchRole = async () => {
    let selectedRole = 0
    if (authState.userRoleType === '1') {
      selectedRole = 0
    } else if (authState.userRoleType === '0') {
      selectedRole = 1
    }
    const res = await makeAuthenticatedServerRequest('/profile', 'POST', {
      preferedRoleType: selectedRole
    })
    if (res.status === 200) {
      if (selectedRole === 0) {
        setUserDetails({ ...authState, userRoleType: '0' })
        dispatch(UserInfo({ ...authState, userRoleType: '0' }))
        toast('Role type changed to Instructor')
        navigate('/Dashboard', { replace: true })
      } else if (selectedRole === 1) {
        setUserDetails({ ...authState, userRoleType: '1' })
        dispatch(UserInfo({ ...authState, userRoleType: '1' }))
        toast('Role type changed to Student')
        navigate('/studentDashboard', { replace: true })
      } else {
        toast('Unable to change the role, something went wrong.')
      }
    } else {
      toast('Some thing went wrong error code 445')
    }
  }

  useEffect(() => {
    refreshPlan()
    if (location.pathname === '/EditQuiz') {
      setDropdownClass(' -right-4 top-12 ')
    }
  })

  return (
        <div
            className={`${showBox ? 'block' : 'hidden'} w-fit flex flex-col absolute top-0 right-0 rounded-lg ${dropdownClass}bg-gray-100 text-black shadow-lg z-50 duration-100 rounded-lg`}
        >
            <Link to='/AddMoreDetails?mode=edit' className="flex items-center border-b border-app-grey p-4 hover:bg-white rounded-t-lg">
                <div className="bg-white rounded-full">
                    {/* <ImageTag
                        src={authState.profilePicture}
                        className="w-16 rounded-full object-cover aspect-square"
                        alt='profile'
                    /> */}
                    <ProfileIcon authState={authState} className='w-16' />
                </div>
                <div className="ml-2">
                    <TextBody
                        text={authState.firstName + ' ' + authState.lastName}
                        className="text-black font-semibold  "
                    />
                    <TextXs text={authState.email} />
                    <div className="text-app-grey text-xs underline" to={'/'}>
                        Manage account
                    </div>
                </div>
            </Link>
                {authState.userRoleType === '0'
                  ? (
      <ProfilePlan authState ={authState}></ProfilePlan>
                    )
                  : (null)}
            {authState.userRoleType === '0'
              ? (
                    <div className="p-4 border-b border-app-grey hover:bg-white">
                    <Link to={'/subscription'} className="cursor-pointer">
                        Subscription History
                    </Link>
                </div>
                )
              : (null)}
      <div className=" flex flex-row hover:cursor-pointer  hover:bg-[#F4F7F9]" onClick={switchRole}>
      <ImageTag src={arrowReload} className={'mr-1 w-5 ml-4'} />
                <GeneralButton
                    content={`Switch role to ${authState.userRoleType === '1' ? 'Instructor' : 'Student'}`}
                    className="w-full h-full p-4 flex justify-start"
                />
            </div>
      <div className="flex flex-row hover:cursor-pointer hover:bg-white rounded-b-lg" onClick={signOut}>
      <ImageTag src={logout} className={'mr-1 w-5 ml-4'} />
                <GeneralButton
                    content="Sign Out"
                    className="w-full h-full p-4 flex justify-start "
                />
      </div>
        </div>
  )
}

function ProfilePlan ({ authState, showBox }) {
  const navigate = useNavigate()
  return (
    <div
    className="px-4 pt-2 hover:bg-[#F4F7F9]"
    onClick={() => navigate('/pricing', { state: authState })}
>
    <TextBody text={'Subscribed Plan'} />
    {/* <TextXs
        text={'Available quota'}
        className="text-app-grey mb-2"
    /> */}
    <div className="rounded-[3px] text-black text-xs bg-[#E1E1E1] w-fit px-3 py-1">
        {authState.activeSubscription
          ? (
            <TextXs
                text={
                    authState.subscription[
                      authState.subscription.length - 1
                    ].name + ' ( ' + authState.subscription[
                      authState.subscription.length - 1
                    ].planType + ' )'
                }
            />
            )
          : (
            <TextXs text="No plan activated" />
            )}
    </div>
    <div className="border-b border-app-grey w-3/4 pt-2"></div>
</div>
  )
}
