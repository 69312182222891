import React, { useState } from 'react'
import ButtonPrimary from '../_atomic-design/atoms/Button/ButtonPrimary'

const AddInstructions = ({ onAddNote }) => {
  const [noteText, setNoteText] = useState('')
  const [disableAdd, setDisableAdd] = useState(true)

  const handleChange = (event) => {
    setNoteText(event.target.value)
    if (event.target.value.trim() !== '') {
      setDisableAdd(false)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (noteText.trim() !== '') {
      onAddNote({
        text: noteText,
        completed: false
      })
      setNoteText('')
    }
    setDisableAdd(true)
  }

  return (
    <div className='flex flex-row gap-2 justify-center items-center pb-8 px-8 my-4'>
      <div className='w-full'>
        <textarea
          id="message"
          name="message"
          rows="1"
          className="block w-full shadow-sm bg-[#F4F7F9] rounded-lg py-3 px-4 outline-none focus:border focus:border-app-primary"
          placeholder="Type here....."
          value={noteText}
          onChange={handleChange}
        ></textarea>
      </div>
      <div>
        <ButtonPrimary onClick={handleSubmit} text={'Add'} className={'w-max px-5 m-auto text-xs sm:text-sm'} disabled={disableAdd} />

      </div>

    </div>
  )
}

export default AddInstructions
