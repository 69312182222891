import React from 'react'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import searchImg from '../../assets/images/Icons/Search.svg'
import GeneralTextInput from '../../_atomic-design/molecules/Input/GeneralTextInput'
// import TextBody from '../../_atomic-design/atoms/Text/TextBody'
// import GeneralDropdown from '../../_atomic-design/molecules/Input/GeneralDropdown'

export default function FilterBar ({ tags, currentTag, setCurrentTag, statusArr, setCurrentStatus, className, search, setSearch }) {
  return (
      <div className={` ${className} flex flex-col sm:flex-row border-b border-app-grey pb-5`}>
          <div className="flex flex-row mt-5 w-full">
            <ImageTag src={searchImg} className="w-3" />
            {/* <GeneralTextInput className={'ml-4 border-none outline-none w-full border bg-transparent'} value={search} onKeyDown={(e) => { if (e.key === 'Enter') setSearch(e.target.value) }} placeholder={'search'} /> */}
        <GeneralTextInput className={'ml-4 border-none outline-none w-full border bg-transparent'} value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder={'search'} />
          </div>
          {/* <div className="flex flex-1 flex-row flex-wrap justify-start sm:justify-center mt-5">
            {
              tags && tags.map((item, key) => {
                return (
                  <TextBody key={key} text={item} className={` inline-block mx-3 cursor-pointer ${item === currentTag ? 'border-b-app-w-3 border-black' : ''}`} onClick={() => { setCurrentTag(item) }} />
                )
              })
            }
          </div> */}
          {/* <div className="flex justify-start sm:justify-end mt-5">
            {statusArr && <GeneralDropdown label={'Status'} options={statusArr} className="outline-none bg-transparent text-xs" />}
            {statusArr && <GeneralDropdown label={'Status'} options={statusArr} className="outline-none bg-transparent text-xs ml-5" />}
          </div> */}
      </div>
  )
}
