import React, { useState } from 'react'
import ModeSelector from '../_atomic-design/molecules/Others/ModeSelector'
// import PlanCards from './PlanCards'
import GeneralText from '../_atomic-design/atoms/Text/GeneralText'
import HeadingXlg from '../_atomic-design/atoms/Text/HeadingXlg'
import PlanCards from './PlanCards'

function PricePlans () {
  const [mode, setMode] = useState('Monthly')

  const modes = [
    { id: 1, text: 'Monthly', value: 'Monthly' },
    { id: 2, text: 'Annually', value: 'Annually' }
  ]

  return (
        <div
            id="pricing"
            className={'w-full min-h-screen py-10 sm:py-20 bg-[#F4F3FD]'}
        >
            <div className="w-4/5 sm:w-full mx-auto text-center mb-10 sm:mb-10 px-3">
                <HeadingXlg
                    text="Instructor's Paradise: Exclusive Subscription Plan!"
                    className="mb-4 text-app-purple-heading "
                />
                <GeneralText
                    text={
                        <>
                            Whether you are embarking on this platform for
                            personal use, as a freelancer or running a business,
                            our platform has a plan that is catered to your
                            needs!
                        </>
                    }
                    className="sm:text-lg font-sans w-full"
                />
            </div>
            <ModeSelector
                modes={modes}
                currentMode={mode}
                changeMode={setMode}
            />
            {/* <GeneralText text={'Pricing'} className='font-bold text-5xl text-black mb-1 text-center pb-4' /> */}

            <PlanCards selectedPlanType={mode} />
            {(mode === 'Annually')
              ? (
            <ul key={'safsdfsdfdsgf'} className='list-disc text-sm text-center p-4'>*Annual plans are calculated on a monthly basis.</ul>
                )
              : (
                <ul key={'safsdfsdfdsl'} className='list-disc text-lg font-semibold text-center p-4'>*Contact us if you need a special plan.</ul>

                )}
        </div>
  )
}

export default PricePlans
