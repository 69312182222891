import React from 'react'
import crossSrc from '../../assets/images/Icons/cross.svg'
import PaymentSuccessfull from '../../assets/images/PaymentSuccessfull.svg'
import ButtonPrimaryLg from '../../_atomic-design/atoms/Button/ButtonPrimaryLg'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import HeadingLg from '../../_atomic-design/atoms/Text/HeadingLg'
import { useNavigate } from 'react-router-dom'

export default function PaymentSuccessful ({ className, active, setActive }) {
  const navigate = useNavigate()
  const seeInvoice = () => {
    navigate('/subscription')
  }
  const goHome = () => {
    navigate('/')
  }
  if (!active) return null
  return (
    <div className='fixed w-screen h-screen bg-white/20 backdrop-blur-[20px] z-50 flex justify-center items-center' >
        <div className="w-fit md:w-1/2 mx-4 bg-white rounded-[20px] p-8 max-h-screen max-w-xl flex flex-col justify-center items-center " style={{ boxShadow: '0 0 27px rgb(60, 56, 205, 0.15)' }}>
        {
          active && <ImageTag src={crossSrc} onClick={setActive} className='h-5 mb-4 ml-auto cursor-pointer ' />
        }
        <HeadingLg text='Payment Successful' />
        <ImageTag src={PaymentSuccessfull} className='w-full max-w-xs h-auto' />
        <ButtonPrimaryLg className='' text='See Subscription ' onClick={seeInvoice}/>
        <GeneralButton className='text-blue-600 mt-4' content='Go back' onClick={goHome} />
        </div>
    </div>
  )
}
