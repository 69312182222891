import React from 'react'
import FeatureCardCompact from '../../components/FeatureCardCompact'
import Feature1 from '../../assets/images/Icons/Feature1.svg'
import Feature2 from '../../assets/images/Icons/Feature2.svg'
import Feature3 from '../../assets/images/Icons/Feature3.svg'
import Feature4 from '../../assets/images/Icons/Feature4.svg'
import Feature5 from '../../assets/images/Icons/Feature5.svg'
import Feature6 from '../../assets/images/Icons/Feature6.svg'

export default function FeatureList () {
  return <div className='p-2 mx-auto flex flex-col sm:flex-row justify-evenly gap-8'>
  <div className='flex flex-col gap-8'>
  <FeatureCardCompact imgSrc={Feature1} bgColor={'bg-gradient-to-r from-[#EBC3CC] to-[#E4AFBB]'} textHeading={'Intuitive Question Generator'} textBody={'Quiz generator with a simple interface, one-click content upload, and extensive menu options.'}/>
    <FeatureCardCompact imgSrc={Feature2} bgColor={'bg-gradient-to-r from-[#A1B9D5] to-[#82A1C8]'} textHeading={'Customizable Edits'} textBody={'Customise options with an editable field of MCQ questions as well as subjective questions.'}/>
    <FeatureCardCompact imgSrc={Feature3} bgColor={'bg-gradient-to-r from-[#EBC3CC] to-[#E4AFBB]'} textHeading={'Automated Grading'} textBody={'To eliminate bias and human mistake, weighted points or certificates could be automatically given out based on pre-determined answer keys.'}/>
  </div>
  <div className='flex flex-col gap-8'>
    <FeatureCardCompact imgSrc={Feature4} bgColor={'bg-gradient-to-r from-[#A1B9D5] to-[#82A1C8]'} textHeading={'One Click Publication on Every Device'} textBody={'The quiz may be published with only one click and works flawlessly on all devices. automated sharing of quiz results with personalised grading position notifications'}/>
    <FeatureCardCompact imgSrc={Feature5} bgColor={'bg-gradient-to-r from-[#EBC3CC] to-[#E4AFBB]'} textHeading={'Seamless Integration'} textBody={'Integration with multiple learning management systems or marketing platforms with embedding, APIs or webhooks.'}/>
    <FeatureCardCompact imgSrc={Feature6} bgColor={'bg-gradient-to-r  from-[#A1B9D5] to-[#82A1C8]'} textHeading={'Real-time Analytics'} textBody={'Real-time tracking of quiz takers, results, and performance enables both individual and group analysis to aid in the learning process.'}/>
  </div>
  </div>
}
