// import debarka from '../images/debarka.jpg'
import tarini from '../images/tarini.jpg'
import mohit from '../images/mohit2.jpg'

const about1 = ' ilearnkraft.ai s a cutting-edge, all-in-one educational platform that revolutionizes teaching and learning experiences. Designed with an innovative approach, it offers a sophisticated yet user-friendly interface, empowering educators to create, evaluate, and customize assignments seamlessly.learnkraft.ai \'s versatility shines through its multimedia integration, facilitating the inclusion of diverse visual and interactive elements within assignments. This functionality enables educators to craft dynamic, engaging content through videos, images, and audio, enriching the learning experience for students.The platform\'s adaptability is a significant advantage, offering extensive customization options that align with various teaching styles and curriculum requirements. Educators can tailor assignments, quizzes, and evaluations, delivering personalized learning experiences to their students effortlessly.Moreover, learnkraft.ai\'s robust analytics and insights provide educators with comprehensive data on student performance. These valuable insights drive data-informed instruction, enabling educators to identify strengths and areas for improvement, thereby enhancing teaching outcomes.In essence, learnkraft.ai is not just an educational platform; it\'s a dynamic, comprehensive tool designed to empower educators and inspire students on their learning journey. Its innovative features and commitment to ongoing advancement make it an indispensable asset for the modern classroom.'
const about2 = 'learnkraft.ai , an all-in-one educational platform, redefines teaching and learning experiences. With an innovative, user-friendly interface, educators effortlessly create and customize assignments. Multimedia integration empowers dynamic content creation using videos, images, and audio, enhancing student engagement. Customization options align with diverse teaching styles, while robust analytics offer valuable insights for data-informed instruction.In essence, learnkraft.ai is not just an educational platform; it\'s a dynamic, comprehensive tool designed to empower educators and inspire students on their learning journey. Its innovative features and commitment to ongoing advancement make it an indispensable asset for the modern classroom.'
const about3 = 'learnkraft.ai , an all-in-one educational platform, redefines teaching and learning experiences. With an innovative, user-friendly interface, educators effortlessly create and customize assignments. Multimedia integration empowers dynamic content creation using videos, images, and audio, enhancing student engagement. Customization options align with diverse teaching styles, while robust analytics offer valuable insights for data-informed instruction.learnkraft.ai is the dynamic, indispensable tool that empowers educators and inspires students on their learning journey.'
const about4 = '<b>learnkraft.ai</b>, an environment to empower the instructors to minimize administrative burden and maximize educators impact. <br/> <br/> In our integrated learning environment,<b>learnkraft.ai</b>, redefines information interaction. By seamlessly incorporating natural language processing and machine learning, it facilitates instant Q&A generation and auto-evaluation<b> (Qzkraft) </b>, catering to research, troubleshooting, and knowledge expansion.<br/> <br/>Additionally, our multi-lingual chatbot, <b>Biva</b>, transcends language barriers, fostering global communication and access.These tools exemplify the transformative potential of artificial intelligence, enhancing convenience and connectivity within any LMS.'
const mission = 'learnkraft.ai is to revolutionize education by empowering educators and inspiring students. We aim to provide an all-encompassing educational platform that redefines the teaching and learning experience. Through innovative technology, personalized content creation, and robust analytics, we strive to support educators in delivering dynamic, engaging lessons tailored to diverse learning needs. Our mission is to continuously evolve and advance learnkraft.ai, ensuring it remains an indispensable asset for the modern classroom, fostering a love for learning and nurturing the future generation\'s potential.'
const vision = 'At learnkraft.ai , our vision is to be the catalyst for a transformative educational landscape. We envision a future where our platform becomes the cornerstone of modern learning, seamlessly integrating cutting-edge technology with educational pedagogy. Our goal is to foster a dynamic, interactive learning environment that adapts to diverse teaching styles and student needs. We aspire to empower educators with innovative tools that amplify their impact, while inspiring students to explore, engage, and excel in their learning journeys. Through continuous innovation and adaptability, our vision is to shape a brighter, more inclusive future of education.'
const registrationText = 'Experience the Power of learnkraft.ai\'s Comprehensive Educational Suite to Empower Your Teaching with learnkraft.ai: Seamless Question Generation, Online Exams, and Customizable Auto-Evaluation & Grading'
const testimonials = [
  {
    image: tarini,
    paraText: 'learnkraft.ai is very useful product for me and my colleagues. It saves lots of my unutilized efforts and time.',
    location: 'Assistant Professor, IIITD, India',
    name: 'Dr. Tarini Shankar Ghosh',
    bgcolor: '#111111',
    id: 1
  },
  {
    image: mohit,
    paraText: 'learnkraft.ai is very useful product. It reduces manual efforts on quiz creation and value for money.',
    location: 'Sr. Consultant, JIL Information Technology',
    name: 'Dr. Kumar Mohit',
    bgcolor: '#E8F2FF',
    id: 2
  }
]

export { about1, about2, about3, about4, mission, vision, registrationText, testimonials }
