import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  isEmailVerified: false,
  profilePicture: '',
  detailsCompleted: false,
  isLoggedIn: false,
  isAdminLoggedIn: false
}

export const authStudentReducer = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    loginSuccess (state) {
      state.isLoggedIn = true
    },
    logoutSuccess (state) {
      state.isLoggedIn = false
    },
    USER_EMAIL_VERIFICATION_SUCCESS (state) {
      state.isEmailVerified = true
    },
    adminLoginSuccess (state) {
      state.isAdminLoggedIn = true
    },
    adminLogoutSuccess (state) {
      state.isAdminLoggedIn = false
    }
  }
})

export const authStudentActions = authStudentReducer.actions
