import React from 'react'
import ImageTag from '../../atoms/ImageTag'
import cross from '../../../assets/images/Icons/CrossGreenCircular.svg'

export default function ImageViewBox ({ image, className, onRemove }) {
  function onClickHandler () {
    if (onRemove) {
      onRemove()
    }
  }
  return <>
  {image !== ''
    ? <div className={` ${className} relative`}>
        <ImageTag src={image} className={' w-full h-full border rounded-xl overflow-hidden '} />
        <ImageTag src={cross} className={'absolute -top-2 -right-2 cursor-pointer'} onClick={onClickHandler}/>
      </div>
    : <></>}
  </>
}
