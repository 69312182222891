import React from 'react'

export default function ImageCard ({ image, className }) {
  const bgStyle = {
    background: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  }
  return <div className={` ${className} w-[300px] h-[250px] sm:w-[440px] sm:h-[340px]`}>
  <div className='relative'>
  <div className='rounded-tl-3xl rounded-br-3xl overflow-hidden transition-all w-[250px] h-[200px] sm:w-[400px] sm:h-[300px] duration-300 absolute left-4 top-4 hover:left-0 hover:top-0 z-20' style={bgStyle}></div>
    <div className='rounded-tl-3xl rounded-br-3xl overflow-hidden w-[250px] h-[200px] sm:w-[400px] sm:h-[300px] bg-gradient-to-r from-[#4C41CB] to-[#C787DD]  absolute left-6 top-6 z-10 ' ></div>
  </div>
  </div>
}
