/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import AddInstructions from '../../components/AddInstructions'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
// import TickGreenCircular from '../../assets/images/Icons/TickGreenCircular.svg'
import crossCircle from '../../assets/images/Icons/CrossGreenCircular.svg'

export default function RulesAndRegulations ({ setNotes, notes, setRulesError, rulesError }) {
  const handleAddNote = (noteText) => {
    setNotes([...notes, noteText])
  }
  const handleToggleNote = (index) => {
    const updatedNotes = [...notes]
    updatedNotes[index].completed = !updatedNotes[index].completed
    setNotes(updatedNotes)
  }
  const handleDeleteNote = (index) => {
    const updatedNotes = [...notes]
    updatedNotes.splice(index, 1)
    setNotes(updatedNotes)
  }

  useEffect(() => {
    if (notes.length === 0) {
      setRulesError('Please add some Instructions')
    } else {
      setRulesError('')
    }
  }, [notes])

  return (
    <div>
        <div className="">
      {/* <div className="w-full bg-white p-8 rounded-[8px] flex justify-between my-2">
        <TextBody text="Scoring Points for every question" className={'font-medium'} />
        <div className="flex ">
          <GeneralTextInput
            className="w-10 mx-2 text-center outline-none border-b-2 border-app-gradient-setting text-sm"
            id="1"
            type='Number'
            value={ScoringPoints}
            onChange={(e) => {
              setScoringPoints(e.target.value)
            }}
          />
          <TextBody text="points" />
        </div>
      </div> */}

      <div className="w-full bg-white rounded-3xl justify-between my-4 shadow-2xl">
        <div className='bg-violet-100 p-4 px-8 text-app-primary text-lg rounded-t-3xl font-bold'>Instructions</div>
        {rulesError && <p className='text-app-red ml-auto w-max mr-4'>{rulesError}</p>}
        <div className=''>
      <h1></h1>
      <NoteList notes={notes} toggleNote={handleToggleNote} deleteNote={handleDeleteNote} />
      <AddInstructions onAddNote={handleAddNote} />
    </div>
  </div>
  </div>
  </div>
  )
}
const NoteList = ({ notes, toggleNote, deleteNote }) => {
  return (
    <>
      {notes.map((note, index) => (
        <div className=" flex flex-row justify-between gap-2 px-0 p-2 mx-8 border-b" key={index}>
          <div className='flex flex-row gap-2  '>
          <TextBody text={index + 1 + '. ' + note.text} className={'font-medium'} />
          </div>
          <ImageTag
                                                src={crossCircle}
                                                onClick={() => deleteNote(index)}
                                                className="w-4 sm:w-5 mr-2"
                                            />
        </div>
      ))}
    </>
  )
}
