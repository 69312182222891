/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import MainBackgroundSrc from '../../assets/images/MainBackground.svg'
import HomeNavbar from '../../_atomic-design/molecules/Navbars/HomeNavbar'
import PrivacyNPolicyModal from '../../containers/Modals/PrivacyNPolicyModal'
import { makeServerRequest } from '../../utils/helpers'
import ButtonPrimary from '../../_atomic-design/atoms/Button/ButtonPrimary'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify'
import TextInputGray from '../../_atomic-design/molecules/Input/TextInputGray'
import { MIN_MESSAGE_LENGTH } from '../../config/config'
import TrialRibbon from '../../_atomic-design/molecules/Others/TrialRibbon'
import { useNavigate } from 'react-router-dom'

export default function ContactUs () {
  const navigate = useNavigate()
  const [value, setValue] = useState()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState()
  const [message, setMessage] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(false)
  const [agree, setAgree] = useState(false)
  const [showPrivacyNPolicy, setShowPrivacyNPolicy] = React.useState(false)
  const minMessageLength = MIN_MESSAGE_LENGTH || 50
  const [Error, setError] = useState({ type: null, message: '' })

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email)
  }

  const checkboxHandler = () => {
    if (!checkError()) {
      return
    }
    setAgree(!agree)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const phoneNumber = phone || 0
    const Email = email || 'null@g.com'

    // if ((phoneNumber === 0) && (Email === 'null@g.com')) {
    //   setAgree(!agree)
    //   toast('Phone or Email required')
    //   return
    // }
    // if (name === '') {
    //   setAgree(!agree)
    //   toast('Name field is empty.')
    //   return
    // }
    // if (message.length < minMessageLength) {
    //   toast(`Please enter at least ${minMessageLength} Characters.`)
    //   return
    // }
    if (!checkError()) {
      return
    }
    setError({ type: null, message: '' })
    const res = await makeServerRequest('/support/', 'POST', {
      name,
      email: Email,
      phoneNumber: phoneNumber,
      message
    })
    if (res.status === 200) {
      toast('Your message has been sent successfully!')
      setTimeout(function () {
        navigate(0)
      }, 3000)
    } else {
      toast('Something went wrong. Please try again later.')
    }
    setDisabled(false)
  }

  function checkError () {
    const phoneNumber = phone || 0
    const Email = email || 'null@g.com'
    if (name === '') {
      setError({ type: 'name', message: 'Please enter name.' })
      return false
    }
    if ((phoneNumber === 0) && (Email === 'null@g.com')) {
      setError({ type: 'email', message: 'Phone or Email required' })
      return false
    }
    if ((Email !== 'null@g.com') && (!isValidEmail(Email))) {
      setError({ type: 'email', message: 'Please provide a valid email address' })
      return false
    }
    if (message.length < minMessageLength) {
      setError({ type: 'message', message: `Please enter at least ${minMessageLength} Characters.` })
      return false
    }
    setError('')
    return true
  }

  const bgStyle = {
    backgroundImage: `url(${MainBackgroundSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
  useEffect(() => {
    document.title = 'ContactUs'
  }, [])

  return (<>
  <TrialRibbon/>
        <PrivacyNPolicyModal active={showPrivacyNPolicy} setActive={() => setShowPrivacyNPolicy(!showPrivacyNPolicy)} />
        <div
            className="w-full min-h-screen flex flex-col md:flex-row justify-center items-center"
            style={bgStyle}
        >
            <HomeNavbar selected='/ContactUs' white />
            <div className="flex mt-20 flex-col md:flex-row items-stretch    justify-center gap-8  text-xs sm:text-sm md:text-base rounded-3xl sm:p-10">

                <div className="bg-white rounded-lg shadow-lg shadow-app-primary">
                    <div className="max-w-lg mx-auto p-8 pt-4">
                        <h2 className=" mx-auto text-center font-extrabold tracking-tight">
                            Contact Us
                        </h2>
                        <dl className="mt-8 text-gray-500 ">
                            <div className="">
                                {/* <dt className="text-black font-bold mb-3">
                                        Our Head Office
                                    </dt> */}
                                <dd>
                                    <p>
                                        EDTECH KRAFT PRIVATE LIMITED
                                    </p>
                                    <p>
                                        Room No 502, IIITD Incubation
                                        Center,
                                    </p>
                                    <p>
                                        Indraprastha Institute of
                                        Information Technology
                                    </p>
                                    <p>Delhi, Okhla Phase-3,</p>
                                    <p>New Delhi 110020</p>
                                </dd>
                            </div>
                            <div className="mt-3">
                                <dt className="sr-only">Email</dt>
                                <dd className="flex">
                                    <svg
                                        className="flex-shrink-0 h-6 w-6 text-gray-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                        />
                                    </svg>
                                    <span className="ml-3">
                                        {' '}
                                        <a href="mailto:contact@dkraftlearning.com" className="text-blue-600 underline" > contact@dkraftlearning.com </a>{' '}
                                    </span>
                                </dd>
                            </div>
                            <div className="my-6 ">
                                <dt className="text-black font-bold mb-3 flex flex-col justify-center items-center ">
                                    Our Head Office
                                </dt>
                                <dd>
                                    <p>d.kraft Pte Ltd,</p>
                                    <p>8 Eu Tong Sen St,</p>
                                    <p>#14-94 The Central,</p>
                                    <p>Singapore 059818</p>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div className="flex flex-col h-full justify-center items-center p-2 w-full px-4 bg-white rounded-xl shadow-lg shadow-app-primary">
                    <form
                        className="flex flex-col justify-center items-center w-full"
                        onSubmit={handleSubmit}
                    >
                        <h2 className="font-extrabold tracking-tight text-gray-900 pt-4">
                            Send your query
                        </h2>
                        <TextInputGray
                            className={' w-full '}
                            defaultValue={''}
                            placeholder={'Full name'}
                            label={'Full name'}
                            id={'full-name'}
                            onChange={(e) => setName(e.target.value)}
                        />
                        { Error.type === 'name' && <div className='w-full text-app-red'>{Error.message}</div>}
                        <TextInputGray
                            className={'w-full '}
                            defaultValue={''}
                            placeholder={'@mail.com'}
                            label={'Email Address'}
                            id={'email'}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        { Error.type === 'email' && <div className='w-full text-app-red'>{Error.message}</div>}
                        <div className="w-full ">
                            <label htmlFor="phone" className="">
                                Phone
                            </label>
                            <div className=''>
                                <PhoneInput
                                    placeholder="Phone"
                                    defaultCountry="IN"
                                    limitMaxLength={true}
                                    value={phone}
                                    className=" focus:border-blue-600 bg-[#F4F7F9] rounded-lg p-2 h-8"
                                    onChange={setPhone} />
                            </div>
                        </div>
                        <div className="w-full ">
                            <label
                                htmlFor="message"
                                className=""
                            >
                                Message
                            </label>
                            <textarea
                                id="message"
                                name="message"
                                rows="4"
                                className="block shadow-sm bg-[#F4F7F9] rounded-lg py-3 px-4 outline-none focus:border-2 focus:border-blue-600 border w-full"
                                placeholder="Message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                        </div>
                        { Error.type === 'message' && <div className='w-full text-app-red'>{Error.message}</div>}
                        <div className='mx-auto p-4'>
                            <input type="checkbox" id="agree" checked={agree} onChange={checkboxHandler} className='mr-1' />
                            <label > I agree to <b className='cursor-pointer text-blue-500 ' onClick={() => setShowPrivacyNPolicy(!showPrivacyNPolicy)} >Privacy Policy</b></label>
                        </div>
                        <div className='w-full'>
                            <ButtonPrimary className='mx-auto transition-colors duration-300  ' text="Submit" disabled={!agree} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
  )
}
