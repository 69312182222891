import React, { useEffect, useRef, useState } from 'react'
// import ButtonPrimarySm from '../_atomic-design/atoms/Button/ButtonPrimarySm'
import GeneralButton from '../../atoms/Button/GeneralButton'
import ImageTag from '../../atoms/ImageTag'
import NavIcon from '../../../assets/images/Icons/NavIcon.svg'
import NavIconPhone from '../../../assets/images/Icons/NavIconPhone.svg'
import LogoIcon from '../../../assets/images/Icons/learnkraft.svg'
import CrossIcon from '../../../assets/images/Icons/cross.svg'
import { useSelector } from 'react-redux'
import ProfileComponent from '../ProfileComponent'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { getCookies } from '../../../utils/cookieStorage'

// import { Link } from 'react-router-dom'

export default function AboutUsNavbar ({ selected, white }) {
  const authState = useSelector((state) => state.authReducer)
  const navRef = useRef(null)
  const navListRef = useRef(null)
  const navParentRef = useRef(null)
  const [isNavbarOpen, setIsNavbarOpen] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [active, setActive] = useState(selected || 'Home')
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (isNavbarOpen || navRef.current === null) {
        return
      }
      if (window.scrollY > 10) {
        navRef.current.style.backgroundColor = '#fff'
        navListRef.current.style.color = 'black'
      } else {
        navRef.current.style.backgroundColor = white ? '#fff' : '#fff'
        navListRef.current.style.color = white ? 'black' : 'black'
      }
    })
    navRef.current.style.backgroundColor = white ? '#fff' : '#fff'
    navListRef.current.style.color = white ? 'black' : 'black'

    return () => {
      window.removeEventListener('scroll', navRef)
    }
  }, [])

  // eslint-disable-next-line no-unused-vars
  const changeNavState = () => {
    const currentMaxHeight = navListRef.current.style.maxHeight
    if (currentMaxHeight === '0px' || currentMaxHeight === '') {
      navListRef.current.style.maxHeight = '500px'
      navParentRef.current.style.minHeight = '100px'
      navParentRef.current.style.backdropFilter = 'blur(0px)'
      navRef.current.style.backgroundColor = '#fff'
      navListRef.current.style.color = 'black'
      setIsNavbarOpen(true)
    } else {
      navListRef.current.style.maxHeight = '0px'
      navParentRef.current.style.minHeight = '0'
      navParentRef.current.style.backdropFilter = 'blur(0px)'
      navRef.current.style.backgroundColor = '#fff'
      navListRef.current.style.color = 'black'
      setIsNavbarOpen(false)
    }
  }

  useEffect(() => {
    if (authState && authState.isLoggedIn) {
      if (!authState.isEmailVerified) {
        setLoggedIn(false)
        return <Navigate to='/SendVerificationMail' state={{ redirectBack: location }} replace />
      } else {
        setLoggedIn(true)
      }

      const generatedLastQuizId = getCookies().lastQuizId
        ? getCookies().lastQuizId
        : null
      if (generatedLastQuizId) {
        navigate(`/EditQuiz?quizId=${generatedLastQuizId}`, { replace: true })
      }
    } else {
      setLoggedIn(false)
    }
  }, [authState])

  const pageSectionLinks = [
    { name: 'Home', to: '/#', protected: false, id: 'home_ride' },
    { name: 'Features', to: '/#features', protected: false, id: 'features_ride' },
    { name: 'Pricing', to: (loggedIn ? '/pricing' : '/#pricing'), protected: false, id: 'pricing_ride' },
    { name: 'FAQ', to: '/#faq', protected: false, id: 'faq_ride' },
    { name: 'Contact Us', to: '/ContactUs', protected: false, id: 'contactus_ride' },
    { name: 'About Us', to: '/about-us', protected: false, id: 'aboutus_ride' },
    { name: 'Dashboard', to: '/Dashboard', protected: true, id: 'dashboard_ride' }
  ]

  return (
        <div
            ref={navParentRef}
            className="fixed top-0 left-0 md:h-20 mb-8 flex flex-col justify-start w-full z-50 transition-opacity duration-500"
        >
        {/* <Testimonials /> */}
            <div
                ref={navRef}
                className="flex flex-col md:flex-row md:justify-between md:items-center mx-auto w-full px-4 md:px-10 duration-500 md:py-2 "
            >
                <div key="fsdfsd" className="flex justify-between items-center py-3">
                    <Link to={'/'} className="md:text-3xl font-bold text-[#3C38CD] ">
                        <img className="w-32" src={LogoIcon} alt="LOGO" />
                    </Link>
                    <div className="md:hidden m-3">
                        {isNavbarOpen
                          ? (
                            <ImageTag
                                src={CrossIcon}
                                alt="cross"
                                className="w-5 cursor-pointer "
                                onClick={changeNavState}
                            />
                            )
                          : (
                            <>
                              <ImageTag
                                  src={NavIcon}
                                  alt="nav"
                                  className="w-5 hidden md:block cursor-pointer"
                                  onClick={changeNavState}
                              />
                              <ImageTag
                                  src={NavIconPhone}
                                  alt="nav"
                                  className="w-5 md:hidden cursor-pointer"
                                  onClick={changeNavState}
                              />
                            </>
                            )}
                    </div>
                </div>
                <div
                    ref={navListRef}
                    className={
                        'max-h-0 md:max-h-28 flex flex-col items-start md:flex-row md:items-center md:justify-end overflow-hidden md:overflow-visible md:gap-x-6 lg:gap-x-10 duration-500 text-black md:text-white'
                    }
                >
                    {pageSectionLinks.map((item, key) => {
                      return (
                        !item.protected || item.protected === loggedIn
                          ? (<div key = {key}>
                              <NavLink
                                name={item.name}
                                to={item.to}
                                rideId={item.id}
                                active={active}
                                setActive={setActive}
                                changeNavState={changeNavState}
                            />
                            </div>
                            )
                          : null
                      )
                    })}
                    {!loggedIn
                      ? (
                        <Link to="/Auth?mode=login" className='min-w-full md:min-w-[106px]' id={'nav_login'}>
                            <GeneralButton
                                content={'Login'}
                                className=" bg-app-primary text-white h-9 rounded-[5px] min-w-full py-3 flex items-center justify-center mb-4 md:mb-0"
                            />
                        </Link>
                        )
                      : (
                        <ProfileComponent />
                        )}
                </div>
            </div>
        </div>
  )
}

function NavLink ({ active, setActive, name, to, changeNavState, rideId }) {
  const onClick = () => {
    setActive(to)
    changeNavState()
  }
  return (
        <>
            <HashLink id ={rideId} className={` cursor-pointer py-3 w-max  ${active === to ? 'font-bold' : ''} `} to={to} smooth onClick={onClick} >
                {name}
            </HashLink>
        </>
  )
}
