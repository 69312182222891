import React from 'react'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import ProfileAddIcon from '../../assets/images/Icons/ProfileAdd.svg'
import HiddenInput from '../../_atomic-design/atoms/Home/HiddenInput'
import { fileUploadRequest } from '../../utils/helpers'

export default React.memo(function AddProfilePicture ({ profilePicture, setprofilePicture }) {
  const uploadFiles = async (file) => {
    // setDisabled(true)
    const formData = new FormData()
    formData.append('image', file)
    const res = await fileUploadRequest('/upload/profile-photo', formData)
    setprofilePicture(res.data.url)

    // setDisabled(false)
  }
  const onFileDrop = async (e) => {
    uploadFiles(e.target.files[0])
  }

  return (
        <div className="relative">
            <div className="relative bg-app-blue-2xlight rounded-lg overflow-hidden w-28 h-28 flex justify-center items-center mb-4 mt-2 hover:opacity-80 transition-opacity duration-400">
                <ImageTag
                    src={profilePicture || ProfileAddIcon}
                    className={profilePicture ? 'w-full object-cover aspect-square' : 'p-4'}
                />
                <HiddenInput
                    onChange={onFileDrop}
                    acceptFileType="image/png, image/jpeg"
                />
            </div>
        </div>
  )
})
