import React from 'react'
import DisplayTag from '../../_atomic-design/molecules/Others/DisplayTag'
// import TickSrc from '../../assets/images/Icons/Tick.svg'
// import Paper from '../../assets/images/Icons/Paper.svg'
import CalendarSrc from '../../assets/images/Icons/Calendar.svg'
import AverageScoreIcon from '../../assets/images/Icons/score.svg'
// import CommentSrc from '../../assets/images/Icons/Comment.svg'
import { TiWarning } from 'react-icons/ti'
// import TimeSrc from '../../assets/images/Icons/Time.svg'
// import TwoUserSrc from '../../assets/images/Icons/TwoUser.svg'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
import { useNavigate } from 'react-router-dom'
import GeneralButton from '../../_atomic-design/atoms/Button/GeneralButton'
import { makeAuthenticatedServerRequest, makeServerRequest } from '../../utils/helpers'
import DeleteQuestionIcon from '../../assets/images/Icons/DeleteQuestion.svg'
import ButtonIconOnly from '../../_atomic-design/atoms/Button/ButtonIconOnly'
import copyIcon from '../../assets/images/Icons/Copy.svg'
import TooltipLeft from '../../_atomic-design/molecules/Others/TooltipLeft'

export default function QuizCard ({ data }) {
  console.log('data123456', data.quizError ? data.quizError : 'nodata', data.anyFileError)
  const navigate = useNavigate()
  // const score = data.questions && data.questions.reduce((item, acc) => item.score + acc, 0)
  function clickHandler () {
    if (data.published) {
      navigate(`/PublishedQuizOverview?quizId=${data._id}`)
    } else {
      navigate(`/EditQuiz?quizId=${data._id}`)
    }
  }

  async function duplicateQuiz (e) {
    e.stopPropagation()
    const confirmation = window.confirm('Are you sure you want to generate duplicate of this quiz?')
    if (!confirmation) {
      return
    }
    const res = await makeServerRequest('/quiz/duplicateQuiz', 'post', { quizId: data._id })
    if (res.status === 200) {
      alert('Successfull')
      window.location.reload()
    } else {
      alert('Error')
    }
  }

  async function deleteQuiz () {
    const confirmation = window.confirm('Are you sure you want to delete this quiz? This will delete the quiz forever.')
    if (!confirmation) {
      return
    }
    const res = await makeAuthenticatedServerRequest('/quiz', 'delete', { quizId: data._id })
    if (res.status === 200) {
      alert('Deleted Successfully')
      window.location.reload()
    } else {
      alert('Error in deleting !!')
    }
  }

  return (
        <div className=" flex flex-row w-full h-fit max-w-7xl mb-4 p-4 justify-center items-center  sm:items-start rounded-[10px] bg-white hover:bg-[#F9E1FF] text-xs sm:text-sm md:text-base shadow-md transition-all duration-300" >
      <div className={'flex flex-col cursor-pointer w-full'} onClick={clickHandler}>
        {/* <div className="">
                {data.published ? <DisplayTag src={TickSrc} text={'Published'} rounded dark /> : <DisplayTag src={Paper} text={'Draft'} rounded/> }
            </div> */}
            <div className=" flex justify-between sm:justify-start gap-2 items-center mb-1 sm:items-start">
                <GeneralText text={data.name} className='text-lg font-medium' />
                <PublishStatus isPublished={data.published} className='' />
            </div>
            <div className='flex justify-between'>
            <div className="flex flex-col sm:flex-row sm:space-x-4">
                <DisplayTag
                    text={'Score: ' + data.totalScore}
                    src={AverageScoreIcon}
                />
                {/* <DisplayTag src={TwoUserSrc} text={data.attempted} rounded /> */}
                <DisplayTag src={CalendarSrc} text={data.date} />
                {/* <DisplayTag src={TimeSrc} text={data.duration} rounded /> */}
            </div>
            <div className='flex flex-col gap-2 my-auto w-10'>
            <ButtonIconOnly onClick={duplicateQuiz} image={copyIcon} className={'w-8'} tooltipText={'Duplicate this quiz'} />
        {data.anyFileError
          ? (
                    <TooltipLeft content={
                      data.questions.length > 0 ? (<TiWarning className={'text-yellow-400 w-6 h-6'}/>) : (<TiWarning className={'text-red-500 w-6 h-6'}/>)
                    }
                    className={''}
                    message={Object.values(data.quizError ? data.quizError : {}).join(',')}
                        />
            )
          : null}

        </div>
            </div>
      </div>
      <ButtonIconOnly onClick={deleteQuiz} image={DeleteQuestionIcon} className={'w-10 my-auto hidden'} hidden={data.published} disabled={data.published} />
       </div>
  )
}

function PublishStatus ({ isPublished, className }) {
  return (
    <GeneralButton content={isPublished ? 'Published' : 'Draft'} className={`${className} ${isPublished ? 'bg-[#D1F6D5]  text-[#009329]' : 'bg-[#FFF2AB] text-[#907001]'} w-max px-4 py-1 sm:py-0 my-auto text-xs sm:text-sm font-normal rounded `} />
  )
}
