import React from 'react'
import ButtonPrimaryLg from '../../_atomic-design/atoms/Button/ButtonPrimaryLg'
import HeadingLg from '../../_atomic-design/atoms/Text/HeadingLg'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'

export default function TermsNConditionsModal ({ active, setActive }) {
  if (!active) return null
  return (
        <div className="fixed top-0 left-0 w-screen h-screen text-base bg-white/20 backdrop-blur-[20px] z-50 flex justify-center items-center text-left ">
            <div className="w-fit md:w-1/2 mx-4 bg-white rounded-[20px] p-8 max-h-screen h-[80%] min-h-[90%] max-w-xl flex flex-col justify-center items-center " style={{ boxShadow: '0 0 27px rgb(60, 56, 205, 0.15)' }}>
                <HeadingLg className="text-center mb-5" text="Terms and Condition" />

                <div className="flex-1 overflow-y-scroll">
                    <TextBody
                        text="By visiting learnkraft.ai, you agree to indemnify, defend, and hold harmless learnkraft.ai,
                              Edtech Kraft, d.kraft learning, and its owner from and against any and all damages, claims,
                              fees, losses, expenses, demands of liability, mental or emotional distress, or other negative
                              circumstances that you may incur or encounter while visiting learnkraft.ai."
                    />
                    <TextBody
                        text="You acknowledge that quizzes and other content are not screened by a human before
                              appearing live on learnkraft.ai, however, automated techniques are employed to ensure
                              quality and rule compliance. Human review of the posted content is carried out routinely as
                              an added measure. Please report any content that you find objectionable. Content submitted
                              by users does not necessarily reflect the views or values of learnkraft.ai, and you should not
                              assume that learnkraft.ai or our advertisers endorse any user-submitted content."
                    />
                    <br />

                    <TextBody text='When creating a quiz or other content at learnkraft.ai, you are not to incorporate any of the following:' />
                    <ul className='list-disc list-inside ml-5' >
                      <li>Racist, sexist, or hateful speech</li>
                      <li>Sexual, &quot;R-rated&quot;, or &quot;mature audience&quot; content</li>
                      <li>Excessive profanity</li>
                      <li>Violent content</li>
                      <li>Promotion of illegal activities or substances</li>
                      <li>Libel, copyrighted material, or other unlawful content</li>
                      <li>Hurtful, threatening, malicious, or abusive content</li>
                    </ul>
                    <TextBody text='All content must be your own original creation. You acknowledge and accept that you bear
                                    sole responsibility for the legality of any content you submit.' />
                    <TextBody text='Failure to abide by these rules will result in your quiz or other content being deleted. Edtech
                                    Kraft reserves the right to remove any content that does not meet our standards, at any time
                                    and at our personal discretion, to protect the quality and character of this site. Edtech Kraft
                                    actively monitors for improper content. Even if your quiz does comply with these terms, no
                                    guarantees are made that any quiz you create will be hosted for any specified length of time,
                                    and in case, you have created any quiz or content using these terms, you will be solely
                                    responsible for using these terms and Edtech Kraft can remove your content any time without
                                    notifying you.' />
                    <TextBody text='Use of learnkraft.ai is not permitted for minors under the age of 12.' />
                    <TextBody text='By creating a quiz at learnkraft.ai, you give learnkraft.ai an unlimited free license to host that
                                    quiz in the manner that we see fit. Any and all profits derived from that quiz belong solely to
                                    learnkraft.ai.' />
                    <TextBody text='learnkraft.ai reserves the right to modify, update, or otherwise change the terms found in this
                                    document at any time and for any reason. You understand that it is your responsibility to
                                    check this document for any changes that may occur. If you continue to visit learnkraft.ai
                                    after changes to this document have been made, it means you have accepted the new terms.' />
                    <TextBody text='learnkraft.ai is bound by the laws of India and the state of Kerala. You agree to file, address,
                                    or settle any legal matters within the state of Kerela and not any other state.' />
                    <TextBody text='Failure by learnkraft.ai to enforce any provision in this document shall not be construed as a
                                    waiver of any provision or right. If any provision contained in this document is found to be
                                    invalid or unenforceable, the remaining provisions shall remain in full force and effect and
                                    will continue to be enforced to the fullest extent possible. The terms listed in this document
                                    constitute the entire agreement between you and Edtech Kraft and supersede any and all prior
                                    agreements or understandings.' />
                    <br />
                    <TextBody text='EVERYTHING FOUND AT learnkraft.ai IS PRESENTED &quot;AS IS&quot;. learnkraft.ai
                                    MAKES NO WARRANTIES ON ANY SERVICES OR CONTENT FOUND ON THIS
                                    WEBSITE. YOUR USE OF learnkraft.ai IS AT YOUR OWN RISK.' />
                    <br />
                    <TextBody className='font-bold' text='Copyright Policy:' />
                    <TextBody text='Users submit content to learnkraft.ai daily. Posting content that the user does not have
                                    legal rights to is against our rules. learnkraft.ai complies with the Digital Millennium
                                    Copyright Act (DMCA) and responds promptly to takedown notices. To file such a notice,
                                    please see our contact information page. Resolving matters of copyright infringement will be
                                    treated with top priority.' />
                    <br />
                    <TextBody className='font-bold' text='Image Attribution:' />
                    <TextBody text='Several mini-icons used on learnkraft.ai are open-source and collected from Google,
                                    Facebook, and LinkedIn.' />
                    <br />
                    <TextBody className='font-bold' text='Contact Us:' />
                    <TextBody text='Thank you for your interest in learnkraft.ai. Your correspondence is important! All
                                    suggestions, questions, advertising inquiries, and complaints are welcome. The preferred
                                    method of contact is via e-mail.' />
                    <TextBody text={<>You can reach out to us at the following e-mail address: <a className='text-blue-500 underline' href="mailto:contact@dkraftlearning.com" >contact@dkraftlearning.com</a> </>} />
                    <TextBody text='All legal correspondence should be sent to the following address:
                                    *** No solicitations to this address ***' />
                    <TextBody className='text-center' text={<>EDTECH KRAFT PRIVATE LIMITED
                                    <br /> Kuzhimattathil Chambers,
                                    <br /> Bld No. VI/726 B2, Kochi,
                                    <br /> Kozhikode, Kerala, India, 682021</>} />
                    <TextBody text='Edtech Kraft complies with the Digital Millennium Copyright Act (DMCA). Send any
                                    DMCA takedown notice to the above address, and action will be promptly taken.' />
                    <br />
                    <TextBody text='Thank you for using learnkraft.ai' />

                    <TextBody className='font-bold' text='Subscription Fees (applicable to the paid version):' />
                    <TextBody text='In consideration of the Services provided under this Agreement by Edtech Kraft, you agree to
                                    pay us the Subscription fees (&quot;Subscription Fees&quot;) in the amount and for the Subscription
                                    Term, you have opted for and agreed to, on our website. Unless you terminate your
                                    subscription any time before the end of your billing cycle, you authorize us to charge you the
                                    Subscription Fees for the next billing cycle, at the then applicable rates. Subscription Fees
                                    and other prices quoted are exclusive of applicable taxes. You shall pay all applicable taxes,
                                    duties, levies, and other similar charges (and any related interest and penalties) imposed,
                                    however, designated as a result of the existence or operation of this EULA (except for taxes
                                    on Edtech Kraft’s net income).' />
                    <TextBody text='In the event of non-payment of the Subscription Fees-' />
                    <ul className='list-disc list-inside ml-5'>
                      <li>We may at our discretion suspend your subscription and intimate you about the same.
                      You may at your option, choose to have the subscription reinstated subject to the
                      payment of the Subscription Fees. OR</li>
                      <li>We may at our discretion choose to terminate your subscription, in which case, we
                      shall notify you about the same in writing.
                      In the event of termination of your subscription, we reserve our right to delete all the
                      User Content, at our sole discretion.</li>
                    </ul>
                    <TextBody text='If you wish to unsubscribe for the Services, you may do so during the currency of the plan. In
                                    case you unsubscribe from the Services, Edtech Kraft will NOT refund the Subscription Fees
                                    paid by you as the subscription features shall remain active till the end of the Subscription
                                    Term.' />

                </div>
                <ButtonPrimaryLg className='mt-6' text='Close' onClick={setActive} />
            </div>
        </div>
  )
}
