import React, { useState } from 'react'
import ProfileAddIcon from '../../assets/images/Icons/ProfileAdd.svg'
import { fileUploadRequest } from '../../utils/helpers'
import ReactLoading from 'react-loading'
import ImageInput from '../../_atomic-design/atoms/Home/ImageInput'
import ImageTag from '../../_atomic-design/atoms/ImageTag'

export default function AddQuizPicture ({ quizPicture, setQuizPicture, handleQuestionPictureChange, uploadenable }) {
  const [loading, setLoading] = useState(false)
  const uploadFiles = async (file) => {
    // setDisabled(true)
    const formData = new FormData()
    formData.append('image', file)
    const res = await fileUploadRequest('/upload/profile-photo', formData)
    console.log(res)
    setQuizPicture(res.data.url)
    handleQuestionPictureChange(res.data.url)
    // setDisabled(false)
  }
  // const [zoomLevel, setZoomLevel] = useState(1)

  // const handleZoomIn = () => {
  //   setZoomLevel(prevZoom => Math.min(prevZoom + 0.1, 3)) // Limit the maximum zoom level to 3x
  // }

  // const handleZoomOut = () => {
  //   setZoomLevel(prevZoom => Math.max(prevZoom - 0.1, 1)) // Limit the minimum zoom level to 1x
  // }
  const onFileDrop = async (e) => {
    setLoading(true)
    await uploadFiles(e.target.files[0])
    setLoading(false)
  }

  return (
    <div className=" ">
      <div className=" rounded-lg flex justify-center items-center">
      {loading
        ? (
          <ReactLoading type="spin" color="#916AF8" height={40} width={35} />
          )
        : (<div className="relative rounded-lg overflow-hidden flex justify-center items-center">
        <ImageTag
            src={quizPicture || ProfileAddIcon}
            className={quizPicture ? 'object-cover' : 'p-4'}
        />
        {uploadenable
          ? (
          <ImageInput
            onChange={onFileDrop}
            acceptFileType="image/png, image/jpeg"
        />
            )
          : null}
    </div>)}
        </div>

        </div>
  )
}
