import React, { useState } from 'react'
import ButtonPrimaryLg from '../../_atomic-design/atoms/Button/ButtonPrimaryLg'
import HeadingLg from '../../_atomic-design/atoms/Text/HeadingLg'
import Timer from '../../components/Timer'
import HeadingSm from '../../_atomic-design/atoms/Text/HeadingSm'

export default function RequestFullScreenModal ({ timeOut, textMain, onProceed, onTimeoutExpiration }) {
  const [time, setTime] = useState(timeOut)

  return (<div className="fixed top-0 left-0 w-screen h-screen text-base bg-white/20 backdrop-blur-[20px] z-40 flex justify-center items-center text-left ">
    <div className="w-fit md:w-1/2 mx-4 bg-white rounded-[20px] p-8 max-h-screen h-max max-w-xl flex flex-col justify-center items-center " style={{ boxShadow: '0 0 27px rgb(60, 56, 205, 0.15)' }}>
    <HeadingLg className="text-center mb-5" text={textMain} />
      <div className=''>
        <Timer time={time} setTime={setTime} onTimeUp={onTimeoutExpiration} />
      </div>
      <HeadingSm className="text-center mt-6 mb-2" text="Please enter Full Screen to resume test." />
      <ButtonPrimaryLg className='' text='Enter Full Screen' onClick={onProceed} />
    </div>
    </div>
  )
}
