import React, { useState } from 'react'
import { enterFullScreen } from '../../utils/fullScreen'
import { detectWindowResize } from '../../utils/detectWindowResize'
// import PopUp from '../General/PopUp'
import RequestFullScreenModal from '../Modals/RequestFullScreenModal'

/* This function attempts to set the browser window to full screen mode
  during quiz in progress.
  Timeout can be set to trigger a task based on timeout expiration.
  Currently nothing happens on timeout expiration. */
export default function EnforceFullScreen ({ quizInProgress, onTimeoutExpiration }) {
  const [isFullScreen, setIsFullScreen] = useState(quizInProgress)
  const timeOut = { minutes: 0, seconds: 10 }
  detectWindowResize({ isFullScreen, setIsFullScreen })

  function toggleFullScreen () {
    enterFullScreen()
  }
  const warningText = 'The quiz will be auto submitted on timer expiration.'
  return <div>
    {!isFullScreen && <RequestFullScreenModal textMain={warningText} onProceed={toggleFullScreen} timeOut={timeOut} onTimeoutExpiration={onTimeoutExpiration} />}
    </div>
}
