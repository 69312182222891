import React, { useEffect } from 'react'
import TimerMock from './TimerCard'

function Timer (props) {
  // const [time, setTime] = useState({ minutes: 10, seconds: 59 });
  const time = props.time
  const setTime = props.setTime

  function myNewFn (myInterval) {
    if (time.seconds > 0) {
      setTime({ minutes: time.minutes, seconds: time.seconds - 1 })
    }
    if (time.seconds === 0) {
      if (time.minutes === 0) {
        clearInterval(myInterval)
        if (props.onTimeUp) {
          props.onTimeUp()
        } else {
          console.log('onTimeUp: 404')
        }
      } else {
        setTime({ minutes: time.minutes - 1, seconds: 59 })
      }
    }
  }

  useEffect(() => {
    const myInterval = setInterval(() => { myNewFn(myInterval) }, 1000)
    return () => clearInterval(myInterval)
  }, [time])

  return <TimerMock time={time}/>
}

export default Timer
