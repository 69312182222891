import React, { useState } from 'react'
// import Activity from '../../assets/images/Icons/Activity.svg'
// import totalScore from '../../assets/images/Icons/totalScore.svg'
import MarksGraph from '../../assets/images/Icons/MarksGraph.svg'
// import Deadline from '../../assets/images/Icons/Deadline.svg'
// import Duration from '../../assets/images/Icons/Duration.svg'
// import Download from '../../assets/images/Icons/Download.svg'
import Import from '../../assets/images/Icons/DownloadWhite.svg'
// import DownloadTransparent from '../../assets/images/Icons/DownloadTransparent.svg'
// import Profile from '../../assets/images/Icons/Profile.svg'
// import TwoUser from '../../assets/images/Icons/TwoUser.svg'
import Calendar from '../../assets/images/Icons/Calendar.svg'
import Time from '../../assets/images/Icons/Time1.svg'
import { toast } from 'react-toastify'
import { makeServerGETRequest } from '../../utils/helpers'
import ReactLoading from 'react-loading'
import HeadingMd from '../../_atomic-design/atoms/Text/HeadingMd'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
// import { makeAuthenticatedServerRequest } from '../../utils/helpers'

export default function LeftBar ({ className, quiz, studentQuiz }) {
  const [loading, setLoading] = useState(false)
  const [rubricloading, setRubricloading] = useState(false)
  const [questionloading, setQuestionloading] = useState(false)
  const date = new Date(quiz.settings.endDate)

  const createCSV = (headers, data) => {
    let csv = headers.map(header => header.label).join(',') + '\n'

    data.forEach(row => {
      const values = headers.map(header => row[header.key])
      csv += values.join(',') + '\n'
    })

    return csv
  }
  const downloadCSV = (headers, data) => {
    const csv = createCSV(headers, data)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    const fileName = quiz.name + date.toLocaleString() + '_result.csv'
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const downloadPDF = (url, name) => {
    const link = document.createElement('a')
    link.href = url
    const fileName = `${quiz.name} + ${date.toLocaleString()} + _${name}.pdf`
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  // const generateCsv = async () => {
  //   const headers = [
  //     { label: 'Name', key: 'name' },
  //     { label: 'Email', key: 'email' },
  //     { label: 'FullMark', key: 'fullmark' },
  //     { label: 'MarkSecured', key: 'marksecured' }
  //   ]
  //   const newArrayData = quiz.participants.map(item => {
  //     const filteredData = studentQuiz.filter(studentItem => studentItem.attemptedById === item._id)
  //     return {
  //       name: item.firstName,
  //       email: item.email,
  //       fullmark: quiz.totalScore,
  //       marksecured: (filteredData.length > 0) ? filteredData[0].marksGivenByInstructor : 'NA'

  //     }
  //   })
  //   downloadCSV(headers, newArrayData)
  // }

  const generateCsvDetailed = async () => {
    let questionsPopulated = []
    const res = await makeServerGETRequest('/quiz', { quizId: quiz._id })
    if (res.status === 200) {
      questionsPopulated = res.data.quiz.questions
    } else {
      toast('Unknow error occured')
      return
    }
    // const questions = questionsPopulated.map((item, index) => { return { label: `Q${index + 1}. ` + item.questionContent.replace(/,/g, ''), key: item._id } })
    const questions = questionsPopulated.map((item, index) => { return { label: `Q${index + 1}`, key: item._id } })

    const headers = [
      { label: 'Name', key: 'name' },
      { label: 'Email', key: 'email' },
      ...questions,
      { label: 'Total', key: 'total' }
    ]
    const newArrayData = quiz.participants.map(item => {
      const filteredData = studentQuiz.filter(studentItem => studentItem.attemptedById === item._id)
      const marks = (filteredData.length > 0)
        ? quiz.questions.map((item) => {
          try {
            // here catching the undefiend mark from the response where user autosubmited due to uncomplete attempt of question.
            return { [item]: filteredData[0].instructorResponse.filter((question) => question.questionID === item)[0].markSecured }
          } catch (error) {
            return { [item]: 0 }
          }
        }).reduce((acc, obj) => {
          const key = Object.keys(obj)[0]
          const value = obj[key]
          acc[key] = value
          return acc
        }, {})
        : 'NA'

      return {
        name: item.firstName,
        email: item.email,
        total: (filteredData.length > 0) ? filteredData[0].marksGivenByInstructor : 'NA',
        ...marks
      }
    })
    downloadCSV(headers, newArrayData)
  }

  // const downloadCSVResult = async () => {
  //   if (loading) {
  //     toast('On progress, please wait')
  //     return
  //   }
  //   setLoading(true)
  //   try {
  //     generateCsv()
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   setLoading(false)
  //   console.log('end')
  // }

  const downloadCSVResultDetailed = async () => {
    if (loading) {
      toast('On progress, please wait')
      return
    }
    setLoading(true)
    try {
      generateCsvDetailed()
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
    console.log('end')
  }

  const downloadQuizRubric = async () => {
    setRubricloading(true)
    const res = await makeServerGETRequest(
      '/downloadQuiz/questionDownload',
      {
        quizId: quiz._id
      }
    )
    if (res.status === 200) {
      downloadPDF(res.data.QuestionAnswer, 'QuestionAnswer')
    } else {
      toast('error in fetching data')
    }
    setRubricloading(false)
  }
  const downloadQuizQuestion = async () => {
    setQuestionloading(true)
    const res = await makeServerGETRequest(
      '/downloadQuiz/questionDownload',
      {
        quizId: quiz._id
      }
    )
    if (res.status === 200) {
      downloadPDF(res.data.QuestionOnly, 'QuestionOnly')
    } else {
      toast('error in fetching data')
    }
    setQuestionloading(false)
  }
  return (
    <div className={` ${className} flex flex-col gap-4 pt-5 max-h-full w-[90%] mx-auto`}>
      {/* <LeftMenuItem className={' mx-auto text-app-green '} img={totalScore} text={'Total Score'} subText={quiz.totalScore} /> */}
      {/* <LeftMenuItem className={'my-5 mx-auto'} img={Activity} text={'Average Score'} subText={'averageScore'} />
        <LeftMenuItem className={'my-5 mx-auto'} img={Profile} text={'Average Time Spent'} subText="100min" /> */}
      {/* <LeftMenuItem className={'my-5 mx-auto'} img={TwoUser} text={'Attendees Completed'} subText="95/100" /> */}
      {/* <LeftMenuItem className={' mx-auto text-app-red '} img={Deadline} text={'Deadline'} subText={date.toLocaleString()} />
      <LeftMenuItem className={' mx-auto'} img={Duration} text={'Quiz Duration'} subText={quiz.settings.duration + 'mins'} /> */}
      <TextItem className={' mx-auto '} img={MarksGraph} text={'Total Score'} subText={quiz.totalScore}/>
      <hr />
      <TextItem className={' mx-auto '} img={Calendar} text={'Deadline'} subText={date.toLocaleString()}/>
      <hr />
      <TextItem className={' mx-auto'} img={Time} text={'Quiz Duration'} subText={quiz.settings.duration + 'mins'} />
      <hr/>
      <div>
          {rubricloading
            ? <ReactLoading type="spin" color="#916AF8" className='mx-auto m-4' height={40} width={35} />
            : <ButtonItem text={'Download Quiz (Rubric)'} image={Import} className={' w-full '} onClick={downloadQuizRubric}/>}
      </div>
      <div>
          {questionloading
            ? <ReactLoading type="spin" color="#916AF8" className='mx-auto m-4' height={40} width={35} />
            : <ButtonItem text={'Download Questions'} image={Import} className={' w-full '} onClick={downloadQuizQuestion}/>}
      </div>
      <div>
        {loading
          ? <ReactLoading type="spin" color="#916AF8" className='mx-auto m-4' height={40} width={35} />
          : <ButtonItem text={'Download Result'} image={Import} className={' w-full '} onClick={downloadCSVResultDetailed}/>}
      </div>
      {/* <div onClick={rubricloading ? null : downloadQuizRubric}>
      <LeftMenuItem className={' mx-auto cursor-pointer hover:bg-indigo-100'} img={Download} text={'Download'} subText={'Quiz (Rubric)'} loading={rubricloading}/>
      </div>
      <div onClick={questionloading ? null : downloadQuizQuestion}>
      <LeftMenuItem className={' mx-auto cursor-pointer hover:bg-indigo-100'} img={DownloadTransparent} text={'Download'} subText={'Questions'} loading={questionloading}/>
      </div>
      <div onClick={loading ? null : downloadCSVResult} className='hidden'>
      <LeftMenuItem className={' mx-auto cursor-pointer hover:bg-indigo-100'} img={DownloadTransparent} text={'Download'} subText={'Result'} loading={loading}/>
      </div>
      <div onClick={loading ? null : downloadCSVResultDetailed}>
      <LeftMenuItem className={' mx-auto cursor-pointer hover:bg-indigo-100'} img={DownloadTransparent} text={'Download (New)'} subText={'Detailed Result'} loading={loading}/>
      </div> */}
    </div>
  )
}

function ButtonItem ({ text, onClick, className, disabled, image, imagePosition }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
            // eslint-disable-next-line no-undef
      className={` ${disabled ? 'cursor-not-allowed bg-app-grey' : ' bg-app-primary hover:bg-app-green  '} px-2 h-8 sm:h-12 block rounded-lg text-white ${className} `} >
      <div className='flex flex-row px-0 sm:px-4 justify-between'>
        <img src={image} alt='ic' className={`${imagePosition === 'left' ? 'order-1 mr-2' : 'order-2 ml-2'} h-4/5 aspect-square mr-2 my-auto`}></img>
        <div className={`${imagePosition === 'left' ? 'order-2' : 'order-1'}`}>{text}</div>
      </div>
      </button>
  )
}

function TextItem ({ img, text, subText, className }) {
  return (
    <div className={`flex flex-row w-full bg-white ${className} `}>
      <ImageTag src={img} className="w-8" />
        <div className="ml-4">
        <HeadingMd text={text} className=" text-xs sm:text-sm md:text-base lg:text-lg" />
        <HeadingMd text={subText} className="text-app-primary text-xs sm:text-sm md:text-base lg:text-lg" />
        </div>
    </div>
  )
}
