import { actionTypes } from '../../actionTypes'

const initialState = {
  loading: false,
  firstName: '',
  lastName: '',
  email: '',
  isEmailVerified: false,
  profilePicture: '',
  detailsCompleted: false,
  education: '',
  profession: '',
  departmentName: '',
  language: '',
  isLoggedIn: false,
  error: '',
  userRoleType: 'NA',
  preferedRoleType: 0
  // userRoleType is 1 for student, 0 for instructor

}

export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isLoggedIn: true
      }
    case actionTypes.USER_INFO:
      return {
        ...state,
        ...payload
      }
    case actionTypes.USER_SIGNOUT_SUCCESS:
      return {
        ...initialState
      }
    case actionTypes.USER_EMAIL_VERIFICATION_SUCCESS:
      return { ...state, isEmailVerified: true }
    case actionTypes.USER_ROLE_TYPE_UPDATE:
      return { ...state, ...payload }

    default:
      return state
  }
}
