import { actionTypes } from '../../actionTypes'

const initialState = {
  subQues: 5,
  objQues: 5,
  urls: [],
  quizId: '',
  quizName: '',
  questions: [],
  settings: {},
  createdQuiz: {},
  quizTopics: [],
  totalScore: 0
}

export const quizReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.UPLOAD_FILE:
      return { ...state, urls: [...state.urls, payload.url] }
    case actionTypes.DELETE_FILE:
      return { ...state, urls: state.urls.filter(url => url !== payload.url) }
    case actionTypes.REMOVE_QUIZ:
      return { ...state, ...initialState }
    case actionTypes.STORE_QUIZ:
      return { ...state, ...payload, totalScore: payload.questions.reduce((acc, curr) => acc + curr.score, 0) }
    case actionTypes.SAVE_QUIZ:
      return { ...state, quizName: payload.quizName, questions: payload.questions, settings: payload.settings, urls: payload.urls, quizTopics: payload.quizTopics }
    case actionTypes.UPDATE_QUIZ_NAME:
      return { ...state, quizName: payload.name }
    case actionTypes.ADD_QUESTION:
      return { ...state, questions: [payload.newQuestion, ...state.questions] }
    case actionTypes.REMOVE_QUESTION:
      return { ...state, questions: state.questions.filter(question => question._id !== payload.question._id) }
    case actionTypes.UPDATE_QUESTION:
      return {
        ...state,
        questions: state.questions.map(question => {
          if (question._id === payload.question._id) {
            return payload.question
          }
          return question
        })
      }
    case actionTypes.ADD_TOPIC_TO_QUIZ:
      return {
        ...state,
        quizTopics: [...state.quizTopics, payload.topic]
      }
    case actionTypes.REMOVE_TOPIC_FROM_QUIZ:
      return {
        ...state,
        quizTopics: [
          ...state.quizTopics.filter((topic) => topic !== payload.topic)
        ]
      }
    case actionTypes.CLEAN_DELETED_QUESTIONS:
      return { ...state, questions: state.questions.filter(question => !question.isDelted) }
    case actionTypes.CLEAN_NEWLY_CREATED_QUESTIONS:
      return { ...state, questions: state.questions.filter(question => { question.isNewlyCreated = false; return question }) }
    case actionTypes.UPDATE_QUESTION_COUNT:
      return { ...state, subQues: payload.subQues, objQues: payload.objQues }
    case actionTypes.UPDATE_TOTAL_SCORE:
      return { ...state, totalScore: state.questions.filter(question => !question.isDeleted).reduce((acc, curr) => acc + curr.score, 0) }
    default:
      return state
  }
}
